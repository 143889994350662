import { useGetProductsQuery } from '../slices/productsApiSlice.js';
import Message from '../components/Message/Message.jsx';
import { useParams } from 'react-router-dom';
import DataTable from '../components/DataTable/DataTable.jsx';
import ToolsPanel from '../components/ToolsPanel/ToolsPanel.jsx';
import { LinearProgress } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { addToCart, removeFromCart } from '../slices/cartSlice.js';
import HistoryChart from '../components/HistoryChart/HistoryChart.jsx';
import BottomCanvas from '../components/BottomCanvas/BottomCanvas.jsx';
import { colsWidth } from '../utils/authUtils.js';
import ModalFrame from '../components/ModalFrame/ModalFrame.jsx';
// import ProductCard from '../components/ProductCard/depricated_ProductCard.jsx';
import DataGrid from '../components/DataGrid/DataGrid.jsx';
import { FaCogs, FaHome, FaTable } from 'react-icons/fa';
import {
  existNewsSet,
  setCurrentGroup,
  setCurrentProvider,
  setGridSettingMode,
  setSelectedProducts,
  setViewMode,
} from '../slices/authSlice.js';
import { Container } from 'react-bootstrap';
import CustomerToolbar from '../components/DataTableToolbar/CustomerToolbar/CustomerToolbar.jsx';
// import { useGridApiRef } from '@mui/x-data-grid';
import { useUpdateCartItemMutation } from '../slices/cartsApiSlice.js';
import { toast } from 'react-toastify';
import ProductCard from '../components/ProductCard/ProductCard.jsx';
// import ZoomableImage from '../components/Image/ZoomableImage.jsx';

const CustomerOrderScreen = () => {
  const { groupId, pageNumber, keyword } = useParams();
  const dispatch = useDispatch();
  // const apiDataGridRef = useGridApiRef();
  const [oneClickMode, setOneClickMode] = useState(false);
  const [showHistory, setShowHistory] = useState(false);
  const [showProductCard, setShowProductCard] = useState(false);
  const { cartItems, cartParams } = useSelector((state) => state.cart);

  const {
    gridSettingMode,
    currentProvider,
    currentGroup,
    currentBreakpoint,
    currentCustomer,
    showUnitColumn,
    showPrice,
    showReturnColumn,
    showMultipleColumn,
    viewMode,
    pov,
    theme,
  } = useSelector((state) => state.auth);

  const [updateCartItemOnServer] = useUpdateCartItemMutation();

  useEffect(() => {
    if (['xs', 'sm', 'md'].includes(currentBreakpoint)) {
      setOneClickMode(true);
    }
  }, [currentBreakpoint]);

  const setRowSelectionModel = (data) => {
    dispatch(setSelectedProducts(data));
  };

  const queryData = {
    pov,
    groupId,
    providerId: currentProvider?._id,
    customerId: currentCustomer?._id,
    keyword: keyword,
    pageNumber: pageNumber,
  };

  const {
    data,
    refetch,
    isLoading: loadingProducts,
    isError,
    error,
  } = useGetProductsQuery(queryData);

  // console.log(data);

  useEffect(() => {
    if (data?.group) {
      // if (!currentGroup && groupId && data?.group) {
      dispatch(setCurrentGroup(data.group));
    }

    if (data?.existNews) {
      dispatch(existNewsSet(currentProvider?._id));
    }

    try {
      if (
        data?.options &&
        'canReturn' in data?.options &&
        data?.options?.canReturn !== currentProvider?.canReturn
      ) {
        dispatch(
          setCurrentProvider({
            ...currentProvider,
            canReturn: data?.options?.canReturn,
          })
        );
      }
    } catch (error) {}
    // eslint-disable-next-line
  }, [data]);

  const getValues = (productId) => {
    const exist = cartItems.find(
      (x) =>
        x.providerId === currentProvider._id &&
        x.customerId === currentCustomer._id &&
        x.id === productId
    );
    const result = { qty: exist?.qty || '', ret: exist?.ret || '' };

    return result;
  };

  let rows = [];

  if (Array.isArray(data?.rows) && data.rows.length > 0) {
    if (groupId === 'all') {
      for (let i = 0; i < data.rows.length; i++) {
        const lev1 = data.rows[i];
        rows.push({
          id: lev1?._id || `group_${i}`,
          name: lev1?.name,
          noteditable: true,
        });
        if (Array.isArray(lev1?.children)) {
          for (let j = 0; j < lev1?.children.length; j++) {
            const item = lev1?.children[j];
            let name = item?.shortName || item?.name;

            if (!showUnitColumn && !showMultipleColumn) {
              name = `${name} ( ${item?.multiple} ${item?.unit} )`;
            } else if (!showUnitColumn && showMultipleColumn) {
              name = `${name} ( ${item?.unit} )`;
            } else if (showUnitColumn && !showMultipleColumn) {
              name = `${name} ( ${item?.multiple} в уп.)`;
            }

            rows.push({
              name,
              unit: item?.unit,
              id: item?._id || `unit_${i + '-' + j}`,
              parentCode: item?.parentCode,
              image: item?.image,
              description: item?.description,
              code: item?.code,
              price: item?.price,
              multiple: item?.multiple,
              ...getValues(item?._id),
            });
          }
        }
      }
    } else {
      for (let index = 0; index < data.rows.length; index++) {
        const item = data.rows[index];

        let multiple = item?.multiple ? item?.multiple : '';
        let name = item?.shortName || item?.name;
        if (!showUnitColumn && !showMultipleColumn) {
          name = `${name} ( ${multiple} ${item?.unit} )`;
        } else if (!showUnitColumn && showMultipleColumn) {
          name = `${name} ( ${item?.unit} )`;
        } else if (showUnitColumn && !showMultipleColumn) {
          name = `${name}  ${multiple ? '(' + multiple + ' в уп.)' : ''}`;
        }

        rows.push({
          name,
          unit: item?.unit,
          id: item._id,
          parentCode: item?.parentCode,
          image: item?.image,
          description: item?.description,
          code: item?.code,
          price: item?.price,
          multiple: item?.multiple,
          ...getValues(item._id),
          // ...item.prices.reduce((result, item) => {
          //   result['price'] = item['value'];
          //   return result;
          //}, {}),
        });
      }
    }
  }

  //определяем находимся ли мы сейчас в режиме редактирования заявки
  let editedOrderId = '';
  const key = `${currentCustomer?._id}-${currentProvider?._id}`;
  if (cartParams[key]) {
    const { orderId } = cartParams[key];
    if (orderId) editedOrderId = orderId;
  }

  const columns = [
    { field: 'id', headerName: '№', width: 50, hide: true },
    { field: 'noteditable', headerName: '№', width: 50, hide: true },
    { field: 'code', headerName: 'Код1С', width: 150, hide: true },
    { field: 'image', headerName: '№', width: 50, hide: true },
    { field: 'description', headerName: '№', width: 50, hide: true },
    {
      field: 'name',
      headerName: 'Название',
      flex: 1,
      editable: false,
      cellClassName: 'grid-cell-name',
    },
    {
      field: 'parentCode',
      headerName: 'Код группы',
      width: 100,
      editable: false,
      sortable: false,
    },
    {
      field: 'multiple',
      headerName: 'в уп',
      width: 50,
      editable: false,
      sortable: false,
    },
    {
      field: 'price',
      headerName: 'Цена',
      width: 80,
      editable: false,
      headerClassName: 'grid-header-price',
      cellClassName: 'grid-cell-price',
      sortable: false,
    },
    {
      field: 'unit',
      headerName: 'Ед.',
      width: colsWidth.unit,
      editable: false,
      sortable: false,
      align: 'left',
      headerAlign: 'left',
      headerClassName: 'grid-header-unit',
      cellClassName: 'grid-cell-unit',
    },
    {
      field: 'qty',
      type: 'number',
      headerName: 'Заявка',
      width: colsWidth.qty,
      editable: true,
      //renderEditCell: renderQtyEditInputCell,
      headerAlign: 'left',
      headerClassName: 'grid-header-order',
      cellClassName: 'grid-cell-order',
    },
  ];

  if (currentProvider?.canReturn) {
    columns.push({
      field: 'ret',
      type: 'number',
      headerName: 'Возврат',
      width: colsWidth.qty,
      editable: true,
      //sortable: false,
      headerAlign: 'left',
      headerClassName: 'grid-header-order',
      cellClassName: 'grid-cell-return',
    });
  }

  if (['xl', 'xxl'].includes(currentBreakpoint)) {
    columns.push({
      field: 'info',
      type: 'string',
      headerName: 'Информация',
      flex: 0.5,
      editable: false,
      headerAlign: 'left',
      // headerClassName: 'grid-header-order',
      // cellClassName: 'grid-cell-return',
    });
  }

  const visibilityColumns = {
    parentCode: false,
    promo: false,
    image: false,
    description: false,
    code: false,
    noteditable: false,
    price: showPrice,
    ret: showReturnColumn,
    unit: showUnitColumn,
    multiple: showMultipleColumn,
  };

  const handleOnSelectCell = (data) => {
    // if (oneClickMode) {
    //   try {
    //     if (data.field === 'qty' || data.field === 'ret') {
    //       try {
    //         apiDataGridRef?.current?.startCellEditMode({
    //           id: data.row.id,
    //           field: data.field,
    //         });
    //       } catch (error) {}
    //     }
    //   } catch (error) {}
    // }

    if (data.field === 'name' && data?.row?.image) {
      //setShowHistory({ id: data?.row?.id, name: data?.row?.name });
      setShowProductCard({
        id: data?.row?.id,
        name: data?.row?.name,
        ...data?.row,
      });
    }
  };
  const handleOnSelectRow = (rows) => {};

  const updateOnServer = async (data) => {
    try {
      await updateCartItemOnServer({ ...data });
    } catch (error) {
      toast.warn(error?.data?.message || error?.message);
    }
  };

  const handleOnUpdateRow = (data) => {
    // console.log(data);

    const price = data.newRow?.price || 0;
    const multiple = data.newRow?.multiple || 0;
    let qty = data.newRow?.qty || 0;
    if (qty !== 0) {
      qty = qty < 0 ? qty * -1 : qty; //Math.abs(qty)
      // if (multiple > 0) {
      //   if (qty / multiple >= 0) {
      //     qty = Math.floor(qty / multiple) * multiple;
      //   } else {
      //     qty = Math.ceil(qty / multiple) * multiple;
      //   }
      //   qty = multiple > qty ? multiple : qty;
      // }
    }

    let ret = data.newRow?.ret || 0;
    if (ret !== 0) {
      ret = ret < 0 ? ret * -1 : ret; //Math.abs(ret)
    }

    if (qty === 0 && ret === 0) {
      dispatch(
        removeFromCart({
          id: data.newRow.id,
          providerId: currentProvider._id,
          customerId: currentCustomer._id,
        })
      );
    } else {
      dispatch(
        addToCart({
          ...data.newRow,
          _id: data.newRow.id,
          providerName: currentProvider.name,
          providerId: currentProvider._id,
          customerName: currentCustomer.name,
          customerId: currentCustomer._id,
          qty,
          ret,
        })
      );
    }

    if (editedOrderId === '') {
      updateOnServer({
        providerId: currentProvider._id,
        customerId: currentCustomer._id,
        productId: data.newRow.id,
        qty,
        ret,
        price,
        multiple,
      });
    }
  };

  const handleClickShowStuff = () => {
    dispatch(setGridSettingMode(!gridSettingMode));
    if (!gridSettingMode) {
      setRowSelectionModel([]);
    }
  };

  const handleOnHideModalProductCard = () => {
    setShowProductCard(false);
  };

  const handleSwitchViewMode = () => {
    // console.log(viewMode);
    dispatch(setViewMode(viewMode === 'table' ? 'grid' : 'table'));
  };

  let buttons = [];
  buttons.push({
    className: `bg-transparent ${
      gridSettingMode && 'border-success  border-2'
    }`,
    name: 'Инструменты',
    type: 'component',
    action: handleClickShowStuff,
    component: (props) => {
      return <FaCogs size={22} color={theme === 'dark' ? 'white' : 'black'} />;
    },
  });

  buttons.push({
    className: `bg-transparent ${
      viewMode !== 'table' && 'border-success  border-2'
    }`,
    name: 'Таблица',
    type: 'component',
    action: handleSwitchViewMode,
    component: (props) => {
      return <FaTable size={22} color={theme === 'dark' ? 'white' : 'black'} />;
    },
  });

  return (
    <>
      <ToolsPanel
        backURL={'/customer'}
        backIcon={<FaHome size={22} />}
        buttons={buttons}
        title={
          (keyword && `Поиск по фразе "${keyword}"`) ||
          `${currentGroup?.name} ` ||
          ''
        }
      />
      {loadingProducts ? (
        <LinearProgress />
      ) : isError ? (
        <Message> {error?.data?.message || error.error} </Message>
      ) : (
        <>
          <Container>
            {Array.isArray(rows) && (
              <>
                {viewMode === 'table' ? (
                  <span>
                    {gridSettingMode && <CustomerToolbar refetch={refetch} />}
                    <DataTable
                      // addMargin={addMarginWhenCellEdit ? 45 : 0}
                      // onCellEditStop={onCellEditStop}
                      // onCellEditStart={onCellEditStart}
                      // apiDataGridRef={apiDataGridRef}

                      mobileView={oneClickMode}
                      rows={rows}
                      columns={columns}
                      columnVisible={{ id: false, ...visibilityColumns }}
                      onSelectRow={handleOnSelectRow}
                      onUpdateRow={handleOnUpdateRow}
                      onSelectCell={handleOnSelectCell}
                      // setRowSelectionModel={setRowSelectionModel}
                      // rowSelectionModel={selectedProducts}
                      // checkboxSelection={gridSettingMode}
                    />
                  </span>
                ) : (
                  <DataGrid
                    rows={rows}
                    refetch={refetch}
                    canReturn={currentProvider?.canReturn}
                  />
                )}
              </>
            )}
          </Container>
          {/* {showNumpad && <VirtualNumpad onPressKey={onPressKey} />} */}
          <BottomCanvas onHide={setShowHistory} item={showHistory}>
            <HistoryChart item={showHistory} />
          </BottomCanvas>

          <ModalFrame
            size='sm'
            show={!!showProductCard}
            onHide={handleOnHideModalProductCard}
            header={showProductCard?.name}
          >
            <ProductCard
              item={showProductCard}
              onHide={handleOnHideModalProductCard}
              theme={theme}
              onlyImage={true}
              showPrice={true}
              zoom={false}
              showDescription={true}
            />
          </ModalFrame>
        </>
      )}
    </>
  );
};

export default CustomerOrderScreen;
