import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Accordion, Col, Form, Row } from 'react-bootstrap';
import SelectUnit from '../../SelectUnit/SelectUnit';

const ProviderOrdersToolbar = ({
  refetch,
  date1,
  date2,
  setDate1,
  setDate2,
  selectedFilters,
  setSelectedFilters,
}) => {
  const { theme, pov, gridSettingMode, currentProvider } = useSelector(
    (state) => state.auth
  );
  //   const [selectedOption, setSelectedOption] = useState('');

  // const [filter, setFilter] = useState();
  // const [dateFilterState, setDateFilterState] = useState(false);
  // const [group, setGroup] = useState();
  const [modalTitle, setModalTitle] = useState('');

  const [showSelectWindow, setShowSelectWindow] = useState(false);

  // const [hideProducts] = useHideProductsMutation();
  // const [moveToGroup] = useMoveToGroupMutation();

  // const handleHideSelected = async () => {
  //   // if (selectedProducts) {
  //   //   try {
  //   //     await hideProducts({
  //   //       ids: selectedProducts,
  //   //       providerId: currentProvider?._id,
  //   //       status: true,
  //   //     }).unwrap();
  //   //     refetch();
  //   //   } catch (error) {}
  //   // }
  // };

  // const handleSetVisibleSelected = async () => {
  //   // if (selectedProducts) {
  //   //   try {
  //   //     await hideProducts({
  //   //       ids: selectedProducts,
  //   //       providerId: currentProvider?._id,
  //   //       status: false,
  //   //     }).unwrap();
  //   //     refetch();
  //   //   } catch (error) {}
  //   // }
  // };
  // const handleCreateNewProduct = () => {};
  // const handleCreateNewGroup = () => {};

  const handleSelectGroup = (item) => {
    // console.log(item);
    // setGroup(item);
    // setShowSelectWindow(false);
  };

  const selectUnit = (type) => {
    setModalTitle(type);
    setShowSelectWindow(true);
  };

  // const handleMoveToGroup = async () => {
  //   // if (
  //   //   Array.isArray(selectedProducts) &&
  //   //   selectedProducts.length > 0 &&
  //   //   group?._id
  //   // ) {
  //   //   try {
  //   //     const result = await moveToGroup({
  //   //       ids: selectedProducts,
  //   //       providerId: currentProvider?._id,
  //   //       groupId: group._id,
  //   //     }).unwrap();
  //   //     console.log(result);
  //   //     refetch();
  //   //   } catch (error) {
  //   //     toast.error(error?.data?.message || error.error);
  //   //   }
  //   // }
  // };

  // const Filters = ({ variant, date1, date2, func, type }) => {
  //   if (variant === 1 || variant === '1') {
  //     return (
  //       <Stack direction='horizontal' gap={1} className='mx-0 px-0'>
  //         <MobileDatePicker
  //           autoOk='true'
  //           disableFuture={true}
  //           disableCloseOnSelect={true}
  //           value={date1}
  //           onChange={(newValue) => setDate1(newValue)}
  //         />
  //         <MobileDatePicker
  //           disableFuture={true}
  //           disableCloseOnSelect={true}
  //           value={date2}
  //           onChange={(newValue) => setDate2(newValue)}
  //         />
  //         <Button onClick={() => refetch()}>OK</Button>
  //       </Stack>
  //     );
  //   } else if (variant === 2 || variant === '2') {
  //     return (
  //       <Stack direction='horizontal' gap={1} className='mx-0 px-0'>
  //         <Col xs={12} className='mx-0 px-0'>
  //           <Form.Group controlId='group' className='my-2'>
  //             <Form.Control
  //               isInvalid={!group}
  //               isValid={!!group}
  //               style={themeStyle}
  //               type='text'
  //               placeholder='Выберите покупателя'
  //               value={group?.name || ''}
  //               onClick={() => func(type)}
  //               //onChange={(e) => onChangeHandler}
  //               readOnly={true}
  //             ></Form.Control>
  //           </Form.Group>
  //         </Col>
  //       </Stack>
  //     );
  //   }
  // };

  // //ВНИМАНИЕ В КОДЕ ЕСТЬ ПРИВЯЗКА к ИНДЕКСАМ МАССИВА
  // const filters = [
  //   {
  //     title: 'Скрыть',
  //     name: 'Выбрать фильтр',
  //   },
  //   {
  //     title: 'Скрыть',
  //     name: 'По датам',
  //   },
  //   {
  //     title: 'Скрыть',
  //     name: 'По покупателю',
  //   },
  //   {
  //     title: 'Вернуть',
  //     name: 'По автору',
  //   },
  //   {
  //     title: 'Переместить',
  //     name: 'По менеджеру',
  //   },
  // ];

  if (!pov || pov !== 'provider') {
    return <></>;
  }

  if (!gridSettingMode) {
    return <></>;
  }

  const themeStyle = {
    backgroundColor: theme === 'dark' ? '#212529' : 'white',
    borderColor: theme === 'dark' ? '#212529' : '#b5c0c1',
    color: theme === 'dark' ? 'white' : 'black',
  };

  // const handleSelectFilter = (value) => {
  //   if (!selectedFilters.includes(value)) {
  //     setSelectedFilters([...selectedFilters, value]);
  //   }
  // };

  // const types = ['', '', 'Покупатели'];
  // const functions = ['', '', selectUnit];

  return (
    <>
      <Accordion className='mb-2' defaultActiveKey='0'>
        <Accordion.Item
          eventKey='0'
          style={{ backgroundColor: 'none !important' }}
        >
          <Accordion.Header>Фильтры</Accordion.Header>
          <Accordion.Body>
            <Form.Group controlId='customer' className='my-0' as={Row}>
              <Form.Label column sm='4' className='pb-0'>
                Отбор по покупателю
              </Form.Label>
              <Col sm='8'>
                <Form.Control
                  disabled={true}
                  style={themeStyle}
                  type='text'
                  placeholder='Функция в разработке...'
                  value={''}
                  onClick={() => selectUnit('Покупатели')}
                  readOnly={true}
                ></Form.Control>
              </Col>
            </Form.Group>
            <Form.Group controlId='customer' className='my-0' as={Row}>
              <Form.Label column sm='4' className='pb-0'>
                Отбор по менеджеру
              </Form.Label>
              <Col sm='8'>
                <Form.Control
                  disabled={true}
                  style={themeStyle}
                  type='text'
                  placeholder='Функция в разработке...'
                  value={''}
                  onClick={() => selectUnit('Покупатели')}
                  readOnly={true}
                ></Form.Control>
              </Col>
            </Form.Group>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      {/* <Row>
          {showFilterSelect && (
            <Stack direction='horizontal' gap={1} className='mx-0 px-0'>
              <Col xs={7}>
                <Form.Select
                  style={themeStyle}
                  size='sm'
                  aria-label='Filter select'
                  className='my-1 mx-0'
                  value={filter}
                  onChange={(e) => handleSelectFilter(e.target.value)}
                >
                  {filters &&
                    filters.map((item, index) => (
                      <option key={index} value={index}>
                        {item.name}
                      </option>
                    ))}
                </Form.Select>
              </Col>
            </Stack>
          )}
        </Row>
        <Row>
          {selectedFilters &&
            selectedFilters.map((item, index) => (
              <Filters
                key={index}
                variant={item}
                date1={date1}
                date2={date2}
                func={functions[item]}
                type={types[item]}
              />
            ))}
        </Row> */}

      <SelectUnit
        show={showSelectWindow}
        setShow={setShowSelectWindow}
        setSelected={handleSelectGroup}
        //rows={unitRows}
        payload={{ providerId: currentProvider?._id, pov }}
        title={modalTitle}
        owner={currentProvider?._id}
      />
    </>
  );
};

export default ProviderOrdersToolbar;
