import React, { useState } from 'react';
import { Col, Image, Row } from 'react-bootstrap';
import ZoomableImage from './ZoomableImage';

const ImagesStack = ({ rows }) => {
  const [clickedImage, setClickedImage] = useState('');

  const handleClickOnImage = (img) => {
    setClickedImage(img);
  };

  if (!!clickedImage) {
    return (
      <ZoomableImage
        zoom={true}
        className={'w-100'}
        rows={rows}
        startImage={clickedImage}
        setClickedImage={setClickedImage}
      />
    );
  } else {
    return (
      <>
        {rows?.map((img, index) => (
          <Col key={index} style={{ height: '100%' }}>
            <Image
              role='button'
              src={img}
              onClick={() => handleClickOnImage(img)}
              alt='photo'
              className={'w-100'}
            ></Image>
          </Col>
        ))}
      </>
    );
  }
};

export default ImagesStack;
