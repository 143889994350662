import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { useGetProviderProductMenuQuery } from '../../../slices/providersApiSlice';
import { Alert, Chip, Collapse, LinearProgress, Toolbar } from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { setCurrentGroup, setGridSettingMode } from '../../../slices/authSlice';
import { Button, Col, Container, Row, Stack } from 'react-bootstrap';
// import { FaEye, FaList, FaStar, FaSyncAlt } from 'react-icons/fa';
import { Badge } from '@mui/material';
// import Skeleton from '@mui/material/Skeleton';

const ProductGroupMenu = ({ handleDrawerClose, pathname }) => {
  const { groupId } = useParams();
  // console.log('groupId', groupId);
  const { search } = useLocation();
  const sp = new URLSearchParams(search);
  const back = sp.get('back') || '';

  const [openId, setOpenId] = useState('');
  const [selectedId, setSelectedId] = useState('');
  const [openedGroups, setOpenedGroups] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (groupId) {
      setSelectedId(groupId);
      setOpenId(groupId);
    }
  }, [groupId]);

  const { cartItems } = useSelector((state) => state.cart);
  const { currentProvider, currentCustomer, theme, pov, existNews } =
    useSelector((state) => state.auth);

  const { data, isLoading, isError, error } = useGetProviderProductMenuQuery({
    providerId: currentProvider?._id,
    pov,
    customerId: currentCustomer?._id,
  });

  // console.log('data', data);

  const handleGroupClick = (id) => {
    if (openedGroups.includes(id)) {
      setOpenedGroups(openedGroups.filter((item) => item !== id));
    } else {
      setOpenedGroups([...openedGroups, id]);
    }
  };

  let cartStat = [];
  if (cartItems && pov === 'customer') {
    try {
      cartItems.forEach((element) => {
        const customerId = element.customerId;
        const providerId = element.providerId;
        if (
          currentProvider?._id === providerId &&
          currentCustomer?._id === customerId
        ) {
          if (!cartStat[element.parentCode]) {
            cartStat[element.parentCode] = [];
          }
          cartStat[element.parentCode].push(element?._id);
        }
      });
    } catch (error) {
      console.log(error);
    }
  }

  const handleClick = (item, children) => {
    if (item._id === openId) {
      setOpenId('');
    } else {
      setOpenId(item._id);
    }
    setSelectedId(item._id);
    dispatch(setCurrentGroup(item));
    if (children && children.length) {
      handleGroupClick(item._id);
      //не реагируем, а лишь раскрываем подменю
    } else {
      if (pov === 'provider') {
        navigate(`/provider/products/${item._id}`);
      } else {
        navigate(`/customer/order/${item._id}`);
      }
      handleDrawerClose();
      dispatch(setGridSettingMode(false));
    }
  };

  const handleClickSublevel = (item) => {
    setSelectedId(item._id);
    dispatch(setCurrentGroup(item));
    if (pov === 'provider') {
      navigate(`/provider/products/${item._id}`);
    } else {
      navigate(`/customer/order/${item._id}`);
    }
    handleDrawerClose();
  };

  const handleClickNews = () => {
    const url = `/news/${currentProvider?._id}`;
    const redirect = back ? `?redirect=${back}` : '';
    navigate(url.concat(redirect));

    handleDrawerClose();
  };
  const handleClickAbout = () => {
    navigate(`/customer/about/provider/${currentProvider?._id}`);
    handleDrawerClose();
  };

  // console.log('cartStat', cartStat);
  // console.log(existNews.includes(currentProvider?._id));
  const ProviderAppBar = () => {
    const news = existNews?.includes(currentProvider?._id);
    return (
      <Toolbar className='mx-1 px-0 mobileMenu-toolbar'>
        {pov === 'customer' && currentProvider?._id && (
          <>
            <Container>
              <Row className='p-0 g-2 justify-content-between align-items-center'>
                <Col className='p-0 d-grid'>
                  <Button
                    onClick={handleClickNews}
                    className={`border-${
                      pathname.includes('/news/') ? 'warning' : 'dark'
                    }-subtle ms-1 text-secondary`}
                    as='div'
                    variant={` bg-transparent text-primary`}
                    style={{ border: '1px solid #81c784' }}
                  >
                    {news ? (
                      <Badge
                        badgeContent={' '}
                        // variant='dot'
                        color='error'
                      >
                        {/* <Skeleton
                          variant='rectangular'
                          className='rounded px-1'
                        > */}
                        Новости
                        {/* </Skeleton> */}
                      </Badge>
                    ) : (
                      'Новости'
                    )}
                  </Button>
                </Col>
                <Col className='p-0  d-grid'>
                  <Button
                    onClick={handleClickAbout}
                    className={`border-${
                      pathname.includes('/customer/about/provider')
                        ? 'warning'
                        : 'dark'
                    }-subtle ms-1 text-secondary`}
                    as='div'
                    variant={` bg-transparent text-primary`}
                    // style={{ border: '1px solid #81c784' }}
                  >
                    Инфо
                  </Button>
                </Col>
              </Row>
            </Container>
            <Divider />
          </>
        )}
        {pov === 'provider' && currentProvider?._id && (
          <Stack direction='horizontal' gap={1} className='mx-auto'>
            {currentProvider?.role === 'a' && (
              <>
                <div
                  onClick={() => navigate(`/provider/groups/`)}
                  className='m-2 text-muted text-decoration-underline'
                  role='button'
                >
                  Редактор меню
                </div>
              </>
            )}
            {/* <span role='button' onClick={() => refetch()} className=''>
              <FaSyncAlt size={28} />
            </span> */}
          </Stack>
        )}
      </Toolbar>
    );
  };

  const BadgeCount = ({ code, count }) => {
    // console.log(code);
    if (count) {
      // return <Badge color='info' badgeContent={count} />;
      return (
        <Chip
          label={count}
          size='small'
          color={pov === 'customer' ? 'primary' : 'success'}
          variant='outlined'
        />
      );
    }
    return (
      <>
        {cartStat && Array.isArray(cartStat) && cartStat[code] && (
          // <Badge color='info' badgeContent={cartStat[code].length} />
          <Chip
            label={cartStat[code]?.length}
            size='small'
            color={pov === 'customer' ? 'primary' : 'success'}
            variant='outlined'
          />
        )}
      </>
    );
  };

  return (
    <>
      {isLoading ? (
        <LinearProgress />
      ) : isError ? (
        <>
          {currentProvider && (
            <Alert
              className='text-wrap'
              variant={theme === 'dark' ? 'outlined' : 'filled'}
              severity='warning'
              sx={{ display: 'block', mt: 2 }}
            >
              {error?.data?.message || error?.message || error.error}
            </Alert>
          )}
        </>
      ) : (
        <>
          <ProviderAppBar />
          <List
            sx={{
              '& .Mui-selected': {
                backgroundColor:
                  pov === 'customer'
                    ? '#1976d261 !important'
                    : '#2fd21961 !important',
              },
            }}
          >
            {pov === 'customer' && (
              <ListItemButton
                sx={{ borderLeftWidth: '3px !important' }}
                onClick={() => handleClick({ _id: 'all', name: 'Все' })}
                selected={selectedId === 'all'}
              >
                <ListItemText primary={'Все'} />
              </ListItemButton>
            )}
            {Array.isArray(data?.rows) &&
              data.rows.map((group) => (
                <span key={group._id}>
                  <ListItemButton
                    sx={{ borderLeftWidth: '3px !important' }}
                    className={
                      openId === group._id
                        ? pov === 'customer'
                          ? 'border-info border-start '
                          : 'border-success border-start '
                        : ''
                    }
                    onClick={() => handleClick(group, group.children)}
                    selected={selectedId === group._id}
                  >
                    <ListItemText primary={group.name} />
                    {Array.isArray(group?.children) &&
                    group.children.length > 0 ? (
                      openedGroups.includes(group._id) ? (
                        <ExpandLess />
                      ) : (
                        <ExpandMore />
                      )
                    ) : (
                      <BadgeCount code={group?._id} count={group?.count} />
                    )}
                  </ListItemButton>

                  {Array.isArray(group?.children) &&
                    group.children.length > 0 && (
                      <Collapse
                        className={
                          pov === 'customer'
                            ? 'border-info border-start border-bottom'
                            : 'border-success border-start border-bottom'
                        }
                        sx={{ borderLeftWidth: '3px !important' }}
                        // in={openId === group._id}
                        in={openedGroups.includes(group._id)}
                        timeout='auto'
                        unmountOnExit
                      >
                        <List component='div' disablePadding>
                          {group.children.map((sub) => (
                            <ListItemButton
                              sx={{ pl: 2 }}
                              key={sub._id}
                              onClick={() => handleClickSublevel(sub)}
                              selected={selectedId === sub._id}
                            >
                              <ListItemText primary={sub.name} />
                              <BadgeCount code={sub?._id} count={sub?.count} />
                            </ListItemButton>
                          ))}
                        </List>
                      </Collapse>
                    )}
                </span>
              ))}
          </List>
          <Divider />
        </>
      )}
    </>
  );
};

export default ProductGroupMenu;
