import { CONTRACTS_URL } from '../constans';
import { apiSlice } from './apiSlice'

export const contractsApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getContracts: builder.query({
			query: (data) => ({
				url: CONTRACTS_URL,
				params: {
					providerId: data?.providerId,
					customerId: data?.customerId,
					keyword: data?.keyword,
					regionId: data?.regionId,
					groupBy: data?.groupBy,
				},
			}),
			providesTags: ['Contract'],
			keepUnusedDataFor: 5
		}),
		getContractDetails: builder.query({
			query: (data) => ({
				url: `${CONTRACTS_URL}/${data.contractId}`,
				params: {
					providerId: data?.providerId,
					customerId: data?.customerId,
				}
			}),
			keepUnusedDataFor: 5,
			invalidatesTags: ['Contract']
		}),
		checkExistContract: builder.mutation({
			query: (data) => ({
				url: `${CONTRACTS_URL}/exist`,
				method: 'POST',
				body: { ...data }
			}),
			invalidatesTags: ['Contract'] //очистить ранее созданный кеш
		}),
		checkContractAllowed: builder.mutation({
			query: (data) => ({
				url: `${CONTRACTS_URL}/allowed`,
				method: 'POST',
				body: {
					customerId: data?.customerId,
					providerId: data?.providerId
				}
			}),
		}),
		createContract: builder.mutation({
			query: (data) => ({
				url: `${CONTRACTS_URL}`,
				method: 'POST',
				body: { ...data }
			}),
			invalidatesTags: ['Contract', 'Regions'] //очистить ранее созданный кеш
		}),
		updateContract: builder.mutation({
			query: (data) => ({
				url: `${CONTRACTS_URL}/${data._id}`,
				method: 'PUT',
				body: data
			}),
			invalidatesTags: ['Contract', 'Regions'] //очистить ранее созданный кеш
		}),
		deleteContract: builder.mutation({
			query: (data) => ({
				url: `${CONTRACTS_URL}/delete`,
				method: 'POST',
				params: {
					contractId: data?.contractId,
					providerId: data?.providerId,
					customerId: data?.customerId
				}
			}),
			invalidatesTags: ['Contract', 'Regions'] //очистить ранее созданный кеш
		}),
	})
})

export const {
	useCheckContractAllowedMutation,
	useCheckExistContractMutation,
	useCreateContractMutation,
	useDeleteContractMutation,
	useGetContractDetailsQuery,
	useGetContractsQuery,
	useUpdateContractMutation
} = contractsApiSlice;