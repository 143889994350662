import { DRAWER_WIDTH } from '../screens/MuiScreen';

export const colsWidth = { price: 40, unit: 40, qty: 62 };

export const updateState = (state) => {

	//state.columnNameWidth = 0;


	const innerWidth = window.innerWidth;
	//console.log('updateState.innerWidth', innerWidth);
	let calculated = innerWidth;// > 1000 ? 500 : innerWidth;
	//console.log('updateState.calculated 0', calculated);
	if (innerWidth < 1500) {
		calculated -= 25;
		//console.log('updateState.calculated 1', calculated);
		calculated -= colsWidth.qty;
		//console.log('updateState.calculated 2', calculated);
		if (state.leftMenuBinded) {
			calculated -= DRAWER_WIDTH + 48; // добавляем отступы 48
		}
		//console.log('updateState.calculated 3', calculated);
		if (state.showPrice) {
			calculated -= colsWidth.price;
		} else {
			calculated += 18;
		}
		//console.log('updateState.calculated 4', calculated);
		if (state.showReturnColumn) {
			calculated -= colsWidth.qty;
		}
		//console.log('updateState.calculated 5', calculated);
		if (state.showUnitColumn) {
			calculated -= colsWidth.unit;
		} else {
			calculated = calculated + 10;
		}
		//console.log('updateState.calculated 6', calculated);
		colsWidth.name = calculated;
	} else {
		calculated = 700;
	}
	//console.log('updateState.calculated LAST:', calculated);
	state.columnNameWidth = calculated;

}