import { Button, Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useCheckIsUnitAllowedMutation } from '../slices/unitsApiSlice';
import {
  logout,
  setCurrentCustomer,
  setCurrentProvider,
} from '../slices/authSlice';
import { useCheckUserExistMutation } from '../slices/usersApiSlice';
import { useCheckCustomerAllowedMutation } from '../slices/customersApiSlice';
import { useCheckContractAllowedMutation } from '../slices/contractsApiSlice';

const HomeScreen = () => {
  const dispatch = useDispatch();
  const { currentProvider, currentCustomer, pov, userInfo } = useSelector(
    (state) => state.auth
  );

  const [checkContractAllowed] = useCheckContractAllowedMutation();
  const [checkCustomerAllowed] = useCheckCustomerAllowedMutation();
  const [checkIsUnitAllowed] = useCheckIsUnitAllowedMutation();
  const [checkUserExist] = useCheckUserExistMutation();

  const navigate = useNavigate();
  //console.log(window.location.href);
  // 👇️ /about
  //console.log(window.location.pathname);
  // 👇️ http:
  //console.log(window.location.protocol);
  // localhost
  //console.log(window.location.hostname);

  const onClick = async () => {
    // console.log('userInfo', userInfo);
    // console.log('pov', pov);
    if (userInfo?._id) {
      try {
        const res = await checkUserExist({
          userId: userInfo?._id,
          simple: true,
        }).unwrap();
        console.log('res', res);
      } catch (error) {
        //в LocalStorage есть данные о залогинном пользователе, однако
        //запрос на сервер показал что такого пользователя не существует.
        console.log('error', error);
        dispatch(logout());
        // navigate('/login');
        window.location.href = `/login`;
        return;
      }
    } else {
      console.log('userInfo?._id не установлен', userInfo);
      dispatch(logout());
      // navigate('/login');
      window.location.href = `/login`;
      return;
    }

    if (pov === 'provider') {
      if (currentProvider?._id) {
        try {
          await checkIsUnitAllowed({
            unitId: currentProvider._id,
            pov,
          }).unwrap();
          // navigate(`/provider`);
          window.location.href = `/provider`;
        } catch (error) {
          dispatch(setCurrentProvider(''));
          // navigate(`/provider/select/provider/`);
          window.location.href = `/provider/select/provider/`;
        }
      } else {
        // navigate(`/provider/select/provider/`);
        window.location.href = `/provider/select/provider/`;
      }
    } else if (pov === 'customer') {
      if (currentCustomer?._id) {
        //Покупатель разрешен
        if (currentProvider?._id) {
          //если выбран и поставщик, тогда проверим есть ли у пользователя право работать с этим покупателем и
          //если у этого покупателя договор с этим поставщиком
          // try {
          //   const result = await checkContractAllowed({
          //     customerId: currentCustomer._id,
          //     providerId: currentProvider._id,
          //   }).unwrap();
          //   console.log(result);
          // } catch (error) {
          //   //неудачный ответ
          //   dispatch(setCurrentProvider(''));
          // }
          window.location.href = `/customer/cart`;
        } else {
          //если  поставщик не выбран, тогда проверим есть ли у пользователя право работать с этим покупателем
          try {
            const result = await checkCustomerAllowed({
              customerId: currentCustomer._id,
            }).unwrap();
            console.log(result);
          } catch (error) {
            //проверка неудачна
            dispatch(setCurrentCustomer(''));
            dispatch(setCurrentProvider(''));
            window.location.href = `/customer/select/customer/`;

            // navigate('/customer/select/customer/');
            return;
          }
          window.location.href = `/customer/select/provider/`;
        }
      } else {
        dispatch(setCurrentCustomer(''));
        // dispatch(setCurrentProvider(''));
        // navigate(`/customer/select/customer/`);
        window.location.href = `/customer/select/customer/`;
      }
    } else {
      // navigate(`/start`);
      window.location.href = `/start`;
    }
  };

  return (
    <>
      <Container className='d-flex flex-column min-vh-100 justify-content-center align-items-center'>
        <Row>
          <Col>
            <Button onClick={onClick}>Вход</Button>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default HomeScreen;
