import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ToolsPanel from '../components/ToolsPanel';
import { useUploadImageMutation } from '../slices/imagesApiSlice';
import { toast } from 'react-toastify';
import {
  Accordion,
  Button,
  Col,
  Container,
  Form,
  ListGroup,
  Row,
  Stack,
} from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useUpdateUnitMutation } from '../slices/unitsApiSlice';
import UnitManagerList from '../components/UnitManagerList';
import Invites from '../components/Invites';
import {
  useGetProviderDetailsQuery,
  useUpdateProviderFieldMutation,
} from '../slices/providersApiSlice';
import GroupTwoColumnList from '../components/GroupList/GroupTwoColumnList/GroupTwoColumnList';
import { FaEdit, FaPhone, FaPhoneAlt } from 'react-icons/fa';
import GroupListSimple from '../components/GroupList/GroupListSimple';
import SelectUnit from '../components/SelectUnit/SelectUnit';
import ZoomableImage from '../components/Image/ZoomableImage';
import { Chip } from '@mui/material';

const ProviderDetailsEditScreen = () => {
  const [showSelectWindow, setShowSelectWindow] = useState(false);
  const [selectedUnit, setSelectedUnit] = useState();
  const [modalTitle, setModalTitle] = useState('');
  const navigate = useNavigate();
  const [uploadImage] = useUploadImageMutation();
  const [editMode, setEditMode] = useState(false);
  const [values, setValues] = useState([]);
  const [admins, setAdmins] = useState([]);
  const [users, setUsers] = useState([]);
  const [role, setRole] = useState('user');
  const [token, setToken] = useState('');
  const [newContact, setNewContact] = useState(false);
  const [defaultGroup, setDefaultGroup] = useState({});
  const [defaultPrice, setDefaultPrice] = useState({});
  const [settingsChanged, setSettingsChanged] = useState(false);

  useEffect(() => {
    if (selectedUnit) {
      if (modalTitle === 'Группы') {
        setDefaultGroup(selectedUnit);
      } else if (modalTitle === 'Типы цен') {
        setDefaultPrice(selectedUnit);
      }
      setSettingsChanged(true);
      setSelectedUnit('');
      setModalTitle('');
      setShowSelectWindow(false);
    }
    // eslint-disable-next-line
  }, [selectedUnit]);

  const { theme, pov, currentProvider, currentBreakpoint, leftMenuBinded } =
    useSelector((state) => state.auth);

  const setValuesHandler = (val, name) => {
    const newValues = values.map((el) =>
      el.name === name ? { ...el, value: val } : { ...el }
    );
    setValues(newValues);
  };

  const [updateField] = useUpdateProviderFieldMutation();
  const [updateUnit, { isLoading: loadingUpdate }] = useUpdateUnitMutation();
  // const [updateContactId, setUpdateContactId] = useState('');
  const { data, refetch, isLoading } = useGetProviderDetailsQuery(
    { providerId: currentProvider?._id },
    { refetchOnMountOrArgChange: true }
  );

  useEffect(() => {
    if (data) {
      setDefaultPrice(data?.defaultPrice);
      setDefaultGroup(data?.defaultGroup);
      setValues(data?.rows);
      //setUsers(data?.users);
      setAdmins(data?.admins);
      setToken(data?.token);
      if (data?.role) {
        setRole(data.role);
      }
    }
  }, [data]);

  const submitHandler = async (event) => {
    event.preventDefault();
    const closeAfter = event.target.getAttribute('close') === 'true';
    let toSave = {};
    toSave.fields = [...values];
    toSave._id = currentProvider?._id;
    toSave.type = 'provider';

    try {
      const result = await updateUnit(toSave).unwrap();
      if (result.error) {
        toast.error(result.error);
        refetch();
      } else {
        refetch();
        toast.success(`Успешно обновили`);

        if (closeAfter) {
          navigate(`/provider`);
        }
      }
    } catch (error) {
      toast.error(error?.data?.message || error?.message || error.error);
    }
  };

  const uploadFileHandler = async (e) => {
    const controlId = e.target.getAttribute('name');
    const formData = new FormData();
    formData.append('image', e.target.files[0]);
    try {
      const res = await uploadImage(formData).unwrap();
      toast.success(res.message);
      setValuesHandler(res.image, controlId);
    } catch (error) {
      toast.error(error?.data.message || error?.message || error.error);
      return false;
    }
  };

  const handleTurnEditMode = () => {
    setEditMode(!editMode);
    setNewContact(false);
  };

  const onlyRead = currentProvider?.role === 'a' ? false : true;

  const buttons = [];
  if (data?.role === 'a') {
    buttons.push({
      className: `bg-transparent ${editMode && 'border-success'}`,
      name: 'Инструменты',
      type: 'component',
      action: handleTurnEditMode,
      component: (props) => {
        return (
          <FaEdit size={22} color={theme === 'dark' ? 'white' : 'black'} />
        );
      },
    });
  }

  const handleClickAddContact = (e) => {
    e.stopPropagation();
    navigate('/provider/contacts/new');
    // setNewContact(true);
  };

  const handleClickEditContact = (id) => {
    navigate(`/provider/contacts/${id}`);
    // setNewContact(true);
  };

  const themeStyle = {
    backgroundColor: theme === 'dark' ? '#212529' : 'white',
    borderColor: theme === 'dark' ? '#212529' : '#b5c0c1',
    color: theme === 'dark' ? 'white' : 'black',
  };

  const settings = [
    {
      _id: defaultGroup?._id,
      field: 'Группа для новых товаров:',
      value: defaultGroup?.name,
    },
    {
      _id: defaultPrice?._id,
      field: 'Основной тип цен:',
      value: defaultPrice?.name,
    },
  ];

  const selectUnit = (name) => {
    setModalTitle(name);
    setShowSelectWindow(true);
  };

  const handleClickUpdateField = async () => {
    try {
      await updateField({
        _id: currentProvider?._id,
        fieldName: 'defaultPrice',
        fieldValue: defaultPrice?._id,
      }).unwrap();
    } catch (error) {
      toast.error(error?.data?.message || error.error);
    }
    try {
      await updateField({
        _id: currentProvider?._id,
        fieldName: 'defaultGroup',
        fieldValue: defaultGroup?._id,
      }).unwrap();
    } catch (error) {
      toast.error(error?.data?.message || error.error);
    }
    refetch();
    setSettingsChanged(false);
  };

  const handleClickRefreshToken = async () => {
    try {
      const result = await updateField({
        _id: currentProvider?._id,
        fieldName: 'token',
        fieldValue: 'new',
      }).unwrap();
      setToken(result?.newValue);
    } catch (error) {
      toast.error(error?.data?.message || error.error);
    }
  };

  return (
    <>
      <ToolsPanel
        buttons={buttons}
        backURL={'/provider'}
        title={`Реквизиты`}
        isLoading={isLoading || loadingUpdate}
      />
      <Container>
        {/* <Row>
          <Col>
            <Accordion className='me-1 mt-2' defaultActiveKey='0'></Accordion>
          </Col>
        </Row> */}
        <Row>
          <Col md={12} lg={8}>
            <Accordion
              className='me-1 mt-2'
              defaultActiveKey='0'
              activeKey={newContact ? '0' : undefined}
            >
              <Accordion.Item
                eventKey='0'
                style={{ backgroundColor: 'none !important' }}
              >
                <Accordion.Header>Контакты</Accordion.Header>
                <Accordion.Body>
                  <ListGroup variant={'flush'}>
                    {data?.contacts &&
                      data?.contacts.map((contact, index) => (
                        <ListGroup.Item
                          as={'div'}
                          // active={false}
                          // className={`p-0 list-group-item d-flex justify-content-between align-items-center `}
                          variant={theme}
                          key={contact?._id || index}
                        >
                          <Row>
                            <Col
                              xs={4}
                              md={3}
                              lg={3}
                              xl={2}
                              className='text-wrap text-break align-self-center'
                            >
                              {editMode ? (
                                <Button
                                  size='sm'
                                  className={`bg-transparent border-0`}
                                  onClick={() =>
                                    handleClickEditContact(contact?._id)
                                  }
                                >
                                  <FaEdit
                                    size={22}
                                    color={theme === 'dark' ? 'white' : 'black'}
                                  />
                                </Button>
                              ) : (
                                <>
                                  {contact?.image && (
                                    <ZoomableImage
                                      zoom={true}
                                      className='rounded-2 img-fluid '
                                      variant='top'
                                      src={contact?.image}
                                    />
                                  )}
                                </>
                              )}
                            </Col>
                            <Col xs={'auto'}>
                              <ListGroup variant={'flush'}>
                                <ListGroup.Item
                                  active={false}
                                  variant={theme}
                                  className={`p-1 border-0 fs-6 text-${
                                    theme === 'dark' ? 'light' : 'dark'
                                  }`}
                                >
                                  {contact?.name}
                                </ListGroup.Item>
                                <ListGroup.Item
                                  active={false}
                                  variant={theme}
                                  className={`p-1 border-0`}
                                >
                                  {contact?.description}
                                </ListGroup.Item>
                                {contact?.email && (
                                  <ListGroup.Item
                                    active={false}
                                    variant={theme}
                                    className={`p-1 border-0`}
                                  >
                                    <a
                                      className={`fs-6 text-info text-decoration-none fw-medium`}
                                      href={`mailto:${contact.email}`}
                                    >
                                      {contact.email}
                                    </a>
                                  </ListGroup.Item>
                                )}
                                <ListGroup.Item
                                  active={false}
                                  variant={theme}
                                  className={`p-1 border-0`}
                                >
                                  <Chip
                                    icon={<FaPhoneAlt />}
                                    color='success'
                                    variant='outlined'
                                    className={`fs-6`}
                                    label={contact?.phone}
                                    component='a'
                                    href={`tel:${contact?.phone}`}
                                    clickable
                                  />
                                  {/* className='text-success text-decoration-none fw-medium'
                                    href={`tel:${contact?.phone}`}
                                  > */}
                                  {/* {contact?.phone}
                                  </a> */}
                                </ListGroup.Item>
                              </ListGroup>
                            </Col>
                          </Row>
                        </ListGroup.Item>
                      ))}
                  </ListGroup>
                  {editMode && (
                    <Button
                      size={
                        ['lg', 'xl', 'xxl'].includes(currentBreakpoint)
                          ? 'lg'
                          : 'sm'
                      }
                      type='button'
                      variant='primary'
                      className='my-2'
                      onClick={() => navigate(`/provider/contacts/new`)}
                    >
                      Создать новый контакт
                    </Button>
                  )}
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item
                eventKey='1'
                style={{ backgroundColor: 'none !important' }}
              >
                <Accordion.Header>Настройки</Accordion.Header>
                <Accordion.Body>
                  {editMode ? (
                    <>
                      <Form onSubmit={submitHandler} className='me-1'>
                        <Form.Group
                          as={Row}
                          controlId={'defaultGroup'}
                          className='my-2'
                        >
                          <Form.Label column sm='3' className='pb-0'>
                            Основная группа:
                          </Form.Label>

                          <Col sm={9}>
                            <Form.Control
                              isInvalid={!defaultGroup?.name}
                              style={themeStyle}
                              type={'text'}
                              placeholder={'Выберите основную группу меню'}
                              value={defaultGroup?.name}
                              onClick={() => {
                                selectUnit('Группы');
                              }}
                              readOnly={true}
                            ></Form.Control>
                          </Col>
                        </Form.Group>
                        <Form.Group
                          as={Row}
                          controlId={'defaultPrice'}
                          className='my-2'
                        >
                          <Form.Label column sm='3' className='pb-0'>
                            Тип цен для новых покупателей:
                          </Form.Label>

                          <Col sm={9}>
                            <Form.Control
                              isInvalid={!defaultPrice?.name}
                              style={themeStyle}
                              type={'text'}
                              placeholder={'Выберите тип цен'}
                              value={defaultPrice?.name}
                              onClick={() => {
                                selectUnit('Типы цен');
                              }}
                              readOnly={true}
                            ></Form.Control>
                          </Col>
                        </Form.Group>
                      </Form>
                      {editMode && (
                        <>
                          <Button
                            size={
                              ['lg', 'xl', 'xxl'].includes(currentBreakpoint)
                                ? 'lg'
                                : 'sm'
                            }
                            type='button'
                            variant={settingsChanged ? 'info' : 'primary'}
                            className='my-2'
                            onClick={handleClickUpdateField}
                          >
                            Сохранить
                          </Button>
                        </>
                      )}
                      <Form.Group as={Row} controlId={'token'} className='my-2'>
                        <Form.Label column sm='3' className='pb-0'>
                          Токен для обмена с 1С:
                        </Form.Label>

                        <Col sm={9}>
                          <Stack direction='horizontal' gap={1}>
                            <Form.Control
                              style={themeStyle}
                              type={'text'}
                              placeholder={'Токен для обмена с 1С'}
                              value={token}
                              // onClick={() => {
                              //   selectUnit('Группы');
                              // }}
                              readOnly={true}
                            ></Form.Control>
                            <Button onClick={handleClickRefreshToken}>
                              Обновить
                            </Button>
                          </Stack>
                        </Col>
                      </Form.Group>
                    </>
                  ) : (
                    <>
                      <GroupTwoColumnList
                        rows={settings}
                        rowClasses='p-1'
                        leftKey='field'
                        rightKey='value'
                      />
                      {role === 'a' && (
                        <Row className={'p-1'}>
                          <Col sm={12} md={6}>
                            Токен для обмена с 1С:
                          </Col>
                          <Col sm={12} md={6}>
                            {token}
                          </Col>
                        </Row>
                      )}
                    </>
                  )}
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item
                eventKey='2'
                style={{ backgroundColor: 'none !important' }}
              >
                <Accordion.Header>Реквизиты</Accordion.Header>
                <Accordion.Body>
                  {editMode ? (
                    <Form onSubmit={submitHandler} className='me-1'>
                      {Array.isArray(values) &&
                        values.map((item) => (
                          <span key={item['name']}>
                            {item.file ? (
                              !onlyRead && (
                                <Form.Group
                                  as={Row}
                                  controlId={item['name']}
                                  className='my-2'
                                >
                                  <Form.Label column sm='3' className='pb-0'>
                                    {item['title']}
                                  </Form.Label>
                                  <Col sm='4'>
                                    <Form.Control
                                      autoComplete='off'
                                      required={item?.required}
                                      isInvalid={
                                        item?.required && !item['value']
                                      }
                                      disabled={true}
                                      style={themeStyle}
                                      type={item['type']}
                                      placeholder={item['placeholder']}
                                      value={item['value']}
                                      onChange={(e) =>
                                        setValuesHandler(
                                          e.target.value,
                                          item['name']
                                        )
                                      }
                                    ></Form.Control>
                                  </Col>
                                  <Col sm='5'>
                                    <Form.Control
                                      required={item?.required}
                                      isInvalid={
                                        item?.required && !item['value']
                                      }
                                      disabled={onlyRead}
                                      style={themeStyle}
                                      type='file'
                                      label='Выберите файл'
                                      name={item['name']}
                                      onChange={uploadFileHandler}
                                    ></Form.Control>
                                  </Col>
                                </Form.Group>
                              )
                            ) : (
                              <Form.Group
                                as={Row}
                                controlId={item['name']}
                                className='my-2'
                              >
                                <Form.Label column sm='3' className='pb-0'>
                                  {item['title']}
                                </Form.Label>

                                <Col sm={9}>
                                  <Form.Control
                                    isInvalid={item?.required && !item['value']}
                                    required={item?.required}
                                    disabled={onlyRead}
                                    style={themeStyle}
                                    type={item['type']}
                                    placeholder={item['placeholder']}
                                    value={item['value']}
                                    onChange={(e) =>
                                      setValuesHandler(
                                        e.target.value,
                                        item['name']
                                      )
                                    }
                                  ></Form.Control>
                                </Col>
                              </Form.Group>
                            )}
                          </span>
                        ))}
                    </Form>
                  ) : (
                    <GroupTwoColumnList rows={values} rowClasses='p-1' />
                  )}
                  {editMode && (
                    <>
                      <Button
                        size={
                          ['lg', 'xl', 'xxl'].includes(currentBreakpoint)
                            ? 'lg'
                            : 'sm'
                        }
                        type='button'
                        variant='primary'
                        className='my-2'
                        onClick={submitHandler}
                      >
                        Сохранить
                      </Button>
                    </>
                  )}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>

            <Button
              size={
                ['lg', 'xl', 'xxl'].includes(currentBreakpoint) ? 'lg' : 'sm'
              }
              variant='primary'
              className='my-2'
              onClick={() => navigate(`/provider`)}
            >
              Закрыть
            </Button>
          </Col>
          <Col md={12} lg={4}>
            {data && (
              <Accordion className='me-1 mt-2' defaultActiveKey='0'>
                <Accordion.Item
                  eventKey='0'
                  style={{ backgroundColor: 'none !important' }}
                >
                  <Accordion.Header>Категории товаров/услуг</Accordion.Header>
                  <Accordion.Body>
                    <GroupListSimple
                      action={false}
                      variant={'flush'}
                      className='p-0 border-0'
                      rows={data?.sections}
                      onClickItem={() => {}}
                    />
                    {editMode && (
                      <Button
                        size={
                          ['lg', 'xl', 'xxl'].includes(currentBreakpoint)
                            ? 'lg'
                            : 'sm'
                        }
                        type='button'
                        variant='primary'
                        className='my-2'
                        onClick={() => navigate(`/provider/sections/`)}
                      >
                        Изменить
                      </Button>
                    )}
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item
                  eventKey='1'
                  style={{ backgroundColor: 'none !important' }}
                >
                  <Accordion.Header>География доставки</Accordion.Header>
                  <Accordion.Body>
                    <>
                      {Array.isArray(data?.cities) &&
                        data.cities.map((sub, index) => (
                          <ListGroup key={index} variant={'flush'}>
                            {/* <div
                              key={index}
                              className='d-flex justify-content-between align-items-center'
                            >
                              {sub.name}
                            </div> */}

                            {Array.isArray(sub?.cities) &&
                              sub.cities.map((city, ind) => (
                                <ListGroup.Item
                                  as={'div'}
                                  className={`p-0 list-group-item d-flex justify-content-between align-items-center border-0`}
                                  variant={theme}
                                  key={city?._id ? city?._id : ind}
                                >
                                  <span className='p-2 w-100 d-flex overflow-hidden'>
                                    {city?.name}
                                  </span>
                                </ListGroup.Item>
                              ))}
                          </ListGroup>
                        ))}

                      {/* <GroupListSimple
                      variant={'flush'}
                      className='p-1 border-0'
                      rows={data?.cities}
                      onClickItem={() => {}}
                    /> */}
                      {editMode && (
                        <Button
                          size={
                            ['lg', 'xl', 'xxl'].includes(currentBreakpoint)
                              ? 'lg'
                              : 'sm'
                          }
                          type='button'
                          variant='primary'
                          className='my-2'
                          onClick={() => navigate(`/provider/cities/`)}
                        >
                          Изменить
                        </Button>
                      )}
                    </>
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item
                  eventKey='2'
                  style={{ backgroundColor: 'none !important' }}
                >
                  <Accordion.Header>Управляющие</Accordion.Header>
                  <Accordion.Body>
                    <UnitManagerList
                      className='p-1 border-0'
                      role={role}
                      admins={admins}
                      users={users}
                    />
                    {editMode && (
                      <Accordion className='mt-2'>
                        <Accordion.Header>Приглашения</Accordion.Header>
                        <Accordion.Body className='p-0'>
                          <Invites
                            onlyRead={onlyRead}
                            unitType={'provider'}
                            unitId={currentProvider?._id}
                          />
                        </Accordion.Body>
                      </Accordion>
                    )}
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            )}
          </Col>
        </Row>
      </Container>
      <SelectUnit
        show={showSelectWindow}
        setShow={setShowSelectWindow}
        setSelected={setSelectedUnit}
        //rows={unitRows}
        payload={{ providerId: currentProvider?._id, pov }}
        title={modalTitle}
        owner={currentProvider?._id}
      />
    </>
  );
};

export default ProviderDetailsEditScreen;
