import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Button, Row, Col, Container } from 'react-bootstrap';
import { useLoginMutation } from '../slices/usersApiSlice';
import {
  setCredentials,
  setCurrentProvider,
  setShowLeftMenu,
} from '../slices/authSlice';
import { toast } from 'react-toastify'; //для вывода всплывающих сообщений
import ToolsPanel from '../components/ToolsPanel';
import { useCheckIsUnitAllowedMutation } from '../slices/unitsApiSlice';

const LoginScreen = () => {
  const [uid, setUid] = useState('');
  const [password, setPassword] = useState('');

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [login, { isLoading }] = useLoginMutation();
  const [checkIsUnitAllowed] = useCheckIsUnitAllowedMutation();
  const { userInfo, theme, pov, currentProvider, currentCustomer } =
    useSelector((state) => state.auth);

  //вот такая конструкция позволят получить значение параметра,
  //который передаётся в URL:
  const { search } = useLocation();
  const sp = new URLSearchParams(search);
  const redirect = sp.get('redirect');

  useEffect(() => {
    dispatch(setShowLeftMenu(false));
    //если пользователь авторизован
    if (userInfo?._id) {
      navigate(redirect);
    }
    // eslint-disable-next-line
  }, [userInfo, redirect, navigate]);

  const start = async () => {
    if (pov === 'provider') {
      if (currentProvider?._id) {
        try {
          await checkIsUnitAllowed({ unitId: currentProvider, pov }).unwrap();
          window.location.href = `/provider`;
        } catch (error) {
          dispatch(setCurrentProvider(null));
          window.location.href = `/provider/select/provider/`;
        }
      } else {
        window.location.href = `/provider/select/provider/`;
      }
    } else if (pov === 'customer') {
      if (currentCustomer) {
        try {
          await checkIsUnitAllowed({ unitId: currentCustomer, pov }).unwrap();
          window.location.href = `/customer/order/`;
        } catch (error) {
          dispatch(setCurrentProvider(''));
          window.location.href = `/customer/select/customer/`;
        }
      } else {
        window.location.href = `/customer/select/customer/`;
      }
    } else {
      window.location.href = `/start`;
    }
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      const user = await login({ login: uid, password }).unwrap();
      dispatch(setCredentials({ ...user }));
      if (redirect) {
        navigate(redirect);
      } else {
        start();
      }
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  const themeStyle = {
    backgroundColor: theme === 'dark' ? '#212529' : 'white',
    borderColor: theme === 'dark' ? '#212529' : '#b5c0c1',
    color: theme === 'dark' ? 'white' : 'black',
  };

  return (
    <>
      <ToolsPanel isLoading={isLoading} title='Вход в систему' />
      <Container>
        <Row>
          <Col xs={12} sm={12} md={10} lg={6}>
            <Form onSubmit={submitHandler}>
              <Form.Group as={Row} controlId='uid' className='my-2'>
                <Form.Label column sm='4' className='pb-0'>
                  Логин или email
                </Form.Label>
                <Col sm='8'>
                  <Form.Control
                    autoComplete='on'
                    type='text'
                    style={themeStyle}
                    placeholder='Введите email или логин'
                    value={uid}
                    onChange={(e) => setUid(e.target.value)}
                  ></Form.Control>
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId='password' className='my-3'>
                <Form.Label column sm='4' className='pb-0'>
                  Пароль
                </Form.Label>
                <Col sm='8'>
                  <Form.Control
                    autoComplete='on'
                    type='password'
                    style={themeStyle}
                    placeholder='Введите пароль'
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  ></Form.Control>
                </Col>
              </Form.Group>
              <Button
                type='submit'
                variant='primary'
                className='mt-3'
                disabled={isLoading}
              >
                Войти
              </Button>
            </Form>
            <Row className='py-3'>
              <Col>
                Вы здесь впервые ?{' '}
                <Link
                  className={`link-${theme === 'dark' ? 'light' : 'dark'}`}
                  to={redirect ? `/register?redirect=${redirect}` : '/register'}
                >
                  Зарегистрироваться
                </Link>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default LoginScreen;
