import React, { useLayoutEffect, useRef, useState } from 'react';
import { BarChart } from '@mui/x-charts';

const HistoryChart = ({ item }) => {
  let container = useRef(null);
  let [height, setHeight] = useState(null);
  let [width, setWidth] = useState(null);

  useLayoutEffect(() => {
    setWidth(container.current.offsetWidth);
    setHeight(container.current.offsetHeight);
  }, []);

  return (
    <>
      <div
        style={{
          display: 'block',
          height: '100%',
        }}
        ref={container}
      >
        <BarChart
          skipAnimation={true}
          series={[{ data: [40, 60, 50] }]}
          xAxis={[
            {
              scaleType: 'band',
              data: [40, 60, 50],
              color: 'red',
              position: 'bottom',
              label: `Прошлые заявки (шт)`,
            },
          ]}
          width={width}
          height={height}
          leftAxis={{ disableLine: false }}
          margin={{
            left: 25,
            right: 20,
            top: 10,
            bottom: 40,
          }}
          colors={['grey', 'blue']}
        />
      </div>
    </>
  );
};

export default HistoryChart;
