import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ToolsPanel from '../components/ToolsPanel';
import { useUploadImageMutation } from '../slices/imagesApiSlice';
import { toast } from 'react-toastify';
import EditForm from '../components/EditForm';
import { Button, Col, Container, Row, Stack } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { LinearProgress } from '@mui/material';
import Message from '../components/Message';
import { setCurrentProvider } from '../slices/authSlice';
import { useGetCustomerFieldsQuery } from '../slices/customersApiSlice';
import { useCreateProviderMutation } from '../slices/providersApiSlice';

const ProviderCreateProviderScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [uploadImage] = useUploadImageMutation();
  const [values, setValues] = useState([]);
  const { theme, currentBreakpoint } = useSelector((state) => state.auth);

  const setValuesHandler = (val, name) => {
    const newValues = values.map((el) =>
      el.name === name ? { ...el, value: val } : { ...el }
    );
    setValues(newValues);
  };

  const [createProvider, { isLoading: loadingCreate }] =
    useCreateProviderMutation();

  //Получение данных
  const { data, isLoading, isError, error } = useGetCustomerFieldsQuery();

  useEffect(() => {
    if (Array.isArray(data?.rows)) {
      setValues(data.rows);
    }
  }, [data]);

  const submitHandler = async (event) => {
    event.preventDefault();

    let toSave = {};
    toSave.fields = [...values];

    try {
      const result = await createProvider(toSave).unwrap();
      dispatch(setCurrentProvider(result?.row));
      navigate(`/provider`);
    } catch (error) {
      toast.error(error?.data?.message || error?.message || error.error);
    }
  };

  const uploadFileHandler = async (e) => {
    const controlId = e.target.getAttribute('name');
    const formData = new FormData();
    formData.append('image', e.target.files[0]);
    try {
      const res = await uploadImage(formData).unwrap();
      toast.success(res.message);
      setValuesHandler(res.image, controlId);
    } catch (error) {
      toast.error(error?.data.message || error?.message || error.error);
      return false;
    }
  };

  const buttons = [];
  let backUrl = '/provider/select/provider/';

  return (
    <>
      {isLoading ? (
        <LinearProgress />
      ) : isError ? (
        <>
          <ToolsPanel backURL={backUrl} />
          <Message>{error?.data?.message || error?.error}</Message>
        </>
      ) : (
        <>
          <ToolsPanel
            //backIcon={pov === 'customer' ? <FaHome size={22} /> : ''}
            buttons={buttons}
            backURL={backUrl}
            title={`Создание поставщика`}
            isLoading={isLoading || loadingCreate}
          />
          <Container>
            <Row>
              <Col md={12} lg={8}>
                <EditForm
                  unitId={'new'}
                  theme={theme}
                  back={'customer'}
                  values={values}
                  setValues={setValuesHandler}
                  submit={submitHandler}
                  uploadFileHandler={uploadFileHandler}
                  onlyRead={false}
                />
                <Stack
                  direction='horizontal'
                  gap={3}
                  //class='justify-content-center'
                >
                  <>
                    <Button
                      size={
                        ['lg', 'xl', 'xxl'].includes(currentBreakpoint)
                          ? 'lg'
                          : 'sm'
                      }
                      variant='primary'
                      className='my-2'
                      onClick={submitHandler}
                    >
                      Создать
                    </Button>
                  </>

                  <LinkContainer to={backUrl}>
                    <Button
                      size={
                        ['lg', 'xl', 'xxl'].includes(currentBreakpoint)
                          ? 'lg'
                          : 'sm'
                      }
                      variant='primary'
                      className='my-2'
                    >
                      Закрыть
                    </Button>
                  </LinkContainer>
                </Stack>
              </Col>
            </Row>
          </Container>
        </>
      )}
    </>
  );
};

export default ProviderCreateProviderScreen;
