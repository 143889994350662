import React from 'react';
import { FormControlLabel, LinearProgress, Switch } from '@mui/material';
import { Badge, Button, Col, Container, Row, Stack } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { useSelector } from 'react-redux';

const Item = ({ button, index }) => {
  if (button?.hide) {
    return <></>;
  }
  return (
    <span key={index}>
      {button.type === 'switch' ? (
        <FormControlLabel
          sx={{
            display: 'block',
          }}
          control={
            <Switch
              checked={!button.disabled || false}
              onChange={button.action}
              name={`switch_${index}`}
              color='primary'
            />
          }
          label={button.name}
        />
      ) : button.type === 'component' ? (
        <>
          {button.link ? (
            <LinkContainer to={button.link} disabled={button.disabled || false}>
              <Button
                role='button'
                size={button?.size}
                className={button.className}
              >
                {button.component()}
              </Button>
            </LinkContainer>
          ) : button.action ? (
            <Button
              disabled={button.disabled || false}
              onClick={button.action}
              role='button'
              size={button?.size}
              className={button.className}
            >
              {button.component()}
            </Button>
          ) : (
            button.component()
          )}
        </>
      ) : button.type === 'self-component' ? (
        button.component()
      ) : (
        <Button
          size={button.size}
          disabled={button.disabled || false}
          onClick={button.action}
          type={button?.type || 'submit'}
          className={`${button?.className || 'btn'}`}
          variant={button?.variant || 'outline-secondary'}
        >
          {button.name}
          {button?.badgeValue && (
            <Badge bg={button?.badgeColor ? button.badgeColor : `secondary`}>
              {button.badgeValue}
            </Badge>
          )}
          {button?.icon && <span>{button.icon}</span>}
        </Button>
      )}
    </span>
  );
};

const ToolsPanel = ({
  onClickBack,
  backIcon,
  buttons = [],
  title = '',
  isLoading,
  backURL,
  direction = 'horizontal',
  subtitle,
  submenu,
}) => {
  const { theme, currentBreakpoint, showLeftMenu } = useSelector(
    (state) => state.auth
  );

  return (
    <Container className='my-3' style={{ minHeight: '31px' }}>
      <Row>
        <Col>
          <Stack direction='horizontal'>
            {typeof onClickBack === 'function' ? (
              <Button
                onClick={onClickBack}
                role='button'
                className={`bg-transparent me-2 ${
                  theme === 'dark' ? 'text-white' : 'text-black '
                }`}
              >
                {backIcon ? backIcon : 'Назад'}
              </Button>
            ) : (
              backURL && (
                <LinkContainer
                  to={typeof backURL === 'object' ? { ...backURL } : backURL}
                  role='button'
                  className='me-2'
                >
                  <Button
                    // onClick={onClickBack}
                    role='button'
                    className={`bg-transparent ${
                      theme === 'dark' ? 'text-white' : 'text-black '
                    }`}
                  >
                    {backIcon ? backIcon : 'Назад'}
                  </Button>
                </LinkContainer>
              )
            )}
            {currentBreakpoint === 'xs' ||
            (currentBreakpoint === 'sm' && showLeftMenu) ? (
              <></>
            ) : (
              <span className='text-break'>{title}</span>
            )}
          </Stack>
          {isLoading && <LinearProgress />}
        </Col>
        {Array.isArray(submenu) &&
          submenu.length > 0 &&
          ['xxl', 'xl'].includes(currentBreakpoint) && (
            <Col>
              <Stack
                direction='horizontal'
                className={`align-content-end justify-content-end`}
                gap={1}
              >
                {submenu.map((button, index) => (
                  <Item key={index} button={button} index={index} />
                ))}
              </Stack>
            </Col>
          )}
        {buttons && buttons?.length > 0 && (
          <Col
            className=' my-auto'
            xs={buttons.length}
            sm={buttons.length}
            md={buttons.length}
            lg={buttons.length}
            xxl={buttons.length}
          >
            <Stack
              direction='horizontal'
              className={`align-content-end justify-content-end`}
              gap={1}
            >
              {buttons.map((button, index) => (
                <Item key={index} button={button} index={index} />
              ))}
            </Stack>
          </Col>
        )}
      </Row>
      {(currentBreakpoint === 'xs' ||
        (currentBreakpoint === 'sm' && showLeftMenu)) && (
        <Row>
          <Col className='mt-2 ms-1'>{title}</Col>
        </Row>
      )}
      {subtitle && (
        <Row>
          <Col className='mt-2 ms-1'>{subtitle}</Col>
        </Row>
      )}
      {Array.isArray(submenu) &&
        submenu.length > 0 &&
        !['xxl', 'xl'].includes(currentBreakpoint) && (
          <Row className='mt-2'>
            <Col>
              <Stack
                direction='horizontal'
                className={`align-content-start justify-content-start`}
                gap={1}
              >
                {submenu.map((button, index) => (
                  <Item key={index} button={button} index={index} />
                ))}
              </Stack>
            </Col>
          </Row>
        )}
    </Container>
  );
};

export default ToolsPanel;
