import { useSelector } from 'react-redux';
import { useState } from 'react';
import { Badge, Box, IconButton } from '@mui/material';
import MoreIcon from '@mui/icons-material/MoreVert';
import Offcanvas from 'react-bootstrap/Offcanvas';
import CartLink from '../CartLink/CartLink';
import UnitSelectButton from '../UnitSelectButton';
import HeaderSearchControl from '../HeaderSearchControl';
import MenuAdmin from '../MenuAdmin/MenuAdmin';
import MenuUser from '../MenuUser/MenuUser';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { useLocation, useNavigate } from 'react-router-dom';

const MoreButton = ({ setShowCanvas, showCanvas }) => {
  return (
    <>
      <IconButton
        onClick={() => setShowCanvas(!showCanvas)}
        size='large'
        edge='end'
        aria-label='account of current user'
        aria-haspopup='true'
        color='inherit'
      >
        <MoreIcon />
      </IconButton>
    </>
  );
};

const Header = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const { theme, currentProvider, pov, userInfo, currentCustomer } =
    useSelector((state) => state.auth);
  const [showCanvas, setShowCanvas] = useState(false);

  const key = `${currentCustomer?._id}-${currentProvider?._id}`;
  const tasksCount = useSelector((state) => state.auth?.tasks[key]);

  const closeButtonParams = {};
  if (theme === 'dark') closeButtonParams.closeVariant = 'white';

  const onClickNotification = () => {
    navigate('/customer/tasks', { state: pathname });
  };

  return (
    <>
      <UnitSelectButton />
      <Box sx={{ flexGrow: 1 }} />
      <HeaderSearchControl />
      <Box>
        {tasksCount > 0 && (
          <IconButton
            size='large'
            aria-label='new notifications'
            color='inherit'
            onClick={onClickNotification}
          >
            <Badge badgeContent={tasksCount} color='error'>
              <NotificationsIcon />
            </Badge>
          </IconButton>
        )}
      </Box>
      <Box>
        {currentProvider && pov === 'customer' && <CartLink badgePos='left' />}
      </Box>

      {userInfo?._id && (
        <MoreButton setShowCanvas={setShowCanvas} showCanvas={showCanvas} />
      )}

      <Offcanvas
        style={{ background: theme === 'dark' ? '#212529' : 'white' }}
        show={showCanvas}
        onHide={setShowCanvas}
        placement='end'
        autoFocus={true}
        className='mt-0'
      >
        <Offcanvas.Header closeButton {...closeButtonParams}>
          <Offcanvas.Title></Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body onClick={() => setShowCanvas(false)}>
          <MenuUser onClick={setShowCanvas} />
          <MenuAdmin onClick={setShowCanvas} />
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default Header;
