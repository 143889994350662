import React from 'react';
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFViewer,
  Font,
} from '@react-pdf/renderer';
import { Container, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { useGetOrderDetailsQuery } from '../slices/ordersApiSlice';
import { getOrderHeaderString } from '../utils/gets';
import { useSelector } from 'react-redux';
import ToolsPanel from '../components/ToolsPanel';

Font.register({
  family: 'Roboto',
  src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-medium-webfont.ttf',
});

const borderColor = '#90e5fc';
// Create styles
const styles = StyleSheet.create({
  body: {
    paddingTop: 25,
    paddingBottom: 25,
    paddingHorizontal: 25,
  },
  title: {
    fontSize: 16,
    textAlign: 'left',
    fontFamily: 'Roboto',
  },
  author: {
    fontSize: 12,
    textAlign: 'left',
    marginBottom: 40,
  },
  subtitle: {
    fontSize: 12,
    //margin: 12,
    fontFamily: 'Roboto',
  },
  text: {
    margin: 12,
    fontSize: 14,
    textAlign: 'justify',
    fontFamily: 'Roboto',
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
  },
  header: {
    fontSize: 12,
    marginBottom: 5,
    textAlign: 'right',
    color: 'grey',
    fontFamily: 'Roboto',
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
  page: {
    flexDirection: 'row',
    backgroundColor: '#E4E4E4',
  },
  section: {
    flexGrow: 1,
    fontFamily: 'Roboto',
  },
  myText: {
    fontFamily: 'Roboto',
  },

  row2: {
    borderBottomColor: '#bff0fd',
    backgroundColor: '#bff0fd',
    marginTop: '18px',
    flexDirection: 'row',
    borderBottomWidth: 1,
    alignItems: 'center',
    height: 18,
    fontFamily: 'Roboto',
  },
  row: {
    flexDirection: 'row',
    borderBottomColor: '#bff0fd',
    borderBottomWidth: 1,
    alignItems: 'center',
    height: 18,
    fontFamily: 'Roboto',
  },
  description: {
    width: '60%',
    textAlign: 'left',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingLeft: 2,
    color: 'black',
    fontFamily: 'Roboto',
    fontSize: 10,
  },
  qty: {
    width: '10%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: 'right',
    paddingRight: 2,
    fontFamily: 'Roboto',
    fontSize: 10,
  },
  unit: {
    width: '10%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: 'right',
    paddingRight: 2,
    fontFamily: 'Roboto',
    fontSize: 10,
  },
  price: {
    width: '10%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: 'right',
    paddingRight: 2,
    fontFamily: 'Roboto',
    fontSize: 10,
  },
  ret: {
    width: '10%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: 'right',
    paddingRight: 5,
    fontFamily: 'Roboto',
    fontSize: 10,
  },

  footrow: {
    flexDirection: 'row',
    borderBottomColor: '#bff0fd',
    borderBottomWidth: 1,
    alignItems: 'center',
    height: 24,
    fontSize: 12,
    fontFamily: 'Roboto',
  },
  footdescription: {
    width: '80%',
    textAlign: 'right',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingRight: 8,
    fontFamily: 'Roboto',
    fontSize: 12,
  },
  foottotal: {
    width: '15%',
    textAlign: 'right',
    paddingRight: 8,
    fontFamily: 'Roboto',
    fontSize: 12,
  },
});

const PdfScreen = () => {
  const { orderId } = useParams();
  const cart = useSelector((state) => state.cart);
  const { currentCustomer, currentProvider, pov } = useSelector(
    (state) => state.auth
  );

  const { data } = useGetOrderDetailsQuery(orderId);

  let orderItems;
  let orderParams;

  if (data?.status === 'cart') {
    orderItems = cart?.cartItems.filter(
      (item) =>
        item.providerId === currentProvider?._id &&
        item.customerId === currentCustomer?._id
    );
    //setOrderItems(rows);
    orderParams = {
      provider: currentProvider?.name,
      customer: currentCustomer?.name,
      shippingAddress: cart?.shippingAddress,
      contacts: cart?.contacts,
      comment: cart?.comment,
      date: new Date(),
      _id: 'Новая',
    };
  } else if (data?.status === 'ok' && orderId) {
    orderItems = data.doc?.orderItems || [];
    orderParams = {
      provider: data?.doc?.provider?.name,
      customer: data?.doc?.customer?.name,
      shippingAddress: data?.doc?.shippingAddress,
      contacts: data?.doc?.contacts,
      comment: data?.doc?.comment,
      date: data?.doc?.createdAt,
      _id: data?.doc?._id,
    };
  } else {
    return;
  }

  const DocHead = () => (
    <View style={styles.headerContainer}>
      <Text style={styles.title}>
        {getOrderHeaderString(data, 'Заявка покупателя от ')}
      </Text>
      <Text style={styles.subtitle}>Поставщик: {orderParams.provider}</Text>
      <Text style={styles.subtitle}>Покупатель: {orderParams.customer}</Text>
      <Text style={styles.subtitle}>
        Адрес доставки: {orderParams.shippingAddress}
      </Text>
      <Text style={styles.subtitle}>
        Телефон покупателя: {orderParams.contacts}
      </Text>
      <Text style={styles.subtitle}>Комментарий: {orderParams.comment}</Text>
    </View>
  );

  const TableBody = () => {
    return (
      <>
        <View style={styles.row2}>
          <Text style={styles.description}>Наименование</Text>
          <Text style={styles.price}>Цена</Text>
          <Text style={styles.unit}>Ед.изм</Text>
          <Text style={styles.qty}>Заказ</Text>
          <Text style={styles.amount}>Сумма</Text>
          <Text style={styles.ret}>Возврат</Text>
        </View>
        {orderItems &&
          orderItems.map((item) => (
            <View style={styles.row} key={item._id}>
              <Text style={styles.description}>{item?.name || ' '}</Text>
              <Text style={styles.price}>{item?.price || ' '}</Text>
              <Text style={styles.unit}>{item?.unit || ' '}</Text>
              <Text style={styles.qty}>{item.qty || ' '}</Text>
              <Text style={styles.amount}>
                {(item?.qty * (item?.price || 0)).toFixed(2) || ' '}
              </Text>
              <Text style={styles.ret}>{item?.ret || ' '}</Text>
            </View>
          ))}
      </>
    );
  };

  const TableFooter = ({ items }) => {
    const total =
      orderItems &&
      orderItems
        .map((item) => (item?.qty || 1) * (item?.price || 0))
        .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    return (
      <View style={styles.footrow}>
        <Text style={styles.footdescription}>Итого:</Text>
        <Text style={styles.foottotal}>
          {Number.parseFloat(total).toFixed(2)}
        </Text>
      </View>
    );
  };

  const MyDocument = () => (
    <Document>
      <Page size='A4' style={styles.body}>
        <Text style={styles.header} fixed>
          {orderParams?._id}
        </Text>
        <View style={styles.section}>
          <DocHead />
          <TableBody />
          <TableFooter />
        </View>
      </Page>
    </Document>
  );

  return (
    <>
      <ToolsPanel
        title={'Печать заявки'}
        backURL={
          pov === 'provider'
            ? `/provider/orders/${orderId}`
            : pov === 'customer'
            ? `/customer/cart/${orderId === 'cart' ? '' : orderId}`
            : '/start'
        }
      />

      <Container>
        <Row className='vh-100'>
          <PDFViewer>
            <MyDocument />
          </PDFViewer>
        </Row>
      </Container>
    </>
  );
};

export default PdfScreen;
