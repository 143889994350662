import { useState, useEffect } from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import { toast } from 'react-toastify';
import { useProfileMutation } from '../slices/usersApiSlice';
import { setCredentials, setShowLeftMenu } from '../slices/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row, Form, Button, Container, Stack } from 'react-bootstrap';
import ToolsPanel from '../components/ToolsPanel';

const ProfileScreen = () => {
  const [name, setName] = useState('');
  const [login, setLogin] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const dispatch = useDispatch();

  const [updateProfile, { isLoading }] = useProfileMutation();
  // const {
  //   data: orders,
  //   isLoading: isLoadingOrders,
  //   isError: isErrorGetOrders,
  // } = useGetMyOrdersQuery();

  const { userInfo, theme, pov, currentCustomer, currentProvider } =
    useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(setShowLeftMenu(false));
    if (userInfo) {
      setName(userInfo.name || '');
      setLogin(userInfo.login || '');
      setEmail(userInfo.email || '');
    }
    // eslint-disable-next-line
  }, [userInfo, userInfo.name, userInfo.login, userInfo.email]);

  const submitHandler = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      toast.error('Password do not match');
    } else {
      if (name && email) {
        try {
          const res = await updateProfile({
            _id: userInfo._id,
            name,
            login,
            email,
            password,
          }).unwrap();

          dispatch(setCredentials(res));
          toast.success('Updated');
        } catch (error) {
          toast.error(error?.data?.message || error.error);
        }
      } else {
        toast.error('Check empty ');
      }
    }
  };

  const themeStyle = {
    backgroundColor: theme === 'dark' ? '#212529' : 'white',
    borderColor: theme === 'dark' ? '#212529' : '#b5c0c1',
    color: theme === 'dark' ? 'white' : 'black',
  };

  let backUrl = '/start';
  if (pov === 'customer') {
    if (!currentCustomer) {
      backUrl = `/customer/select/customer`;
    }
    if (currentCustomer && !currentProvider) {
      backUrl = `/customer/select/provider`;
    }
    if (currentCustomer && currentProvider) {
      backUrl = `/customer/cart`;
    }
  } else if (pov === 'provider') {
    if (currentProvider) {
      backUrl = `/provider`;
    } else {
      backUrl = `/provider/select/provider`;
    }
  }

  return (
    <>
      <ToolsPanel
        title='Профиль пользователя'
        backURL={backUrl}
        isLoading={isLoading}
      />
      <Container>
        <Row>
          <Col xs={12} sm={12} md={10} lg={6}>
            <Form onSubmit={submitHandler}>
              <Form.Group as={Row} controlId='name' className='my-2'>
                <Form.Label column sm='4' className='pb-0'>
                  Имя
                </Form.Label>
                <Col sm='8'>
                  <Form.Control
                    style={themeStyle}
                    type='text'
                    placeholder='Как к вам обращаться'
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  ></Form.Control>
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId='login' className='my-2'>
                <Form.Label column sm='4' className='pb-0'>
                  Логин
                </Form.Label>
                <Col sm='8'>
                  <Form.Control
                    style={themeStyle}
                    type='text'
                    placeholder='Ваш уникальный логин'
                    value={login}
                    onChange={(e) => setLogin(e.target.value)}
                  ></Form.Control>
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId='email' className='my-2'>
                <Form.Label column sm='4' className='pb-0'>
                  Емейл
                </Form.Label>
                <Col sm='8'>
                  <Form.Control
                    style={themeStyle}
                    type='email'
                    placeholder='Почта для документов'
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  ></Form.Control>
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId='password' className='my-2'>
                <Form.Label column sm='4' className='pb-0'>
                  Пароль
                </Form.Label>
                <Col sm='8'>
                  <Form.Control
                    autoComplete='off'
                    style={themeStyle}
                    type='password'
                    placeholder='Введите новый пароль'
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  ></Form.Control>
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId='password2' className='my-2'>
                <Form.Label column sm='4' className='pb-0'>
                  Повтор пароля
                </Form.Label>
                <Col sm='8'>
                  <Form.Control
                    autoComplete='off'
                    style={themeStyle}
                    type='password'
                    placeholder='Введите пароль снова'
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  ></Form.Control>
                </Col>
              </Form.Group>
              <Stack direction='horizontal' gap={3}>
                <Button type='submit' variant='primary' className='mt-3'>
                  Обновить
                </Button>
                <LinkContainer to={backUrl}>
                  <Button type='submit' variant='primary' className='mt-3'>
                    Закрыть
                  </Button>
                </LinkContainer>
              </Stack>
            </Form>
          </Col>
        </Row>
      </Container>
      {/* <Col md={9}>
        <h2>My orders</h2>
        {isLoadingOrders ? (
          <Loader />
        ) : isErrorGetOrders ? (
          <Message variant='danger'>
            {isErrorGetOrders?.data?.message || isErrorGetOrders.error}
          </Message>
        ) : (
          <Table striped hover responsive className='table-sm'>
            <thead>
              <tr>
                <th>ID</th>
                <th>DATE</th>
                <th>TOTAL</th>
                <th>PAID</th>
                <th>DELIVERED</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {orders.map((order) => (
                <tr key={order._id}>
                  <td>{order._id}</td>
                  <td>{order.createdAt.substring(0, 10)}</td>
                  <td>${order.totalPrice}</td>
                  <td>
                    {order.isPaid ? order.paidAt.substring(0, 10) : <FaTimes />}
                  </td>
                  <td>
                    {order.isDelivered ? (
                      order.deliveredAt.substring(0, 10)
                    ) : (
                      <FaTimes />
                    )}
                  </td>
                  <td>
                    <LinkContainer to={`/orders/${order._id}`}>
                      <Button className='btn-sm' variant='light'>
                        <FaSearch />
                      </Button>
                    </LinkContainer>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </Col> */}
    </>
  );
};

export default ProfileScreen;
