import React from 'react';
import { Alert, Button, ListGroup, Spinner } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useGetUnitSectionsQuery } from '../../slices/sectionsApiSlice';
import { useNavigate } from 'react-router-dom';

const UnitSectionList = ({ unitId, onClick }) => {
  const { theme, pov } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  const { data, isLoading, isError, error } = useGetUnitSectionsQuery(unitId);

  const addSectionsHandler = () => {
    navigate(`/provider/sections/`);
  };

  const sections = data?.sections;

  return (
    <>
      {isLoading ? (
        <Spinner animation='border' />
      ) : isError ? (
        <Alert variant='outlined' severity='error'>
          {error?.data?.message || error.error}
        </Alert>
      ) : (
        <>
          <ListGroup bsPrefix='narrow'>
            {sections && sections.length > 0 ? (
              sections.map((item) => (
                <ListGroup.Item
                  onClick={() => onClick(item._id)}
                  as={'div'}
                  variant={theme}
                  action
                  key={item._id}
                  className='d-flex justify-content-between align-items-start'
                >
                  {item.name}
                </ListGroup.Item>
              ))
            ) : (
              <ListGroup.Item
                onClick={() => onClick(undefined)}
                as={'div'}
                variant={theme}
                className='d-flex justify-content-between align-items-start'
              >
                Не указаны
              </ListGroup.Item>
            )}
          </ListGroup>
          {data?.userRole === 'admin' && pov === 'provider' && (
            <Button
              type='button'
              variant='primary'
              className='my-2'
              onClick={addSectionsHandler}
            >
              Изменить
            </Button>
          )}
        </>
      )}
    </>
  );
};

export default UnitSectionList;
