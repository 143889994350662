import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Col, Container, Form, Row, Stack } from 'react-bootstrap';
import {
  useHideProductsMutation,
  useMoveToGroupMutation,
} from '../../../slices/productsApiSlice';
import SelectUnit from '../../SelectUnit/SelectUnit';
import { toast } from 'react-toastify';

const ProviderToolbar = ({ refetch }) => {
  //const dispatch = useDispatch();
  const { theme, pov, gridSettingMode, selectedProducts, currentProvider } =
    useSelector((state) => state.auth);
  //   const [selectedOption, setSelectedOption] = useState('');

  const [action, setAction] = useState();
  const [group, setGroup] = useState();
  const [modalTitle, setModalTitle] = useState('');
  const [showSelectWindow, setShowSelectWindow] = useState(false);

  const [hideProducts] = useHideProductsMutation();
  const [moveToGroup] = useMoveToGroupMutation();

  const handleHideSelected = async () => {
    if (selectedProducts) {
      try {
        await hideProducts({
          ids: selectedProducts,
          providerId: currentProvider?._id,
          status: true,
        }).unwrap();
        refetch();
      } catch (error) {}
    }
  };

  const handleSetVisibleSelected = async () => {
    if (selectedProducts) {
      try {
        await hideProducts({
          ids: selectedProducts,
          providerId: currentProvider?._id,
          status: false,
        }).unwrap();
        refetch();
      } catch (error) {}
    }
  };
  // const handleCreateNewProduct = () => {};
  // const handleCreateNewGroup = () => {};

  const handleSelectGroup = (item) => {
    //console.log(item);
    setGroup(item);
    setShowSelectWindow(false);
  };

  const selectGroup = () => {
    setModalTitle('Группы');
    setShowSelectWindow(true);
  };

  const handleSelectAction = (value) => {
    setGroup(false);
    if (actions[value]?.name) {
      setAction(value);
    } else {
      setAction(false);
    }
  };

  const handleMoveToGroup = async () => {
    if (
      Array.isArray(selectedProducts) &&
      selectedProducts.length > 0 &&
      group?._id
    ) {
      try {
        await moveToGroup({
          ids: selectedProducts,
          providerId: currentProvider?._id,
          groupId: group._id,
        }).unwrap();

        refetch();
      } catch (error) {
        toast.error(error?.data?.message || error.error);
      }
    }
  };

  //ВНИМАНИЕ В КОДЕ ЕСТЬ ПРИВЯЗКА к ИНДЕКСАМ МАССИВА
  const actions = [
    {
      title: 'Скрыть',
      func: handleHideSelected,
      name: 'Скрыть выбранные товары',
    },
    {
      title: 'Вернуть',
      func: handleSetVisibleSelected,
      name: 'Сделать видимыми',
    },
    {
      title: 'Переместить',
      func: handleMoveToGroup,
      name: 'Переместить в группу',
    },
  ];

  if (!pov || pov !== 'provider') {
    return <></>;
  }

  if (!gridSettingMode) {
    return <></>;
  }

  const themeStyle = {
    backgroundColor: theme === 'dark' ? '#212529' : 'white',
    borderColor: theme === 'dark' ? '#212529' : '#b5c0c1',
    color: theme === 'dark' ? 'white' : 'black',
  };

  return (
    <>
      <Container>
        <Row>
          <Stack direction='horizontal' gap={1} className='mx-0 px-0'>
            <Col xs={7}>
              <Form.Select
                style={themeStyle}
                size='sm'
                aria-label='Action select'
                className='my-1 mx-0'
                value={action}
                onChange={(e) => handleSelectAction(e.target.value)}
              >
                <option>Выберите действие</option>
                {actions &&
                  actions.map((item, index) => (
                    <option key={index} value={index}>
                      {item.name}
                    </option>
                  ))}
              </Form.Select>
            </Col>

            <Col xs={5}>
              <>
                {!action ? (
                  <></>
                ) : (
                  // <span style={{ fontSize: '0.8rem' }}>
                  //   &nbsp;Выберите действие
                  // </span>
                  <Button
                    size='sm'
                    onClick={actions[action]?.func}
                    disabled={action === '2' && !group}
                    variant={action === '2' && !!group ? 'success' : 'primary'}
                  >
                    {actions[action]?.title}
                  </Button>
                )}
              </>
            </Col>
          </Stack>
          {action === '2' && (
            <Col xs={12} className='mx-0 px-0'>
              <Form.Group controlId='group' className='my-2'>
                <Form.Control
                  isInvalid={!group}
                  isValid={!!group}
                  style={themeStyle}
                  type='text'
                  placeholder='Выберите группу'
                  value={group?.name || ''}
                  onClick={() => selectGroup()}
                  //onChange={(e) => onChangeHandler}
                  readOnly={true}
                ></Form.Control>
              </Form.Group>
            </Col>
          )}
        </Row>
      </Container>
      <SelectUnit
        show={showSelectWindow}
        setShow={setShowSelectWindow}
        setSelected={handleSelectGroup}
        //rows={unitRows}
        payload={{ providerId: currentProvider?._id, pov }}
        title={modalTitle}
        owner={currentProvider?._id}
      />
    </>
  );
};

export default ProviderToolbar;
