import React, { useState } from 'react';
import { Button, Carousel, Col, Container, Row } from 'react-bootstrap';
import AvatarProviderColored from '../../SVG/AvatarProviderColored';
import {
  FaExpeditedssl,
  FaMobileAlt,
  FaPhoneSlash,
  FaRoute,
  FaTags,
  FaUsersCog,
} from 'react-icons/fa';
import { Box, Divider, Stack } from '@mui/material';

const WelcomeProvider = ({ handleClickCreate, theme }) => {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  const bgDefault = 'bg-dark';
  const bgActive = 'bg-success';

  const carouselItemClasses = 'h-auto';

  const clickOnItem = (ind) => {
    setIndex(ind);
  };

  let heroClasses = ['p-4', 'rounded-5', 'bg-primary'];
  if (theme === 'dark') {
    heroClasses.push('text-light');
  } else if (theme === 'light') {
    heroClasses.push('text-light');
  } else {
    heroClasses.push('text-light');
  }

  return (
    <>
      <Row>
        <Col sm={12} md={12} lg={8}>
          <Container
            className={heroClasses.join(' ')}
            style={{ minHeight: '240px' }}
          >
            <Row>
              <Col sm={12} md={6} lg={6}>
                <h4>Приветствуем вас</h4>
                <span>
                  Магазины, производители и оптовики находят своих &nbsp;
                  <strong>поставщиков</strong>&nbsp; здесь и скидывают им свои
                  заявки. <br /> Всё просто.
                  <br />
                  И если вы поставщик товаров или услуг, мы приглашаем вас
                  подключится к нашей группе и стать найденным.
                  <br />
                  <Box sx={{ display: { xs: 'none', lg: 'flex' } }}>
                    <br /> <br />
                    Вот некоторые возможности: <br />
                    <br /> - &nbsp; Клиент, открывшийся в зоне ваших продаж,
                    мгновенно найдёт вас здесь.
                    <br /> - &nbsp; Сообщайте о своих скидках, акциях и новинках
                    сразу, всем, и с картинкой.
                    <br /> - &nbsp; Освободите своих операторов для более важных
                    дел, а заявки пусть автоматически загружаются в вашу 1С.
                    <br /> - &nbsp; Не нужно устанавливать приложения, а значит
                    санкции никогда не помешают работе.
                    <br /> - &nbsp; Торговый представитель может использовать
                    сайт как мобильное приложение для визитов и задач
                  </Box>
                </span>
                <Divider />
                <Button
                  variant='success'
                  className='mb-3 mt-1'
                  onClick={() => handleClickCreate('customer')}
                >
                  Создать поставщика
                </Button>
              </Col>
              <Col>
                <AvatarProviderColored />
              </Col>
              <Box sx={{ display: { xs: 'flex', lg: 'none' } }}>
                <br /> <br />
                Вот некоторые возможности: <br />
                <br /> - &nbsp; Клиент, открывшийся в зоне ваших продаж,
                мгновенно найдёт вас здесь.
                <br /> - &nbsp; Сообщайте о своих скидках, акциях и новинках
                сразу, всем, и с картинкой.
                <br /> - &nbsp; Освободите своих операторов для более важных
                дел, а заявки пусть автоматически загружаются в вашу 1С.
                <br /> - &nbsp; Не нужно устанавливать приложения, а значит
                санкции никогда не помешают работе.
                <br /> - &nbsp; Торговый представитель может использовать сайт
                как мобильное приложение для визитов и задач
                <br /> - &nbsp;...
              </Box>
            </Row>
          </Container>
        </Col>
        {/* <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
          <Col sm={12} md={12} lg={4} onClick={() => clickOnItem(0)}>
            <FaExpeditedssl
              size={35}
              className={`m-2 ${index === 0 ? 'text-white' : 'text-muted'}`}
            />
          </Col>
          <Col sm={12} md={12} lg={4} onClick={() => clickOnItem(0)}>
            <FaUsersCog
              size={35}
              className={`m-2 ${index === 1 ? 'text-white' : 'text-muted'}`}
            />
          </Col>
          <Col sm={12} md={12} lg={4} onClick={() => clickOnItem(0)}>
            <FaTags
              size={35}
              className={`m-2 ${index === 2 ? 'text-white' : 'text-muted'}`}
            />
          </Col>
          <Col sm={12} md={12} lg={4} onClick={() => clickOnItem(0)}>
            <FaPhoneSlash
              size={35}
              className={`m-2 ${index === 3 ? 'text-white' : 'text-muted'}`}
            />
          </Col>
          <Col sm={12} md={12} lg={4} onClick={() => clickOnItem(0)}>
            <FaMobileAlt
              size={35}
              className={`m-2 ${index === 4 ? 'text-white' : 'text-muted'}`}
            />
          </Col>
          <Col sm={12} md={12} lg={4} onClick={() => clickOnItem(0)}>
            <FaRoute
              size={35}
              className={`m-2 ${index === 5 ? 'text-white' : 'text-muted'}`}
            />
          </Col>
        </Box> */}
        <Col sm={12} md={12} lg={4}>
          <Carousel
            activeIndex={index}
            onSelect={handleSelect}
            controls={false}
          >
            <Carousel.Item className={carouselItemClasses}>
              <Col className='h-100 d-inline-block m-5'>
                <FaExpeditedssl size={60} className='mb-4' />
                <p className='fs-5'>
                  Информация на сайте защищена и зашифрована.
                </p>
              </Col>
            </Carousel.Item>
            <Carousel.Item className={carouselItemClasses}>
              <Col className='h-100 m-5'>
                <FaUsersCog size={60} className='mb-4' />
                <p className='fs-5'>
                  Каждому покупателю можно назначить свои цены и скидки.
                </p>
              </Col>
            </Carousel.Item>
            <Carousel.Item className={carouselItemClasses}>
              <Col className='h-100 m-5'>
                <FaTags size={60} className='mb-4' />
                <p className='fs-5'>
                  Гибкая настройка акций в ленте новостей с
                </p>
              </Col>
            </Carousel.Item>
            <Carousel.Item className={carouselItemClasses}>
              <Col className='h-100 m-5'>
                <FaPhoneSlash size={60} className='mb-4' />
                <p className='fs-5'>
                  Заявки можно загружать сразу в 1С
                  <br />
                  Освободите своих операторов для других задач.
                </p>
              </Col>
            </Carousel.Item>
            <Carousel.Item className={carouselItemClasses}>
              <Col className='h-100 m-5'>
                <FaMobileAlt size={60} className='mb-4' />
                <p className='fs-5'>
                  Проверить и обработать заявки можно прямо через смартфон.
                </p>
              </Col>
            </Carousel.Item>
            <Carousel.Item className={carouselItemClasses}>
              <Col className='h-100 m-5'>
                <FaRoute size={60} className='mb-4' />
                <p className='fs-5'>
                  Расширяйте зону продаж без найма торговых представителей!
                </p>
              </Col>
            </Carousel.Item>
          </Carousel>
        </Col>
      </Row>

      <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
        <Row>
          <Col
            sm={12}
            md={12}
            lg={4}
            style={{ maxHeight: '150px' }}
            onClick={() => clickOnItem(0)}
          >
            <Stack
              direction='row'
              spacing={2}
              className={`p-3  rounded-3 align-items-center my-2 ${
                index === 0 ? bgActive : bgDefault
              }`}
            >
              <FaExpeditedssl size={35} className='text-primary' />
              <span className='fs-4'>Безопасно</span>
            </Stack>
          </Col>

          <Col
            sm={12}
            md={12}
            lg={4}
            style={{ maxHeight: '150px' }}
            onClick={() => clickOnItem(1)}
          >
            <Stack
              direction='row'
              spacing={2}
              className={`p-3  rounded-3 align-items-center my-2 ${
                index === 1 ? bgActive : bgDefault
              }`}
            >
              <FaUsersCog size={35} className='text-primary' />
              <span className='fs-4'>Типы цен</span>
            </Stack>
          </Col>

          <Col
            sm={12}
            md={12}
            lg={4}
            style={{ maxHeight: '150px' }}
            onClick={() => clickOnItem(2)}
          >
            <Stack
              direction='row'
              spacing={2}
              className={`p-3  rounded-3 align-items-center my-2 ${
                index === 2 ? bgActive : bgDefault
              }`}
            >
              <FaTags size={35} className='text-primary' />
              <span className='fs-4'>Лента новостей</span>
            </Stack>
          </Col>

          <Col
            sm={12}
            md={12}
            lg={4}
            style={{ maxHeight: '150px' }}
            onClick={() => clickOnItem(3)}
          >
            <Stack
              direction='row'
              spacing={2}
              className={`p-3  rounded-3 align-items-center my-2 ${
                index === 3 ? bgActive : bgDefault
              }`}
            >
              <FaPhoneSlash size={35} className='text-primary' />
              <span className='fs-4'>Никаких звонков</span>
            </Stack>
          </Col>

          <Col
            sm={12}
            md={12}
            lg={4}
            style={{ maxHeight: '150px' }}
            onClick={() => clickOnItem(4)}
          >
            <Stack
              direction='row'
              spacing={2}
              className={`p-3  rounded-3 align-items-center my-2 ${
                index === 4 ? bgActive : bgDefault
              }`}
            >
              <FaMobileAlt size={35} className='text-primary' />
              <span className='fs-4'>Компьютер не нужен</span>
            </Stack>
          </Col>

          <Col
            sm={12}
            md={12}
            lg={4}
            style={{ maxHeight: '150px' }}
            onClick={() => clickOnItem(5)}
          >
            <Stack
              direction='row'
              spacing={2}
              className={`p-3  rounded-3 align-items-center my-2 ${
                index === 5 ? bgActive : bgDefault
              }`}
            >
              <FaRoute size={35} className='text-primary' />
              <span className='fs-4'>Расширение</span>
            </Stack>
          </Col>
        </Row>
      </Box>
    </>
  );
};

export default WelcomeProvider;
