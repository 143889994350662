import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Message from '../components/Message';
import ToolsPanel from '../components/ToolsPanel';
import {
  Accordion,
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
  Stack,
} from 'react-bootstrap';
import { LinearProgress } from '@mui/material';
import { toast } from 'react-toastify';
import {
  useCreateUpdateRouteMutation,
  useGetRouteDetailsQuery,
  useGetRoutePointsQuery,
} from '../slices/routesApiSlice';
import GroupListSimple from '../components/GroupList/GroupListSimple';

const ManagerRoutesEditScreen = () => {
  const { routeId } = useParams();
  //console.log('groupId', groupId); //id || new

  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [shortName, setShortName] = useState('');
  const [code, setCode] = useState('');
  const [children, setChildren] = useState([]);
  const { theme, currentBreakpoint, pov } = useSelector((state) => state.auth);
  const { data, refetch, isLoading, isError, error } = useGetRouteDetailsQuery({
    routeId,
  });
  const { data: points } = useGetRoutePointsQuery(routeId);

  useEffect(() => {
    if (Array.isArray(data?.children)) {
      setChildren(data.children);
    }
    if (data && data?.row) {
      const row = data?.row;
      setName(row?.name || '');
      setShortName(row?.shortName || '');
      setCode(row?.code || '');
    }
  }, [data]);

  const [createUpdateRoute, { isLoading: loadingUpdate }] =
    useCreateUpdateRouteMutation();

  const save = async () => {
    if (name) {
      const payload = {
        routeId,
        name,
        shortName,
        code,
      };

      try {
        await createUpdateRoute(payload).unwrap();
        refetch();
        navigate(`/${pov}/manager/routes`);
      } catch (error) {
        toast.error(error?.data?.message || error.error);
      }
    }
  };

  const submitHandler = (event) => {
    event.preventDefault();
    if (!name) {
      toast.warning('Пустое наименование недопустимо');
      return;
    }
    save();
  };

  const themeStyle = {
    backgroundColor: theme === 'dark' ? '#212529' : 'white',
    borderColor: theme === 'dark' ? '#212529' : '#b5c0c1',
    color: theme === 'dark' ? 'white' : 'black',
  };

  return (
    <>
      <ToolsPanel
        //buttons={buttons}
        backURL={`/${pov}/manager/routes/`}
        title={`Маршрут: ${name === 'new' ? 'Новый' : name}`}
        isLoading={loadingUpdate}
      />
      {isLoading ? (
        <LinearProgress />
      ) : isError ? (
        <Message>{error?.data?.message || error.error}</Message>
      ) : (
        <Container>
          <Row>
            <Col lg={12} xl={8} xxl={8}>
              <Form onSubmit={submitHandler}>
                <Card
                  className={`bg-transparent ${
                    currentBreakpoint === 'xs' || currentBreakpoint === 'sm'
                      ? 'border-0 shadow-none'
                      : 'p-3'
                  }`}
                >
                  <Form.Group as={Row} controlId='name' className='my-2'>
                    <Form.Label column sm='3' className='pb-0'>
                      Наименование
                    </Form.Label>
                    <Col sm='9'>
                      <Form.Control
                        isInvalid={!name}
                        style={themeStyle}
                        type='text'
                        placeholder='Наименование'
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      ></Form.Control>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} controlId='shortname' className='my-2'>
                    <Form.Label column sm='3' className='pb-0'>
                      Краткое наименование
                    </Form.Label>
                    <Col sm='9'>
                      <Form.Control
                        style={themeStyle}
                        type='text'
                        placeholder='Введите краткое наименование'
                        value={shortName}
                        onChange={(e) => setShortName(e.target.value)}
                      ></Form.Control>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} controlId='code' className='my-2'>
                    <Form.Label column sm='3' className='pb-0'>
                      Код
                    </Form.Label>
                    <Col sm='9'>
                      <Form.Control
                        style={themeStyle}
                        type='text'
                        placeholder='Код из вашей системы учёта'
                        value={code}
                        onChange={(e) => setCode(e.target.value)}
                      ></Form.Control>
                    </Col>
                  </Form.Group>
                </Card>
                <Stack
                  direction='horizontal'
                  gap={3}
                  //class='justify-content-center'
                >
                  <Button
                    type='button'
                    variant='primary'
                    className='my-2'
                    onClick={submitHandler}
                    size={
                      ['lg', 'xl', 'xxl'].includes(currentBreakpoint)
                        ? 'lg'
                        : 'sm'
                    }
                  >
                    Сохранить
                  </Button>
                  <Button
                    size={
                      ['lg', 'xl', 'xxl'].includes(currentBreakpoint)
                        ? 'lg'
                        : 'sm'
                    }
                    variant='primary'
                    className='my-2'
                    onClick={() => navigate(`/${pov}/manager/routes/`)}
                  >
                    Закрыть
                  </Button>
                </Stack>
              </Form>
            </Col>
            {!['new'].includes(routeId) &&
              Array.isArray(points?.rows) &&
              points.rows.length > 0 && (
                <Col lg={12} xl={4} xxl={4} className='mt-3'>
                  <Accordion
                    defaultActiveKey='1'
                    //className='position-fixed me-4'
                  >
                    <Accordion.Item eventKey='1'>
                      <Accordion.Header>Состав:</Accordion.Header>
                      <Accordion.Body>
                        <GroupListSimple rows={points.rows} />
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </Col>
              )}
          </Row>
        </Container>
      )}
    </>
  );
};

export default ManagerRoutesEditScreen;
