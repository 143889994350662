import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  setAnimateMenuIcon,
  setCurrentGroup,
  setCurrentProvider,
  setPriceEditMode,
  setShowLeftMenu,
} from '../slices/authSlice';
import ToolsPanel from '../components/ToolsPanel/ToolsPanel';
import { LinearProgress } from '@mui/material';
import Message from '../components/Message';
import GroupListSimple from '../components/GroupList/GroupListSimple';
import { FaHome, FaPlus } from 'react-icons/fa';
import WelcomeProvider from '../components/welcom/WelcomeProvider';
import { useGetProvidersOfUserQuery } from '../slices/providersApiSlice';

const ProviderSelectProviderScreen = () => {
  const { search } = useLocation();
  const sp = new URLSearchParams(search);
  const back = sp.get('back');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { currentCustomer, currentProvider, theme } = useSelector(
    (state) => state.auth
  );

  const { data, isLoading, isError, error } = useGetProvidersOfUserQuery(
    {},
    { refetchOnMountOrArgChange: true }
  );

  //console.log('useGetProvidersQuery', data);
  // const [checkContract] = useCheckExistContractMutation();
  // const [createContract] = useCreateContractMutation();

  const handleSelectUnit = async (provider) => {
    if (!provider?._id) {
      return;
    }
    dispatch(setCurrentGroup(''));
    dispatch(setShowLeftMenu(true));
    dispatch(setAnimateMenuIcon(false));
    dispatch(setCurrentProvider(provider));
    dispatch(setPriceEditMode(true));
    navigate('/provider');
  };

  const handleClickAdd = (unit) => {
    // navigate('/user/provider/new/edit');
    navigate('/provider/create/provider/');
  };

  // const handleClickSearch = () => {
  //   navigate(`/customer/search/provider/`);
  // };

  let buttons = [];
  buttons.push({
    className: `bg-transparent`,
    name: 'Создать поставщика',
    type: 'component',
    action: handleClickAdd,
    component: (props) => {
      return <FaPlus size={22} color={theme === 'dark' ? 'white' : 'black'} />;
    },
  });

  return (
    <>
      {isLoading ? (
        <LinearProgress />
      ) : isError ? (
        <Message>{error?.data?.message || error?.error}</Message>
      ) : (
        <>
          <ToolsPanel
            backIcon={currentProvider?._id ? <FaHome size={22} /> : null}
            backURL={
              currentProvider?._id ? '/provider' : back ? back : '/start'
            }
            buttons={buttons}
            title={`Список поставщиков`}
          />
          <Container>
            <Row>
              <Col>
                {data?.rows?.length === 0 ? (
                  <>
                    <Container>
                      <Row>
                        <Col>
                          <WelcomeProvider
                            handleClickCreate={handleClickAdd}
                            theme={theme}
                          />
                        </Col>
                      </Row>
                    </Container>
                  </>
                ) : (
                  <>
                    {Array.isArray(data?.rows) && (
                      <GroupListSimple
                        variant=''
                        rows={data.rows}
                        onClickItem={handleSelectUnit}
                        currentId={currentCustomer?._id}
                      />
                    )}
                    {/* <LinkContainer to={'/user/invite/'} className='m-2'>
                      <span>У меня есть код</span>
                    </LinkContainer> */}
                  </>
                )}
              </Col>
            </Row>
          </Container>
        </>
      )}
    </>
  );
};

export default ProviderSelectProviderScreen;
