import { createSlice } from '@reduxjs/toolkit';

const initialState = localStorage.getItem('menu')
	? JSON.parse(localStorage.getItem('menu'))
	: { menuItems: [] };

const menuSlice = createSlice({
	name: 'menu',
	initialState,
	reducers: {
		addItems: (state, action) => {
			return state.menuItems = action.payload
		},
		removeItem: (state, action) => {
			return state.menuItems = state.menuItems
				.filter((item) => item._id === action.payload);
		},
		changeName: (state, action) => {
			const { _id, name } = action.payload;
			const clone = [...state.menuItems];
			for (let index = 0; index < clone.length; index++) {
				const element = clone[index];
				if (element._id === _id) {
					element.name = name;
					break
				}
				if (element?.children && element.children.length > 0) {
					const indx = element.children.find(el => el._id === _id);
					if (indx) {
						element.children[indx].name = name;
						break
					}
				}
			}
			return state.menuItems = clone;
		},
		addNewItem: (state, action) => {
			return state.menuItems = [action.payload, ...state.menuItems];
		},
		changeParent: (state, action) => {
			const { _id, oldParentCode, newParentCode } = action.payload;
			if (oldParentCode) {
				//из одной группы перемещаем в другую
				const oldParent = { ...state.menuItems.find(item => item.code === oldParentCode) };
				const newParent = { ...state.menuItems.find(item => item.code === newParentCode) };
				const targetItem = { ...oldParent.children.find(item => item._id === _id) };
				targetItem.parentCode = newParentCode;
				const updatedOldParent = oldParent.children.filter(item => item._id !== _id);
				const updatedNewParent = newParent.children = [...newParent.children, targetItem];

				return state.menuItems = state.menuItems.map(item => {
					if (item.code === oldParentCode) {
						return updatedOldParent;
					}
					if (item.code === newParentCode) {
						return updatedNewParent
					}
					return item;
				})
			}
			if (oldParentCode === '') {
				//из корневого уровня перемещаем внутри группы
				const targetItem = { ...state.menuItems.find(item => item._id === _id) };
				const newParent = { ...state.menuItems.find(item => item.code === newParentCode) };
				targetItem.parentCode = newParentCode;
				const updatedState = state.menuItems.filter(item => item._id !== _id);
				const updatedNewParent = newParent.children = [...newParent.children, targetItem];

				return state.menuItems = updatedState.map(item => {
					if (item.code === newParentCode) {
						return updatedNewParent;
					}
					return item;
				})
			}
			if (newParentCode === '') {
				//вынимаем из группы на корневой уровень
				const oldParent = { ...state.menuItems.find(item => item.code === oldParentCode) };
				const targetItem = { ...oldParent.children.find(item => item._id === _id) };
				targetItem.parentCode = newParentCode;
				const updatedOldParent = oldParent.children.filter(item => item._id !== _id);
				const updatedState = state.menuItems.map(item => {
					if (item.code === oldParentCode) {
						return updatedOldParent
					}
					return item;
				});
				return [targetItem, ...updatedState]
			}
		}
	}
});

export const { addToCart, removeItem, changeName, addNewItem, changeParent } = menuSlice.actions; //экспорт для вызова в компонентах
export default menuSlice.reducer; //экспорт для добавления в store
