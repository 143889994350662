import React from 'react';
import { ListGroup } from 'react-bootstrap';
import { FaCheck } from 'react-icons/fa';

const ListItem = ({
  idx,
  item,
  cid,
  className,
  theme,
  onClick,
  secondParam,
  buttons,
  keyTitle = 'name',
  keyCount = 'count',
  keyArray = '',
  title = 'Бузымянный',
}) => {
  return (
    <ListGroup.Item
      as={'div'}
      active={item?._id === cid}
      action
      className={`p-0 list-group-item d-flex justify-content-between align-items-center ${
        className ? className : ''
      }`}
      variant={theme}
      key={item?._id ? item?._id : idx}
    >
      <span
        className='p-2 w-100 d-flex overflow-hidden'
        role='button'
        onClick={() => onClick(item, secondParam)}
      >
        {item[keyTitle] ? item[keyTitle] : title}
      </span>
      <span className='d-flex'>
        {item[keyCount] && <span className='d-flex'>{item[keyCount]}</span>}
        {keyArray &&
          Array.isArray(item[keyArray]) &&
          item[keyArray].length > 0 && (
            <span className='d-flex  p-0'>
              <FaCheck />
            </span>
          )}
        {Array.isArray(buttons) && buttons.length > 0 && (
          <span className='d-flex  p-0'>
            {buttons.map((button, index) => (
              <span key={index}>
                {button?.cond ? (
                  <span className='me-1'>
                    {item[button.cond.fieldName] === button.cond.fieldValue
                      ? button.cond.icon
                      : ''}
                  </span>
                ) : (
                  <button
                    key={index}
                    type='button'
                    className={`btn btn-sm btn-outline-primary ${
                      button?.icon ? 'border-0' : ''
                    }`}
                    onClick={() => button.onClick(item)}
                  >
                    {button?.icon ? button.icon : button.name}
                  </button>
                )}
              </span>
            ))}
          </span>
        )}
      </span>
    </ListGroup.Item>
  );
};

export default ListItem;
