import { Outlet, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const CheckPovRoute = () => {
  const { pov } = useSelector((state) => state.auth);

  return pov ? <Outlet /> : <Navigate to='/start' replace />;
};

export default CheckPovRoute;
