import { UPLOAD_URL } from '../constans';
import { apiSlice } from './apiSlice';

export const imagesApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		uploadImage: builder.mutation({
			query: (data) => ({
				url: UPLOAD_URL,
				method: 'POST',
				body: data
			}),
		}),

	})
});

export const { useUploadImageMutation } = imagesApiSlice;