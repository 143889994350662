import { GROUPS_URL } from '../constans';
import { apiSlice } from './apiSlice'

export const groupsApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		bindProductToGroup: builder.query({
			query: (data) => ({
				url: `${GROUPS_URL}/${data.groupId}/${data.productId}`,
				method: 'GET',
			}),
			keepUnusedDataFor: 5,
			invalidatesTags: ['ProviderProductMenu'],
		}),
		setParentCode: builder.mutation({
			query: (data) => ({
				url: `${GROUPS_URL}/bind`,
				method: 'POST',
				body: {
					groupId: data?.groupId,
					parentId: data?.parentId,
					providerId: data?.providerId
				}
			}),
			keepUnusedDataFor: 5
		}),
		getGroupsToEdit: builder.query({
			query: (data) => ({
				url: `${GROUPS_URL}/edit/`,
				params: {
					...data
				}
			}),
			keepUnusedDataFor: 5,
		}),
		getGroups: builder.query({
			query: (data) => ({
				url: `${GROUPS_URL}`,
				params: {
					...data
				}
			}),
			keepUnusedDataFor: 5,
		}),
		getGroupDetails: builder.query({
			query: (data) => ({
				url: `${GROUPS_URL}/${data?.groupId}`,
				params: {
					providerId: data?.providerId
				}
			}),
			keepUnusedDataFor: 5,
		}),
		getGroupChildren: builder.query({
			query: (data) => ({
				url: `${GROUPS_URL}/children`,
				params: {
					providerId: data?.providerId,
					groupId: data?.groupId
				}
			}),
			keepUnusedDataFor: 5,
		}),
		createGroup: builder.mutation({
			query: (data) => ({
				url: `${GROUPS_URL}`,
				method: 'POST',
				body: { ...data }
			}),
			invalidatesTags: ['ProviderProductMenu'],
		}),
		updateGroup: builder.mutation({
			query: (data) => ({
				url: `${GROUPS_URL}/${data.groupId}`,
				method: 'PUT',
				body: data
			}),
			invalidatesTags: ['ProviderProductMenu'],
		}),
		updateGroupSort: builder.mutation({
			query: (data) => ({
				url: `${GROUPS_URL}/resort`,
				method: 'POST',
				body: {
					itemList: data?.itemList,
					providerId: data?.providerId
				}
			}),
			invalidatesTags: ['ProviderProductMenu'],
		}),
		// updateGroupValue: builder.mutation({
		// 	query: (data) => ({
		// 		url: `${GROUPS_URL}/${data._id}`,
		// 		method: 'POST',
		// 		body: data
		// 	}),
		// 	invalidatesTags: ['ProviderProductMenu'],
		// }),
		updateGroups: builder.mutation({
			query: (data) => ({
				url: `${GROUPS_URL}/menu`,
				method: 'POST',
				body: {
					itemList: data,
				}
			}),
			invalidatesTags: ['ProviderProductMenu'],
		}),
		deleteGroup: builder.mutation({
			query: (data) => ({
				url: `${GROUPS_URL}/${data?.groupId}`,
				method: 'DELETE',
				params: {
					providerId: data?.providerId,
					groupId: data?.groupId
				}
			}),
			invalidatesTags: ['ProviderProductMenu'],
		}),
	})
})

export const {
	useGetGroupChildrenQuery,
	useUpdateGroupSortMutation,
	useGetGroupsToEditQuery,
	useSetParentCodeMutation,
	// useUpdateGroupValueMutation,
	useBindProductToGroupQuery,
	useGetGroupsQuery,
	useGetGroupDetailsQuery,
	useCreateGroupMutation,
	useDeleteGroupMutation,
	useUpdateGroupMutation,
	useUpdateGroupsMutation,
} = groupsApiSlice;