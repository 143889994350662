import { PRICESVALUE_URL, PRICES_URL } from '../constans';
import { apiSlice } from './apiSlice'

export const pricesApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		savePriceValue: builder.mutation({
			query: (data) => ({
				url: PRICESVALUE_URL,
				method: 'POST',
				body: data
			}),
			keepUnusedDataFor: 5
		}),
		getPrices: builder.query({
			query: () => ({
				url: PRICES_URL,
			}),
			providesTags: ['Price'],
			keepUnusedDataFor: 5
		}),
		getProviderPrices: builder.query({
			query: (data) => ({
				url: `${PRICES_URL}/provider/${data?.providerId}`,
				params: {
					...data
				}
			}),
			providesTags: ['Price'],
			keepUnusedDataFor: 5
		}),
		getPriceDetails: builder.query({
			query: (data) => ({
				url: `${PRICES_URL}/${data?.priceId}`,
				params: {
					providerId: data?.providerId,
					getcustomers: data?.getcustomers,

				}
			}),
			keepUnusedDataFor: 5,
			invalidatesTags: ['Price']
		}),
		createPrice: builder.mutation({
			query: (data) => ({
				url: `${PRICES_URL}`,
				method: 'POST',
				body: { ...data }
			}),
			invalidatesTags: ['Price'] //очистить ранее созданный кеш
		}),
		updatePrice: builder.mutation({
			query: (data) => ({
				url: `${PRICES_URL}/${data?.priceId}`,
				method: 'PUT',
				body: data
			}),
			invalidatesTags: ['Price'] //очистить ранее созданный кеш
		}),
		deletePrice: builder.mutation({
			query: (PriceId) => ({
				url: `${PRICES_URL}/${PriceId}`,
				method: 'DELETE'
			}),
			invalidatesTags: ['Price'] //очистить ранее созданный кеш
		}),
	})
})

export const {
	useSavePriceValueMutation,
	useGetProviderPricesQuery,
	useCreatePriceMutation,
	useDeletePriceMutation,
	useGetPriceDetailsQuery,
	useGetPricesQuery,
	useUpdatePriceMutation
} = pricesApiSlice;