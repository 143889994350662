import {
  Chip,
  Collapse,
  LinearProgress,
  List,
  ListItemButton,
  ListItemText,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import Message from '../../Message';
import { useGetUserRoutesMenuQuery } from '../../../slices/routesApiSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { setCurrentRoute } from '../../../slices/authSlice';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Stack } from 'react-bootstrap';

const RoutesMenu = ({ handleDrawerClose }) => {
  const { pathname } = useLocation();
  const { customerId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [openId, setOpenId] = useState('');
  const [selectedId, setSelectedId] = useState(customerId);
  const [openedGroups, setOpenedGroups] = useState([]);
  const [openedGroup, setOpenedGroup] = useState('');

  const { currentProvider, pov, currentRoute } = useSelector(
    (state) => state.auth
  );
  const { data, isLoading, isError, error } = useGetUserRoutesMenuQuery(
    {
      withPoints: pov === 'provider',
      providerId: pov === 'provider' ? currentProvider?._id : '',
    },
    { refetchOnMountOrArgChange: true }
  );

  // console.log('data', data);

  useEffect(() => {
    if (customerId !== selectedId) {
      setSelectedId(customerId);
    }
    //eslint-disable-next-line
  }, [customerId]);

  // useEffect(() => {
  //   //если мы пришли по ссылке в которой в параметрах указан id покупателя
  //   //то сначала мы устанавливаем id в качестве текущего
  //   //затем, отлавливаем тут изменение текущего id
  //   //и вычисляем первую группу, к которой относится этот id
  //   //и устанавливаем группу в качестве открытой
  //   if (selectedId && Array.isArray(data?.rows)) {
  //     for (let index = 0; index < data.rows.length; index++) {
  //       const group = data.rows[index];
  //       if (group._id === 'empty') {
  //         continue;
  //       }
  //       const exist = group.children.find((item) => item._id === selectedId);
  //       if (exist) {
  //         setOpenedGroups([group._id]);
  //         break;
  //       }
  //     }
  //   }
  //   //eslint-disable-next-line
  // }, [selectedId]);

  const handleGroupClick = (_id, name) => {
    if (openedGroup === _id) {
      setOpenedGroup('');
    } else {
      setOpenedGroup(_id);
    }
    // if (openedGroups.includes(id)) {
    //   setOpenedGroups(openedGroups.filter((item) => item !== id));
    // } else {
    //   setOpenedGroups([...openedGroups, id]);
    // }
    dispatch(setCurrentRoute({ _id, name }));
  };

  const handleClick = (item, children) => {
    if (pov === 'customer') {
      dispatch(setCurrentRoute(item));
      navigate(`/customer/select/customer/${item._id}`);
      handleDrawerClose(true);
    } else if (pov === 'provider') {
      if (item._id === openId) {
        setOpenId('');
      } else {
        setOpenId(item._id);
      }
      // setSelectedId(item._id);
      handleGroupClick(item._id, item?.name);
      // if (Array.isArray(children) && children.length > 0) {
      //   handleGroupClick(item._id);
      //   //не реагируем, а лишь раскрываем подменю
      // } else {
      //   //устанавливаем в документ Покупателя
      //   // if (pov === 'provider') {
      //   //   navigate(`/provider/products/${item._id}`);
      //   // } else {
      //   //   navigate(`/customer/order/${item._id}`);
      //   // }
      //   handleDrawerClose();
      //   // dispatch(setGridSettingMode(false));
      // }
    }
  };

  const handleClickSublevel = (item) => {
    setSelectedId(item._id);

    // if (pov === 'provider') {
    //   navigate(`/provider/products/${item._id}`);
    // } else {
    navigate(`/provider/orders/new/${item._id}`);
    // }
    handleDrawerClose();
  };

  return (
    <>
      {isLoading ? (
        <LinearProgress />
      ) : isError ? (
        <Message>
          {error?.data?.message || error?.message || error.error}
        </Message>
      ) : (
        <>
          <Stack direction='horizontal' gap={1} className='mx-auto'>
            <div
              onClick={() =>
                navigate(`/provider/manager/routes/`, {
                  state: { backUrl: pathname },
                })
              }
              className='m-2 text-muted text-decoration-underline'
              role='button'
            >
              Редактор меню
            </div>
          </Stack>
          <List
            sx={{
              '& .Mui-selected': {
                backgroundColor:
                  pov === 'customer'
                    ? '#1976d261 !important'
                    : '#2fd21961 !important',
              },
            }}
          >
            {Array.isArray(data?.rows) &&
              data.rows.map((group, index) => (
                <span key={group._id || index}>
                  <ListItemButton
                    sx={{ borderLeftWidth: '3px !important' }}
                    className={
                      openId === group._id
                        ? pov === 'customer'
                          ? 'border-info border-start '
                          : 'border-success border-start '
                        : ''
                    }
                    onClick={() => handleClick(group, group.children)}
                    selected={
                      group.children?.length > 0
                        ? selectedId === group._id
                        : currentRoute?._id === group._id
                    }
                  >
                    <ListItemText primary={group.name} />
                    {Array.isArray(group?.children) && (
                      <>
                        {group._id === currentRoute?._id &&
                        group._id === openedGroup ? (
                          <ExpandLess />
                        ) : (
                          <ExpandMore />
                        )}
                      </>
                    )}
                    {/* {Array.isArray(group?.children) &&
                    group.children.length > 0 ? (
                      openedGroups.includes(group._id) ? (
                        <ExpandLess />
                      ) : (
                        <ExpandMore />
                      )
                    ) : (
                      <></>
                    )} */}
                  </ListItemButton>

                  {Array.isArray(group?.children) &&
                    group.children.length > 0 && (
                      <Collapse
                        className={
                          pov === 'customer'
                            ? 'border-info border-start border-bottom'
                            : 'border-success border-start border-bottom'
                        }
                        sx={{ borderLeftWidth: '3px !important' }}
                        // in={openId === group._id}
                        // in={openedGroups.includes(group._id)}
                        in={openedGroup === group._id}
                        timeout='auto'
                        unmountOnExit
                      >
                        <List component='div' disablePadding>
                          {group.children.map((sub) => (
                            <ListItemButton
                              sx={{ pl: 2 }}
                              key={sub._id}
                              onClick={() => handleClickSublevel(sub)}
                              selected={selectedId === sub._id}
                            >
                              <ListItemText primary={sub.name} />
                              {sub?.count && sub.count > 0 ? (
                                <Chip
                                  label={sub.count}
                                  size='small'
                                  color={
                                    pov === 'customer' ? 'primary' : 'success'
                                  }
                                  variant='outlined'
                                />
                              ) : (
                                <></>
                              )}
                            </ListItemButton>
                          ))}
                        </List>
                      </Collapse>
                    )}
                </span>
              ))}
          </List>
        </>
      )}
    </>
  );
};

export default RoutesMenu;
