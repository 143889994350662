import { Alert } from '@mui/material';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
// import { AddressSuggestions } from 'react-dadata';
// import { PartySuggestions } from 'react-dadata';
import 'react-dadata/dist/react-dadata.css';
import { useSelector } from 'react-redux';
import ToolsPanel from '../components/ToolsPanel';

const ProviderCreateCustomerScreen = () => {
  const { currentProvider } = useSelector((state) => state.auth);
  //   const [value, setValue] = useState();
  return (
    <>
      <ToolsPanel backURL={'/provider/contracts/'} title={``} />
      <Container>
        <Row>
          <Col>
            <Alert
              severity='info'
              variant='outlined'
              icon={false}
              className='mt-3'
            >
              Вариант заключения договора с покупателем:
              <br />
              <br /> 1. сменить роль и выбрать "Покупатель"
              <br /> 2. перейти на страницу "Мои покупатели"
              <br /> 3. создать Покупателя
              <br /> 4. перейти на страницу "Выбрать поставщика"
              <br /> 5. найти и кликнуть на поставщика {currentProvider?.name}.
            </Alert>
            {/* <Alert
            severity='success'
            variant='outlined'
            icon={false}
            className='mt-3'
          >
            Но есть короткий вариант, прямо тут создать покупателя:
            <br />
            <br /> 1. сменить роль и выбрать "Покупатель"
            <br /> 2. перейти на страницу "Мои покупатели"
            <br /> 3. создать Покупателя
            <br /> 4. перейти на страницу "Выбрать поставщика"
            <br /> 5. найти и кликнуть на поставщика {currentProvider?.name}.
          </Alert> */}
          </Col>
        </Row>
        {/* <AddressSuggestions
        token='8831349f1c7880fed16beb8f19b4780b6b4ae335'
        value={value}
        onChange={setValue}
      />

      <PartySuggestions
        token='8831349f1c7880fed16beb8f19b4780b6b4ae335'
        value={value}
        onChange={setValue}
      /> */}
      </Container>
    </>
  );
};

export default ProviderCreateCustomerScreen;
