import React, { useState } from 'react';
import { useGetAllSectionsQuery } from '../../../slices/sectionsApiSlice';
import {
  Col,
  Container,
  ListGroup,
  Row,
  Spinner,
  Stack,
} from 'react-bootstrap';
import Message from '../../Message';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  setCurrentSection,
  setMobileMenuIsOpen,
  setSearchString,
} from '../../../slices/authSlice';
import {
  FormControlLabel,
  LinearProgress,
  Switch,
  Toolbar,
} from '@mui/material';
import {
  Chip,
  Collapse,
  Divider,
  List,
  ListItemButton,
  ListItemText,
} from '@mui/material';

const SectionsMenu = ({ handleDrawerClose }) => {
  const { search } = useLocation();
  const sp = new URLSearchParams(search);
  const sectionId = sp.get('section');
  const { theme, selectedCitiesIds } = useSelector((state) => state.auth);

  const { data, isLoading, isError, error } = useGetAllSectionsQuery(
    {
      citiesIds: selectedCitiesIds,
    },
    { refetchOnMountOrArgChange: true }
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showAll, setShowAll] = useState(false);

  const onClickHandler = (item) => {
    handleDrawerClose(false);
    dispatch(setCurrentSection(item));
    dispatch(setSearchString(''));
    dispatch(setMobileMenuIsOpen(false));
    navigate(`/customer/search/provider/?section=${item._id}`);
  };

  let rows = data?.rows;
  if (!showAll) {
    rows = data?.rows?.filter((row) => row?.count > 0);
  }

  if (1) {
    return (
      <>
        {isLoading ? (
          <LinearProgress />
        ) : isError ? (
          <Message>{error?.data?.message || error.error}</Message>
        ) : (
          <>
            <Toolbar className='mx-1 p-2'>
              <Stack direction='horizontal' gap={4}>
                <span>Категории</span>
                <FormControlLabel
                  control={
                    <Switch
                      checked={showAll}
                      onChange={() => setShowAll(!showAll)}
                    />
                  }
                  label='Все'
                />
              </Stack>
            </Toolbar>
            <Divider color={theme === 'dark' ? 'white' : 'black'} />
            {/* <ListItemButton
              sx={{ borderLeftWidth: '3px !important' }}
              className={'border-info'}
            >
              <ListItemText primary={'Категории:'} />
            </ListItemButton> */}
            <List
              sx={{
                '& .Mui-selected': { backgroundColor: '#1976d261 !important' },
              }}
            >
              <Collapse in={true} unmountOnExit>
                {Array.isArray(rows) &&
                  rows.map((item, index) => (
                    <ListItemButton
                      key={item?._id || index}
                      sx={{ borderLeftWidth: '3px !important' }}
                      onClick={() => onClickHandler(item)}
                      selected={sectionId === item._id}
                      className={
                        sectionId === item._id
                          ? 'border-info border-start '
                          : ''
                      }
                    >
                      <ListItemText primary={item.name} />
                      {!!item?.count && (
                        <Chip
                          label={item?.count}
                          size='small'
                          color='primary'
                          variant='outlined'
                        />
                      )}
                    </ListItemButton>
                  ))}
              </Collapse>
            </List>
          </>
        )}
      </>
    );
  }

  // return (
  //   <>
  //     {isLoading ? (
  //       <LinearProgress />
  //     ) : isError ? (
  //       <Message>{error?.data?.message || error.error}</Message>
  //     ) : (
  //       <Container>
  //         <Row className='p-0'>
  //           <Col>
  //             <ListGroup
  //               //bsPrefix='narrow'
  //               variant='flush'
  //             >
  //               {sections &&
  //                 sections.map((item) => (
  //                   <ListGroup.Item
  //                     className={`px-0 py-1 ${
  //                       sectionId === item._id && 'ps-2'
  //                     }`}
  //                     active={sectionId === item._id}
  //                     onClick={() => onClickHandler(item)}
  //                     variant={theme}
  //                     action
  //                     key={item._id}
  //                     //className='d-flex justify-content-between align-items-start py-1'
  //                   >
  //                     {item.name}
  //                   </ListGroup.Item>
  //                 ))}
  //             </ListGroup>
  //           </Col>
  //         </Row>
  //       </Container>
  //     )}
  //   </>
  // );
};

export default SectionsMenu;
