import React, { useEffect, useState } from 'react';
import { Button, Form, ListGroup, Stack } from 'react-bootstrap';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import DraggableList from '../DraggableList';
import ListItem from '../ListItem/ListItem';

const SortableList = ({
  updateParent,
  secondParam,
  updateList,
  refetch,
  rows,
  onClickItem,
  cid,
  updateKey,
  buttons,
  keyTitle = 'name',
  keyCount = 'count',
  keyArray = '',
  emptyName = 'Бузымянный',
  className,
}) => {
  const { theme, currentBreakpoint } = useSelector((state) => state.auth);
  const [itemList, setItemList] = useState([]);
  const [itemListChanged, setItemListChanged] = useState(false);
  const [sortMode, setSortMode] = useState(false);

  useEffect(() => {
    if (Array.isArray(rows)) {
      setItemList(rows);
    }
  }, [rows]);

  const onDragEnd = (result) => {
    if (!itemListChanged) {
      setItemListChanged(true);
    }
    if (!result.destination) return;
    const items = Array.from(itemList);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setItemList(items);
  };

  const saveWork = async () => {
    const result = await updateList(itemList, updateParent);
    if (result === true) {
      setItemListChanged(false);
    } else {
      console.log(result);
      toast.error(result?.data?.message || result?.error || result);
    }
    setSortMode(false);
  };

  const handleChangeSortMode = (value) => {
    if (sortMode && itemListChanged) {
      //refetch();
      updateKey();
    }
    setSortMode(Boolean(value));
    setItemListChanged(false);
  };

  return (
    <>
      {itemList?.length > 1 && (
        <Stack direction='horizontal' className='justify-content-between  mb-3'>
          <Form.Check
            className={`align-self-center ${sortMode ? 'text-warning' : ''}`}
            type='switch'
            label={`${
              ['xs', 'sm'].includes(currentBreakpoint) ? 'Сорт.' : 'Cортировка'
            }`}
            checked={sortMode}
            onChange={(e) => handleChangeSortMode(e.target.checked)}
          ></Form.Check>
          {itemListChanged && (
            <Button
              onClick={saveWork}
              size='sm'
              variant={!itemListChanged ? 'outline-secondary' : 'success'}
            >
              Сохранить
            </Button>
          )}
        </Stack>
      )}
      {!sortMode ? (
        <ListGroup>
          {itemList &&
            itemList.map((item, index) => (
              <ListItem
                key={item._id || index}
                idx={index}
                className={className}
                keyTitle={keyTitle}
                keyCount={keyCount}
                keyArray={keyArray}
                emptyName={emptyName}
                secondParam={secondParam}
                cid={cid}
                item={item}
                theme={theme}
                onClick={onClickItem}
                buttons={buttons}
              />
            ))}
        </ListGroup>
      ) : (
        <ListGroup>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId='itemList'>
              {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {itemList &&
                    itemList.map((item, index) => (
                      <DraggableList
                        key={index}
                        id={item._id}
                        index={index}
                        isDisable={!sortMode}
                      >
                        <ListItem
                          idx={index}
                          className={`${className} border-warning rounded mb-1`}
                          keyTitle={keyTitle}
                          keyCount={keyCount}
                          keyArray={keyArray}
                          emptyName={emptyName}
                          secondParam={secondParam}
                          cid={cid}
                          item={item}
                          theme={theme}
                          onClick={onClickItem}
                          buttons={sortMode ? [] : buttons}
                        />
                      </DraggableList>
                    ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </ListGroup>
      )}
    </>
  );
};

export default SortableList;
