import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Button, Row, Col, Container } from 'react-bootstrap';
import { useRegisterMutation } from '../slices/usersApiSlice';
import { setCredentials, setShowLeftMenu } from '../slices/authSlice';
import { toast } from 'react-toastify'; //для вывода всплывающих сообщений
import ToolsPanel from '../components/ToolsPanel';

const RegisterScreen = () => {
  const [name, setName] = useState('');
  const [login, setLogin] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [register, { isLoading }] = useRegisterMutation();
  const { userInfo, theme, pov } = useSelector((state) => state.auth);

  //вот такая конструкция позволят получить значение параметра,
  //который передаётся в URL:
  const { search } = useLocation();
  const sp = new URLSearchParams(search);
  const redirect = sp.get('redirect') || '';

  useEffect(() => {
    //если пользователь авторизован
    dispatch(setShowLeftMenu(false));
    if (userInfo) {
      navigate(redirect);
    }
    // eslint-disable-next-line
  }, [userInfo, redirect, navigate]);

  const start = async () => {
    if (pov === 'provider') {
      window.location.href = `/select/provider/`;
    } else if (pov === 'customer') {
      window.location.href = `/select/customer/`;
    } else {
      window.location.href = `/start`;
    }
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      toast.error('Пароли не совпадают');
      return;
    } else {
      try {
        const user = await register({ name, login, email, password }).unwrap();
        dispatch(setCredentials({ ...user }));
        if (redirect) {
          navigate(redirect);
        } else {
          navigate(`/start`);
        }
      } catch (err) {
        toast.error(err?.data?.message || err.error);
      }
    }
  };

  const themeStyle = {
    backgroundColor: theme === 'dark' ? '#212529' : 'white',
    borderColor: theme === 'dark' ? '#212529' : '#b5c0c1',
    color: theme === 'dark' ? 'white' : 'black',
  };

  return (
    <>
      <ToolsPanel title='Регистрация' isLoading={isLoading} />
      <Container>
        <Row>
          <Col xs={12} sm={12} md={10} lg={6}>
            <Form onSubmit={submitHandler}>
              <Form.Group as={Row} controlId='name' className='my-3'>
                <Form.Label column sm='4' className='pb-0'>
                  Имя
                </Form.Label>
                <Col sm='8'>
                  <Form.Control
                    type='text'
                    style={themeStyle}
                    placeholder='Как к вам обращаться по телефону ?'
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  ></Form.Control>
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId='login' className='my-3'>
                <Form.Label column sm='4' className='pb-0'>
                  Логин
                </Form.Label>
                <Col sm='8'>
                  <Form.Control
                    type='text'
                    autoComplete='off'
                    style={themeStyle}
                    placeholder='Придумайте уникальный логин'
                    value={login}
                    onChange={(e) => setLogin(e.target.value)}
                  ></Form.Control>{' '}
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId='email' className='my-3'>
                <Form.Label column sm='4' className='pb-0'>
                  Мобильный номер или Email
                </Form.Label>
                <Col sm='8'>
                  <Form.Control
                    style={themeStyle}
                    type='email'
                    placeholder='Укажите свой номер сотового или почту'
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  ></Form.Control>
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId='password' className='my-3'>
                <Form.Label column sm='4' className='pb-0'>
                  Придумайте пароль
                </Form.Label>
                <Col sm='8'>
                  <Form.Control
                    autoComplete='off'
                    style={themeStyle}
                    type='password'
                    placeholder=''
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  ></Form.Control>
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId='password2' className='my-3'>
                <Form.Label column sm='4' className='pb-0'>
                  Повторите пароль
                </Form.Label>
                <Col sm='8'>
                  <Form.Control
                    autoComplete='off'
                    style={themeStyle}
                    type='password'
                    placeholder=''
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  ></Form.Control>
                </Col>
              </Form.Group>
              <Button
                type='submit'
                variant='primary'
                className='mt-3'
                disabled={isLoading}
              >
                Зарегистрироваться
              </Button>
            </Form>
            <Row className='py-3'>
              <Col>
                Уже есть учётная запись ?{' '}
                <Link
                  to={redirect ? `/login?redirect=${redirect}` : '/login'}
                  className={`link-${theme === 'dark' ? 'light' : 'dark'}`}
                >
                  Войти
                </Link>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default RegisterScreen;
