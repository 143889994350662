import React from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import ToolsPanel from '../components/ToolsPanel';
import {
  Accordion,
  Card,
  Col,
  Container,
  ListGroup,
  Row,
  Stack,
} from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Box, Chip, LinearProgress } from '@mui/material';
import { useGetCustomerProviderDetailsQuery } from '../slices/customersApiSlice';
import GroupListSimple from '../components/GroupList/GroupListSimple';
import GroupTwoColumnList from '../components/GroupList/GroupTwoColumnList/GroupTwoColumnList';
import Message from '../components/Message';
import { FaHome, FaPhoneAlt, FaUser, FaUserAstronaut } from 'react-icons/fa';
import ZoomableImage from '../components/Image/ZoomableImage';

const CustomerAboutProviderScreen = () => {
  const { search } = useLocation();
  const sp = new URLSearchParams(search);
  const back = sp.get('back');

  const { providerId } = useParams();
  const { currentProvider, theme } = useSelector((state) => state.auth);

  const onClickSection = (item) => {
    //
  };

  const { data, isLoading, isError, error } =
    useGetCustomerProviderDetailsQuery({
      providerId: providerId,
    });

  // console.log(data);

  if (!providerId || providerId === 'undefined') {
    return;
  }

  let backURL = '/customer/';
  if (back) {
    backURL = {
      pathname: '/customer/search/provider/',
      search: back,
    };
  }

  const fakeContacts = [
    {
      _id: '1',
      name: 'Иван',
      description: 'отдел продаж',
      image: '',
      phone: '89874569852',
      email: 'op@post.ru',
      url: 'vk.com/ivanpost',
    },
    {
      _id: '2',
      name: 'Ветрякова Светлана Николаевна фбу Владимировна',
      description: 'Бухгалтерия',
      image: '',
      phone: '89875285852',
      email: 'sv@post.ru',
      url: 'vk.com/svet23567',
    },
    {
      _id: '3',
      name: 'Светлана',
      description: 'Бухгалтерия',
      image: '',
      phone: '89875285852',
      email: 'sv@post.ru',
      url: 'vk.com/svet23567',
    },
  ];

  const ContactRow = ({ item }) => {
    return (
      <Row>
        <Col xs={6} className='text-wrap text-break align-self-center'>
          {item.name}
        </Col>
        <Col xs={6} sm={6} md={6} lg={6}>
          <Stack
            direction='vertical'
            gap={0}
            className='justify-content-center'
          >
            <a
              className='text-success text-decoration-none fw-medium'
              href={`tel:${item.phone}`}
            >
              {item.phone}
            </a>

            <span className=' text-wrap text-break'>{item.description}</span>
          </Stack>
        </Col>
      </Row>
    );
  };

  return (
    <>
      <ToolsPanel
        backIcon={<FaHome size={22} />}
        title={`О компании ${currentProvider?.name}`}
        backURL={backURL}
      />
      {isLoading ? (
        <LinearProgress />
      ) : isError ? (
        <Message>{error?.data?.message || error.error}</Message>
      ) : (
        <>
          <Container>
            {/* {Array.isArray(data?.contacts) && data?.contacts.length > 0 && (
              <Accordion className='me-1 mt-2' defaultActiveKey='0'>
                <Accordion.Item
                  eventKey='0'
                  style={{ backgroundColor: 'none !important' }}
                >
                  <Accordion.Header>Контакты:</Accordion.Header>
                  <Accordion.Body>
                  <Row xs={1} sm={2} md={3} className='g-2'> 
                    {data?.contacts.map((item, idx) => (
                      <ContactRow item={item} />
                    ))}
                   </Row> 
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            )} */}
            <Row>
              <Col md={12} lg={8}>
                <Accordion className='me-1 mt-0 mb-2' defaultActiveKey='0'>
                  <Accordion.Item
                    eventKey='0'
                    style={{ backgroundColor: 'none !important' }}
                  >
                    <Accordion.Header>Контакты:</Accordion.Header>
                    <Accordion.Body>
                      <ListGroup variant={'flush'}>
                        {data?.contacts &&
                          data?.contacts.map((contact, index) => (
                            <ListGroup.Item
                              as={'div'}
                              // active={false}
                              // className={`p-0 list-group-item d-flex justify-content-between align-items-center `}
                              variant={theme}
                              key={contact?._id || index}
                            >
                              <Row>
                                {contact?.image && (
                                  <Col
                                    xs={4}
                                    md={3}
                                    lg={3}
                                    xl={2}
                                    className='text-wrap text-break align-self-center'
                                  >
                                    <ZoomableImage
                                      zoom={true}
                                      className='rounded-2 img-fluid '
                                      variant='top'
                                      src={contact?.image}
                                    />
                                  </Col>
                                )}
                                <Col xs={'auto'}>
                                  <ListGroup variant={'flush'}>
                                    <ListGroup.Item
                                      active={false}
                                      variant={theme}
                                      className={`p-1 border-0 fs-6 text-${
                                        theme === 'dark' ? 'light' : 'dark'
                                      }`}
                                    >
                                      {contact?.name}
                                    </ListGroup.Item>
                                    <ListGroup.Item
                                      active={false}
                                      variant={theme}
                                      className={`p-1 border-0`}
                                    >
                                      {contact?.description}
                                    </ListGroup.Item>
                                    {contact?.email && (
                                      <ListGroup.Item
                                        active={false}
                                        variant={theme}
                                        className={`p-1 border-0`}
                                      >
                                        <a
                                          className={`fs-6 text-info text-decoration-none fw-medium`}
                                          href={`mailto:${contact.email}`}
                                        >
                                          {contact.email}
                                        </a>
                                      </ListGroup.Item>
                                    )}
                                    <ListGroup.Item
                                      active={false}
                                      variant={theme}
                                      className={`p-1 border-0`}
                                    >
                                      <Chip
                                        icon={<FaPhoneAlt />}
                                        color='success'
                                        variant='outlined'
                                        className={`fs-6`}
                                        label={contact?.phone}
                                        component='a'
                                        href={`tel:${contact?.phone}`}
                                        clickable
                                      />
                                      {/* className='text-success text-decoration-none fw-medium'
                                    href={`tel:${contact?.phone}`}
                                  > */}
                                      {/* {contact?.phone}
                                  </a> */}
                                    </ListGroup.Item>
                                  </ListGroup>
                                </Col>
                              </Row>
                            </ListGroup.Item>
                          ))}
                      </ListGroup>
                      {/* {data?.contacts.map((item, idx) => (
                        <span key={idx}>
                          <ContactRow item={item} />
                          {idx < data?.contacts.length - 1 && (
                            <hr class='hr m-1' />
                          )}
                        </span>
                      ))} */}
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item
                    eventKey='1'
                    style={{ backgroundColor: 'none !important' }}
                  >
                    <Accordion.Header>Реквизиты:</Accordion.Header>
                    <Accordion.Body>
                      {/* <UnitDetailList rows={data?.rows || []}></UnitDetailList> */}
                      <GroupTwoColumnList
                        rowClasses='p-1'
                        rows={data?.rows || []}
                      />
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </Col>
              <Col md={12} lg={4}>
                <Accordion className='me-1 mt-0' defaultActiveKey='0'>
                  {/* <Box sx={{ display: { xs: 'block', md: 'none' } }}> */}
                  <Accordion.Item
                    eventKey='0'
                    style={{ backgroundColor: 'none !important' }}
                  >
                    <Accordion.Header>
                      Категории товаров/услуг:
                    </Accordion.Header>
                    <Accordion.Body>
                      <GroupListSimple
                        variant={'flush'}
                        className='p-1 border-0'
                        rows={data?.sections || []}
                      />
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item
                    eventKey='2'
                    style={{ backgroundColor: 'none !important' }}
                  >
                    <Accordion.Header>География доставки:</Accordion.Header>
                    <Accordion.Body>
                      <GroupListSimple
                        variant={'flush'}
                        className='p-1 border-0'
                        rows={data?.cities || []}
                      />
                    </Accordion.Body>
                  </Accordion.Item>
                  {/* </Box> */}
                </Accordion>
              </Col>
              {/* <Col>
                <Accordion
                  className='me-1 mt-2'
                  defaultActiveKey='1'
                  alwaysOpen
                >
                  <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                    <Accordion.Item
                      eventKey='1'
                      style={{ backgroundColor: 'none !important' }}
                    >
                      <Accordion.Header>
                        Категории товаров/услуг:
                      </Accordion.Header>
                      <Accordion.Body>
                        <GroupListSimple
                          variant={'flush'}
                          className='p-1 border-0'
                          rows={data?.sections || []}
                          onClickItem={onClickSection}
                        />
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item
                      eventKey='2'
                      style={{ backgroundColor: 'none !important' }}
                    >
                      <Accordion.Header>География доставки:</Accordion.Header>
                      <Accordion.Body>
                        <GroupListSimple
                          variant={'flush'}
                          className='p-1 border-0'
                          rows={data?.cities || []}
                        />
                      </Accordion.Body>
                    </Accordion.Item>
                  </Box>
                </Accordion>
              </Col> */}
            </Row>
          </Container>
        </>
      )}
    </>
  );
};

export default CustomerAboutProviderScreen;
