import React, { useState } from 'react';
import { Col, Container, Form, Row } from 'react-bootstrap';
import {
  FaArrowAltCircleLeft,
  FaArrowAltCircleRight,
  FaPlus,
} from 'react-icons/fa';
import { useSelector } from 'react-redux';
import ToolsPanel from '../components/ToolsPanel';
import GroupListSortable from '../components/GroupList/GroupListSortable';
import { useNavigate } from 'react-router-dom';
import GroupListCollapsed from '../components/GroupList/GroupListCollapsed/GroupListCollapsed';
import {
  useDeleteGroupMutation,
  useGetGroupsToEditQuery,
  useSetParentCodeMutation,
} from '../slices/groupsApiSlice';
import { Alert, AlertTitle } from '@mui/material';
import { toast } from 'react-toastify';
import { LinearProgress } from '@mui/material';
import Message from '../components/Message/Message';

const ProviderGroupsScreen = () => {
  const navigate = useNavigate();
  const { theme, pov, currentBreakpoint, currentProvider } = useSelector(
    (state) => state.auth
  );
  const [selectedItemId, setSelectedItemId] = useState('');
  const [selectedRoot, setSelectedRoot] = useState({ _id: '', code: '' });

  const [setParentCode] = useSetParentCodeMutation();
  const [deleteGroup] = useDeleteGroupMutation();
  const [leftSideKey, setLeftSideKey] = useState(new Date().toString());
  const [rightSideKey, setRightSideKey] = useState(new Date().toString());

  const updateLeftKey = () => {
    setLeftSideKey(new Date().toString());
  };
  const updateRightKey = () => {
    setRightSideKey(new Date().toString());
  };

  const { data, refetch, isLoading, isError, error } = useGetGroupsToEditQuery(
    {
      providerId: currentProvider?._id,
      pov,
    },
    { refetchOnMountOrArgChange: true }
  );

  // console.log(data);

  const onClickMove = async (groupId, parentId) => {
    // console.log('groupId', groupId, 'parentId', parentId);
    if (selectedRoot?._id) {
      try {
        await setParentCode({
          groupId,
          parentId: parentId,
          providerId: currentProvider?._id,
        }).unwrap();
        refetch();
      } catch (error) {
        toast.error(error?.data?.message || error.error);
      }
    }
  };

  const onClickDelete = async (payload) => {
    if (payload?._id) {
      try {
        const result = await deleteGroup({
          groupId: payload?._id,
          providerId: currentProvider?._id,
        });

        if (result?.error?.data) {
          toast.error(result?.error?.data?.message || result?.error?.error);
        } else {
          refetch();
        }
      } catch (error) {
        console.log(error);
        toast.error(error?.data?.message || error.error);
      }
    }
  };

  const handleClickRoot = (payload) => {
    if (selectedRoot._id === payload._id) {
      setSelectedRoot({ _id: '', code: '', name: '' }); //просто кликнули по уже открытому
    } else {
      setSelectedRoot({ ...payload });
    }
  };
  const handleClickItem = (payload) => {
    if (payload?.isGroup) {
      handleClickRoot(payload);
    }
    setSelectedItemId(payload);
  };

  // const handleClickItemToMove = async (itemId) => {
  //   console.log('move ', itemId, 'to ', selectedRoot.name);
  //   try {
  //     const result = await setParentCode({
  //       groupId: itemId,
  //       rootId: selectedRoot._id,
  //     }).unwrap();
  //   } catch (error) {}
  // };

  const createNew = () => {
    navigate('/provider/groups/new');
  };

  const createNewItem = () => {
    navigate('/provider/groups/newItem');
  };

  let viewMode = 'simple';
  if (data?.rows?.length > 10 || data?.roots?.length > 0) {
    viewMode = 'pro';
  }

  let buttons = [];
  buttons.push({
    className: `bg-transparent`,
    name: 'Создать группу',
    type: 'component',
    action: viewMode === 'pro' ? createNew : createNewItem,
    component: (props) => {
      return <FaPlus size={22} color={theme === 'dark' ? 'white' : 'black'} />;
    },
  });

  // buttons.push({
  //   size: 'sm',
  //   name: 'Создать пункт',
  //   action: () => {},
  //   className: 'btn-block',
  //   variant: 'outline-secondary',
  // });
  // buttons.push({
  //   size: 'sm',
  //   name: 'Создать группу',
  //   action: () => {},
  //   className: 'btn-block d-block',
  //   variant: 'outline-secondary',
  // });

  // const leftKey = new Date().toString();
  // const rightKey = new Date().toString();
  // console.log('leftKey', leftKey);
  // console.log('rightKey', rightKey);

  return (
    <>
      <ToolsPanel
        title={currentBreakpoint === 'xs' ? 'Меню' : 'Редактор меню'}
        buttons={buttons}
        backURL={pov === 'provider' ? '/provider' : ''}
        //isLoading={isLoading}
      />
      {isLoading ? (
        <LinearProgress />
      ) : isError ? (
        <Message>{error?.data?.message || error.error}</Message>
      ) : (
        <Container>
          <Row>
            <Col xs={6} sm={6} md={6} lg={6} xl={6} xxl={6}>
              <GroupListSortable
                key={leftSideKey}
                updateKey={updateLeftKey}
                rows={data?.rows || []}
                onClick={handleClickRoot}
                onClickItem={handleClickItem}
                onClickMove={onClickMove}
                onClickDelete={onClickDelete}
                selectedRoot={selectedRoot}
                selectedItemId={selectedItemId}
                iconPosition={'right'}
                refetch={refetch}
                viewMode={viewMode}
                rootsLength={data?.roots?.length}
              />
            </Col>
            <Col
              xs={6}
              sm={6}
              md={6}
              lg={6}
              xl={6}
              xxl={6}
              className='mb-3 ps-4'
            >
              {viewMode === 'pro' ? (
                <>
                  {data?.roots?.length === 0 ? (
                    <Alert
                      icon={false}
                      severity='success'
                      variant='outlined'
                      className='mt-4'
                    >
                      <AlertTitle>Пояснение</AlertTitle>
                      Когда ваше меню становится слишком длинным, вы можете
                      создавать группы.
                      <br />
                      Затем помещать в эти группы пункты меню.
                      <br />
                      <br />
                      Теперь вам доступ флаг "Это группа" при создании нового
                      пункта меню
                    </Alert>
                  ) : (
                    <>
                      <Alert icon={false} severity='info'>
                        {`Кликните на группу ${
                          ['xs', 'sm'].includes(currentBreakpoint)
                            ? ''
                            : ' чтобы добавить в нее пункты'
                        }`}
                      </Alert>
                      <GroupListCollapsed
                        key={rightSideKey}
                        updateKey={updateRightKey}
                        rows={data?.roots || []}
                        onClickMove={onClickMove}
                        onClickDelete={onClickDelete}
                        onClick={handleClickRoot}
                        onClickItem={handleClickItem}
                        selectedRoot={selectedRoot}
                        iconPosition={'left'}
                        refetch={refetch}
                        viewMode={viewMode}
                      />
                      {Array.isArray(data?.roots) &&
                        data.roots?.length === 1 && (
                          <Alert
                            icon={false}
                            severity='info'
                            variant='outlined'
                            className='mt-4'
                          >
                            <AlertTitle>Подсказка:</AlertTitle>
                            Теперь, когда вы создали первую группу, кликните на
                            неё и у пунктов меню активируется кнопка &nbsp;
                            <FaArrowAltCircleRight
                              size={22}
                              className={`text-info`}
                            />
                            &nbsp; для перемещения
                            <br /> <br />
                            Попробуйте
                            <br />
                          </Alert>
                        )}
                      {Array.isArray(data?.roots) &&
                        data.roots.length === 1 &&
                        data.roots[0]?.children?.length === 1 && (
                          <Alert
                            icon={false}
                            severity='success'
                            variant='outlined'
                            className='mt-4'
                          >
                            <AlertTitle>Подсказка:</AlertTitle>
                            Вы можете вынимать пункты из групп с помощью кнопки
                            &nbsp;
                            <FaArrowAltCircleLeft
                              size={22}
                              className={`text-success`}
                            />
                            &nbsp; для возврата пункта на верхний уровень меню
                            <br /> <br />
                            Попробуйте
                            <br />
                          </Alert>
                        )}
                      {Array.isArray(data?.roots) &&
                        data.roots?.length === 1 &&
                        data.roots[0]?.children?.length === 2 && (
                          <Alert
                            icon={false}
                            severity='warning'
                            variant='outlined'
                            className='mt-4'
                          >
                            <AlertTitle>Подсказка:</AlertTitle>
                            Теперь, когда внутри группы количество стало больше,
                            вам доступна сортировка пунктов путем их
                            перетаскивания. Для активации сортировки,
                            используйте переключатель
                            <br /> &nbsp;
                            <Form.Check
                              disabled={true}
                              className='align-self-center'
                              type='switch'
                              label={`${
                                ['xs', 'sm'].includes(currentBreakpoint)
                                  ? 'Сорт.'
                                  : 'Режим сортировки'
                              }`}
                            ></Form.Check>
                            <br />
                            Попробуйте
                            <br />
                          </Alert>
                        )}
                    </>
                  )}
                </>
              ) : (
                <>
                  <Alert
                    icon={false}
                    severity='info'
                    variant='outlined'
                    className='mt-4'
                  >
                    <AlertTitle>Пояснение</AlertTitle>
                    Создайте пункты меню, внутри которых будут находится ваши
                    товары или услуги.
                    <br />
                    <br />
                    Цифра справа означает количество товаров или услуг, которые
                    включены в данный пункт меню
                  </Alert>
                </>
              )}
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
};

export default ProviderGroupsScreen;
