import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useActivateInviteCodeMutation } from '../slices/invitesApiSlice';
import { Button, Col, Container, Form, Row, Stack } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import ToolsPanel from '../components/ToolsPanel/ToolsPanel';
import { toast } from 'react-toastify';

const AddUnitByInvite = () => {
  const { invite } = useParams();
  const { search } = useLocation();
  const sp = new URLSearchParams(search);
  const back = sp.get('back');
  const [code, setCode] = useState('');
  const { theme } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  useEffect(() => {
    if (invite) {
      setCode(invite);
    }
  }, [invite]);

  const [bindUnit] = useActivateInviteCodeMutation();

  const submitHandler = async (e) => {
    e.preventDefault();
    if (code) {
      try {
        const result = await bindUnit(code).unwrap();
        //if (result?.message === 'Успех') {
        navigate(`/${result.type}/select/${result.type}/`);
        //}
      } catch (error) {
        toast.error(error?.data.message || error?.message || error.error, {
          autoClose: 4000,
        });
      }
    }
  };
  const themeStyle = {
    backgroundColor: theme === 'dark' ? '#212529' : 'white',
    borderColor: theme === 'dark' ? '#212529' : '#b5c0c1',
    color: theme === 'dark' ? 'white' : 'black',
  };
  return (
    <>
      <ToolsPanel backURL={back && `/select/${back}`} />
      <Container>
        <Row>
          <Col>
            <Form onSubmit={submitHandler}>
              <Form.Group as={Row} controlId='invite' className='my-2'>
                <Stack direction='horizontal' gap={3}>
                  <Form.Label column>Код</Form.Label>
                  <Form.Control
                    style={themeStyle}
                    type='text'
                    placeholder='Введите код'
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                  ></Form.Control>
                  <Button
                    type='submit'
                    variant='primary'
                    className='my-2'
                    onClick={submitHandler}
                  >
                    Применить
                  </Button>
                </Stack>
              </Form.Group>
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default AddUnitByInvite;
