import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

const GroupTwoColumnList = ({
  rowClasses,
  colClasses,
  rows,
  leftKey = 'title',
  rightKey = 'value',
  indexKey,
}) => {
  return (
    <>
      {Array.isArray(rows) &&
        rows.map(
          (item, index) =>
            !item.file && (
              <Row key={indexKey || index} className={rowClasses}>
                <Col xs={6} className={colClasses}>
                  {item[leftKey]}
                </Col>
                <Col xs={6} className={colClasses}>
                  <span className='text-break'>{item[rightKey]}</span>
                </Col>
              </Row>
            )
        )}
    </>
  );
};

export default GroupTwoColumnList;
