import React from 'react';
import { useParams } from 'react-router-dom';
import ToolsPanel from '../components/ToolsPanel';
import { Accordion, Col, Container, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import UnitSectionList from '../components/UnitSectionList';
import UnitCityList from '../components/UnitCityList';
import UnitDetailList from '../components/UnitDetailList/UnitDetailList';
import { Box } from '@mui/material';

const AboutUnitScreen = () => {
  const { providerId } = useParams();
  const { currentProvider } = useSelector((state) => state.auth);
  // const dispatch = useDispatch();

  const handleOnClickSection = () => {
    // dispatch(setMobileMenuIsOpen(true));
  };

  if (!providerId || providerId === 'undefined') {
    return;
  }
  return (
    <>
      <ToolsPanel title={`О компании ${currentProvider?.name}`} />
      <Container>
        <Row>
          <Col xs={12} sm={12} md={6} xl={6}>
            <Accordion className='me-1 mt-2' defaultActiveKey='0'>
              <Accordion.Item
                eventKey='0'
                style={{ backgroundColor: 'none !important' }}
              >
                <Accordion.Header>Реквизиты:</Accordion.Header>
                <Accordion.Body>
                  <UnitDetailList
                    unitId={currentProvider?._id}
                  ></UnitDetailList>
                </Accordion.Body>
              </Accordion.Item>
              <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                <Accordion.Item
                  eventKey='1'
                  style={{ backgroundColor: 'none !important' }}
                >
                  <Accordion.Header>Категории товаров/услуг:</Accordion.Header>
                  <Accordion.Body>
                    <UnitSectionList
                      unitId={currentProvider?._id}
                      unitType={'provider'}
                      role={currentProvider?.role}
                      onClick={handleOnClickSection}
                    />
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item
                  eventKey='2'
                  style={{ backgroundColor: 'none !important' }}
                >
                  <Accordion.Header>География доставки:</Accordion.Header>
                  <Accordion.Body>
                    <UnitCityList
                      role={currentProvider?.role}
                      unitId={currentProvider?._id}
                      unitType={'provider'}
                    />
                  </Accordion.Body>
                </Accordion.Item>
              </Box>
            </Accordion>
          </Col>
          <Col>
            <Accordion className='me-1 mt-2' defaultActiveKey='1' alwaysOpen>
              <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                <Accordion.Item
                  eventKey='1'
                  style={{ backgroundColor: 'none !important' }}
                >
                  <Accordion.Header>Категории товаров/услуг:</Accordion.Header>
                  <Accordion.Body>
                    <UnitSectionList
                      unitId={currentProvider?._id}
                      unitType={'provider'}
                      role={currentProvider?.role}
                      onClick={handleOnClickSection}
                    />
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item
                  eventKey='2'
                  style={{ backgroundColor: 'none !important' }}
                >
                  <Accordion.Header>География доставки:</Accordion.Header>
                  <Accordion.Body>
                    <UnitCityList
                      role={currentProvider?.role}
                      unitId={currentProvider?._id}
                      unitType={'provider'}
                    />
                  </Accordion.Body>
                </Accordion.Item>
              </Box>
            </Accordion>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default AboutUnitScreen;
