import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  setAnimateMenuIcon,
  setCurrentCustomer,
  setCurrentProvider,
  setPriceEditMode,
  setShowLeftMenu,
} from '../slices/authSlice';
import ToolsPanel from '../components/ToolsPanel/ToolsPanel';
import { toast } from 'react-toastify';
import { LinearProgress } from '@mui/material';
import Message from '../components/Message';
import { useGetCustomersOfUserQuery } from '../slices/customersApiSlice';
import GroupListSimple from '../components/GroupList/GroupListSimple';
import { FaPlus, FaRoute } from 'react-icons/fa';
import {
  useCheckExistContractMutation,
  useCreateContractMutation,
} from '../slices/contractsApiSlice';
import WelcomeCustomer from '../components/welcom/WelcomeCustomer';
import {
  useDeletePointFromAllRoutesMutation,
  useGetRoutePointsQuery,
} from '../slices/routesApiSlice';

const CustomerSelectCustomerScreen = () => {
  const { routeId } = useParams();
  const { search } = useLocation();
  const sp = new URLSearchParams(search);
  const back = sp.get('back');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    currentCustomer,
    currentProvider,
    theme,
    animateMenuIcon,
    currentRoute,
  } = useSelector((state) => state.auth);

  const {
    data: customers,
    isLoading,
    isError,
    error,
  } = useGetCustomersOfUserQuery(
    { routeId, providerId: currentProvider?._id },
    { refetchOnMountOrArgChange: true }
  );
  // console.log('customers', customers);

  const {
    data: points,
    refetch,
    isLoading: isLoadingPoints,
    isError: isErrorLoadingPoints,
    error: errorloadingPoints,
  } = useGetRoutePointsQuery(
    routeId
    // { skip: !routeId }
  );
  // console.log('route data', routeId, points);
  // console.log('points', points);

  const [checkContract] = useCheckExistContractMutation();
  const [createContract] = useCreateContractMutation();
  const [deletePointFromRoutes] = useDeletePointFromAllRoutesMutation();

  const handleSelectUnit = async (customer) => {
    // console.log(customer);
    if (!customer?._id) {
      return;
    }
    let selectedProvider = undefined;

    // console.log('customer', customer);

    if (Array.isArray(customer?.providers)) {
      if (customer?.providers.length === 1) {
        //one
        dispatch(setCurrentProvider(customer.providers[0]));
        selectedProvider = customer.providers[0];
      } else if (customer?.providers.length === 0) {
        //no one
      } else {
        //multiple
        if (currentProvider?._id) {
          const exist = customer.providers.find(
            (item) => item._id === currentProvider._id
          );
          if (exist) {
            dispatch(setCurrentProvider(exist));
            selectedProvider = exist;
          }
        }
      }
    } else if (customer?.provider?._id) {
      dispatch(setCurrentProvider(customer.provider));
      selectedProvider = customer.provider;
    } else {
      //no one
    }

    dispatch(setShowLeftMenu(true));
    dispatch(setAnimateMenuIcon(false));
    dispatch(setCurrentCustomer(customer));
    dispatch(setPriceEditMode(false));

    const checkProvider = selectedProvider ? selectedProvider : currentProvider;

    if (checkProvider?._id) {
      //проверим что между выбранным в этот момент поставщиком и выбранным покупателем заключен договор
      try {
        await checkContract({
          customerId: customer?._id,
          providerId: checkProvider?._id,
        }).unwrap();
        //переносим пользователя на страницу новостей поставщика
        navigate(`/customer/cart`);
        return;
      } catch (error) {
        //договор не найден
        //создадим
        try {
          await createContract({
            customerId: customer?._id,
            providerId: checkProvider?._id,
          }).unwrap();
          //переносим пользователя на страницу новостей поставщика
          navigate(`/customer/cart`);
          return;
        } catch (error) {
          toast.error('Выберите поставщика');
          navigate(`/customer/select/provider/`);
          return;
        }
      }
    } else {
      toast.error('Выберите поставщика');
      navigate(`/customer/select/provider/`);
    }
  };

  const handleClickAdd = (unit) => {
    navigate('/customer/create/customer/');
  };

  const handleDeletePointFromRoute = async (point) => {
    if (routeId && point?.contractId) {
      try {
        await deletePointFromRoutes({
          contractId: point.contractId,
        }).unwrap();
        refetch();
      } catch (error) {
        toast.error(error?.data?.message || error?.message || error?.error);
      }
    }
  };

  // const handleClickUpdateOrderStatus = () => {
  //   setIncludeOrder(true);
  //   refetch();
  // };

  // const handleClickIcon = (customer, children, event) => {
  //   event.preventDefault();
  //   if (Array.isArray(children)) {
  //     if (children.length === 1 && children[0]?._id) {
  //       console.log(children);
  //       navigate(
  //         `/customer/cart/${children[0]?._id}?back=/customer/select/customer`
  //       );
  //     }
  //   }
  // };

  let isNew = false;
  if (
    ((Array.isArray(customers?.rows) && customers.rows.length === 0) ||
      !Array.isArray(customers?.rows)) &&
    ((Array.isArray(points?.rows) && points.rows.length === 0) ||
      !Array.isArray(points?.rows))
  ) {
    isNew = true;
  }

  let buttons = [];
  // if (Array.isArray(data?.routes) && data?.routes.length > 0) {
  //   buttons.push({
  //     className: `bg-transparent`,
  //     name: 'Обновить статус заявок',
  //     type: 'component',
  //     action: handleClickUpdateOrderStatus,
  //     component: (props) => {
  //       return (
  //         <FaTasks size={22} color={theme === 'dark' ? 'white' : 'black'} />
  //         // <CircularProgress size={18} />
  //       );
  //     },
  //   });
  // }
  if (!isNew && Array.isArray(points?.rows) && points.rows.length > 0) {
    buttons.push({
      className: `bg-transparent border-2`,
      name: 'Редактор марщрутов',
      type: 'component',
      action: () => navigate(`/manager/routes/`),
      component: (props) => {
        return (
          <FaRoute size={22} color={theme === 'dark' ? 'white' : 'black'} />
        );
      },
    });
    buttons.push({
      className: `bg-transparent`,
      name: 'Создать покупателя',
      type: 'component',
      action: handleClickAdd,
      component: (props) => {
        return (
          <FaPlus size={22} color={theme === 'dark' ? 'white' : 'black'} />
        );
      },
    });
  }

  // console.log('points.rows', points?.rows);
  // console.log('customers.rows', customers?.rows);

  return (
    <>
      <ToolsPanel
        backURL={back ? back : currentProvider?._id ? '/customer' : '/start'}
        buttons={buttons}
        title={`${
          points?.route?.name
            ? 'Маршрут: ' + points?.route?.name
            : 'Список покупателей'
        }`}
      />
      {isLoading || isLoadingPoints ? (
        <LinearProgress />
      ) : (
        <>
          <Container>
            {isNew && !isLoading && !isLoadingPoints ? (
              <Row>
                <Col>
                  <WelcomeCustomer
                    handleClickCreate={handleClickAdd}
                    theme={theme}
                  />
                </Col>
              </Row>
            ) : (
              <>
                {isError ? (
                  <Message>
                    {error?.data?.message || error?.error || error?.message}
                  </Message>
                ) : (
                  <>
                    {Array.isArray(customers?.rows) && (
                      <Row className='mb-3'>
                        <Col>
                          <GroupListSimple
                            variant=''
                            rows={customers.rows}
                            onClickItem={handleSelectUnit}
                            currentId={currentCustomer?._id}
                            arrayFieldName={'orders'}
                          />
                        </Col>
                      </Row>
                    )}
                  </>
                )}
                {isErrorLoadingPoints ? (
                  <Message>
                    {errorloadingPoints?.data?.message ||
                      errorloadingPoints?.error ||
                      errorloadingPoints?.message}
                  </Message>
                ) : (
                  <>
                    {Array.isArray(points?.rows) && points.rows.length > 0 && (
                      <Row>
                        <Col>
                          <GroupListSimple
                            variant=''
                            rows={points?.rows}
                            onClickItem={handleSelectUnit}
                            currentId={currentRoute?._id}
                            arrayFieldName={'orders'}
                            onDeleteItem={handleDeletePointFromRoute}
                          />
                        </Col>
                      </Row>
                    )}
                  </>
                )}
              </>
            )}
          </Container>
        </>
      )}
    </>
  );
};

export default CustomerSelectCustomerScreen;
