import React, { useState } from 'react';
import { styled, alpha } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import { Box, InputBase } from '@mui/material';
import { setCurrentSection, setSearchString } from '../../slices/authSlice';
import {
  Button,
  Container,
  Form,
  InputGroup,
  Offcanvas,
} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { FaSearch } from 'react-icons/fa';

//import logo from '../../assets/logo.png';
const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  width: '10px',
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    //border: '1px solid red',
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));

const HeaderSearchControl = () => {
  const { pathname } = useLocation() || '';

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showSearchCanvas, setShowSearchCanvas] = useState(false);
  const {
    userInfo,
    pov,
    searchString,
    theme,
    currentCustomer,
    currentProvider,
  } = useSelector((state) => state.auth);

  const allowedPath = [
    '/provider/contracts',
    '/provider/products',
    '/customer/cart',
    '/customer/order',
    '/customer/search',
  ];
  const allowSearch = allowedPath.some((path) => pathname.includes(path));
  if (!allowSearch) {
    return;
  }

  const searchSubmitHandler = (e) => {
    e.preventDefault();

    if (searchString) {
      if (pov === 'provider') {
        if (pathname.includes('provider/contracts')) {
          //console.log('pathname', pathname);
          navigate(`/provider/contracts/search/${searchString}`);
        } else if (pathname.includes('provider/orders')) {
          //console.log('pathname', pathname);
          navigate(`/provider/orders/search/${searchString}`);
        } else {
          navigate(`/provider/products/search/${searchString}`);
        }
      } else {
        if (pathname.includes('/customer/search/provider')) {
          dispatch(setCurrentSection(''));
          navigate(`/customer/search/provider/${searchString}`);
        } else {
          navigate(`/customer/order/search/${searchString}`);
        }
      }
    }
  };

  const onClickMobileSearch = () => {
    setShowSearchCanvas(!showSearchCanvas);
  };

  const setSearchStringHandle = (str) => {
    dispatch(setSearchString(str));
  };

  if (!pov || !userInfo?._id || (!currentProvider && !currentCustomer)) {
    return;
  }

  return (
    <>
      <Box sx={{ display: { xs: 'flex', md: 'none' }, mr: 0 }} role='button'>
        <FaSearch onClick={onClickMobileSearch} />
      </Box>

      <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
        <Form onSubmit={searchSubmitHandler}>
          <Search>
            <SearchIconWrapper onClick={searchSubmitHandler}>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              value={searchString}
              onChange={(e) => setSearchStringHandle(e.target.value)}
              placeholder='Поиск...'
              inputProps={{ 'aria-label': 'search' }}
            />
          </Search>
        </Form>
      </Box>

      <Offcanvas
        style={{ background: theme === 'dark' ? '#212529' : 'white' }}
        //bsPrefix='search'
        show={showSearchCanvas}
        onHide={setShowSearchCanvas}
        placement='top'
      >
        <Offcanvas.Header
          closeButton
          closeVariant={theme === 'dark' ? 'white' : 'black'}
        >
          {/* <Offcanvas.Title>Offcanvas</Offcanvas.Title> */}
          <Container>
            <Form onSubmit={searchSubmitHandler}>
              <InputGroup>
                <Form.Control
                  placeholder='Введите фразу'
                  aria-label='Search field'
                  aria-describedby='Search field'
                  value={searchString}
                  onChange={(e) => setSearchStringHandle(e.target.value)}
                />
                <Button
                  onClick={searchSubmitHandler}
                  variant='outline-secondary'
                  id='button-addon2'
                >
                  <FaSearch />
                </Button>
              </InputGroup>
            </Form>
          </Container>
        </Offcanvas.Header>
      </Offcanvas>
    </>
  );
};

export default HeaderSearchControl;
