import React from 'react';
import { ListGroup } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const MenuAdmin = ({ onClick }) => {
  const { userInfo, theme } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  const handleClickCanvas = (to) => {
    if (typeof onClick === 'function') {
      onClick(false);
    }
    navigate(to);
  };

  const showOffset = false;
  return (
    <>
      {userInfo && userInfo.isAdmin && showOffset && (
        <>
          <ListGroup>
            <ListGroup.Item
              variant={theme}
              action
              onClick={() => handleClickCanvas('/admin/providerlist')}
            >
              Поставщики
            </ListGroup.Item>
            <ListGroup.Item
              variant={theme}
              action
              onClick={() => handleClickCanvas('/admin/customerlist')}
            >
              Покупатели
            </ListGroup.Item>
            <ListGroup.Item
              variant={theme}
              action
              onClick={() => handleClickCanvas('/admin/contractlist')}
            >
              Договоры
            </ListGroup.Item>
            <ListGroup.Item
              variant={theme}
              action
              onClick={() => handleClickCanvas('/admin/pricelist')}
            >
              Типы цен
            </ListGroup.Item>
            <ListGroup.Item
              variant={theme}
              action
              onClick={() => handleClickCanvas('/admin/productlist')}
            >
              Товары
            </ListGroup.Item>
            <ListGroup.Item
              variant={theme}
              action
              onClick={() => handleClickCanvas('/admin/grouplist')}
            >
              Группы товаров
            </ListGroup.Item>
            <ListGroup.Item
              variant={theme}
              action
              onClick={() => handleClickCanvas('/admin/userlist')}
            >
              Users
            </ListGroup.Item>
            <ListGroup.Item
              variant={theme}
              action
              onClick={() => handleClickCanvas('/admin/orderlist')}
            >
              Orders
            </ListGroup.Item>
          </ListGroup>
        </>
      )}
    </>
  );
};

export default MenuAdmin;
