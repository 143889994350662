import { Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';

const CheckProviderRoute = () => {
  const { pov, currentProvider } = useSelector((state) => state.auth);

  if (pov === 'provider') {
    if (currentProvider?._id) {
      // console.log('currentProvider', currentProvider);
      return <Outlet />;
    } else {
      return <Navigate to='/provider/select/provider' replace />;
    }
  } else {
    return <Navigate to='/start' replace />;
  }
};

export default CheckProviderRoute;
