import React from 'react';
import {
  Box,
  Checkbox,
  Chip,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
} from '@mui/material';
import ListSubheader from '@mui/material/ListSubheader';

const MultiSelectInput = ({
  mode,
  selectedItems,
  handleMultiSelect,
  items,
  label = 'Выберите',
  closeButton = false,
  handleRemoveSelected,
}) => {
  const handleSingleSelect = (e) => {
    console.log(e);
  };

  return (
    <FormControl
      sx={{
        my: 1,
        // minWidth: 300,
        width: '100%',
        // maxWidth: 500,
      }}
    >
      <InputLabel id='multiple-checkbox-label'>{label}</InputLabel>
      {mode === 'single' ? (
        <Select
          defaultValue=''
          id='grouped-select'
          label='Grouping'
          onChange={(e) => handleSingleSelect(e)}
          input={<OutlinedInput label={label} />}
        >
          <MenuItem value=''>
            <em>None</em>
          </MenuItem>
          {items.map((item) => (
            <MenuItem key={item._id} value={item._id}>
              {/* <Checkbox checked={selectedItems.indexOf(item._id) > -1} /> */}
              <ListItemText primary={item.name} />
            </MenuItem>
          ))}
          {/* <ListSubheader>Category 1</ListSubheader>
          <MenuItem value={1}>Option 1</MenuItem>
          <MenuItem value={2}>Option 2</MenuItem>
          <ListSubheader>Category 2</ListSubheader>
          <MenuItem value={3}>Option 3</MenuItem>
          <MenuItem value={4}>Option 4</MenuItem> */}
        </Select>
      ) : (
        <Select
          labelId='multiple-checkbox-label'
          id='multiple-checkbox'
          multiple
          value={selectedItems}
          onChange={(e) => handleMultiSelect(e)}
          input={<OutlinedInput label={label} id='wwwwww' />}
          renderValue={(selected) => (
            <Box
              id='eeeeeeee'
              sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}
            >
              {selected.map((value, index) => (
                <Chip
                  key={index}
                  label={items.find((item) => item._id === value)?.name}
                  onDelete={
                    typeof handleRemoveSelected === 'function'
                      ? (e) => handleRemoveSelected(e)
                      : null
                  }
                />
              ))}
            </Box>
          )}
          //MenuProps={MenuProps}
        >
          {items.map((item) => (
            <MenuItem key={item._id} value={item._id}>
              <Checkbox checked={selectedItems.indexOf(item._id) > -1} />
              <ListItemText primary={item.name} />
            </MenuItem>
          ))}
        </Select>
      )}
    </FormControl>
  );
};

export default MultiSelectInput;
