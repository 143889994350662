import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

const UnitDetailList = ({ unitId, rows }) => {
  // const { data, refetch, isLoading, isError, error } = useGetUnitDetailsQuery(
  //   {
  //     unitId,
  //     unitType: 'provider',
  //     filter: 'fields, users',
  //   },
  //   { refetchOnMountOrArgChange: true }
  // );

  return (
    <Container>
      {Array.isArray(rows) &&
        rows.map(
          (item) =>
            !item.file && (
              <Row key={item['name']}>
                <Col xs={6}>{item['title']}</Col>
                <Col xs={6}>{item['value']}</Col>
              </Row>
            )
        )}
    </Container>
  );
};

export default UnitDetailList;
