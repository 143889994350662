import { ROUTES_URL } from '../constans';
import { apiSlice } from './apiSlice';

export const routesApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		createUpdateRoute: builder.mutation({
			query: (data) => ({
				url: `${ROUTES_URL}/${data?.routeId}`,
				method: 'POST',
				body: {
					...data
				}
			}),
			invalidatesTags: ['RouteList']
		}),
		getRouteDetails: builder.query({
			query: (data) => ({
				url: `${ROUTES_URL}/${data?.routeId}`,
			}),
			keepUnusedDataFor: 5
		}),
		getRoutes: builder.query({
			query: (data) => ({
				url: `${ROUTES_URL}`,
				params: {
					...data
				}
			}),
		}),
		getRoutePoints: builder.query({
			query: (routeId) => ({
				url: `${ROUTES_URL}/${routeId}/points`,
				params: {
					withProvider: true
				}
			}),
			keepUnusedDataFor: 5,
		}),
		getUserRoutes: builder.query({
			query: (data) => ({
				url: `${ROUTES_URL}/ofuser`,
				params: {
					withPoints: data?.withPoints,
					withEmpty: data?.withEmpty
				}
			}),
			keepUnusedDataFor: 5,
			providesTags: ['RouteList']
		}),
		getUserRoutesMenu: builder.query({
			query: (data) => ({
				url: `${ROUTES_URL}/menu`,
				params: {
					...data
				}
			}),
			keepUnusedDataFor: 5,
			providesTags: ['RouteList']
		}),
		getContractsRoutes: builder.query({
			query: (data) => ({
				url: `${ROUTES_URL}/ofcontract`,
				params: {
					includeAll: data?.includeAll,
					contractId: data?.contractId,
				}
			}),
			keepUnusedDataFor: 5,
		}),
		updateContractRoutes: builder.mutation({
			query: (data) => ({
				url: `${ROUTES_URL}/ofcontract`,
				method: 'POST',
				body: {
					itemList: data?.itemList,
					contractId: data?.contractId,
				}
			}),
			invalidatesTags: ['RouteList']
		}),
		deleteRoute: builder.mutation({
			query: (data) => ({
				url: `${ROUTES_URL}/${data?.routeId}`,
				method: 'DELETE',
				params: {
					...data
				}
			}),
			invalidatesTags: ['RouteList']
		}),
		deletePointFromAllRoutes: builder.mutation({
			query: (data) => ({
				url: `${ROUTES_URL}/${data?.contractId}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['RouteList']
		}),
		sortRoutes: builder.mutation({
			query: (data) => ({
				url: `${ROUTES_URL}/sort`,
				method: 'POST',
				body: {
					itemList: data?.itemList,
				}
			}),
			invalidatesTags: ['RouteList']
		}),
		sortPoints: builder.mutation({
			query: (data) => ({
				url: `${ROUTES_URL}/${data?.routeId}/sort`,
				method: 'POST',
				body: {
					itemList: data?.itemList,
				}
			}),
			invalidatesTags: ['RouteList']
		}),
	})
});

export const {
	useGetUserRoutesMenuQuery,
	useDeletePointFromAllRoutesMutation,
	useGetRoutePointsQuery,
	useUpdateContractRoutesMutation,
	useSortRoutesMutation,
	useSortPointsMutation,
	useGetContractsRoutesQuery,
	useGetUserRoutesQuery,
	useDeleteRouteMutation,
	useCreateUpdateRouteMutation,
	useGetRouteDetailsQuery,
	useGetRoutesQuery
} = routesApiSlice;