import React from 'react';
import Modal from 'react-bootstrap/Modal';

const ModalFrame = (props) => {
  return (
    <>
      <Modal
        {...props}
        aria-labelledby='contained-modal-title-vcenter'
        centered
        animation={false}
        className='m-0 p-0'
      >
        {/* <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>
            {props.header}
          </Modal.Title>
        </Modal.Header> */}
        <Modal.Body className='m-0 p-0'>{props.children}</Modal.Body>
        {/* <Modal.Footer>
          <Button onClick={props.onHide}>Закрыть</Button>
        </Modal.Footer> */}
      </Modal>
    </>
  );
};

export default ModalFrame;
