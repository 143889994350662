import React, { useState } from 'react';
import { Button, Carousel, Col, Container, Row } from 'react-bootstrap';

import {
  FaExpeditedssl,
  FaMobileAlt,
  FaPeopleArrows,
  FaPhoneSlash,
  FaShippingFast,
  FaUserSecret,
  FaWindowMaximize,
} from 'react-icons/fa';
import { Box, Divider, Stack } from '@mui/material';
import AvatarCustomerColored from '../../SVG/AvatarCustomerColored';

const WelcomeCustomer = ({ handleClickCreate, theme }) => {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  const bgDefault = 'bg-dark';
  const bgActive = 'bg-success';

  const carouselItemClasses = 'h-auto';

  const clickOnItem = (ind) => {
    setIndex(ind);
  };

  let heroClasses = ['p-4', 'rounded-5', 'bg-primary'];
  if (theme === 'dark') {
    heroClasses.push('text-light');
  } else if (theme === 'light') {
    heroClasses.push('text-light');
  } else {
    heroClasses.push('text-light');
  }

  return (
    <>
      <Row>
        <Col sm={12} md={12} lg={8}>
          <Container
            className={heroClasses.join(' ')}
            style={{ minHeight: '240px' }}
          >
            <Row>
              <Col sm={12} md={6} lg={6}>
                <h4>Приветствуем вас!</h4>
                <span>
                  С нами вы экономьте своё время:
                  <br /> - &nbsp; на поисках поставщиков в интернете
                  <br /> - &nbsp; на поездках по офисам и складам
                  <br /> - &nbsp; на звонках операторам поставщиков
                  <br /> - &nbsp; на очередях торговых представителей
                  <br />
                  <br />
                  Все поставщики скоро будут тут. И вы можете легко находить их,
                  изучать цены и скидывать им заявку на доставку. <br /> При
                  этом вы остаётесь невидимым для остальных поставщиков и вас
                  никто не будет беспокоить звонками и рекламой. <br /> <br />
                  Если вы тот, кому это интересно, то приглашаем вас в нашу
                  группу.
                </span>
                <Divider />
                <Button
                  variant='success'
                  className='my-2'
                  onClick={() => handleClickCreate('customer')}
                >
                  Создать покупателя
                </Button>
              </Col>
              <Col>
                <AvatarCustomerColored />
              </Col>
            </Row>
          </Container>
        </Col>
        <Col sm={12} md={12} lg={4}>
          <Carousel
            activeIndex={index}
            onSelect={handleSelect}
            controls={false}
          >
            <Carousel.Item className={carouselItemClasses}>
              <Col className='h-100 m-5'>
                <FaExpeditedssl size={60} className='mb-4' />
                <p className='fs-5'>
                  Информация на сайте защищена и зашифрована
                </p>
              </Col>
            </Carousel.Item>
            <Carousel.Item className={carouselItemClasses}>
              <Col className='h-100 m-5'>
                <FaPeopleArrows size={60} className='mb-4' />
                <p className='fs-5'>
                  Здесь вы можете выйти на поставщика напрямую. Никаких
                  посредников и перекупов.
                </p>
              </Col>
            </Carousel.Item>
            <Carousel.Item className={carouselItemClasses}>
              <Col className='h-100 m-5'>
                <FaWindowMaximize size={60} className='mb-4' />
                <p className='fs-5'>
                  Ничего лишнего на экране во время заявки.
                </p>
              </Col>
            </Carousel.Item>
            <Carousel.Item className={carouselItemClasses}>
              <Col className='h-100 m-5'>
                <FaPhoneSlash size={60} className='mb-4' />
                <p className='fs-5'>
                  Заявки по телефону отвлекают продавцов. Создаются очереди
                  людей и задач.
                </p>
              </Col>
            </Carousel.Item>
            <Carousel.Item className={carouselItemClasses}>
              <Col className='h-100 m-5'>
                <FaMobileAlt size={60} className='mb-4' />
                <p className='fs-5'>
                  Заявки можно отправлять через смартфон или планшет. Без
                  установки программ.
                </p>
              </Col>
            </Carousel.Item>
            <Carousel.Item className={carouselItemClasses}>
              <Col className='h-100 m-5'>
                <FaUserSecret size={60} className='mb-4' />
                <p className='fs-5'>
                  Все покупатели скрыты. Никаких рекламных звонков и сообщений !
                </p>
              </Col>
            </Carousel.Item>
          </Carousel>
        </Col>
      </Row>
      <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
        <Row className='mt-4'>
          <Col
            sm={12}
            md={12}
            lg={4}
            style={{ maxHeight: '150px' }}
            onClick={() => clickOnItem(0)}
          >
            <Stack
              direction='row'
              spacing={2}
              className={`p-3  rounded-3 align-items-center my-2 ${
                index === 0 ? bgActive : bgDefault
              }`}
            >
              <FaExpeditedssl size={35} className='text-primary' />
              <span className='fs-4'>Безопасно</span>
            </Stack>
          </Col>
          <Col
            sm={12}
            md={12}
            lg={4}
            style={{ maxHeight: '150px' }}
            onClick={() => clickOnItem(1)}
          >
            <Stack
              direction='row'
              spacing={2}
              className={`p-3  rounded-3 align-items-center my-2 ${
                index === 1 ? bgActive : bgDefault
              }`}
            >
              <FaPeopleArrows size={35} className='text-primary' />{' '}
              <span className='fs-4'>Без посредников</span>
            </Stack>
          </Col>
          <Col
            sm={12}
            md={12}
            lg={4}
            style={{ maxHeight: '150px' }}
            onClick={() => clickOnItem(2)}
          >
            <Stack
              direction='row'
              spacing={2}
              className={`p-3  rounded-3 align-items-center my-2 ${
                index === 2 ? bgActive : bgDefault
              }`}
            >
              <FaWindowMaximize size={35} className='text-primary' />
              <span className='fs-4'>Быстро и просто</span>
            </Stack>
          </Col>
          <Col
            sm={12}
            md={12}
            lg={4}
            style={{ maxHeight: '150px' }}
            onClick={() => clickOnItem(3)}
          >
            <Stack
              direction='row'
              spacing={2}
              className={`p-3  rounded-3 align-items-center my-2 ${
                index === 3 ? bgActive : bgDefault
              }`}
            >
              <FaPhoneSlash size={35} className='text-primary' />
              <span className='fs-4'>Никаких звонков</span>
            </Stack>
          </Col>
          <Col
            sm={12}
            md={12}
            lg={4}
            style={{ maxHeight: '150px' }}
            onClick={() => clickOnItem(4)}
          >
            <Stack
              direction='row'
              spacing={2}
              className={`p-3  rounded-3 align-items-center my-2 ${
                index === 4 ? bgActive : bgDefault
              }`}
            >
              <FaMobileAlt size={35} className='text-primary' />
              <span className='fs-4'>Не нужен компьютер</span>
            </Stack>
          </Col>
          <Col
            sm={12}
            md={12}
            lg={4}
            style={{ maxHeight: '150px' }}
            onClick={() => clickOnItem(5)}
          >
            <Stack
              direction='row'
              spacing={2}
              className={`p-3  rounded-3 align-items-center my-2 ${
                index === 5 ? bgActive : bgDefault
              }`}
            >
              <FaUserSecret size={35} className='text-primary' />
              <span className='fs-4'>Покупателей не видно</span>
            </Stack>
          </Col>
        </Row>
      </Box>
    </>
  );
};

export default WelcomeCustomer;
