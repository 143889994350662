import React from 'react';
import { Draggable } from 'react-beautiful-dnd';

const DraggableList = ({ id, index, isDisable, children }) => {
  return (
    <Draggable
      key={id}
      draggableId={id}
      index={index}
      isDragDisabled={isDisable}
    >
      {(provided, snapshot) => (
        <div
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
        >
          {children}
        </div>
      )}
    </Draggable>
  );
};

export default DraggableList;
