import React from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { Divider } from '@mui/material';

import SearchPartnerColored from '../../SVG/SearchPartnerColored';

const LetsSearchProvider = ({ handleClick, theme }) => {
  let heroClasses = ['p-4', 'rounded-5', 'bg-primary'];
  if (theme === 'dark') {
    heroClasses.push('text-light');
  } else if (theme === 'light') {
    heroClasses.push('text-dark');
    heroClasses.push('bg-opacity-10');
  } else {
    heroClasses.push('text-light');
  }

  return (
    <>
      <Row>
        <Col sm={12} md={12} lg={8}>
          <Container
            className={heroClasses.join(' ')}
            style={{ minHeight: '240px' }}
          >
            <Row>
              <Col sm={12} md={6} lg={6}>
                <h4>Список ваших поставщиков пуст?</h4>
                <span>
                  Перейдите на страницу поиска и выбирайте поставщиков из нужных
                  вам категорий продуктов. <br />
                  <br />
                  Обратите внимание: поставщики сами указывают в какие города
                  они готовы организовать доставку, поэтому выбирайте тот город,
                  рядом с которым находится ваша точка.
                </span>
                <br />
                <br />
                <Divider />
                <br />
                <Button
                  variant='success'
                  className='my-2'
                  onClick={handleClick}
                >
                  Перейти на страницу поиска
                </Button>
              </Col>
              <Col>
                <SearchPartnerColored />
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
    </>
  );
};

export default LetsSearchProvider;
