import { PRODUCTS_URL, UPLOAD_URL } from '../constans';
import { apiSlice } from './apiSlice'

export const productsApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getHierarchyProducts: builder.query({
			query: (data) => ({
				url: `${PRODUCTS_URL}/hierarchy`,
				params: {
					...data
				}
			}),
			keepUnusedDataFor: 5
		}),
		getProducts: builder.query({
			query: (data) => ({
				//url: `${PRODUCTS_URL}/parent/${data.groupId}/${data.customerId}/${data.prices}`,
				url: PRODUCTS_URL,
				params: {
					pov: data?.pov,
					keyword: data?.keyword,
					pageNumber: data?.pageNumber,
					pageSize: data?.pageSize,
					groupId: data?.groupId,
					providerId: data?.providerId,
					customerId: data?.customerId,
					pricesString: data?.pricesString
				}
			}),
			keepUnusedDataFor: 5
		}),
		getUserProducts: builder.mutation({
			query: (data) => ({
				url: `${PRODUCTS_URL}/parent/${data.code}`,
				method: 'POST',
				body: { ...data }
			}),
			providesTags: ['Product']
		}),
		hideProducts: builder.mutation({
			query: (data) => ({
				url: `${PRODUCTS_URL}/hide`,
				method: 'POST',
				body: { ...data }
			}),
			invalidatesTags: ['Product', 'ProviderProductMenu'] //очистить ранее созданный кеш
		}),
		moveToGroup: builder.mutation({
			query: (data) => ({
				url: `${PRODUCTS_URL}/move`,
				method: 'POST',
				body: { ...data }
			}),
			invalidatesTags: ['Product', 'ProviderProductMenu'] //очистить ранее созданный кеш
		}),
		getProductPrices: builder.query({
			query: (data) => ({
				url: `${PRODUCTS_URL}/${data?.productId}/prices`,
				params: {
					...data
				}
			}),
			keepUnusedDataFor: 5
		}),
		getProductDetails: builder.query({
			query: (data) => ({
				url: `${PRODUCTS_URL}/${data?.productId}`,
				params: {
					...data
				}
			}),
			keepUnusedDataFor: 5
		}),
		getProductFieldValue: builder.mutation({
			query: (data) => ({
				url: `${PRODUCTS_URL}/value/${data.fields}`,
				method: 'POST',
				body: {
					productId: data.productId,
					providerId: data.providerId
				}
			}),
			keepUnusedDataFor: 5
		}),
		createProduct: builder.mutation({
			query: (data) => ({
				url: `${PRODUCTS_URL}`,
				method: 'POST',
				body: { ...data }
			}),
			invalidatesTags: ['Product', 'ProviderProductMenu'] //очистить ранее созданный кеш
		}),
		updateProduct: builder.mutation({
			query: (data) => ({
				url: `${PRODUCTS_URL}/${data._id}`,
				method: 'PUT',
				body: data
			}),
			invalidatesTags: ['Product', 'ProviderProductMenu'] //очистить ранее созданный кеш
		}),
		uploadProductImage: builder.mutation({
			query: (data) => ({
				url: UPLOAD_URL,
				method: 'POST',
				body: data
			}),
		}),
		deleteProduct: builder.mutation({
			query: (productId) => ({
				url: `${PRODUCTS_URL}/${productId}`,
				method: 'DELETE'
			}),
			invalidatesTags: ['Product', 'ProviderProductMenu'] //очистить ранее созданный кеш
		}),
	})
})

export const {
	useGetHierarchyProductsQuery,
	useGetProductPricesQuery,
	useGetProductFieldValueMutation,
	useMoveToGroupMutation,
	useHideProductsMutation,
	useGetUserProductsMutation,
	useGetProductsQuery,
	useGetProductDetailsQuery,
	useCreateProductMutation,
	useUpdateProductMutation,
	useUploadProductImageMutation,
	useDeleteProductMutation } = productsApiSlice;