import { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useActivateInviteCodeMutation } from '../../slices/invitesApiSlice';
import { toast } from 'react-toastify';
import {
  setCurrentCustomer,
  setCurrentProvider,
  setShowLeftMenu,
} from '../../slices/authSlice';

const EditForm = ({
  unitId,
  onlyRead,
  theme,
  values,
  setValues,
  submit,
  uploadFileHandler,
}) => {
  const [invite, setInvite] = useState('');
  const { pov } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [bindUnit] = useActivateInviteCodeMutation();
  const submitClickFill = async () => {
    if (invite) {
      try {
        const result = await bindUnit(invite).unwrap();
        if (result?.unit && result?.type) {
          if (result?.type === 'provider') {
            dispatch(setCurrentProvider(result?.unit));
          }
          if (result?.type === 'customer') {
            dispatch(setCurrentCustomer(result?.unit));
          }
          dispatch(setShowLeftMenu(true));
        }
        if (result?.type) {
          navigate(`/${result.type}/`);
        } else if (pov) {
          navigate(`/${pov}`);
        } else {
          navigate(`/start`);
        }
      } catch (error) {
        toast.error(error?.data.message || error?.message || error.error, {
          autoClose: 4000,
        });
      }
    }
  };

  const themeStyle = {
    backgroundColor: theme === 'dark' ? '#212529' : 'white',
    borderColor: theme === 'dark' ? '#212529' : '#b5c0c1',
    color: theme === 'dark' ? 'white' : 'black',
  };

  return (
    <Form onSubmit={submit} className='me-1'>
      {unitId === 'new' && (
        <Form.Group
          as={Row}
          controlId={'invite'}
          className='my-2  py-2 bg-success bg-opacity-25'
        >
          <Form.Label column sm='3' className='pb-0'>
            У меня есть код:
          </Form.Label>

          <Col sm={9}>
            <Row>
              <Col>
                <Form.Control
                  style={themeStyle}
                  type={'text'}
                  placeholder={'Введите код'}
                  value={invite || ''}
                  onChange={(e) => setInvite(e.target.value)}
                ></Form.Control>
              </Col>
              <Col>
                <Button
                  type='button'
                  variant='primary'
                  className='w-100'
                  close='true'
                  // size={
                  //   ['lg', 'xl', 'xxl'].includes(currentBreakpoint) ? 'lg' : 'sm'
                  // }
                  onClick={submitClickFill}
                >
                  Применить
                </Button>
              </Col>
            </Row>
          </Col>
        </Form.Group>
      )}
      {values &&
        values.map((item) => (
          <span key={item['name']}>
            {item.file ? (
              !onlyRead && (
                <Form.Group as={Row} controlId={item['name']} className='my-2'>
                  <Form.Label column sm='3' className='pb-0'>
                    {item['title']}
                  </Form.Label>
                  <Col sm='4'>
                    <Form.Control
                      autoComplete='off'
                      required={item?.required}
                      isInvalid={item?.required && !item['value']}
                      disabled={true}
                      style={themeStyle}
                      type={item['type']}
                      placeholder={item['placeholder']}
                      value={item['value'] || ''}
                      onChange={(e) => setValues(e.target.value, item['name'])}
                    ></Form.Control>
                  </Col>
                  <Col sm='5'>
                    <Form.Control
                      required={item?.required}
                      isInvalid={item?.required && !item['value']}
                      disabled={onlyRead}
                      style={themeStyle}
                      type='file'
                      label='Выберите файл'
                      name={item['name']}
                      onChange={uploadFileHandler}
                    ></Form.Control>
                  </Col>
                </Form.Group>
              )
            ) : (
              <Form.Group as={Row} controlId={item['name']} className='my-2'>
                <Form.Label column sm='3' className='pb-0'>
                  {item['title']}
                </Form.Label>

                <Col sm={9}>
                  <Form.Control
                    isInvalid={item?.required && !item['value']}
                    required={item?.required}
                    disabled={onlyRead}
                    style={themeStyle}
                    type={item['type']}
                    placeholder={item['placeholder']}
                    value={item['value'] || ''}
                    onChange={(e) => setValues(e.target.value, item['name'])}
                  ></Form.Control>
                </Col>
              </Form.Group>
            )}
          </span>
        ))}
    </Form>
  );
};

export default EditForm;
