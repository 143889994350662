import React from 'react';
import ToolsPanel from '../components/ToolsPanel';
import { Col, Container, Row } from 'react-bootstrap';
import { FaPlus } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { LinearProgress } from '@mui/material';
import Message from '../components/Message';
import DataTable from '../components/DataTable/DataTable';
import { useNavigate } from 'react-router-dom';
import { useGetProviderPricesQuery } from '../slices/pricesApiSlice';

const ProviderPricesScreen = () => {
  const navigate = useNavigate();
  const { theme, pov, currentProvider } = useSelector((state) => state.auth);

  const { data, isLoading, isError, error } = useGetProviderPricesQuery({
    providerId: currentProvider?._id,
    pov,
    count: true,
  });

  // console.log(data);

  const rows = data?.rows?.map((item) => ({
    ...item,
    id: item._id,
  }));

  const columns = [
    { field: 'id', headerName: 'id', width: 50, hide: true },
    {
      field: 'name',
      headerName: 'Наименование',
      flex: 1,
    },
    // {
    //   field: 'shortName',
    //   headerName: 'Краткое имя',
    //   flex: 0.7,
    // },
    // {
    //   field: 'code',
    //   headerName: 'Код поставщика',
    //   flex: 0.7,
    // },
    {
      field: 'count',
      headerName: 'Покупателей',
      flex: 0.5,
    },
  ];

  const visibilityColumns = {};
  visibilityColumns.id = false;

  const handleOnSelectCell = (data) => {
    if (data?.row?.id) {
      navigate(`/provider/prices/${data.row.id}`);
    }
  };

  const handleClickAdd = () => {
    navigate(`/provider/prices/new`);
  };

  const buttons = [];
  buttons.push({
    className: `bg-transparent`,
    name: 'Добавить тип цен',
    type: 'component',
    action: handleClickAdd,
    component: (props) => {
      return <FaPlus size={22} color={theme === 'dark' ? 'white' : 'black'} />;
    },
  });

  return (
    <>
      <ToolsPanel
        title='Типы цен'
        buttons={buttons}
        backURL={pov === 'provider' ? '/provider' : ''}
      />
      {isLoading ? (
        <LinearProgress />
      ) : isError ? (
        <Message>{error?.data?.message || error.error}</Message>
      ) : (
        <Container>
          <Row>
            <Col
              // md={12}
              // lg={8}
              className='mb-3'
            >
              {Array.isArray(rows) && (
                <DataTable
                  rows={rows || []}
                  columns={columns}
                  columnVisible={{ id: false, ...visibilityColumns }}
                  onSelectCell={handleOnSelectCell}
                  checkboxSelection={false}
                />
              )}
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
};

export default ProviderPricesScreen;
