import { PROVIDERS_URL, UPLOAD_URL } from '../constans';
import { apiSlice } from './apiSlice';

export const providersApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		// Вызываем когда пользователь в роли покупателя выбирает другого покупателя
		// CustomerSelectCustomerScreen.jsx
		getProvidersOfUser: builder.query({
			query: (data) => ({
				url: `${PROVIDERS_URL}/ofuser/`,
				params: {
					...data
				}
			}),
			keepUnusedDataFor: 5
		}),
		getProviderUsers: builder.query({
			query: (data) => ({
				url: `${PROVIDERS_URL}/${data?.providerId}/users/`,
				params: {
					...data,
				}
			}),
			keepUnusedDataFor: 5
		}),
		getProviderCities: builder.query({
			query: (data) => ({
				url: `${PROVIDERS_URL}/cities/`,
				params: {
					all: data?.all,
					providerId: data?.providerId
				}
			}),
			keepUnusedDataFor: 5
		}),
		getProviderProductMenu: builder.query({
			query: (data) => ({
				url: `${PROVIDERS_URL}/${data?.providerId || data?.owner}/menu`,
				method: 'GET',
				params: {
					...data
				}
			}),
			keepUnusedDataFor: 5,
			providesTags: ['ProviderProductMenu']
		}),
		getProviderGroups: builder.query({
			query: (data) => ({
				url: `${PROVIDERS_URL}/${data?.providerId}/groups`,
				method: 'GET',
				params: {
					...data
				}
			}),
			keepUnusedDataFor: 5,
		}),
		getProviders: builder.query({
			query: () => ({
				url: PROVIDERS_URL,
			}),
			providesTags: ['Provider'],
			keepUnusedDataFor: 5
		}),
		getProviderDetails: builder.query({
			query: (data) => ({
				url: `${PROVIDERS_URL}/${data?.providerId}`,
				method: 'GET',
			}),
			keepUnusedDataFor: 5,
		}),

		createProvider: builder.mutation({
			query: (data) => ({
				url: `${PROVIDERS_URL}`,
				method: 'POST',
				body: { ...data }
			}),
			invalidatesTags: ['Provider'] //очистить ранее созданный кеш
		}),
		updateProvider: builder.mutation({
			query: (data) => ({
				url: `${PROVIDERS_URL}/${data._id}`,
				method: 'PUT',
				body: data
			}),
			invalidatesTags: ['Provider'] //очистить ранее созданный кеш
		}),
		updateProviderField: builder.mutation({
			query: (data) => ({
				url: `${PROVIDERS_URL}/${data._id}/updateField`,
				method: 'POST',
				body: data
			}),
			invalidatesTags: ['Provider'] //очистить ранее созданный кеш
		}),
		uploadProviderImage: builder.mutation({
			query: (data) => ({
				url: UPLOAD_URL,
				method: 'POST',
				body: data
			}),
		}),
		deleteProvider: builder.mutation({
			query: (ProviderId) => ({
				url: `${PROVIDERS_URL}/${ProviderId}`,
				method: 'DELETE'
			}),
			invalidatesTags: ['Provider'] //очистить ранее созданный кеш
		}),
	})
});

export const {
	useGetProviderCitiesQuery,
	useUpdateProviderFieldMutation,
	useGetProviderUsersQuery,
	useGetProvidersOfUserQuery,
	useGetProviderProductMenuQuery,
	useGetProviderGroupsQuery,
	useGetProvidersQuery,
	useGetProviderDetailsQuery,
	useCreateProviderMutation,
	useUpdateProviderMutation,
	useDeleteProviderMutation,
	useUploadProviderImageMutation
} = providersApiSlice;