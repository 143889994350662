import React, { useEffect, useState } from 'react';
import { useGetProductsQuery } from '../../slices/productsApiSlice';
import { Alert, Button, ListGroup, Pagination, Spinner } from 'react-bootstrap';
import { useSelector } from 'react-redux';

const ProductList = ({ groupId, isGroup }) => {
  const { pov, theme, currentProvider } = useSelector((state) => state.auth);
  const [pageNumber, setPageNamber] = useState(1); //текущая страница
  const [pageSize, setPageSize] = useState(30); //количество на странице
  //const [showPaginationPanel, setShowPaginationPanel] = useState(all || false);

  useEffect(() => {
    return () => {
      //console.log('unmounts');
      //when the component unmounts
      setPageNamber(1);
      setPageSize(30);
    };
  }, []);

  const queryData = {
    pov,
    groupId,
    providerId: currentProvider?._id,
    pageNumber: pageNumber,
    pageSize: pageSize,
  };

  const { data, refetch, isLoading, isError, error } =
    useGetProductsQuery(queryData);

  const handleClickRefresh = () => {
    refetch();
  };

  let pagesItems = [];

  for (let number = 1; number <= data?.pages; number++) {
    pagesItems.push(
      <Pagination.Item
        linkClassName={`archive-page-link-${theme}`}
        onClick={() => setPageNamber(number)}
        key={number}
        active={pageNumber === number}
        className={pageNumber === number ? '' : 'text-white'}
      >
        {number}
      </Pagination.Item>
    );
  }

  return (
    <>
      {isLoading ? (
        <Spinner animation='border' />
      ) : isError ? (
        <>
          <Alert variant='outlined' severity='error'>
            {error?.data?.message || error.error}
          </Alert>
          <Button
            size='sm'
            type='button'
            variant='primary'
            className={`my-2 text-primary`}
            onClick={handleClickRefresh}
          >
            Обновить
          </Button>
        </>
      ) : (
        <>
          <ListGroup bsPrefix='narrow'>
            {Array.isArray(data?.rows) && data.rows.length > 0 ? (
              data.rows.map((item) => (
                <ListGroup.Item
                  //onClick={() => onClick(item._id)}
                  as={'div'}
                  variant={theme}
                  action
                  key={item._id}
                  className='d-flex justify-content-between align-items-start  text-secondary'
                >
                  {item.name}
                </ListGroup.Item>
              ))
            ) : (
              <ListGroup.Item
                // onClick={() => onClick(undefined)}
                as={'div'}
                variant={theme}
                className='d-flex justify-content-between align-items-start'
              >
                {isGroup
                  ? 'Пустая группа может быть удалена и переведена в роль пункта меню'
                  : 'Пустой пункт меню. Можно удалить или превратить в группу'}
              </ListGroup.Item>
            )}
          </ListGroup>
          {
            data?.pages > 1 && (
              <Pagination className='m-0 mt-2'>{pagesItems}</Pagination>
            )
            //   ) : (
            //     <>
            //       {data?.pageSize && Number(data?.pages) > 1 ? (
            //         <Button
            //           size='sm'
            //           type='button'
            //           variant='primary'
            //           className='my-2'
            //           onClick={handleClickMore}
            //         >
            //           {`ещё...`}
            //         </Button>
            //       ) : (
            //         ''
            //       )}
            //     </>
            //   )
          }
        </>
      )}
    </>
  );
};

export default ProductList;
