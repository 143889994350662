import { useState, useCallback, useEffect } from 'react';
import styles from './Image.module.css';
import { Image } from 'react-bootstrap';
import Dialog from '@mui/material/Dialog';
import { FaRegTimesCircle } from 'react-icons/fa';
import Box from '@mui/material/Box';
import { Col } from 'react-bootstrap';
import Paper from '@mui/material/Paper';

const ZoomableImage = ({
  className,
  zoom,
  rows,
  src,
  startImage,
  setClickedImage = () => {},
  ...rest
}) => {
  const [click, setClick] = useState(!!startImage);
  const [currentImage, setCurrentImage] = useState(src || startImage);
  const escFunction = useCallback((event) => {
    if (event.key === 'Escape') {
      // console.log('Escape Pressed!');
      // Your Custom Logic
      setClick(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);

    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, []);

  const setFlag = () => {
    setClick(true);
  };

  const unsetFlag = () => {
    setClickedImage('');
    setClick(false);
  };

  const handleClickImageOnStack = (url) => {
    console.log(url);
    setCurrentImage(url);
  };

  // console.log('currentImage', currentImage);

  if (!zoom)
    return <Image src={currentImage} alt='photo' className={className} />;
  else
    return (
      <>
        {click ? (
          <Dialog fullScreen open={click}>
            <div
              role='button'
              onClick={unsetFlag}
              className={`${styles.lightbox} ${styles.show} relative  ${className} modal`}
            >
              <FaRegTimesCircle
                size={24}
                color='darkgray'
                onClick={unsetFlag}
                className='position-absolute'
                style={{ right: '10px', top: 10 }}
              />
              <Image
                src={currentImage}
                className={`${styles.show_image} ${className}`}
                alt='photo'
              />
            </div>
            {Array.isArray(rows) && (
              <Box sx={{ width: '100%' }}>
                <Paper
                  sx={{
                    height: '5%',
                    position: 'fixed',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    zIndex: 2000,
                  }}
                  elevation={3}
                  className='d-flex justify-content-center gap-1'
                >
                  {rows?.map((img, index) => (
                    <Image
                      key={index}
                      role='button'
                      src={img}
                      onClick={() => handleClickImageOnStack(img)}
                      alt='photo'
                      className={'h-100'}
                      style={{
                        border: img === currentImage ? '2px solid red' : '',
                      }}
                    ></Image>
                  ))}
                </Paper>
              </Box>
            )}
          </Dialog>
        ) : (
          <>
            {Array.isArray(rows) ? (
              rows?.map((img, index) => (
                <Col key={index} style={{ height: '100%' }}>
                  <Image
                    role='button'
                    src={img}
                    onClick={setFlag}
                    alt='photo'
                    className={className}
                  ></Image>
                </Col>
              ))
            ) : (
              // <div
              //   style={{
              //     paddingBottom: '100%',
              //     overflow: 'hidden',
              //     position: 'relative',
              //   }}
              // >
              <Image
                role='button'
                src={currentImage}
                onClick={setFlag}
                alt='photo'
                className={className}
                // style={{ width: '100%;', height: '50vw', objectFit: 'cover' }}
              ></Image>
              // </div>
            )}
          </>
        )}
      </>
    );
};

export default ZoomableImage;
