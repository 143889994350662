// export const BASE_URL = process.env.NODE_ENV === 'development' ?
// 	'http://localhost:5000' : '';
export const BASE_URL = '';
export const PRICESVALUE_URL = '/api/pricevalue';
export const PRICES_URL = '/api/prices';
export const CONTRACTS_URL = '/api/contracts';
export const PRODUCTS_URL = '/api/products';
export const CUSTOMERS_URL = '/api/customers';
export const USERS_URL = '/api/users';
export const ORDERS_URL = '/api/orders';
export const PAYPAL_URL = '/api/config/paypal';
export const UPLOAD_URL = '/api/upload';
export const GROUPS_URL = '/api/groups';
export const PROVIDERS_URL = '/api/providers';
export const UNITS_URL = '/api/units';
export const INVITES_URL = '/api/invites';
export const SECTIONS_URL = '/api/sections';
export const CONTACTS_URL = '/api/contacts';
export const ROUTES_URL = '/api/routes';
export const REGIONS_URL = '/api/regions';
export const NEWS_URL = '/api/news';
export const CARTS_URL = '/api/carts';
export const TASKS_URL = '/api/tasks';
