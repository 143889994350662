import React from 'react';
import { Button } from 'react-bootstrap';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { setNewsVisibility } from '../../../slices/authSlice';

const ShowAllNews = ({ disabled, size, className }) => {
  const dispatch = useDispatch();
  const { theme, showAllNews } = useSelector((state) => state.auth);

  return (
    <Button
      disabled={disabled || false}
      role='button'
      size={size || 22}
      className={className || 'bg-transparent'}
      onClick={() => dispatch(setNewsVisibility(!showAllNews))}
    >
      {!showAllNews ? (
        <FaEyeSlash size={22} color={theme === 'dark' ? 'white' : 'black'} />
      ) : (
        <FaEye size={22} color={theme === 'dark' ? 'white' : 'black'} />
      )}
    </Button>
  );
};

export default ShowAllNews;
