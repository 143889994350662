import { Outlet, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const CheckCustomerRoute = () => {
  const { pov, currentCustomer } = useSelector((state) => state.auth);

  if (pov === 'customer') {
    if (currentCustomer?._id) {
      return <Outlet />;
    } else {
      return <Navigate to='/customer/select/customer' replace />;
    }
  } else {
    return <Navigate to='/start' replace />;
  }
  // return pov === 'customer' ? <Outlet /> : <Navigate to='/provider' replace />;
};

export default CheckCustomerRoute;
