import React, { useEffect, useState } from 'react';
import ToolsPanel from '../components/ToolsPanel';
import { Button, Col, Container, Row, Spinner, Stack } from 'react-bootstrap';
// import { FaFilter } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { LinearProgress } from '@mui/material';
import Message from '../components/Message';
import DataTable from '../components/DataTable/DataTable';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetProviderOrdersQuery } from '../slices/ordersApiSlice';
import {
  setDate1mem,
  setDate2mem,
  setSearchString,
  // setGridSettingMode,
} from '../slices/authSlice';
import ProviderOrdersToolbar from '../components/DataTableToolbar/ProviderOrdersToolbar/ProviderOrdersToolbar';
import { DateTime } from 'luxon';
import { MobileDatePicker } from '@mui/x-date-pickers';
import NoRowsOverlayOrders from '../components/DataTable/NoRowsOverlayOrders';
import { FaBookMedical, FaPlus, FaRedo, FaSync } from 'react-icons/fa';

const ProviderOrdersScreen = () => {
  const { keyword } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // const [date1, setDate1] = useState(DateTime.local().toISO());
  // const [date2, setDate2] = useState(DateTime.local().toISO());
  // const [showFilterSelect, setShowFilterSelect] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [spin, setSpin] = useState(false);

  const {
    theme,
    pov,
    currentBreakpoint,
    currentProvider,
    gridSettingMode,
    date1mem,
    date2mem,
    searchString,
  } = useSelector((state) => state.auth);

  useEffect(() => {
    //если пользователь пришёл по ссылке
    //установим поисковую строку в менеджере состояний
    if (keyword?.length > 0 && searchString !== keyword) {
      dispatch(setSearchString(keyword));
    }
    // eslint-disable-next-line
  }, [keyword]);

  useEffect(() => {
    //если пользователь стёр значение в поле ввода поиска
    //перебрасываем его на основную страницу
    if (keyword?.length > 0 && !searchString) {
      navigate(`/provider/orders/`);
    }
    // eslint-disable-next-line
  }, [searchString]);

  // console.log('date1.toISO()', date1.toISO());
  // console.log('date2.toISO()', date2.toISO());

  // useEffect(() => {
  //   if (!date1) {
  //     if (!date1mem) {
  //       setDate1(DateTime.local());
  //     } else {
  //       setDate1(date1mem);
  //     }
  //   }
  //   try {
  //     if (date1) {
  //       dispatch(setDate1mem(date1.toISO()));
  //     }
  //     if (date2) {
  //       dispatch(setDate2mem(date2.toISO()));
  //     }
  //   } catch (error) {}
  // }, [date1, date2]);

  const { data, refetch, isLoading, isError, error } =
    useGetProviderOrdersQuery({
      providerId: currentProvider?._id,
      date1: date1mem,
      date2: date2mem,
      keyword,
    });

  // console.log('data', data);

  const rows = data?.orders?.map((item) => ({
    ...item,
    id: item._id,
    customerId: item.customer?._id,
    customer: item.customer?.name,
    price: item.price?.name,
    createdAt: new Date(item?.createdAt),
    user: item?.user?.name,
  }));

  const columns = [
    { field: 'id', headerName: 'id', width: 50, hide: true },
    { field: 'customerId', headerName: 'customerId', width: 50, hide: true },
    { field: 'priceId', headerName: 'priceId', width: 50, hide: true },
    { field: 'isDelivered', headerName: 'isDelivered', width: 5, hide: true },
    { field: 'isCanceled', headerName: 'isCanceled', width: 5, hide: true },
    {
      field: 'createdAt',
      headerName: 'от..',
      flex: 0.4,
      type: 'date',
    },
    {
      field: 'customer',
      headerName: 'Покупатель',
      flex: 1,
    },
    {
      field: 'user',
      headerName: 'Автор',
      flex: 0.2,
    },

    {
      field: 'totalPrice',
      headerName: 'Сумма',
      flex: 0.4,
      type: 'number',
    },
  ];

  const visibilityColumns = {
    id: false,
    customerId: false,
    priceId: false,
    isDelivered: false,
    isCanceled: false,
    user: !['xs', 'sm', 'md'].includes(currentBreakpoint),
    totalPrice: !['xs'].includes(currentBreakpoint),
  };

  const handleRefetch = () => {
    setSpin(true);
    setTimeout(() => setSpin(false), 500);
    refetch();
  };

  const handleSetDate = (num, value) => {
    if (num === 1) {
      //дата начала периода
      dispatch(setDate1mem(value.toISO()));
    }
    if (num === 2) {
      //дата окончания периода
      dispatch(setDate2mem(value.toISO()));
    }
  };

  const handleOnSelectCell = (data) => {
    //при клике на строку открываем детальную страницу заказа
    if (data?.row?.id) {
      navigate(`/provider/orders/${data.row.id}`);
    }
  };

  const handleClickCreateOrder = (data) => {
    navigate(`/provider/orders/new`, {
      state: { backUrl: '/provider/orders' },
    });
  };

  let buttons = [];
  buttons.push({
    className: `bg-transparent`,
    name: 'Создать заявку',
    type: 'component',
    action: handleClickCreateOrder,
    component: (props) => {
      return <FaPlus size={22} color={theme === 'dark' ? 'white' : 'black'} />;
    },
  });

  // buttons.push({
  //   className: `bg-transparent ${gridSettingMode && 'border-success'}`,
  //   name: 'Инструменты',
  //   type: 'component',
  //   action: handleClickShowStuff,
  //   component: (props) => {
  //     return (
  //       <FaFilter size={22} color={theme === 'dark' ? 'white' : 'black'} />
  //     );
  //   },
  // });

  return (
    <>
      <ToolsPanel
        title='Заявки'
        buttons={buttons}
        backURL={pov === 'provider' ? '/provider' : ''}
      />
      {isLoading ? (
        <LinearProgress />
      ) : isError ? (
        <Message>{error?.data?.message || error.error}</Message>
      ) : (
        <>
          <Container>
            <Row>
              <Col className='mb-3'>
                {Array.isArray(rows) && (
                  <>
                    <Stack
                      direction='horizontal'
                      gap={1}
                      className='mx-0 px-0 mb-2'
                    >
                      <MobileDatePicker
                        autoOk='true'
                        disableFuture={true}
                        disableCloseOnSelect={true}
                        value={DateTime.fromISO(date1mem)}
                        onChange={(newValue) => handleSetDate(1, newValue)}
                      />
                      <MobileDatePicker
                        disableFuture={true}
                        disableCloseOnSelect={true}
                        value={DateTime.fromISO(date2mem)}
                        onChange={(newValue) => handleSetDate(2, newValue)}
                      />
                      {spin ? (
                        <Spinner animation='border' className='ms-1' />
                      ) : (
                        <Button onClick={handleRefetch}>
                          <FaSync size={20} />
                        </Button>
                      )}
                    </Stack>
                    {gridSettingMode && (
                      <ProviderOrdersToolbar
                        refetch={refetch}
                        selectedFilters={selectedFilters}
                        setSelectedFilters={setSelectedFilters}
                      />
                    )}

                    <DataTable
                      noRowsOverlay={NoRowsOverlayOrders}
                      rows={rows || []}
                      columns={columns}
                      columnVisible={{ id: false, ...visibilityColumns }}
                      onSelectCell={handleOnSelectCell}
                      checkboxSelection={false}
                    />
                  </>
                )}
              </Col>
            </Row>
          </Container>
        </>
      )}
    </>
  );
};

export default ProviderOrdersScreen;
