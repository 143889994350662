import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
  Stack,
} from 'react-bootstrap';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Message from '../components/Message/Message';
import { toast } from 'react-toastify';
import {
  useUpdateContractMutation,
  useGetContractDetailsQuery,
  useCreateContractMutation,
  useDeleteContractMutation,
} from '../slices/contractsApiSlice';
import SelectUnit from '../components/SelectUnit/SelectUnit';
import ToolsPanel from '../components/ToolsPanel';
import { useSelector } from 'react-redux';
import { LinearProgress } from '@mui/material';
import GroupListSimple from '../components/GroupList/GroupListSimple';
import { FaClipboardList, FaRegFlag, FaTrash } from 'react-icons/fa';

const ProviderContractEditScreen = () => {
  const { state } = useLocation();
  const { contractId } = useParams();
  const navigate = useNavigate();
  const [showSelectWindow, setShowSelectWindow] = useState(false);
  //const [unitRows, setUnitRows] = useState([]);
  const [selectedUnit, setSelectedUnit] = useState();
  const [modalTitle, setModalTitle] = useState('');

  const [regions, setRegions] = useState([]);
  const [route, setRoute] = useState({});
  const [provider, setProvider] = useState({});
  const [ownerId, setOwnerId] = useState('');
  const [customer, setCustomer] = useState({});
  const [price, setPrice] = useState({});
  const [canReturn, setCanReturn] = useState(false);
  const [providerAgree, setProviderAgree] = useState(false);
  const [customerAgree, setCustomerAgree] = useState(false);
  const [providerCode, setProviderCode] = useState('');
  const [customerCode, setCustomerCode] = useState('');
  const {
    pov,
    theme,
    currentBreakpoint,
    currentProvider,
    searchString,
    currentRegion,
  } = useSelector((state) => state.auth);

  // console.log('searchString', searchString);
  // console.log('currentRegion', currentRegion);

  useEffect(() => {
    if (!contractId || contractId === 'search') {
      navigate('/provider/contracts/');
    }
    //eslint-disable-next-line
  }, [contractId]);

  useEffect(() => {
    if (selectedUnit) {
      if (modalTitle === 'Поставщики') {
        setProvider(selectedUnit);
        setOwnerId(selectedUnit._id);
        setPrice('');
      } else if (modalTitle === 'Покупатели') {
        setCustomer(selectedUnit);
      } else if (modalTitle === 'Регионы') {
        setRegions([
          ...regions.filter((item) => item._id !== selectedUnit?._id),
          selectedUnit,
        ]);
      } else if (modalTitle === 'Типы цен') {
        setPrice(selectedUnit);
      }
      setSelectedUnit('');
      setModalTitle('');
      setShowSelectWindow(false);
    }
    // eslint-disable-next-line
  }, [selectedUnit]);

  const payload = {
    contractId,
    providerId: currentProvider?._id,
  };

  const {
    data: contract,
    refetch,
    isLoading,
    isError,
    error,
  } = useGetContractDetailsQuery(payload);
  // console.log(contract);

  useEffect(() => {
    if (contract) {
      setRoute(contract.route || '');
      setRegions(contract.regions || []);
      setProvider(contract.provider || '');
      setOwnerId(contract.provider?._id || '');
      setCustomer(contract.customer || '');
      setPrice(contract.price || '');
      setProviderAgree(contract.providerAgree || false);
      setCustomerAgree(contract.customerAgree || false);
      setProviderCode(contract.providerCode || '');
      setCustomerCode(contract.customerCode || '');
      setCanReturn(contract.canReturn || false);
    }
  }, [contract]);

  const [updateContract, { isLoading: loadingUpdate }] =
    useUpdateContractMutation();
  const [createContract, { isLoading: loadingCreate }] =
    useCreateContractMutation();
  const [deleteContract, { isLoading: loadingDelete }] =
    useDeleteContractMutation();

  if (pov !== 'provider') {
    return;
  }

  let backUrl;
  if (searchString?.length > 0) {
    backUrl = `/provider/contracts/search/${searchString}`;
  } else if (currentRegion?.id) {
    backUrl = `/provider/contracts/region/${currentRegion?.id}`;
  } else {
    backUrl = `/provider/contracts`;
  }

  const updateHandler = async () => {
    const toSave = {
      _id: contractId,
      providerId: provider?._id,
      customerId: customer?._id,
      priceId: price?._id,
      routeId: route?._id,
      providerAgree,
      customerAgree,
      providerCode,
      customerCode,
      canReturn,
      regions,
    };

    try {
      let result = {};
      if (contractId === 'new') {
        result = await createContract(toSave).unwrap();
      } else {
        result = await updateContract(toSave).unwrap();
      }

      if (result.error) {
        toast.error(result.error);
      } else {
        refetch();
        // toast.success(
        //   `Договор ${contractId === 'new' ? 'создан' : 'обновлён'}`
        // );
        navigate(backUrl);
      }
    } catch (error) {
      toast.error(error?.data?.message || error?.message || error.error);
    }
  };

  const submitHandler = (event) => {
    event.preventDefault();
    updateHandler();
    // updateHandler(event.target.getAttribute('close') === 'true');
  };

  const selectUnit = (name) => {
    setModalTitle(name);
    setShowSelectWindow(true);
  };

  const handleClickClose = () => {
    if (pov === 'provider') {
      navigate(backUrl);
    }
  };

  // const handlerClickDelete = async () => {
  //   if (window.confirm('Вы уверены, что хотите удалить договор?')) {
  //     try {
  //       await deleteContract({
  //         contractId,
  //         providerId: currentProvider?._id,
  //       }).unwrap();
  //       //toast.success('Договор удалён');
  //       navigate('/provider/contracts');
  //     } catch (error) {
  //       toast.error(error?.data?.message || error?.message || error.error);
  //     }
  //   }
  // };

  const handleClickDeleteRegion = (payload) => {
    setRegions(regions.filter((region) => region._id !== payload._id));
  };

  const handleClickCreateOrder = () => {
    if (customer?._id) {
      navigate(`/provider/orders/new`, {
        state: {
          customer: customer,
          backUrl: `/provider/contracts/${contractId}`,
        },
      });
    }
  };

  const handleClickCreateTask = () => {
    if (customer?._id) {
      navigate(`/provider/tasks/new?cid=${customer?._id}`);
    }
  };

  const themeStyle = {
    backgroundColor: theme === 'dark' ? '#212529' : 'white',
    borderColor: theme === 'dark' ? '#212529' : '#b5c0c1',
    color: theme === 'dark' ? 'white' : 'black',
  };

  const buttons = [
    {
      className: `bg-transparent`,
      name: 'Принять заявку',
      type: 'component',
      action: handleClickCreateOrder,
      component: (props) => {
        return (
          <FaClipboardList
            size={22}
            color={theme === 'dark' ? 'white' : 'black'}
          />
        );
      },
    },
    {
      className: `bg-transparent`,
      name: 'Добавить задание',
      type: 'component',
      action: handleClickCreateTask,
      component: (props) => {
        return (
          <FaRegFlag size={22} color={theme === 'dark' ? 'white' : 'black'} />
        );
      },
    },
  ];

  // console.log('searchString', searchString);
  // console.log('currentRegion', currentRegion);

  return (
    <>
      <ToolsPanel
        buttons={buttons}
        backURL={backUrl}
        title={`Договор ${customer?.name ? 'c: ' + customer.name : ''}`}
        isLoading={loadingUpdate || loadingCreate}
      />
      {isLoading ? (
        <LinearProgress />
      ) : isError ? (
        <Message variant={'danger'}>
          {error?.data?.message || error.error}
        </Message>
      ) : (
        <Container>
          <Row>
            <Col sx={12} sm={12} md={12} lg={10} xxl={8}>
              <Form onSubmit={submitHandler}>
                <Card className=' bg-transparent p-3'>
                  <Form.Group controlId='provider' className='my-2' as={Row}>
                    <Form.Label column sm='4' className='pb-0'>
                      Поставщик
                    </Form.Label>
                    <Col sm='8'>
                      <Form.Control
                        disabled={true}
                        style={themeStyle}
                        type='text'
                        placeholder='Выберите поставщика'
                        value={provider.name || ''}
                        onClick={() => selectUnit('Поставщики')}
                        //onChange={(e) => onChangeHandler}
                        readOnly={true}
                      ></Form.Control>
                    </Col>
                  </Form.Group>

                  <Form.Group controlId='customer' className='my-2' as={Row}>
                    <Form.Label column sm='4' className='pb-0'>
                      Покупатель
                    </Form.Label>
                    <Col sm='8'>
                      <Form.Control
                        disabled={true}
                        style={themeStyle}
                        type='text'
                        placeholder='Выберите покупателя'
                        value={customer.name || ''}
                        onClick={() => selectUnit('Покупатели')}
                        readOnly={true}
                      ></Form.Control>
                    </Col>
                  </Form.Group>

                  <Form.Group controlId='price' className='my-2' as={Row}>
                    <Form.Label column sm='4' className='pb-0'>
                      Тип цен
                    </Form.Label>
                    <Col sm='8'>
                      <Form.Control
                        className='border-success'
                        style={themeStyle}
                        type='text'
                        placeholder='Выберите тип цен'
                        value={price.name || ''}
                        onClick={() => selectUnit('Типы цен')}
                        //onChange={onChangeHandler}
                        readOnly={true}
                      ></Form.Control>
                    </Col>
                  </Form.Group>
                  <Form.Group controlId='manager' className='my-2' as={Row}>
                    <Form.Label column sm='4' className='pb-0'>
                      Группы
                    </Form.Label>
                    <Col sm='8'>
                      <GroupListSimple
                        rows={regions}
                        buttons={[
                          {
                            icon: <FaTrash />,
                            onClick: handleClickDeleteRegion,
                          },
                        ]}
                      />
                      <span className='d-flex justify-content-end mt-1'>
                        <Button
                          variant='primary'
                          onClick={() => selectUnit('Регионы')}
                        >
                          Добавить к группе
                        </Button>
                      </span>
                    </Col>
                  </Form.Group>

                  {/* <Form.Group controlId='route' className='my-2' as={Row}>
                    <Form.Label column sm='4' className='pb-0'>
                      Маршрут
                    </Form.Label>
                    <Col sm='8'>
                      <Form.Control
                        className='border-success'
                        style={themeStyle}
                        type='text'
                        placeholder='Выберите маршрут'
                        value={route.name || ''}
                        onClick={() => selectUnit('Маршруты')}
                        //onChange={onChangeHandler}
                        readOnly={true}
                      ></Form.Control>
                    </Col>
                  </Form.Group> */}

                  <Form.Group controlId='canReturn' className='my-2' as={Row}>
                    <Col sm='12'>
                      <Form.Check
                        isValid
                        type='checkbox'
                        label='Разрешён возврат товара'
                        checked={canReturn}
                        onChange={(e) => setCanReturn(e.target.checked)}
                      ></Form.Check>
                    </Col>
                  </Form.Group>

                  {pov === 'customer' && (
                    <Form.Group
                      controlId='providerCode'
                      className='my-2'
                      as={Row}
                    >
                      <Form.Label column sm='4' className='pb-0'>
                        Код поставщика в вашей 1С
                      </Form.Label>
                      <Col sm='8'>
                        <Form.Control
                          className='border-success'
                          style={themeStyle}
                          type='text'
                          placeholder='Код договора в учет.системе'
                          value={providerCode}
                          onChange={(e) => setProviderCode(e.target.value)}
                        ></Form.Control>
                      </Col>
                    </Form.Group>
                  )}

                  {pov === 'provider' && (
                    <Form.Group
                      controlId='customerCode'
                      className='my-2'
                      as={Row}
                    >
                      <Form.Label column sm='4' className='pb-0'>
                        Код покупателя в вашей 1С
                      </Form.Label>
                      <Col sm='8'>
                        <Form.Control
                          className='border-success'
                          style={themeStyle}
                          type='text'
                          placeholder='Код покупателя в 1С'
                          value={customerCode}
                          onChange={(e) => setCustomerCode(e.target.value)}
                        ></Form.Control>
                      </Col>
                    </Form.Group>
                  )}
                  <Form.Group
                    controlId='providerAgree'
                    className='my-2'
                    as={Row}
                  >
                    <Col sm='12'>
                      <Form.Check
                        isValid
                        type='checkbox'
                        label='Поставщик согласен'
                        checked={providerAgree}
                        onChange={(e) => setProviderAgree(e.target.checked)}
                      ></Form.Check>
                    </Col>
                  </Form.Group>

                  <Form.Group
                    controlId='customerAgree'
                    className='my-2'
                    as={Row}
                  >
                    <Col sm='12'>
                      <Form.Check
                        disabled={true}
                        type='checkbox'
                        label='Покупатель согласен'
                        checked={customerAgree}
                        onChange={(e) => setCustomerAgree(e.target.checked)}
                      ></Form.Check>
                    </Col>
                  </Form.Group>
                </Card>
                {/* <Stack
                  direction='horizontal'
                  gap={3}
                  //class='justify-content-center'
                > */}
                <div className='d-flex justify-content-between'>
                  <Stack direction='horizontal' gap={3}>
                    <Button
                      size={
                        ['lg', 'xl', 'xxl'].includes(currentBreakpoint)
                          ? 'lg'
                          : 'sm'
                      }
                      type='submit'
                      variant='primary'
                      className='my-2'
                    >
                      Записать
                    </Button>
                    <Button
                      size={
                        ['lg', 'xl', 'xxl'].includes(currentBreakpoint)
                          ? 'lg'
                          : 'sm'
                      }
                      variant='primary'
                      className='my-2'
                      onClick={handleClickClose}
                    >
                      Закрыть
                    </Button>
                  </Stack>
                  {/* <Button
                    size={
                      ['lg', 'xl', 'xxl'].includes(currentBreakpoint)
                        ? 'lg'
                        : 'sm'
                    }
                    variant='primary'
                    className='my-2 mx-2'
                    close='true'
                    onClick={handlerClickDelete}
                  >
                    Удалить
                  </Button> */}
                </div>
              </Form>
            </Col>
            <Col sx={12} sm={12} md={12} lg={6}></Col>
          </Row>
        </Container>
      )}

      <SelectUnit
        show={showSelectWindow}
        setShow={setShowSelectWindow}
        setSelected={setSelectedUnit}
        //rows={unitRows}
        payload={{ providerId: currentProvider?._id, pov }}
        title={modalTitle}
        owner={ownerId}
      />
    </>
  );
};

export default ProviderContractEditScreen;
