import { useEffect, useState } from 'react';
import { Button, Form, Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { savePaymentMethod } from '../slices/cartSlice';
import CheckoutSteps from '../components/Steps/CheckoutSteps';
import ToolsPanel from '../components/ToolsPanel';

const PaymentScreen = () => {
  // const { theme } = useSelector((state) => state.auth);
  const [paymentMethod, setPaymentMethod] = useState('По договору');

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { shippingAddress } = useSelector((state) => state.cart);

  // механизм переадресации пользователя назад, если он попал на страницу payment по ссылке
  //и у него не заполнены обязательные данные с предыдущего шага
  useEffect(() => {
    if (!shippingAddress) {
      navigate('/shipping');
    }
  }, [shippingAddress, navigate]);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(savePaymentMethod(paymentMethod));
    navigate('/placeorder');
  };

  // const themeStyle = {
  //   backgroundColor: theme === 'dark' ? '#212529' : 'white',
  //   borderColor: theme === 'dark' ? '#212529' : '#b5c0c1',
  //   color: theme === 'dark' ? 'white' : 'black',
  // };

  return (
    <>
      <ToolsPanel title='Оплата' />
      <CheckoutSteps step1 step2 />
      <Row>
        <Col md={12} lg={8}>
          <Form onSubmit={submitHandler}>
            <Form.Group>
              {/* <Form.Label as='legend'>Метод</Form.Label> */}
              <Col>
                <Form.Check
                  type='radio'
                  className='my-2'
                  label='по договору'
                  id='bycontract'
                  name='paymentMethod'
                  value={'По договору'}
                  checked
                  onChange={(e) => setPaymentMethod(e.target.value)}
                ></Form.Check>
              </Col>
            </Form.Group>
            <Button type='submit' variant='primary'>
              Продолжить
            </Button>
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default PaymentScreen;
