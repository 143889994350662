import React, { useEffect, useState } from 'react';
import { Button, Col, Form, ListGroup, Row, Stack } from 'react-bootstrap';
import { FaKey, FaShieldAlt, FaTrash } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import {
  useClearInviteMutation,
  useCreateInviteMutation,
  useGetInvitesQuery,
} from '../../slices/invitesApiSlice';
import GroupListSimple from '../GroupList/GroupListSimple';

const Invites = ({ unitType, unitId }) => {
  const { pov, currentBreakpoint } = useSelector((state) => state.auth);
  const [invites, setInvites] = useState();
  const [inviteRole, setInviteRole] = useState(false);

  const { data, refetch } = useGetInvitesQuery({ pov, unitId });

  useEffect(() => {
    if (data) {
      setInvites(data);
    }
  }, [data]);

  const [clearInvite] = useClearInviteMutation();
  const [createInvite] = useCreateInviteMutation();

  const createInviteHandler = async () => {
    const toSend = { unitType: unitType, unitId, isAdmin: inviteRole };
    try {
      const result = await createInvite(toSend).unwrap();
      toast.success(result?.message);
      refetch();
    } catch (error) {
      toast.error(error?.data.message || error?.message || error.error, {
        autoClose: 6000,
      });
    }
  };

  const deleteInviteHandler = async (invite) => {
    const toSend = { unitType: unitType, inviteId: invite._id };
    try {
      const result = await clearInvite(toSend).unwrap();
      toast.success(result?.message);
      refetch();
    } catch (error) {
      toast.error(error?.data.message || error?.message || error.error, {
        autoClose: 6000,
      });
    }
  };

  return (
    <>
      <Form>
        <Row>
          <Col lg={12} xl={5}>
            <Button
              size={
                ['lg', 'xl', 'xxl'].includes(currentBreakpoint) ? 'lg' : 'sm'
              }
              type='button'
              variant='primary'
              className='my-2'
              onClick={createInviteHandler}
            >
              Создать
            </Button>
          </Col>
          <Col lg={12} xl={7} className='mt-3'>
            <Form.Check // prettier-ignore
              checked={inviteRole}
              onChange={(e) => setInviteRole(e.target.checked)}
              type='switch'
              label='Назначить админом'
            />
          </Col>
        </Row>
      </Form>

      <GroupListSimple
        rows={invites}
        titleFieldName='code'
        buttons={[
          {
            cond: {
              fieldName: 'isAdmin',
              fieldValue: 'true',
              icon: <FaKey />,
            },
            onClick: () => {},
          },
          {
            icon: <FaTrash />,
            onClick: deleteInviteHandler,
          },
        ]}
      />

      {/* <ListGroup>
        {invites &&
          invites.map((item) => (
            <ListGroup.Item
              key={item._id}
              variant={theme}
              className='d-flex justify-content-between'
            >
              <Row>
                <Col className='me-auto d-flex'>
                  <h2 className='mb-0'>{item.code}</h2>
                </Col>
                <Col className='d-flex'>
                  <span className='me-1'>
                    {item.isAdmin === 'true' && <FaShieldAlt />}
                  </span>
                  <Button
                    //className='d-flex'
                    type='button'
                    variant={theme}
                    onClick={() => deleteInviteHandler(item._id)}
                  >
                    <FaTrash />
                  </Button>
                </Col>
              </Row>
            </ListGroup.Item>
          ))}
      </ListGroup> */}
    </>
  );
};

export default Invites;
