import { UNITS_URL, USERS_URL } from '../constans';
import { apiSlice } from './apiSlice'

export const usersApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		checkAllowed: builder.mutation({
			query: (data) => ({
				url: `${USERS_URL}/auth/check`,
				method: 'POST',
				body: data
			}),
		}),
		removeFromProvider: builder.mutation({
			query: (data) => ({
				url: `${USERS_URL}/removeFromProvider`,
				method: 'POST',
				body: {
					providerId: data.providerId,
					userId: data.userId
				}
			}),
		}),
		setProviderRole: builder.mutation({
			query: (data) => ({
				url: `${USERS_URL}/setProviderRole`,
				method: 'POST',
				body: {
					providerId: data?.providerId,
					userId: data?.userId,
					role: data?.role,
				}
			}),
		}),
		login: builder.mutation({
			query: (data) => ({
				url: `${USERS_URL}/auth`,
				method: 'POST',
				body: data
			}),
		}),
		logout: builder.mutation({
			query: () => ({
				url: `${USERS_URL}/logout`,
				method: 'POST'
			}),
		}),
		register: builder.mutation({
			query: (data) => ({
				url: USERS_URL,
				method: 'POST',
				body: data
			}),
		}),
		checkUserExist: builder.mutation({
			query: (data) => ({
				url: `${USERS_URL}/${data?.userId}`,
				method: 'POST',
				body: {
					...data
				}
			})
		}),
		profile: builder.mutation({
			query: (data) => ({
				url: `${USERS_URL}/profile`,
				method: 'PUT',
				body: data
			})
		}),
		getUsers: builder.query({
			query: () => ({
				url: USERS_URL,
			}),
			providesTags: ['Users'],
			keepUnusedDataFor: 5
		}),
		deleteUser: builder.mutation({
			query: (userId) => ({
				url: `${USERS_URL}/${userId}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['Users'],
		}),
		getUserDetails: builder.query({
			query: (userId) => ({
				url: `${USERS_URL}/${userId}`,
			}),
			providesTags: ['User'],
			keepUnusedDataFor: 5
		}),
		updateUser: builder.mutation({
			query: (data) => ({
				url: `${USERS_URL}/${data._id}`,
				method: 'PUT',
				body: data
			}),
			invalidatesTags: ['User', 'Users'],

		}),
		getUserUnits: builder.query({
			query: (data) => ({
				//url: `${USERS_URL}/units/${data.unitName}/customer/${data.customerId}`,
				url: `${USERS_URL}/units`,
				params: {
					pov: data?.pov,
					unitName: data?.unitName,
					customerId: data?.customerId,
				}
			}),
			keepUnusedDataFor: 5
		}),
		getAllUserUnits: builder.query({
			query: () => ({
				url: `${UNITS_URL}/all`,
			}),
			keepUnusedDataFor: 5
		}),
		checkRights: builder.query({
			query: (data) => ({
				url: `${USERS_URL}/check/${data.unitName}/${data.unitId}`,
			}),
			keepUnusedDataFor: 5
		}),
	})
})

export const {
	useSetProviderRoleMutation,
	useRemoveFromProviderMutation,
	useCheckAllowedMutation,
	useCheckUserExistMutation,
	useGetAllUserUnitsQuery,
	useCheckRightsQuery,
	useGetUserUnitsQuery,
	useLoginMutation,
	useLogoutMutation,
	useRegisterMutation,
	useProfileMutation,
	useGetUsersQuery,
	useDeleteUserMutation,
	useGetUserDetailsQuery,
	useUpdateUserMutation
} = usersApiSlice;