import { Badge, Breadcrumbs } from '@mui/material';
import {
  Button,
  Col,
  Container,
  Nav,
  Pagination,
  Row,
  Stack,
} from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

const CheckoutSteps = ({ step1, step2, step3, as }) => {
  if (as === 'button') {
    return (
      <>
        <Container className='my-3'>
          <Stack
            direction='horizontal'
            className={`align-content-end justify-content-start`}
            gap={1}
          >
            {step1 ? (
              <LinkContainer to='/customer/cart'>
                <Button className={`btn`}>Заявка</Button>
              </LinkContainer>
            ) : (
              <Button disabled>Заявка</Button>
            )}
            {step2 ? (
              <LinkContainer to='/customer/shipping'>
                <Button className={`btn`}>Сведения</Button>
              </LinkContainer>
            ) : (
              <Button disabled>Сведения</Button>
            )}
            {step3 ? (
              <LinkContainer to='/customer/placeorder'>
                <Button className={`btn`}>Отправить</Button>
              </LinkContainer>
            ) : (
              <Button disabled>Отправить</Button>
            )}
          </Stack>
        </Container>
      </>
    );
  } else if (as === 'breadcrumbs') {
    const activeItem = (title, link, badge) => (
      <span>
        {badge ? (
          <LinkContainer className='me-1' to={link} role='button'>
            <Badge variant='dot' color='success' invisible={false} size='small'>
              <span className={`text-decoration-underline text-info mb-0`}>
                {title}
              </span>
            </Badge>
          </LinkContainer>
        ) : (
          <LinkContainer className='me-1' to={link} role='button'>
            <span className={`text-decoration-underline text-info mb-0`}>
              {title}
            </span>
          </LinkContainer>
        )}
      </span>
    );
    const disabledItem = (title) => (
      <span className={`text-secondary mb-0`}> {title} </span>
    );

    return (
      <Container className='justify-content-center my-3'>
        <Row>
          <Col className=' my-auto'>
            <Breadcrumbs
              separator={<NavigateNextIcon fontSize='small' />}
              aria-label='breadcrumb'
            >
              {step1
                ? activeItem('Заказ', '/customer/cart', !step2)
                : disabledItem('Заказ')}
              {step2
                ? activeItem('Сведения', '/customer/shipping', !step3)
                : disabledItem('Сведения')}
              {step3
                ? activeItem('Отправка', '/customer/placeorder', true)
                : disabledItem('Отправка')}
            </Breadcrumbs>
          </Col>
        </Row>
      </Container>
    );
  } else if (as === 'pagination') {
    const activeItem = (title, link) => (
      <LinkContainer className='me-1' to={link}>
        <Pagination.Item active>{title}</Pagination.Item>
      </LinkContainer>
    );
    const disabledItem = (title) => (
      <Pagination.Item className='me-1' disabled>
        {title}
      </Pagination.Item>
    );

    return (
      <Container className='my-3'>
        <Pagination>
          {step1
            ? activeItem('Заказ', '/customer/cart')
            : disabledItem('Заказ')}
          {/* <Pagination.Next /> */}
          {step2
            ? activeItem('Сведения', '/customer/shipping')
            : disabledItem('Сведения')}
          {/* <Pagination.Next /> */}
          {step3
            ? activeItem('Отправить', '/customer/placeorder')
            : disabledItem('Отправить')}
        </Pagination>
      </Container>
    );
  } else {
    return (
      <Nav className='justify-content-center'>
        <Nav.Item>
          {step1 ? (
            <LinkContainer to='/customer/cart'>
              <Nav.Link>Заявка</Nav.Link>
            </LinkContainer>
          ) : (
            <Nav.Link disabled>Заявка</Nav.Link>
          )}
        </Nav.Item>

        <Nav.Item>
          {step2 ? (
            <LinkContainer to='/customer/shipping'>
              <Nav.Link>Сведения</Nav.Link>
            </LinkContainer>
          ) : (
            <Nav.Link disabled>Сведения</Nav.Link>
          )}
        </Nav.Item>

        <Nav.Item>
          {step3 ? (
            <LinkContainer to='/customer/placeorder'>
              <Nav.Link>Отправить</Nav.Link>
            </LinkContainer>
          ) : (
            <Nav.Link disabled>Отправить</Nav.Link>
          )}
        </Nav.Item>
      </Nav>
    );
  }
};

export default CheckoutSteps;
