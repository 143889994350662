import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	customerParams: {},
}

const customerSlice = createSlice({
	name: 'customerParams',
	initialState,
	reducers: {

		setCustomerParams: (state, action) => {
			const { key, params } = action.payload;
			if (key) {
				state.customerParams[key].params = params;
				localStorage.setItem('customerParams', JSON.stringify(state.customerParams));
			}
		},

	}
});

export const { setCustomerParams } = customerSlice.actions;
export default customerSlice.reducer;