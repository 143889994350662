import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Message from '../components/Message/Message';
import { toast } from 'react-toastify';
import ToolsPanel from '../components/ToolsPanel';
import { useSelector } from 'react-redux';
import { LinearProgress } from '@mui/material';
import { FaPen, FaRegCheckSquare, FaRegSquare } from 'react-icons/fa';
import { useGetTaskDetailsQuery } from '../slices/tasksApiSlice';
import DataTable from '../components/DataTable/DataTable';
import NoRowsOverlay from '../components/DataTable/NoRowsOverlay';
import ImagesStack from '../components/Image/ImagesStack';

const RenderPhotosColumn = (props) => {
  const { value } = props;
  return <>{Array.isArray(value) && value && <ImagesStack rows={value} />}</>;
};

const RenderCheckedColumn = (props) => {
  const { value } = props;
  return (
    <>{value ? <FaRegCheckSquare size={20} /> : <FaRegSquare size={20} />}</>
  );
};

const ProviderTaskScreen = () => {
  const params = useParams();
  const { taskId } = params;
  const { pathname } = useLocation();
  const isMultitask = pathname.includes('/multitasks');
  const navigate = useNavigate();
  const { theme, currentProvider } = useSelector((state) => state.auth);
  const { data, refetch, isLoading, isError, error } = useGetTaskDetailsQuery({
    taskId,
    providerId: currentProvider?._id,
    multitask: isMultitask,
  });

  // console.log(data);

  const {
    author,
    checked,
    commentRequired,
    photoRequired,
    createdAt,
    description,
    title,
    _id,
    tasks,
    customers,
  } = data?.row || {};

  const columns = [
    { field: 'id', headerName: 'id', width: 50 },
    {
      field: 'customer',
      headerName: 'Покупатель',
      flex: 1,
      cellClassName: 'grid-cell-name',
    },
  ];
  if (commentRequired) {
    columns.push({ field: 'comment', headerName: 'Ответ', flex: 0.5 });
  }
  if (photoRequired) {
    columns.push({
      field: 'photos',
      headerName: 'Фото',
      flex: 0.5,
      renderCell: RenderPhotosColumn,
    });
  }
  columns.push({ field: 'user', headerName: 'Выполнил', flex: 0.3 });
  columns.push({
    field: 'checked',
    headerName: '',
    // width: 50,
    flex: 0.1,
    renderCell: RenderCheckedColumn,
  });

  const visibilityColumns = {};
  visibilityColumns.id = false;

  const buttons = [];
  if (taskId !== 'new') {
    buttons.push({
      // name: 'Редактировать задание',
      action: () =>
        navigate(`/provider/multitasks/edit/${taskId}`, {
          state: { backUrl: `/provider/multitasks/${taskId}` },
        }),
      className: 'btn-block',
      icon: <FaPen />,
    });
  }

  return (
    <>
      <ToolsPanel
        backURL={'/provider/multitasks'}
        title={`Групповое задание`}
        buttons={buttons}
      />
      {isLoading ? (
        <LinearProgress />
      ) : isError ? (
        <Message>{error?.data?.message || error.error}</Message>
      ) : (
        <Container>
          <Row className='my-2'>
            <Col>Задание: {title}</Col>
          </Row>
          {description && (
            <Row className='my-2'>
              <Col>Описание: {description}</Col>
            </Row>
          )}
          <Row>
            <Col id='taskgrid'>
              <DataTable
                noRowsOverlay={NoRowsOverlay}
                rows={tasks || []}
                columns={columns}
                columnVisible={{ id: false, ...visibilityColumns }}
              />
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
};

export default ProviderTaskScreen;
