import { CARTS_URL } from '../constans';
import { apiSlice } from './apiSlice';

export const cartsApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getCart: builder.query({
			query: (data) => ({
				url: `${CARTS_URL}`,
				params: {
					providerId: data?.providerId,
					customerId: data?.customerId,
				}
			}),
			keepUnusedDataFor: 5
		}),
		updateCart: builder.mutation({
			query: (data) => ({
				url: `${CARTS_URL}`,
				method: 'POST',
				body: {
					providerId: data?.providerId,
					customerId: data?.customerId,
					itemList: data?.itemList,
				}
			}),
		}),
		updateCartItem: builder.mutation({
			query: (data) => ({
				url: `${CARTS_URL}`,
				method: 'PATCH',
				body: {
					providerId: data?.providerId,
					customerId: data?.customerId,
					productId: data?.productId,
					qty: data?.qty,
					ret: data?.ret,
					price: data?.price,
				}
			}),
			invalidatesTags: ['ProviderProductMenu']
		}),
	})
});

export const { useGetCartQuery, useUpdateCartMutation, useUpdateCartItemMutation } = cartsApiSlice;