import React, { useState } from 'react';
import { Col, Container, ListGroup, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import Message from '../components/Message';
import ToolsPanel from '../components/ToolsPanel/ToolsPanel';
import { toast } from 'react-toastify';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import {
  useChangeTaskStatusMutation,
  useGetProviderTasksQuery,
} from '../slices/tasksApiSlice';
import { useUploadImageMutation } from '../slices/imagesApiSlice';
import TaskItem from '../components/tasks/TaskItem';
import { FaPlus } from 'react-icons/fa';

const ProviderTasksScreen = () => {
  const { userId } = useParams();
  const { pathname } = useLocation();
  const multitask = pathname.includes('/multitasks');
  const navigate = useNavigate();
  const [visibility, setVisibility] = useState(true);
  // const [values, setValues] = useState([]);
  // const [items, setItems] = useState([]);

  const { theme, currentProvider } = useSelector((state) => state.auth);

  const { data, refetch, isLoading, isError, error } = useGetProviderTasksQuery(
    {
      userId,
      providerId: currentProvider?._id,
      all: !visibility,
      multitask,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  // console.log(data);

  // useEffect(() => {
  //   if (Array.isArray(data?.rows)) {
  //     const arr = {};
  //     for (let index = 0; index < data.rows.length; index++) {
  //       const task = data.rows[index];
  //       arr[task._id] = { ...task };
  //     }
  //     setItems(arr);
  //   }
  // }, [data]);

  // console.log(items);

  const [
    changeTaskStatus,
    { isLoading: isLoadingUpdate, isError: isErrorUpdate, error: errorUpdate },
  ] = useChangeTaskStatusMutation();

  const [uploadImage] = useUploadImageMutation();

  const onChangeHandler = async (data) => {
    // console.log(data);
    try {
      await changeTaskStatus({ ...data });
      refetch();
    } catch (error) {
      toast.error(error?.data?.message || error.error);
    }
  };

  if (isErrorUpdate) {
    toast.error(errorUpdate?.data?.message || errorUpdate.error);
  }

  const handleSetVisibility = () => {
    setVisibility(!visibility);
  };

  const handleClickAdd = () => {
    if (multitask) {
      navigate('/provider/multitasks/edit/new');
    } else {
      navigate('/provider/tasks/new');
    }
  };

  const onClickMultitask = (task) => {
    navigate(`/provider/multitasks/${task._id}`);
  };

  const buttons = [
    {
      type: 'switch',
      action: handleSetVisibility,
      disabled: visibility,
      name: 'все',
    },
    { hide: true }, //увеличить ширину родительского компонента Stack
    { hide: true }, //увеличить ширину родительского компонента Stack
    { hide: true }, //увеличить ширину родительского компонента Stack
  ];
  // if (multitask) {
  buttons.push({
    className: `bg-transparent`,
    name: 'Добавить новую мультизадачу',
    type: 'component',
    action: handleClickAdd,
    component: (props) => {
      return <FaPlus size={22} color={theme === 'dark' ? 'white' : 'black'} />;
    },
  });
  // }

  return (
    <>
      <ToolsPanel
        title={multitask ? 'Мультизадачи' : 'Задачи'}
        backURL={`/provider`}
        // isLoading={isLoadingUpdate || isLoading}
        buttons={buttons}
        subtitle={
          data?.user?.name
            ? 'Исполнитель: ' + data.user.name
            : userId === 'all'
            ? 'Все исполнители'
            : ''
        }
      />
      {isLoading ? (
        <></>
      ) : isError ? (
        <Message>{error?.data?.message || error.error}</Message>
      ) : (
        <>
          <Container>
            <Row>
              <Col>
                <ListGroup>
                  {data?.rows?.length > 0 &&
                    data?.rows?.map((item, index) => (
                      <TaskItem
                        userId={userId}
                        isErrorUpdate={isErrorUpdate}
                        isLoadingUpdate={isLoadingUpdate}
                        key={item?._id || index}
                        item={item}
                        theme={theme}
                        uploadImage={uploadImage}
                        onChangeHandler={onChangeHandler}
                        onClick={onClickMultitask}
                      />
                    ))}
                </ListGroup>
              </Col>
            </Row>
          </Container>
        </>
      )}
    </>
  );
};

export default ProviderTasksScreen;
