import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, ListGroup, Row, Col, Container } from 'react-bootstrap';
import { clearCartItems, unsetCartParams } from '../slices/cartSlice';
import { useCreateUpdateOrderMutation } from '../slices/ordersApiSlice';
import CheckoutSteps from '../components/Steps/CheckoutSteps';
import { toast } from 'react-toastify';
import Loader from '../components/Loader/Loader';
import Message from '../components/Message/Message';
import DataTable from '../components/DataTable/DataTable';
import { calculateTotals } from '../utils/cartUtils';

const CustomerPlaceOrderScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [allRight, setAllRight] = useState(false);
  const cart = useSelector((state) => state.cart);
  const { currentProvider, currentCustomer, theme, currentBreakpoint } =
    useSelector((state) => state.auth);

  const { cartParams } = cart;
  const [createUpdateOrder, { isLoading, isError, error }] =
    useCreateUpdateOrderMutation();

  useEffect(() => {
    if (!currentCustomer?.address) {
      navigate('/customer/shipping');
    } else {
      setAllRight(true);
    }
  }, [currentCustomer, navigate]);

  const cartItems = cart.cartItems
    .filter(
      (item) =>
        item.providerId === currentProvider._id &&
        item.customerId === currentCustomer._id
    )
    .map((item) => ({ ...item, id: item._id }));

  const { totalPrice, itemsPrice, shippingPrice, totalReturn, taxPrice } =
    calculateTotals(cartItems);

  let editedOrderId = '';
  if (currentCustomer?._id && currentProvider?._id) {
    const key = `${currentCustomer?._id}-${currentProvider?._id}`;
    if (cartParams[key]) {
      editedOrderId = cartParams[key]['orderId'];
    }
  }

  const placeOrderHandler = async () => {
    const orderId = editedOrderId;
    try {
      const toSend = {
        orderId: orderId,
        orderItems: cartItems,
        providerId: currentProvider?._id,
        customerId: currentCustomer?._id,
        paymentMethod: currentCustomer?.paymentMethod,
        shippingAddress: currentCustomer?.address,
        comment: currentCustomer?.comment,
        contacts: currentCustomer?.contacts,
        itemsPrice: itemsPrice,
        shippingPrice: shippingPrice,
        taxPrice: taxPrice,
        totalPrice: totalPrice,
      };
      // console.log(toSend);
      await createUpdateOrder(toSend).unwrap();

      dispatch(
        clearCartItems({
          providerId: currentProvider?._id,
          customerId: currentCustomer?._id,
        })
      );
      dispatch(
        unsetCartParams({
          providerId: currentProvider?._id,
          customerId: currentCustomer._id,
        })
      );

      if (orderId) {
        navigate(`/customer/cart/${orderId}`);
      } else {
        navigate(`/customer/cart`);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  // const indexRet = data.findIndex((item) => Number(item.ret) > 0);

  const columns = [
    { field: 'id', headerName: '№', width: 50, hide: true },
    {
      field: 'name',
      headerName: 'Название',
      flex: 1,
      //width: 350,
      editable: false,
      cellClassName: 'grid-cell-name',
    },
    {
      field: 'parentCode',
      headerName: 'Группа',
      //flex: 1,
      width: 100,
      editable: false,
      cellClassName: 'grid-cell-name',
    },

    {
      field: 'unit',
      headerName: 'Ед.',
      width: 40,
      editable: false,
      sortable: false,
      align: 'left',
      headerAlign: 'left',
      headerClassName: 'grid-header-unit',
      cellClassName: 'grid-cell-unit',
    },
    {
      field: 'price',
      type: 'number',
      headerName: 'Цена',
      width: 80,
      editable: false,
      headerClassName: 'grid-header-price',
      cellClassName: 'grid-cell-price',
      sortable: false,
    },
    {
      field: 'qty',
      type: 'number',
      headerName: 'Заказ',
      width: 60,
      headerAlign: 'left',
      headerClassName: 'grid-header-order',
      cellClassName: 'grid-cell-order',
    },
  ];

  if (totalReturn !== 0) {
    columns.push({
      field: 'ret',
      type: 'number',
      headerName: 'Возврат',
      width: 60,
      headerAlign: 'left',
      headerClassName: 'grid-header-order',
      cellClassName: 'grid-cell-return',
    });
  }

  const visibilityColumns = {};
  visibilityColumns.parentCode = false;
  visibilityColumns.promo = false;
  visibilityColumns.price = true;
  visibilityColumns.ret = true;
  visibilityColumns.unit = false;

  const handleOnUpdateRow = (rows) => {};

  const handleOnSelectRow = (rows) => {};

  const handleOnSelectCell = (data) => {
    if (data.field === 'name') {
      //setShowHistory({ id: data?.row?.id, name: data?.row?.name });
    }
  };

  return (
    <>
      {/* <ToolsPanel backURL={`/customer`} /> */}
      {allRight && (
        <>
          <CheckoutSteps step1 step2 step3 as={'breadcrumbs'} />
          <Container>
            <Row>
              <Col
                md={4}
                xs={{
                  order:
                    currentBreakpoint === 'xs' || currentBreakpoint === 'sm'
                      ? 1
                      : 2,
                }}
              >
                {/* <Card className='bg-transparent'> */}
                <ListGroup variant='flush'>
                  <ListGroup.Item
                    variant={theme}
                    className={`px-0 py-1 bg-transparent`}
                  >
                    <h2>Итоги:</h2>
                  </ListGroup.Item>
                  {/* <ListGroup.Item>
                <Row>
                  <Col>Сумма:</Col>
                  <Col>{itemsPrice} руб.</Col>
                </Row>
              </ListGroup.Item> */}
                  <ListGroup.Item
                    variant={theme}
                    className={`px-0 py-1 bg-transparent`}
                  >
                    <Row>
                      <Col>Доставка:</Col>
                      <Col>{shippingPrice || 'бесплатно'}</Col>
                    </Row>
                  </ListGroup.Item>
                  {/* <ListGroup.Item>
                <Row>
                  <Col>Tax:</Col>
                  <Col>${taxPrice}</Col>
                </Row>
              </ListGroup.Item> */}
                  <ListGroup.Item
                    variant={theme}
                    className={`px-0 py-1 bg-transparent`}
                  >
                    <Row>
                      <Col>Сумма:</Col>
                      <Col>{totalPrice} руб</Col>
                    </Row>
                  </ListGroup.Item>

                  {isError && (
                    <ListGroup.Item
                      variant={theme}
                      className={`px-0 py-1 bg-transparent`}
                    >
                      <Message variant={'danger'}>
                        {error?.data?.message || error.error}
                      </Message>
                    </ListGroup.Item>
                  )}

                  <ListGroup.Item
                    variant={theme}
                    className={`px-0  bg-transparent`}
                  >
                    <Button
                      type='button'
                      className='btn-block w-100'
                      variant='warning'
                      disabled={cart.cartItems.length === 0}
                      onClick={placeOrderHandler}
                    >
                      {editedOrderId
                        ? 'Отправить изменения'
                        : 'Отправить поставщику'}
                    </Button>
                  </ListGroup.Item>
                </ListGroup>
                {/* </Card> */}
                {isLoading && <Loader />}
              </Col>
              <Col
                md={8}
                xs={{
                  order:
                    currentBreakpoint === 'xs' || currentBreakpoint === 'sm'
                      ? 2
                      : 1,
                }}
              >
                <ListGroup variant='flush'>
                  <ListGroup.Item
                    as={'div'}
                    className={`px-0 py-1 bg-transparent`}
                    variant={theme}
                  >
                    <strong>Поставщик:</strong> {currentProvider?.name}
                  </ListGroup.Item>
                  <ListGroup.Item
                    as={'div'}
                    className={`px-0 py-1 bg-transparent`}
                    variant={theme}
                  >
                    <strong>Покупатель:</strong> {currentCustomer?.name}
                  </ListGroup.Item>
                  <ListGroup.Item
                    as={'div'}
                    className={`px-0 py-1 bg-transparent`}
                    variant={theme}
                  >
                    <strong>Адрес доставки:</strong> {currentCustomer?.address}
                  </ListGroup.Item>
                  <ListGroup.Item
                    as={'div'}
                    className={`px-0 py-1 bg-transparent`}
                    variant={theme}
                  >
                    <strong>Контакты:</strong> {currentCustomer?.phone}
                  </ListGroup.Item>
                  <ListGroup.Item
                    as={'div'}
                    className={`px-0 py-1 bg-transparent`}
                    variant={theme}
                  >
                    <strong>Оплата: </strong>
                    согласно договора
                  </ListGroup.Item>
                  <ListGroup.Item
                    as={'div'}
                    className={`px-0 py-1 bg-transparent`}
                    variant={theme}
                  >
                    <strong>Комментарий: </strong>
                    {currentCustomer?.comment}
                  </ListGroup.Item>
                  <ListGroup.Item
                    as={'div'}
                    className={`px-0 py-1 bg-transparent`}
                    variant={theme}
                  >
                    <strong>Товары: </strong>
                    {cart.cartItems.length === 0 ? (
                      <Message>Ваша заявка пуста</Message>
                    ) : (
                      <>
                        <DataTable
                          sx={{ mt: 1 }}
                          rows={cartItems}
                          columns={columns}
                          columnVisible={{ id: false, ...visibilityColumns }}
                          onSelectRow={handleOnSelectRow}
                          onUpdateRow={handleOnUpdateRow}
                          onSelectCell={handleOnSelectCell}
                        />
                      </>
                    )}
                  </ListGroup.Item>
                </ListGroup>
              </Col>
            </Row>
          </Container>
        </>
      )}
    </>
  );
};

export default CustomerPlaceOrderScreen;
