import { Alert } from '@mui/material';
import { Col, Container, Row } from 'react-bootstrap';

const Message = (props) => {
  return (
    <Container className='my-3' style={{ minHeight: '31px' }}>
      <Row>
        <Col>
          <Alert variant={'outlined'} severity='warning'>
            {props?.children}
          </Alert>
        </Col>
      </Row>
    </Container>
  );
};

Message.defaultProps = {
  variant: 'info',
};

export default Message;
