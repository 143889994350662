import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Accordion, Col, Container, ListGroup, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import ToolsPanel from '../components/ToolsPanel/ToolsPanel';
import { toast } from 'react-toastify';
import { Alert, LinearProgress } from '@mui/material';
import Message from '../components/Message';
import { FaEdit, FaPlus, FaRoute } from 'react-icons/fa';
import {
  useGetUserRoutesQuery,
  useSortPointsMutation,
  useSortRoutesMutation,
} from '../slices/routesApiSlice';
import SortableList from '../components/SortableList';

const ManagerRoutesScreen = () => {
  const navigate = useNavigate();
  const { state, pathname } = useLocation();

  // const dispatch = useDispatch();
  const [routeListKey, setRouteListKey] = useState(new Date().toString());
  const [pointListKey, setPointListKey] = useState(new Date().toString());

  const { theme, currentRoute, pov } = useSelector((state) => state.auth);

  const { data, refetch, isLoading, isError, error } = useGetUserRoutesQuery(
    { withPoints: true },
    { refetchOnMountOrArgChange: true }
  );

  const { data: userRoutes } = useGetUserRoutesQuery(
    { withEmpty: true },
    { skip: false }
  );

  // console.log('userRoutes.withPoints', data);
  // console.log('userRoutes.withEmpty', userRoutes);

  const [saveSortRoutes] = useSortRoutesMutation();
  const [saveSortPoints] = useSortPointsMutation();
  // console.log(userRoutes);

  const handleUpdateRouteList = async (list) => {
    // console.log('list to save', list);
    if (list?.length > 0) {
      try {
        await saveSortRoutes({ itemList: list }).unwrap();
        //refetch();
        setRouteListKey(new Date().toString());
        return true;
      } catch (error) {
        toast.error(error?.data?.message || error?.error || error);
        return error;
      }
    }
  };
  const handleUpdatePointList = async (list, routeId) => {
    // console.log('list to save', list);
    // console.log('routeId to save', routeId);
    if (list?.length > 0) {
      try {
        await saveSortPoints({ itemList: list, routeId: routeId }).unwrap();
        //refetch();
        setRouteListKey(new Date().toString());
        return true;
      } catch (error) {
        toast.error(error?.data?.message || error?.error || error);
        return error;
      }
    }
  };

  const handleSelectUnit = async (item) => {
    // console.log(customer, provider, contractId);
    if (Array.isArray(userRoutes?.rows) && userRoutes.rows.length > 0) {
      navigate(`/${pov}/manager/routes/bind/${item.contractId}`);
    }
  };

  const handleClickAdd = (unit) => {
    navigate(`/${pov}/manager/routes/new`);
  };

  const handleClickEdit = (route) => {
    navigate(`/${pov}/manager/routes/${route._id}`);
  };

  const handleClickOnRoute = (item) => {
    //
  };

  let buttons = [];
  let backUrl;
  if (pathname.includes('/provider/')) {
    console.log(state);
    if (state && state?.backUrl) {
      backUrl = state.backUrl;
    } else {
      backUrl = '/provider/orders/new/';
    }
  } else {
    if (state && state?.backUrl) {
      backUrl = state.backUrl;
    } else {
      backUrl = '/customer/select/customer/';
    }

    buttons.push({
      className: `bg-transparent border-success border-2`,
      name: 'Редактор марщрутов',
      type: 'component',
      action: () =>
        navigate(
          `/customer/select/customer${
            currentRoute?._id ? '/' + currentRoute?._id : ''
          }`
        ),
      component: (props) => {
        return (
          <FaRoute size={22} color={theme === 'dark' ? 'white' : 'black'} />
        );
      },
    });
  }

  buttons.push({
    className: `bg-transparent`,
    name: 'Создать маршрут',
    type: 'component',
    action: handleClickAdd,
    component: (props) => {
      return <FaPlus size={22} color={theme === 'dark' ? 'white' : 'black'} />;
    },
  });
  // console.log(data?.routes);

  return (
    <>
      <ToolsPanel
        backURL={backUrl}
        buttons={buttons}
        title={`Редактор маршрутов`}
      />
      {isLoading ? (
        <LinearProgress />
      ) : isError ? (
        <Message>{error?.data?.message || error?.error}</Message>
      ) : (
        <>
          <Container>
            <>
              {/* {((Array.isArray(data?.routes) && data?.routes.length > 0) ||
                Array.isArray(userRoutes?.rows)) && ( */}
              <Row>
                <Col
                  xs={{ order: 'first', span: 12 }}
                  sm={{ order: 'first', span: 12 }}
                  md={{ order: 'last', span: 4 }}
                  className='mb-3'
                >
                  <Accordion defaultActiveKey='1' className='mt-0'>
                    <Accordion.Item eventKey={'0'}>
                      <Accordion.Header>Редактор маршрутов</Accordion.Header>
                      <Accordion.Body>
                        {Array.isArray(userRoutes?.rows) &&
                        userRoutes.rows.length > 0 ? (
                          <SortableList
                            updateList={handleUpdateRouteList}
                            key={routeListKey}
                            rows={userRoutes?.rows}
                            onClickItem={handleClickOnRoute}
                            refetch={refetch}
                            updateKey={setRouteListKey}
                            buttons={[
                              {
                                icon: <FaEdit />,
                                onClick: handleClickEdit,
                              },
                            ]}
                          />
                        ) : (
                          <Alert
                            severity='info'
                            variant='outlined'
                            icon={false}
                          >
                            С помощью кнопки{' '}
                            <span className='border-secondary-subtle rounded border p-1'>
                              <FaPlus
                                size={22}
                                color={theme === 'dark' ? 'white' : 'black'}
                              />
                            </span>{' '}
                            создавайте персональные маршруты и затем
                            распределяйте свои точки по маршрутам.
                            <br />
                            Например, можно создать маршруты "Понедельник,
                            Вторник, Среда..."
                          </Alert>
                        )}
                        {Array.isArray(data?.unrouted) &&
                          data.unrouted.length > 0 &&
                          Array.isArray(data?.rows) &&
                          data?.rows.length === 0 && (
                            <Alert
                              className='mt-2'
                              severity='info'
                              variant='outlined'
                              icon={false}
                            >
                              Чтобы закрепить точку на маршрут, сначала кликните
                              на неё в списке,
                            </Alert>
                          )}
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </Col>

                <Col
                  xs={{ order: 'last', span: 12 }}
                  sm={{ order: 'last', span: 12 }}
                  md={{ order: 'first', span: 8 }}
                >
                  <Accordion defaultActiveKey='0' className='mt-0'>
                    {Array.isArray(data?.unrouted) &&
                      data.unrouted.length > 0 && (
                        <Accordion.Item eventKey='0'>
                          <Accordion.Header>
                            {'Нераспределённые точки'}
                          </Accordion.Header>
                          <Accordion.Body className=''>
                            <ListGroup variant={'flush'}>
                              {data.unrouted.map((item, index) => (
                                <ListGroup.Item
                                  as={'div'}
                                  //active={group?._id === currentId}
                                  action
                                  className={`p-2 list-group-item d-flex justify-content-between align-items-center`}
                                  variant={theme}
                                  key={item._id}
                                  onClick={() => handleSelectUnit(item)}
                                >
                                  {item?.name}
                                </ListGroup.Item>
                              ))}
                            </ListGroup>
                          </Accordion.Body>
                        </Accordion.Item>
                      )}
                    {data?.rows?.map((route, idx) => (
                      <Accordion.Item eventKey={idx} key={route?._id || idx}>
                        <Accordion.Header>
                          {route?.name ? route.name : 'Основной список'}
                        </Accordion.Header>
                        <Accordion.Body>
                          {Array.isArray(route?.children) &&
                            route.children.length > 0 && (
                              <SortableList
                                updateParent={route?._id}
                                updateList={handleUpdatePointList}
                                key={pointListKey}
                                rows={route.children}
                                onClickItem={handleSelectUnit}
                                refetch={refetch}
                                updateKey={setPointListKey}
                              />
                            )}
                          {/* <ListGroup variant={'flush'}>
                            {route?.children?.map((row, index) => (
                              <ListGroup.Item
                                as={'div'}
                                //active={group?._id === currentId}
                                action
                                className={`p-2 list-group-item d-flex justify-content-between align-items-center`}
                                variant={theme}
                                key={index}
                                onClick={() =>
                                  handleSelectUnit(
                                    row?.customer,
                                    row?.provider,
                                    row?._id
                                  )
                                }
                              >
                                {row?.customer?.name}
                              </ListGroup.Item>
                            ))}
                          </ListGroup> */}
                        </Accordion.Body>
                      </Accordion.Item>
                    ))}
                  </Accordion>
                </Col>
              </Row>
            </>
          </Container>
        </>
      )}
    </>
  );
};

export default ManagerRoutesScreen;
