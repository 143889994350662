import React, { useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ProductGroupMenu from './ProductGroupMenu';
// import UnitSearchMenu from './UnitSearchMenu';
import { useSelector } from 'react-redux';
import { Alert, Divider, Toolbar } from '@mui/material';
import { Button, Stack } from 'react-bootstrap';
import ContractsMenu from './ContractsMenu/ContractsMenu';
import RoutesMenu from './RoutesMenu/RoutesMenu';
import TasksMenu from './TasksMenu/TasksMenu';
import SectionsMenu from './SectionsMenu';

const LeftMenu = ({ handleDrawerClose }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const tooltipRef = useRef(null);
  // const [setShowTooltip] = useState(true);
  const { currentProvider, currentCustomer, pov, theme } = useSelector(
    (state) => state.auth
  );

  // useEffect(() => {
  //   if (!currentProvider) {
  //     setShowTooltip(true);
  //   }
  // }, [currentProvider]);

  if (!pov) {
    return;
  }

  let drawer = '';
  if (pathname.includes('/customer/search/provider')) {
    drawer = <SectionsMenu handleDrawerClose={handleDrawerClose} />;
  } else if (
    pathname.includes('/customer/select/customer') ||
    pathname.includes('provider/orders/new')
  ) {
    drawer = <RoutesMenu handleDrawerClose={handleDrawerClose} />;
  } else if (
    pathname.includes('/provider/tasks') ||
    pathname.includes('/provider/multitasks')
  ) {
    drawer = <TasksMenu handleDrawerClose={handleDrawerClose} />;
  } else if (
    (pathname.includes('/news/') && !pathname.includes('/provider/news/')) ||
    pathname.includes('/customer/order') ||
    pathname.includes('/customer/cart') ||
    pathname.includes('/customer/about') ||
    pathname.includes('/provider/products')
  ) {
    drawer = (
      <ProductGroupMenu
        handleDrawerClose={handleDrawerClose}
        pathname={pathname}
      />
    );
  } else if (pathname.includes('/provider/contracts')) {
    drawer = <ContractsMenu handleDrawerClose={handleDrawerClose} />;
  } else {
    drawer = <></>;
  }

  const handleClickSelectProvider = () => {
    if (pov === 'customer') {
      navigate('/customer/select/provider/');
    } else if (pov === 'provider') {
      navigate('/provider/select/provider/');
    }
    handleDrawerClose(true);
  };

  return (
    <>
      {pov === 'customer' && currentCustomer && (
        <Toolbar className='mx-1 px-0 mobileMenu-toolbar'>
          <Stack
            className='mt-3'
            style={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            <Button
              ref={tooltipRef}
              size='sm'
              variant='primary'
              onClick={handleClickSelectProvider}
            >
              {currentProvider && currentProvider.name
                ? currentProvider.name
                : 'Выбрать поставщика'}
            </Button>

            {!currentProvider && currentCustomer?._id && (
              <Alert
                className='text-wrap'
                variant={theme === 'dark' ? 'outlined' : 'filled'}
                severity='info'
                sx={{ display: 'block', mt: 2 }}
              >
                Выберите поставщика
              </Alert>
            )}
          </Stack>
        </Toolbar>
      )}

      <Divider />

      {drawer}
    </>
  );
};

export default LeftMenu;
