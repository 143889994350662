import React, { useState } from 'react';
import { Button, Col, Container, Form, Row, Stack } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
// import {
//   saveComment,
//   saveContacts,
//   saveShippingAddress,
// } from '../slices/cartSlice';
import CheckoutSteps from '../components/Steps/CheckoutSteps';
import { useUpdateCustomerFieldMutation } from '../slices/customersApiSlice';
import { toast } from 'react-toastify';
import { setCurrentCustomer } from '../slices/authSlice';

const CustomerShippingScreen = () => {
  const { theme, currentCustomer, currentBreakpoint } = useSelector(
    (state) => state.auth
  );
  //const { shippingAddress } = useSelector((state) => state.cart);
  const [address, setAddress] = useState(currentCustomer?.address || '');
  const [comment, setComment] = useState(currentCustomer?.comment || '');
  const [contacts, setContacts] = useState(currentCustomer?.phone || '');

  const [updateField] = useUpdateCustomerFieldMutation();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const submitHandler = (e) => {
    e.preventDefault();
    const updatedCustomerData = {
      ...currentCustomer,
      address: address,
      comment: comment,
      phone: contacts,
    };
    dispatch(setCurrentCustomer(updatedCustomerData));
    navigate('/customer/placeorder');
  };

  const themeStyle = {
    backgroundColor: theme === 'dark' ? '#212529' : 'white',
    borderColor: theme === 'dark' ? '#212529' : '#b5c0c1',
    color: theme === 'dark' ? 'white' : 'black',
  };

  const handleClickBack = () => {
    navigate('/customer/cart');
  };

  const handleClickSave = async (fieldName, fieldValue) => {
    try {
      await updateField({
        _id: currentCustomer._id,
        fieldName,
        fieldValue,
      }).unwrap();
      //refetch();
      // dispatch(
      //   setCurrentCustomer({
      //     ...currentCustomer,
      //     address: address,
      //     phone: contacts,
      //     comment: comment,
      //   })
      // );
      // toast.success(
      //   `Значение ${result.oldValue} изменено на ${result.newValue}`
      // );
    } catch (error) {
      toast.error(error?.data?.message || error.error);
    }
  };

  return (
    <>
      <CheckoutSteps step1 step2 as={'breadcrumbs'} />
      <Container>
        <Row>
          <Col md={12} lg={8}>
            <Form onSubmit={submitHandler}>
              <Form.Group as={Row} controlId='address' className='my-2'>
                <Form.Label column sm='3' className='p-auto'>
                  Адрес доставки:
                </Form.Label>
                <Col sm='9'>
                  <Stack direction='horizontal' gap={2}>
                    <Form.Control
                      isInvalid={!address}
                      autoComplete=''
                      type='text'
                      style={themeStyle}
                      placeholder='Введите адрес доставки для водителя'
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                    ></Form.Control>
                    {currentCustomer?.address !== address && (
                      <Button
                        onClick={() => handleClickSave('address', address)}
                      >
                        Запомнить
                      </Button>
                    )}
                  </Stack>
                </Col>
              </Form.Group>

              <Form.Group as={Row} controlId='phone' className='my-2'>
                <Form.Label column sm='3' className='p-auto'>
                  Телефоны:
                </Form.Label>
                <Col sm='9'>
                  <Stack direction='horizontal' gap={2}>
                    <Form.Control
                      isInvalid={!contacts}
                      type='text'
                      style={themeStyle}
                      placeholder='Телефон для связи'
                      value={contacts}
                      onChange={(e) => setContacts(e.target.value)}
                    ></Form.Control>
                    {currentCustomer?.phone !== contacts && (
                      <Button
                        onClick={() => handleClickSave('phone', contacts)}
                      >
                        Запомнить
                      </Button>
                    )}
                  </Stack>
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId='postalCode' className='my-2'>
                <Form.Label column sm='3' className='p-auto'>
                  Комментарий:
                </Form.Label>
                <Col sm='9'>
                  <Stack direction='horizontal' gap={2}>
                    <Form.Control
                      type='text'
                      style={themeStyle}
                      placeholder='Сообщение для поставщика'
                      value={comment}
                      onChange={(e) => setComment(e.target.value)}
                    ></Form.Control>
                    {currentCustomer?.comment !== comment && comment && (
                      <Button
                        onClick={() => handleClickSave('comment', comment)}
                      >
                        Запомнить
                      </Button>
                    )}
                  </Stack>
                </Col>
              </Form.Group>

              <Stack direction='horizontal' gap={2}>
                <Button
                  // size={
                  //   ['lg', 'xl', 'xxl'].includes(currentBreakpoint)
                  //     ? 'lg'
                  //     : 'sm'
                  // }
                  type='button'
                  className='btn my-2 '
                  variant='primary'
                  onClick={handleClickBack}
                >
                  Назад к заявке
                </Button>
                <Button
                  // size={
                  //   ['lg', 'xl', 'xxl'].includes(currentBreakpoint)
                  //     ? 'lg'
                  //     : 'sm'
                  // }
                  type='submit'
                  className='btn-block my-2 '
                  variant='success'
                >
                  Продолжить
                </Button>
              </Stack>
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default CustomerShippingScreen;
