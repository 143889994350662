import React, { useEffect, useState } from 'react';
import { Stack, InputGroup, Form, Button } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import ZoomableImage from '../Image/ZoomableImage';
import Ratio from 'react-bootstrap/Ratio';
import { FaCheck, FaMinus, FaPlus } from 'react-icons/fa';
import Divider from '@mui/material/Divider';

// const images = [
//   '../../images/goods/chudo-cocktail.jpg',
//   '../../images/goods/danissimo.jpg',
//   '../../images/goods/grusha-karamel.jpg',
//   '../../images/goods/very-berry.jpg',
//   '../../images/goods/chudo-cocktail.jpg',
//   '../../images/goods/danissimo.jpg',
//   '../../images/goods/grusha-karamel.jpg',
//   '../../images/goods/very-berry.jpg',
//   '../../images/goods/chudo-cocktail.jpg',
//   '../../images/goods/house.jpg',
//   '../../images/goods/chat.jpg',
//   // '../../images/goods/very-berry.jpg',
// ];

const ProductCard = ({
  item,
  idx,
  handleOnUpdateRow,
  theme,
  showDescription,
  showUnitColumn,
  showPrice,
  showReturnColumn,
  onlyImage = false,
  zoom = true,
  onHide,
}) => {
  const [ret, setRet] = useState('');
  const [qty, setQty] = useState('');
  const [product, setProduct] = useState('');
  const { multiple } = product;

  // console.log(item);

  useEffect(() => {
    setProduct(item);
    setQty(item?.qty || '');
    setRet(item?.ret || '');
  }, [item]);

  const save = (oper) => {
    let inc = 1;
    if (multiple) {
      inc = Number(multiple);
    }
    // console.log(multiple);

    let newQty = Number(qty);
    let newRet = Number(ret);

    // console.log(oper);
    switch (oper) {
      case 'qty':
        newQty = newQty === '' || newQty === 0 ? inc : newQty;
        setQty(newQty < 0 ? 0 : newQty);
        break;
      case 'qty_plus':
        newQty = newQty === '' || newQty === 0 ? inc : newQty + inc;
        setQty(newQty);
        break;
      case 'qty_minus':
        newQty = newQty === '' || newQty === 0 ? '' : newQty - inc;
        if (newQty < 0) newQty = '';
        setQty(newQty);
        break;
      case 'ret':
        newRet = newRet === '' || newRet === 0 ? 1 : newRet;
        setRet(newRet < 0 ? 0 : newRet);
        break;
      case 'ret_plus':
        newRet = newRet === '' || newRet === 0 ? 1 : newRet + 1;
        setRet(newRet);
        break;
      case 'ret_minus':
        newRet = newRet === '' || newRet === 0 ? 1 : newRet - 1;
        if (newRet < 0) newRet = '';
        setRet(newRet);
        break;
      default:
        break;
    }
    // console.log(e.target.getAttribute('oper'));
    // return;

    // const newValue = qty === '' || qty === 0 ? (multiple ? multiple : 1) : qty;
    // setQty(newValue);
    const payload = {
      newRow: {
        ...product,
        qty: Number(newQty),
        ret: Number(newRet) || '',
      },
    };
    handleOnUpdateRow(payload);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log(e);
    save(e.target.name);
  };

  const handleClick = (e) => {
    save(e.target.name);
  };

  const handleKeypress = (e) => {
    if (e.charCode === 13) {
      e.preventDefault();
      save();
    }
  };

  const handleOnBlur = (fieldName) => {
    // console.log(e);
    if (fieldName === 'qty') {
      if (qty !== item?.qty) {
        save();
      }
    } else if (fieldName === 'ret') {
      if (ret !== item?.ret) {
        save();
      }
    }
  };

  const themeStyle = {
    backgroundColor: theme === 'dark' ? '#212529' : 'white',
    // borderColor: theme === 'dark' ? '#212529' : '#b5c0c1',
    color: theme === 'dark' ? 'white' : 'black',
  };

  return (
    <Card border={theme} bg={theme} onClick={onHide}>
      {/* {images[idx] ? ( */}
      <Ratio aspectRatio={'1x1'}>
        {item?.image ? (
          <ZoomableImage
            zoom={zoom}
            // className='rounded-2 img-fluid '
            // className='object-fit-scale'
            className='object-fit-contain'
            // className='object-fit-fill'
            // style={{ position: 'absolute' }}
            variant='top'
            src={item.image}
          />
        ) : (
          <ZoomableImage
            zoom={false}
            // className='rounded-2 img-fluid '
            // className='object-fit-scale'
            className='object-fit-contain opacity-50'
            // className='object-fit-fill'
            // style={{ position: 'absolute' }}
            variant='top'
            src={'/images/placeholder-square.png'}
          />
        )}
      </Ratio>

      <Card.Body className='m-0 px-2 pt-1 pb-0'>
        {showPrice && (
          <Card.Title>
            <div className='d-flex justify-content-between'>
              {/* <Stack direction='horizontal' gap={3}> */}
              <span>
                <strong>{item?.price || ''} </strong>руб.
              </span>
              {/* <span className='text-decoration-line-through fs-6'>
                {`${Math.floor(item?.price || 0 + 12)} р.`}
              </span>
              <span className='text-danger fs-6'>{'-12%'}</span> */}
            </div>
            {/* </Stack> */}
          </Card.Title>
        )}

        <Card.Title style={{ height: '46px', overflow: 'hidden' }}>
          <span className=' fs-6'>{item.name}</span>
        </Card.Title>

        {showDescription && item?.description && (
          <Card.Title style={{ maxHeight: '120px', overflow: 'hidden' }}>
            <span className='text-muted'>{item?.description}</span>
          </Card.Title>
        )}

        {/* <Rating value={2} text={`${10} reviews`} /> */}
        {onlyImage === false && (
          <Card.Title className='p-0 mb-2'>
            <Form onSubmit={handleSubmit} name='form'>
              <Stack direction='vertical' gap={1}>
                <InputGroup size={showReturnColumn ? 'sm' : null}>
                  <Form.Control
                    style={themeStyle}
                    className='py-1 '
                    placeholder='Заказ'
                    aria-label='Add'
                    type='number'
                    value={qty}
                    onChange={(e) => setQty(e.target.value)}
                    onKeyPress={handleKeypress}
                    onBlur={() => handleOnBlur('qty')}
                  />
                  {showUnitColumn && (
                    <InputGroup.Text style={themeStyle}>
                      {item?.unit}
                    </InputGroup.Text>
                  )}

                  {typeof item?.qty === 'number' && item?.qty !== 0 ? (
                    <>
                      <Button
                        // type='submit'
                        name='qty_minus'
                        onClick={handleClick}
                        // size={showReturnColumn ? 'sm' : null}
                        size='sm'
                        variant={item?.qty ? 'success' : 'outline-secondary'}
                      >
                        <FaMinus style={{ pointerEvents: 'none' }} size={20} />
                      </Button>
                      <Divider
                        orientation='vertical'
                        variant='middle'
                        flexItem
                        sx={{ borderWidth: 'medium' }}
                      />
                      <Button
                        // type='submit'
                        name='qty_plus'
                        onClick={handleClick}
                        // size={showReturnColumn ? 'sm' : null}
                        size='sm'
                        variant={item?.qty ? 'success' : 'outline-secondary'}
                      >
                        <FaPlus style={{ pointerEvents: 'none' }} size={20} />
                      </Button>
                    </>
                  ) : (
                    <Button
                      name='qty'
                      type='submit'
                      size='sm'
                      variant={item?.qty ? 'success' : 'outline-secondary'}
                      onClick={handleSubmit}
                    >
                      {item?.qty ? (
                        <FaCheck style={{ pointerEvents: 'none' }} size={20} />
                      ) : (
                        <FaPlus style={{ pointerEvents: 'none' }} size={20} />
                      )}
                    </Button>
                  )}
                </InputGroup>

                {showReturnColumn && (
                  <InputGroup size={showReturnColumn ? 'sm' : null}>
                    <Form.Control
                      style={themeStyle}
                      className='py-1 '
                      placeholder='Возврат'
                      aria-label='Ret'
                      type='number'
                      value={ret}
                      onChange={(e) => setRet(e.target.value)}
                      onKeyPress={handleKeypress}
                      onBlur={() => handleOnBlur('ret')}
                    />
                    {showUnitColumn && (
                      <InputGroup.Text style={themeStyle}>
                        {item?.unit}
                      </InputGroup.Text>
                    )}
                    {typeof item?.ret === 'number' && item?.ret !== 0 ? (
                      <>
                        <Button
                          // type='submit'
                          name='ret_minus'
                          onClick={handleClick}
                          size='sm'
                          variant={item?.ret ? 'danger' : 'outline-secondary'}
                        >
                          <FaMinus
                            style={{ pointerEvents: 'none' }}
                            size={20}
                          />
                        </Button>
                        <Divider
                          orientation='vertical'
                          variant='middle'
                          flexItem
                          sx={{ borderWidth: 'medium' }}
                        />
                        <Button
                          // type='submit'
                          name='ret_plus'
                          onClick={handleClick}
                          size='sm'
                          variant={item?.ret ? 'danger' : 'outline-secondary'}
                        >
                          <FaPlus style={{ pointerEvents: 'none' }} size={20} />
                        </Button>
                      </>
                    ) : (
                      <Button
                        type='submit'
                        name='ret'
                        variant={item?.ret ? 'danger' : 'outline-secondary'}
                        onClick={handleSubmit}
                      >
                        {item?.ret ? (
                          <FaCheck
                            style={{ pointerEvents: 'none' }}
                            size={20}
                          />
                        ) : (
                          <FaMinus
                            style={{ pointerEvents: 'none' }}
                            size={20}
                          />
                        )}
                      </Button>
                    )}
                  </InputGroup>
                )}
              </Stack>
            </Form>
          </Card.Title>
        )}
      </Card.Body>
    </Card>
  );
};

export default ProductCard;
