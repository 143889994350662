import React from 'react';
import { Accordion, Col, Container, ListGroup, Row } from 'react-bootstrap';
import ToolsPanel from '../components/ToolsPanel';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  FaBook,
  FaFingerprint,
  FaTasks,
  FaShippingFast,
  FaSearchPlus,
} from 'react-icons/fa';
import {
  setAnimateMenuIcon,
  setCurrentProvider,
  setShowLeftMenu,
} from '../slices/authSlice';
import { useGetProvidersOfCustomerQuery } from '../slices/customersApiSlice';
import GroupListSimple from '../components/GroupList/GroupListSimple';
import { toast } from 'react-toastify';

const CustomerHomeScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { theme, currentProvider, currentCustomer } = useSelector(
    (state) => state.auth
  );

  const { data, isLoading, isError, error } = useGetProvidersOfCustomerQuery(
    { customerId: currentCustomer?._id },
    { refetchOnMountOrArgChange: true }
  );

  const handleClickListItem = (to, showDrawer) => {
    // const tosend = { ...parcel, hello: 'I am from CustomerHomeScreen.jsx' };
    // dispatch(setParcel(tosend));

    navigate(to);
    if (showDrawer) {
      dispatch(setShowLeftMenu(true));
    }
  };

  const handleClickProvider = async (provider) => {
    // console.log('handleSelectUnit', currentCustomer, provider?._id);
    if (currentCustomer && provider?._id) {
      dispatch(setShowLeftMenu(true));
      dispatch(setAnimateMenuIcon(false));
      dispatch(setCurrentProvider(provider));
      // dispatch(setPriceEditMode(false));
      //переносим пользователя на страницу новостей
      navigate(`/customer/cart`);
    } else {
      toast.error('Сначала выберите покупателя');
    }
  };

  const handleClickSearch = () => {
    navigate(`/customer/search/provider/`, { state: { backUrl: '/customer' } });
  };

  let buttons = [];
  // if (data?.canSearch) {
  //   buttons.push({
  //     className: `bg-transparent`,
  //     name: 'Поиск',
  //     type: 'component',
  //     action: handleClickSearch,
  //     component: (props) => {
  //       return (
  //         <FaSearchPlus
  //           size={22}
  //           color={theme === 'dark' ? 'white' : 'black'}
  //         />
  //       );
  //     },
  //   });
  // }

  return (
    <>
      <ToolsPanel
        buttons={buttons}
        title={`Домашняя страница покупателя`}
        isLoading={false}
      />
      <Container>
        <Row>
          {/* <Col sm={12} md={7}>
            {Array.isArray(data?.rows) && data.rows.length > 0 ? (
              <Accordion className='me-0 mb-3' defaultActiveKey='0'>
                <Accordion.Item
                  eventKey='0'
                  style={{ backgroundColor: 'none !important' }}
                >
                  <Accordion.Header>Выбрать поставщика:</Accordion.Header>
                  <Accordion.Body>
                    <GroupListSimple
                      variant={'flush'}
                      className='p-1 border-0'
                      rows={data?.rows}
                      onClickItem={handleClickProvider}
                    />
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            ) : (
              <></>
            )}
          </Col> */}
          <Col sm={12}>
            <ListGroup>
              {currentProvider && (
                <ListGroup.Item
                  variant={theme}
                  action
                  onClick={() => handleClickListItem('/customer/cart')}
                >
                  <FaBook
                    size={35}
                    color={theme === 'dark' ? 'gray' : 'black'}
                    className='me-2'
                  />
                  {`Заявка поставщику: ${currentProvider?.name}`}
                </ListGroup.Item>
              )}
              {/* {currentCustomer?._id && (
                <ListGroup.Item
                  onClick={() =>
                    handleClickListItem('/customer/select/provider/')
                  }
                  variant={theme}
                  action
                  //disabled
                >
                  <FaShippingFast
                    size={35}
                    color={theme === 'dark' ? 'gray' : 'black'}
                    className='me-2'
                  />
                  Мои поставщики
                </ListGroup.Item>
              )} */}
              {!currentCustomer?._id && (
                <ListGroup.Item
                  onClick={() =>
                    handleClickListItem('/customer/select/customer/')
                  }
                  variant={theme}
                  action
                  //disabled
                >
                  <FaShippingFast
                    size={35}
                    color={theme === 'dark' ? 'gray' : 'black'}
                    className='me-2'
                  />
                  Выбрать покупателя
                </ListGroup.Item>
              )}
              <ListGroup.Item
                onClick={() => handleClickListItem('/customer/tasks')}
                variant={theme}
                action
                //disabled
              >
                <FaTasks
                  size={35}
                  color={theme === 'dark' ? 'gray' : 'black'}
                  className='me-2'
                />
                Задачи
              </ListGroup.Item>
              {/* <ListGroup.Item
                onClick={() => handleClickListItem('/provider/contracts')}
                variant={theme}
                action
              >
                <FaHandshake
                  size={35}
                  color={theme === 'dark' ? 'gray' : 'black'}
                  className='me-2'
                />{' '}
                Договоры
              </ListGroup.Item> */}
              <ListGroup.Item
                variant={theme}
                action
                onClick={() => handleClickListItem(`/customer/details`)}
              >
                <FaFingerprint
                  size={35}
                  color={theme === 'dark' ? 'gray' : 'black'}
                  className='me-2'
                />
                Реквизиты
              </ListGroup.Item>
              {/* <ListGroup.Item
                variant={theme}
                action
                onClick={() => handleClickListItem(`/provider/prices/`)}
              >
                <FaRubleSign
                  size={35}
                  color={theme === 'dark' ? 'gray' : 'black'}
                  className='me-2'
                />
                Типы цен
              </ListGroup.Item> */}
              {/* <ListGroup.Item
                variant={theme}
                action
                onClick={() =>
                  handleClickListItem(
                    `/provider/products/${
                      currentGroup?._id && currentGroup._id
                    }`,
                    true
                  )
                }
              >
                <FaShapes
                  size={35}
                  color={theme === 'dark' ? 'gray' : 'black'}
                  className='me-2'
                />{' '}
                Номенклатура и цены
              </ListGroup.Item> */}
              {/* <ListGroup.Item
                variant={theme}
                action
                onClick={() => handleClickListItem(`/provider/groups/`)}
              >
                <FaList
                  size={35}
                  color={theme === 'dark' ? 'gray' : 'black'}
                  className='me-2'
                />
                Редактор меню номенклатуры
              </ListGroup.Item> */}
            </ListGroup>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default CustomerHomeScreen;
