import React, { useState } from 'react';
import { useGetRoutesQuery } from '../slices/routesApiSlice';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  FaKey,
  FaLock,
  FaLockOpen,
  FaPlus,
  FaShieldAlt,
  FaTrash,
} from 'react-icons/fa';
import ToolsPanel from '../components/ToolsPanel';
import { Accordion, Col, Container, Row } from 'react-bootstrap';
import DataTable from '../components/DataTable/DataTable';
import { NoRowsOverlayRoutes } from '../components/DataTable/NoRowsOverlayRoutes/NoRowsOverlayRoutes';
import { Alert, LinearProgress, Menu, MenuItem } from '@mui/material';
import Message from '../components/Message';
import { useGetProviderUsersQuery } from '../slices/providersApiSlice';
import Invites from '../components/Invites';

import { GridActionsCellItem } from '@mui/x-data-grid';
import { toast } from 'react-toastify';
import {
  useRemoveFromProviderMutation,
  useSetProviderRoleMutation,
} from '../slices/usersApiSlice';
import { NoRowsOverlayUsers } from '../components/DataTable/NoRowsOverlayUsers/NoRowsOverlayUsers';

const ProviderUsersScreen = () => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState({ anchor: false, id: undefined });
  const open = Boolean(anchorEl.anchor);

  const { theme, currentProvider, showLeftMenu } = useSelector(
    (state) => state.auth
  );

  const { data, refetch, isLoading, isError, error } = useGetProviderUsersQuery(
    { providerId: currentProvider?._id },
    { refetchOnMountOrArgChange: true }
  );

  const [removeFromProvider] = useRemoveFromProviderMutation();
  const [setProviderRole] = useSetProviderRoleMutation();

  // console.log(data);

  const rows = data?.rows?.map((item) => ({
    ...item,
    id: item._id,
    user: item?.manager?.name,
    role: item?.isAdmin ? 'a' : 'm',
    regions: Array.isArray(item?.regions)
      ? item.regions.map((item) => item?.name).join(', ')
      : '',
  }));

  const columns = [
    { field: 'id', headerName: 'id', width: 50, hide: true },
    {
      field: 'name',
      headerName: 'Сотрудник',
      flex: 0.5,
    },
    {
      field: 'regions',
      headerName: 'Группы',
      flex: 1,
    },
    {
      field: 'role',
      headerName: 'Права',
      flex: 0.2,
      cellClassName: 'actions',
      type: 'actions',
      // renderCell: (param) => {
      //   return param?.row?.role === 'a' ? <FaShieldAlt /> : '';
      // },
      getActions: (param) => {
        // console.log(param);
        return [
          <GridActionsCellItem
            icon={
              param?.row?.role === 'a' ? (
                <FaKey size={20} />
              ) : (
                <FaLock size={20} className='text-muted' />
              )
            }
            label='Edit'
            className='textPrimary'
            onClick={(e) => handleClickRole(e, param?.id)}
            color='inherit'
          />,
        ];
      },
    },

    {
      field: 'actions',
      type: 'actions',
      flex: 0.3,
      headerName: 'Уволить',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            icon={<FaTrash size={20} className='text-danger opacity-50' />}
            label='Edit'
            className='textPrimary'
            onClick={() => handleDeleteClick(id)}
            color='inherit'
          />,
        ];
      },
    },
  ];

  const visibilityColumns = {};
  visibilityColumns.id = false;

  const handleClickRole = (event, id) => {
    // console.log(id);
    setAnchorEl({ anchor: event.currentTarget, id: id });
  };

  const onCloseSelectRole = () => {
    setAnchorEl({ anchor: false, id: undefined });
  };

  const handleSelectRole = async (role) => {
    const userId = anchorEl?.id;
    setAnchorEl({ anchor: false, id: undefined });
    if (userId) {
      try {
        await setProviderRole({
          providerId: currentProvider?._id,
          userId,
          role,
        }).unwrap();
        refetch();
      } catch (error) {
        toast.error(error?.data?.message || error?.message || error.error);
      }
    }
  };

  const handleDeleteClick = async (id) => {
    if (window.confirm('Вы действительно хотите уволить сотрудника ?')) {
      try {
        await removeFromProvider({
          providerId: currentProvider?._id,
          userId: id,
        }).unwrap();
        toast.success('Пользователь больше не ваш сотрудник');
        refetch();
      } catch (error) {
        toast.error(error?.data?.message || error.error);
      }
    }
  };

  // const handleClickAdd = () => {
  //   navigate(`/provider/users/new`);
  // };

  const buttons = [];
  // buttons.push({
  //   className: `bg-transparent`,
  //   name: 'Добавить маршрут',
  //   type: 'component',
  //   action: handleClickAdd,
  //   component: (props) => {
  //     return <FaPlus size={22} color={theme === 'dark' ? 'white' : 'black'} />;
  //   },
  // });

  return (
    <>
      <ToolsPanel title='Сотрудники' buttons={buttons} backURL={'/provider'} />
      {isLoading ? (
        <LinearProgress />
      ) : isError ? (
        <Message>{error?.data?.message || error.error}</Message>
      ) : (
        <Container>
          <Row>
            <Col className='mb-3' sm={12} md={showLeftMenu ? 12 : 8} lg={8}>
              {Array.isArray(rows) && (
                <DataTable
                  noRowsOverlay={NoRowsOverlayUsers}
                  rows={rows || []}
                  columns={columns}
                  columnVisible={{ id: false, ...visibilityColumns }}
                  // onSelectCell={handleOnSelectCell}
                  // checkboxSelection={false}
                />
              )}
              {Array.isArray(rows) && rows.length > 0 && rows.length < 3 && (
                <Alert
                  icon={false}
                  variant='outlined'
                  severity='info'
                  className='p-3 mt-2'
                >
                  Процедура добавления сотрудников:
                  <br />
                  1. Сотрудник регистрируется на сайте
                  <br />
                  2. При входе выбирает роль "Поставщик"
                  <br />
                  3. Нажимает кнопку "Создать поставщика"
                  <br />
                  4. В поле "У меня есть код" вводит код Приглашения
                  <br />
                  5. Нажимает кнопку "Применить"
                  <br />
                </Alert>
              )}
            </Col>
            <Col className='mb-3' sm={12} md={showLeftMenu ? 12 : 4} lg={4}>
              <Accordion defaultActiveKey={'0'}>
                <Accordion.Item
                  eventKey='0'
                  style={{ backgroundColor: 'none !important' }}
                >
                  <Accordion.Header>Приглашения</Accordion.Header>
                  <Accordion.Body className=''>
                    <Invites
                      unitType={'provider'}
                      unitId={currentProvider?._id}
                    />
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Col>
          </Row>
        </Container>
      )}
      <Menu
        id='basic-menu'
        anchorEl={anchorEl.anchor}
        open={open}
        onClose={onCloseSelectRole}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={() => handleSelectRole('a')}>
          <FaKey />
          &nbsp;&nbsp;Админ
        </MenuItem>
        <MenuItem onClick={() => handleSelectRole('m')}>
          <FaLock /> &nbsp;&nbsp;Менеджер
        </MenuItem>
      </Menu>
    </>
  );
};

export default ProviderUsersScreen;
