import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Message from '../components/Message';
import ToolsPanel from '../components/ToolsPanel';
import { Button, Card, Col, Container, InputGroup } from 'react-bootstrap';
import { Form, Row, Stack } from 'react-bootstrap';
import { LinearProgress } from '@mui/material';
import { toast } from 'react-toastify';
import {
  useCreateUpdateContactMutation,
  useGetContactDetailsQuery,
  useUploadContactPhotoMutation,
} from '../slices/contactsApiSlice';
import { FaTimes } from 'react-icons/fa';
import InputSelectable from '../components/UI/InputSelectable/InputSelectable';

const ProviderContactEditScreen = () => {
  const { contactId } = useParams();
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [image, setImage] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [user, setUser] = useState('');
  const [url, setUrl] = useState('');
  const [forAll, setForAll] = useState(false);

  const { theme, currentBreakpoint, currentProvider, pov } = useSelector(
    (state) => state.auth
  );

  const { data, refetch, isLoading, isError, error } =
    useGetContactDetailsQuery({
      contactId,
      unitId: currentProvider?._id,
      pov,
    });

  // console.log(data);

  useEffect(() => {
    if (data && data?.row) {
      const { name, description, image, email, phone, url, user, forAll } =
        data.row;
      setName(name || '');
      setDescription(description || '');
      setImage(image || '');
      setEmail(email || '');
      setPhone(phone || '');
      setUrl(url || '');
      setUser(user || '');
      setForAll(forAll || false);
    }
  }, [data]);

  const [
    uploadContactImage,
    {
      isLoading: isLoadingUpload,
      isError: isErrorOmageUpload,
      error: errImageUpload,
    },
  ] = useUploadContactPhotoMutation();

  const [updateContact, { isLoading: loadingUpdate }] =
    useCreateUpdateContactMutation();

  const save = async (withClose) => {
    let result;

    // console.log('user', user);

    if (name) {
      const payload = {
        contactId,
        pov,
        name,
        description,
        image,
        email,
        phone,
        url,
        forAll,
        userId: user?._id,
        unitId: currentProvider?._id,
      };
      //console.log('submitHandler', 'payload', payload);
      try {
        result = await updateContact(payload).unwrap();
        refetch();
        if (withClose) {
          navigate('/provider/details');
        } else {
          navigate(`/provider/contacts/${result?._id}`);
        }
      } catch (error) {
        toast.error(error?.data?.message || error.error);
      }
    }
  };

  const submitHandler = (event) => {
    event.preventDefault();
    if (!name) {
      toast.warning('Пустое имя недопустимо');
      return;
    }
    save(event.target.getAttribute('close') === 'true');
  };

  const uploadFileHandler = async (e) => {
    const formData = new FormData();
    formData.append('image', e.target.files[0]);
    try {
      const res = await uploadContactImage(formData).unwrap();

      if (res?.image) {
        setImage(res.image);
      } else {
        e.preventDefault();
        toast.warn(res?.message);
      }
    } catch (error) {
      toast.error(error?.data?.message || error?.message || error.error);
    }
  };

  const handleSelectUser = (sel) => {
    setUser(sel);
  };

  const themeStyle = {
    backgroundColor: theme === 'dark' ? '#212529' : 'white',
    borderColor: theme === 'dark' ? '#212529' : '#b5c0c1',
    color: theme === 'dark' ? 'white' : 'black',
  };

  return (
    <>
      <ToolsPanel
        backURL={'/provider/details'}
        title={`Контакт ${contactId === 'new' ? ' (новый)' : ''}`}
        isLoading={loadingUpdate}
      />
      {isLoading ? (
        <LinearProgress />
      ) : isError ? (
        <Message>{error?.data?.message || error.error}</Message>
      ) : (
        <Container>
          <Form onSubmit={submitHandler}>
            <Card
              className={`bg-transparent ${
                currentBreakpoint === 'xs' || currentBreakpoint === 'sm'
                  ? 'border-0 shadow-none'
                  : 'p-3'
              }`}
            >
              {' '}
              <Row>
                <Col lg={12} xl={6} xxl={6}>
                  <Form.Group as={Row} controlId='name' className='my-2'>
                    <Form.Label column sm='3' className='pb-0'>
                      Имя сотрудника:
                    </Form.Label>
                    <Col sm='9'>
                      <Form.Control
                        isInvalid={!name}
                        style={themeStyle}
                        type='text'
                        placeholder='Введите имя'
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      ></Form.Control>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} controlId='description' className='my-2'>
                    <Form.Label column sm='3' className='pb-0'>
                      Должность:
                    </Form.Label>
                    <Col sm='9'>
                      <Form.Control
                        style={themeStyle}
                        type='text'
                        placeholder='Должность'
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                      ></Form.Control>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} controlId='phone' className='my-2'>
                    <Form.Label column sm='3'>
                      Телефон:
                    </Form.Label>
                    <Col sm='9'>
                      <Form.Control
                        style={themeStyle}
                        type='text'
                        placeholder='Номер телефона'
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                      ></Form.Control>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} controlId='email' className='my-2'>
                    <Form.Label column sm='3'>
                      Электронная почта:
                    </Form.Label>
                    <Col sm='9'>
                      <Form.Control
                        style={themeStyle}
                        type='text'
                        placeholder='Электронная почта'
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      ></Form.Control>
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} controlId='user' className='my-2'>
                    <Form.Label column sm='3' className='pb-0'>
                      Менеджер:
                    </Form.Label>
                    <Col sm='9'>
                      <InputSelectable
                        key={user?._id || 'empty'}
                        type='text'
                        placeholder='Выберите менеджера'
                        value={user}
                        onChange={handleSelectUser}
                        unitType='Сотрудники'
                        showClearButton={true}
                        onClear={() => setUser('')}
                      />
                    </Col>
                  </Form.Group>

                  <Form.Group controlId='forAll' className='my-2' as={Row}>
                    <Col sm='12'>
                      <Form.Check
                        // isValid
                        type='checkbox'
                        label='Показывать всем покупателям'
                        checked={forAll}
                        onChange={(e) => setForAll(e.target.checked)}
                      ></Form.Check>
                    </Col>
                  </Form.Group>
                </Col>
                <Col lg={12} xl={6} xxl={6}>
                  <Form.Group controlId='image' className='my-2' as={Row}>
                    <Form.Label column sm='3' className='pb-2'>
                      Фотография:
                    </Form.Label>
                    <Col sm='9'>
                      <InputGroup>
                        <Form.Control
                          style={themeStyle}
                          type='file'
                          label='Выберите фото'
                          onChange={uploadFileHandler}
                        />
                        <Button
                          onClick={() => setImage('')}
                          variant='outline-secondary'
                        >
                          <FaTimes />
                        </Button>
                      </InputGroup>
                    </Col>
                    {/* <span>Фотография:</span> */}
                    <Stack
                      direction='vertical'
                      gap={2}
                      className='d-flex justify-content-start'
                    >
                      <div className='w-25 pt-2'>
                        <Card.Img
                          className='rounded-0 img-thumbnail'
                          src={image}
                          alt={name}
                        />
                      </div>
                    </Stack>
                  </Form.Group>
                </Col>
              </Row>
            </Card>
            <Stack
              direction='horizontal'
              gap={3}
              //class='justify-content-center'
            >
              <Button
                variant='primary'
                className='my-2 mx-2'
                close='true'
                size={
                  ['lg', 'xl', 'xxl'].includes(currentBreakpoint) ? 'lg' : 'sm'
                }
                onClick={submitHandler}
              >
                Сохранить
              </Button>
              <Button
                size={
                  ['lg', 'xl', 'xxl'].includes(currentBreakpoint) ? 'lg' : 'sm'
                }
                variant='primary'
                className='my-2'
                onClick={() => navigate(`/provider/details`)}
              >
                Закрыть
              </Button>
            </Stack>
          </Form>
        </Container>
      )}
    </>
  );
};

export default ProviderContactEditScreen;
