import React from 'react';
import { ListGroup } from 'react-bootstrap';
import { FaShieldAlt } from 'react-icons/fa';
import { useSelector } from 'react-redux';

const UnitCityList = () => {
  const { theme } = useSelector((state) => state.auth);
  return (
    <ListGroup bsPrefix='narrow'>
      <ListGroup.Item
        as={'div'}
        variant={theme}
        action
        //key={el._id}
        className='d-flex justify-content-between align-items-start'
      >
        <div>Альметьевск</div> <FaShieldAlt />
      </ListGroup.Item>
    </ListGroup>
  );
};

export default UnitCityList;
