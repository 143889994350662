import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  addToCart,
  clearCartItems,
  removeFromCart,
  unsetCartParams,
} from '../slices/cartSlice';
import ToolsPanel from '../components/ToolsPanel/ToolsPanel';
import DataTable from '../components/DataTable/DataTable';
import { colsWidth } from '../utils/authUtils.js';
import { Accordion, Col, Container, Row } from 'react-bootstrap';
// import { useGetOrderByIdMutation } from '../slices/ordersApiSlice.js';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import OrderArchiveList from '../components/OrderArchiveList/OrderArchiveList.jsx';
import { getLocalDate, getOrderHeaderString } from '../utils/gets.js';
import { FaCogs, FaHome } from 'react-icons/fa';
import {
  setAnimateMenuIcon,
  setGridSettingMode,
  setSelectedProducts,
  setShowLeftMenu,
} from '../slices/authSlice.js';
// import { useGridApiRef } from '@mui/x-data-grid';
import ModalFrame from '../components/ModalFrame/ModalFrame.jsx';
// import ProductCard from '../components/ProductCard/depricated_ProductCard.jsx';
import CustomerToolbar from '../components/DataTableToolbar/CustomerToolbar/CustomerToolbar.jsx';
import { useUpdateCartItemMutation } from '../slices/cartsApiSlice.js';
import ProductCard from '../components/ProductCard/ProductCard.jsx';
// import { useGetCartQuery } from '../slices/cartsApiSlice.js';

const CustomerCartScreen = () => {
  const { orderId } = useParams();
  // const apiDataGridRef = useGridApiRef();
  const [oneClickMode, setOneClickMode] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart);
  const [showProductCard, setShowProductCard] = useState(false);
  const [orderItems, setOrderItems] = useState([]);
  const [orderParams, setOrderParams] = useState({});
  const {
    gridSettingMode,
    selectedProducts,
    currentProvider,
    currentBreakpoint,
    currentCustomer,
    showUnitColumn,
    showPrice,
    showReturnColumn,
    showMultipleColumn,
    showLeftMenu,
    theme,
  } = useSelector((state) => state.auth);
  const { cartItems, cartParams } = cart;

  const [updateCartItemOnServer] = useUpdateCartItemMutation();
  // const [skipLoadCartFromServer, setSkipLoadCartFromServer] = useState(true);
  // const { data: cartOnServer } = useGetCartQuery(
  //   {
  //     providerId: currentProvider?._id,
  //     customerId: currentCustomer?._id,
  //   },
  //   {
  //     skip: skipLoadCartFromServer,
  //   }
  // );
  // console.log('cartOnServer', cartOnServer);

  // useEffect(() => {
  //   if (Array.isArray(cartOnServer?.rows) && cartOnServer?.rows.length > 0) {
  //     setSkipLoadCartFromServer(true);
  //     setOrderItems(cartOnServer.rows);
  //   }
  // }, [cartOnServer]);

  useEffect(() => {
    if (!showLeftMenu && !['xs', 'sm'].includes(currentBreakpoint)) {
      dispatch(setShowLeftMenu(true));
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (['xs', 'sm', 'md'].includes(currentBreakpoint)) {
      setOneClickMode(true);
    }
  }, [currentBreakpoint]);

  // const [getOrderById] = useGetOrderByIdMutation();

  const checkOutHandler = () => {
    navigate('/customer/shipping');
  };

  // console.log('currentProvider', currentProvider);
  // console.log('currentCustomer', currentCustomer);

  useEffect(() => {
    const rows = cartItems.filter(
      (item) =>
        item.providerId === currentProvider._id &&
        item.customerId === currentCustomer._id
    );
    // .map((item) => ({
    //   ...item,
    //   id: item._id,
    //   qty: item?.qty === 0 ? '' : item?.qty,
    //   ret: item?.ret === 0 ? '' : item?.ret,
    // }));

    setOrderItems(rows);
    //eslint-disable-next-line
  }, [cartItems]);

  const rows = [];
  if (Array.isArray(orderItems)) {
    for (let index = 0; index < orderItems.length; index++) {
      const item = orderItems[index];
      let multiple = item?.multiple ? item?.multiple : '';

      let name = item?.shortName || item?.name;
      if (!showUnitColumn && !showMultipleColumn) {
        name = `${name} ( ${multiple} ${item?.unit} )`;
      } else if (!showUnitColumn && showMultipleColumn) {
        name = `${name} ( ${item?.unit} )`;
      } else if (showUnitColumn && !showMultipleColumn) {
        name = `${name}  ${multiple ? '(' + multiple + ' в уп.)' : ''}`;
      }

      rows.push({
        ...item,
        id: item._id,
        qty: item?.qty === 0 ? '' : item?.qty,
        ret: item?.ret === 0 ? '' : item?.ret,
        name,
      });
    }
  }

  // console.log(orderItems);

  let editedOrderId = '';
  let editedOrderDate = '';
  const key = `${currentCustomer?._id}-${currentProvider?._id}`;

  if (cartParams[key]) {
    const { orderId, createdAt } = cartParams[key];

    if (orderId) editedOrderId = orderId;
    if (createdAt) editedOrderDate = createdAt;
  }

  useEffect(() => {
    if (Array.isArray(orderItems) && orderItems.length === 0) {
      dispatch(setAnimateMenuIcon(true));
    }
  }, [orderItems, dispatch]);

  const setRowSelectionModel = (data) => {
    dispatch(setSelectedProducts(data));
  };

  const columns = [
    { field: 'id', headerName: '№', width: 50, hide: true },
    { field: 'promo', headerName: 'promo', width: 10, hide: true },
    {
      field: 'name',
      headerName: 'Название',
      flex: 1,
      editable: false,
      cellClassName: 'grid-cell-name',
      // cellClassName: (params) => {
      //   if (params.value == null) {
      //     return '';
      //   }

      //   return clsx('super-app', {
      //     negative: params.value < 0,
      //     positive: params.value > 0,
      //   });
      // },
    },
    {
      field: 'multiple',
      headerName: 'в уп',
      width: 50,
      editable: false,
      sortable: false,
    },
    {
      field: 'price',
      headerName: 'Цена',
      width: 80,
      editable: false,
      headerClassName: 'grid-header-price',
      sortable: false,
    },
    {
      field: 'unit',
      headerName: 'Ед.',
      type: 'string',
      width: colsWidth.unit,
      editable: false,
      sortable: false,
      align: 'left',
      headerAlign: 'left',
    },
    {
      field: 'qty',
      type: 'number',
      headerName: 'Заявка',
      width: colsWidth.qty,
      editable: !orderId,
      //sortable: false,
      headerAlign: 'left',
      headerClassName: 'grid-header-order',
      cellClassName: 'grid-cell-order',
    },
  ];

  // console.log('!orderId', !orderId);
  // console.log('currentProvider?.canReturn', currentProvider?.canReturn);

  if (currentProvider?.canReturn) {
    columns.push({
      field: 'ret',
      type: 'number',
      headerName: 'Возврат',
      width: colsWidth.qty,
      editable: true,
      //sortable: false,
      headerAlign: 'left',
      headerClassName: 'grid-header-order',
      cellClassName: 'grid-cell-return',
    });
  }

  if (['xl', 'xxl'].includes(currentBreakpoint)) {
    columns.push({
      field: 'info',
      type: 'string',
      headerName: 'Информация',
      flex: 0.4,
      editable: false,
      headerAlign: 'left',
      // headerClassName: 'grid-header-order',
      // cellClassName: 'grid-cell-return',
    });
  }

  const visibilityColumns = {};
  visibilityColumns.parentCode = false;
  visibilityColumns.promo = false;
  visibilityColumns.price = showPrice;
  visibilityColumns.ret = showReturnColumn && currentProvider?.canReturn;
  visibilityColumns.unit = showUnitColumn;
  visibilityColumns.multiple = showMultipleColumn;

  const handleOnHideModalProductCard = () => {
    setShowProductCard(false);
  };

  const handleOnSelectCell = (data) => {
    // console.log('handleOnSelectCell', oneClickMode);
    // if (oneClickMode) {
    //   // console.log(data);
    //   if (data.field === 'qty' || data.field === 'ret') {
    //     try {
    //       apiDataGridRef?.current?.startCellEditMode({
    //         id: data.row.id,
    //         field: data.field,
    //       });
    //     } catch (error) {}
    //   }
    // }

    if (data.field === 'name' && data?.row?.image) {
      //setShowHistory({ id: data?.row?.id, name: data?.row?.name });
      setShowProductCard({
        id: data?.row?.id,
        name: data?.row?.name,
        ...data?.row,
      });
    }
  };

  const handleOnSelectRow = (data) => {};

  const updateOnServer = async (data) => {
    try {
      await updateCartItemOnServer({ ...data });
    } catch (error) {
      toast.warn(error?.data?.message || error?.message);
    }
  };

  const handleOnUpdateRow = (data) => {
    // console.log(data);

    const price = data.newRow?.price || 0;

    const multiple = data.newRow?.multiple || 0;
    let qty = data.newRow?.qty || 0;
    if (qty !== 0) {
      qty = qty < 0 ? qty * -1 : qty; //Math.abs(qty)
      // if (multiple > 0) {
      //   if (qty / multiple >= 0) {
      //     qty = Math.floor(qty / multiple) * multiple;
      //   } else {
      //     qty = Math.ceil(qty / multiple) * multiple;
      //   }
      //   qty = multiple > qty ? multiple : qty;
      // }
    }
    let ret = data.newRow?.ret || 0;
    if (ret !== 0) {
      ret = ret < 0 ? ret * -1 : ret; //Math.abs(ret)
    }

    if (qty === 0 && ret === 0) {
      dispatch(
        removeFromCart({
          id: data.newRow.id,
          providerId: currentProvider._id,
          customerId: currentCustomer._id,
        })
      );
    } else {
      dispatch(
        addToCart({
          ...data.newRow,
          _id: data.newRow.id,
          providerName: currentProvider.name,
          providerId: currentProvider._id,
          customerName: currentCustomer.name,
          customerId: currentCustomer._id,
          qty,
          ret,
        })
      );
    }

    if (!editedOrderId) {
      //сохранять в облачной корзине только если это не режим корректировки заявки
      updateOnServer({
        providerId: currentProvider._id,
        customerId: currentCustomer._id,
        productId: data.newRow.id,
        qty,
        ret,
        price,
        multiple,
      });
    }
  };

  // const handleClickShare = () => {
  //   navigate(`/pdf/${orderId || 'cart'}`);
  // };

  const handleClickShowStuff = () => {
    dispatch(setGridSettingMode(!gridSettingMode));
    if (!gridSettingMode) {
      setRowSelectionModel([]);
    }
  };

  // const handleClickSaveCart = () => {
  //   setSkipLoadCartFromServer(false);
  // };

  const handleCancelOrderEdit = () => {
    dispatch(
      unsetCartParams({
        providerId: currentProvider?._id,
        customerId: currentCustomer._id,
      })
    );
    dispatch(
      clearCartItems({
        providerId: currentProvider?._id,
        customerId: currentCustomer._id,
      })
    );
    setOrderItems([]);
    setOrderParams({});
  };
  const handleClearCartItems = () => {
    dispatch(
      unsetCartParams({
        providerId: currentProvider?._id,
        customerId: currentCustomer._id,
      })
    );
    dispatch(
      clearCartItems({
        providerId: currentProvider?._id,
        customerId: currentCustomer._id,
      })
    );
    setOrderItems([]);
    setOrderParams({});
  };

  const orderMode =
    (orderId === '' ||
      !orderId ||
      orderId === 'undefined' ||
      orderId === 'null') &&
    editedOrderId === '';

  const mobileView = ['xs', 'sm'].includes(currentBreakpoint);

  const buttons = [];
  const submenu = [];

  // const toolbarButtons = [];

  // if (orderItems.length > 0) {
  //   toolbarButtons.push({
  //     size: 'normal',
  //     name: mobileView ? 'Очистить корзину' : 'Очистить корзину',
  //     action: handleClearCartItems,
  //     className: 'btn-block',
  //     variant: 'outline-secondary',
  //   });
  // }

  if (orderMode) {
    buttons.push({
      size: 'normal',
      name: mobileView ? 'Отправить' : 'Отправить',
      action: checkOutHandler,
      disabled: orderItems.length === 0,
      className: 'btn-block',
      variant: orderItems.length === 0 ? 'outline-secondary' : 'success',
    });
    if (orderItems.length > 0) {
      submenu.push({
        size: 'sm',
        name: mobileView ? 'Очистить корзину' : 'Очистить корзину',
        action: handleClearCartItems,
        className: 'btn-block',
        variant: 'outline-secondary',
      });
    }
  } else if (editedOrderId !== '') {
    submenu.push({
      size: 'sm',
      name: mobileView ? 'Отменить' : 'Отменить корретировку',
      action: handleCancelOrderEdit,
      className: 'btn-block',
      variant: orderItems.length === 0 ? 'outline-secondary' : 'secondary',
    });
    submenu.push({
      size: 'sm',
      name: mobileView ? 'Сохранить' : 'Сохранить изменения',
      action: checkOutHandler,
      className: 'btn-block',
      variant: orderItems.length === 0 ? 'outline-secondary' : 'success',
    });
  }

  buttons.push({
    className: `bg-transparent ${gridSettingMode && 'border-success'}`,
    name: 'Инструменты',
    type: 'component',
    action: handleClickShowStuff,
    component: (props) => {
      return <FaCogs size={22} color={theme === 'dark' ? 'white' : 'black'} />;
    },
  });

  let title;
  if (orderMode) {
    title = 'Новая заявка';
  } else if (editedOrderDate) {
    title = `Коррекция заявки от ${getLocalDate(editedOrderDate)}`;
  } else {
    title = getOrderHeaderString(orderParams, 'Заявка от');
  }

  return (
    <>
      <ToolsPanel
        backIcon={<FaHome size={22} />}
        buttons={buttons}
        title={title}
        isLoading={false}
        backURL={'/customer'}
        // submenu={submenu}
      />
      <Container>
        <Row>
          <Col md={12} lg={8} className='mb-3'>
            {Array.isArray(rows) && (
              <>
                {/* {!orderMode ? (
                  <Accordion defaultActiveKey='0'>
                    <Accordion.Item
                      eventKey={`100`}
                      style={{ backgroundColor: 'none !important' }}
                    >
                      <Accordion.Header>Шапка документа:</Accordion.Header>
                      <Accordion.Body></Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                ) : mobileView ? (
                  <></>
                ) : (
                  ''
                )} */}
                {(gridSettingMode || editedOrderId !== '') && (
                  <CustomerToolbar buttons={submenu} />
                )}
                <DataTable
                  // apiDataGridRef={apiDataGridRef}
                  mobileView={oneClickMode}
                  rows={rows}
                  columns={columns}
                  columnVisible={{ id: false, ...visibilityColumns }}
                  onSelectRow={handleOnSelectRow}
                  onUpdateRow={handleOnUpdateRow}
                  setRowSelectionModel={setRowSelectionModel}
                  onSelectCell={handleOnSelectCell}
                  rowSelectionModel={selectedProducts}
                  checkboxSelection={false}
                />
              </>
            )}
          </Col>
          <Col md={12} lg={4}>
            <Accordion defaultActiveKey='0'>
              <Accordion.Item
                eventKey={`${
                  currentBreakpoint === 'xs' ||
                  currentBreakpoint === 'sm' ||
                  currentBreakpoint === 'md'
                    ? '10'
                    : '0'
                }`}
                style={{ backgroundColor: 'none !important' }}
              >
                <Accordion.Header>Архив заявок</Accordion.Header>
                <Accordion.Body>
                  <OrderArchiveList currentId={editedOrderId} />
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>
        </Row>
      </Container>
      <ModalFrame
        size='sm'
        show={!!showProductCard}
        onHide={handleOnHideModalProductCard}
        header={showProductCard?.name}
      >
        <ProductCard
          item={showProductCard}
          onHide={handleOnHideModalProductCard}
          theme={theme}
          onlyImage={true}
          showPrice={true}
          zoom={false}
          showDescription={true}
        />
      </ModalFrame>
    </>
  );
};

export default CustomerCartScreen;
