import React from 'react';
import {
  // setAddMarginWhenCellEdit,
  setShowPrice,
  setShowReturnColumn,
  setShowUnitColumn,
  setShowMyltipleColumn,
} from '../../../slices/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import { Badge, Button, Col, Stack } from 'react-bootstrap';
import { Alert, FormControlLabel, Switch } from '@mui/material';
import { LinkContainer } from 'react-router-bootstrap';

const Item = ({ button, index }) => {
  if (button?.hide) {
    return <></>;
  }
  return (
    <span key={index}>
      {button.type === 'switch' ? (
        <FormControlLabel
          sx={{
            display: 'block',
          }}
          control={
            <Switch
              checked={!button.disabled || false}
              onChange={button.action}
              name={`switch_${index}`}
              color='primary'
            />
          }
          label={button.name}
        />
      ) : button.type === 'component' ? (
        <>
          {button.link ? (
            <LinkContainer to={button.link} disabled={button.disabled || false}>
              <Button
                role='button'
                size={button?.size}
                className={button.className}
              >
                {button.component()}
              </Button>
            </LinkContainer>
          ) : button.action ? (
            <Button
              disabled={button.disabled || false}
              onClick={button.action}
              role='button'
              size={button?.size}
              className={button.className}
            >
              {button.component()}
            </Button>
          ) : (
            button.component()
          )}
        </>
      ) : button.type === 'self-component' ? (
        button.component()
      ) : (
        <Button
          size={button.size}
          disabled={button.disabled || false}
          onClick={button.action}
          type={button?.type || 'submit'}
          className={`${button?.className || 'btn'}`}
          variant={button?.variant || 'outline-secondary'}
        >
          {button.name}
          {button?.badgeValue && (
            <Badge bg={button?.badgeColor ? button.badgeColor : `secondary`}>
              {button.badgeValue}
            </Badge>
          )}
          {button?.icon && <span>{button.icon}</span>}
        </Button>
      )}
    </span>
  );
};

const CustomerToolbar = ({ buttons, showInAnyCase }) => {
  const dispatch = useDispatch();

  const {
    pov,
    showPrice,
    showReturnColumn,
    showUnitColumn,
    showMultipleColumn,
    currentProvider,
    currentBreakpoint,
  } = useSelector((state) => state.auth);
  //   const [selectedOption, setSelectedOption] = useState('');

  // const handleSwitchAddMargin = () => {
  //   dispatch(setAddMarginWhenCellEdit(!addMarginWhenCellEdit));
  // };
  const handleSwitchShowPriceColumn = () => {
    dispatch(setShowPrice(!showPrice));
  };

  const handleSwitchShowReturnColumn = () => {
    dispatch(setShowReturnColumn(!showReturnColumn));
  };

  const handleSwitchShowUnitColumn = () => {
    dispatch(setShowUnitColumn(!showUnitColumn));
  };
  const handleSwitchShowMultipleColumn = () => {
    dispatch(setShowMyltipleColumn(!showMultipleColumn));
  };

  // if (pov !== 'customer') {
  //   return <></>;
  // }

  // if (!gridSettingMode && !showInAnyCase) {
  //   return <></>;
  // }
  return (
    <>
      <Alert icon={false} className='px-2 m-0' severity='warning'>
        Видимость колонок:
        <Stack direction='horizontal' gap={3}>
          <Col>
            <FormControlLabel
              control={
                <Switch
                  checked={showPrice}
                  onChange={handleSwitchShowPriceColumn}
                  name={`switch_1`}
                  color='primary'
                />
              }
              label={currentBreakpoint === 'xs' ? 'Ц' : 'Цены'}
            />
            {currentProvider?.canReturn && (
              <FormControlLabel
                control={
                  <Switch
                    checked={showReturnColumn}
                    onChange={handleSwitchShowReturnColumn}
                    name={`switch_2`}
                    color='primary'
                  />
                }
                label={currentBreakpoint === 'xs' ? 'В' : 'Возв.'}
              />
            )}
            <FormControlLabel
              control={
                <Switch
                  checked={showUnitColumn}
                  onChange={handleSwitchShowUnitColumn}
                  name={`switch_3`}
                  color='primary'
                />
              }
              label={currentBreakpoint === 'xs' ? 'Е' : 'Ед'}
            />
            <FormControlLabel
              control={
                <Switch
                  checked={showMultipleColumn}
                  onChange={handleSwitchShowMultipleColumn}
                  name={`switch_4`}
                  color='primary'
                />
              }
              label={currentBreakpoint === 'xs' ? 'Уп' : 'в уп.'}
            />
            {/* <FormControlLabel
              control={
                <Switch
                  checked={addMarginWhenCellEdit}
                  onChange={handleSwitchAddMargin}
                  name={`switch_4`}
                  color='primary'
                />
              }
              label={currentBreakpoint === 'xs' ? 'О' : 'Ед'}
            /> */}
          </Col>
        </Stack>
        {Array.isArray(buttons) && buttons.length > 0 && (
          <Stack
            direction='horizontal'
            className={`align-content-start justify-content-start`}
            gap={1}
          >
            {buttons.map((button, index) => (
              <Item key={index} button={button} index={index} />
            ))}
          </Stack>
        )}
      </Alert>
    </>
  );
};

export default CustomerToolbar;
