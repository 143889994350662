import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Container, Row, Stack, ListGroup } from 'react-bootstrap';
import { FaHome, FaPlus } from 'react-icons/fa';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import { LinearProgress } from '@mui/material';
import NewsItem from '../components/news/NewsItem/NewsItem';
import ShowAllNews from '../components/buttons/ShowAllNews';
import ToolsPanel from '../components/ToolsPanel';
import Message from '../components/Message';
import { useGetNewsQuery } from '../slices/newsApiSlice';
import { existNewsUnset } from '../slices/authSlice';

const NewsScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    currentProvider,
    currentCustomer,
    theme,
    pov,
    showAllNews,
    existNews,
  } = useSelector((state) => state.auth);

  useEffect(() => {
    if (pov === 'customer' && existNews.includes(currentProvider?._id)) {
      dispatch(existNewsUnset(currentProvider?._id));
    }
    // eslint-disable-next-line
  }, []);

  const payload = {
    providerId: currentProvider?._id,
    customerId: currentCustomer?._id,
    pov: pov,
    pageNumber: 1,
    visibility: pov === 'customer' ? 'active' : showAllNews ? 'all' : 'active',
  };

  const { data, isLoading, isError, error } = useGetNewsQuery(
    { ...payload },
    { refetchOnMountOrArgChange: true }
  );

  if (!currentProvider) {
    return;
  }

  const handleClickAdd = () => {
    if (
      pov === 'provider' &&
      currentProvider?._id
      // currentProvider?.role === 'a'
    ) {
      navigate('/provider/news/new');
    }
  };

  const buttons = [];
  if (pov === 'provider' && currentProvider?._id) {
    buttons.push({
      name: 'Только активные',
      type: 'self-component',
      component: (props) => {
        return <ShowAllNews />;
      },
    });
    buttons.push({
      className: `bg-transparent`,
      name: 'Добавить новость',
      type: 'component',
      action: handleClickAdd,
      component: (props) => {
        return (
          <FaPlus size={22} color={theme === 'dark' ? 'white' : 'black'} />
        );
      },
    });
  }

  let backURL = '/start';
  if (pov === 'customer') {
    if (currentCustomer?._id) {
      backURL = '/customer';
    } else {
      backURL = '/customer/select/provider';
    }
  } else if (pov === 'provider') {
    if (currentProvider?._id) {
      backURL = '/provider';
    } else {
      backURL = '/provider/select/provider/';
    }
  }

  return (
    <>
      {isLoading ? (
        <LinearProgress />
      ) : isError ? (
        <Message>{error?.date?.message || error.error}</Message>
      ) : (
        <>
          <ToolsPanel
            backIcon={pov === 'customer' ? <FaHome size={22} /> : ''}
            backURL={backURL}
            title={`Новости ${currentProvider?.name}`}
            buttons={buttons}
          />
          <Container>
            <ListGroup as='ol'>
              {/* <ResponsiveMasonry
                columnsCountBreakPoints={{ 350: 1, 860: 2, 1200: 3 }}
              > */}
              {/* <Masonry> */}
              {Array.isArray(data?.rows) &&
                data.rows.map((item) => (
                  <NewsItem
                    key={item._id}
                    item={item}
                    variant={'linear'}
                    className={'my-2 border-0'}
                  />
                ))}
              {/* </Masonry> */}
              {/* </ResponsiveMasonry> */}
            </ListGroup>
          </Container>
        </>
      )}
    </>
  );
};

export default NewsScreen;
