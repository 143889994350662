/**
 * @author Junaid Atari
 * @link https://github.com/blacksmoke26
 * @since 2020-09-20
 */

// npm i -S debounce

import { useState, useEffect } from 'react';
import debounce from 'debounce';


/**
 * @typedef {"xs" | "sm" | "md" | "lg" | "xl" | "xxl"} Breakpoint
 */

export const SIZE_XS = 'xs';//<576
export const SIZE_SM = 'sm';//width >= 576 && width < 768
export const SIZE_MD = 'md';//width >= 768 && width < 992
export const SIZE_LG = 'lg';//width >= 992 && width < 1200
export const SIZE_XL = 'xl';//width >= 1200 && width < 1440
export const SIZE_XXL = 'xxl';//width >= 1440

/**
 * @param {number} width
 * @returns {Breakpoint}
 */
const resolveBreakpoint = (width) => {
	if (width < 600) {
		return SIZE_XS;
	} else if (width >= 600 && width < 700) {
		return SIZE_SM;
	} else if (width >= 700 && width < 900) {
		return SIZE_MD;
	} else if (width >= 900 && width < 1200) {
		return SIZE_LG;
	} else if (width >= 1200 && width < 1536) {
		return SIZE_XL;
	} else if (width >= 1536) {
		return SIZE_XXL;
	}
};

/**
 * @returns {Breakpoint}
 */
const useBreakpoint = (params) => {
	const [size, setSize] = useState(() => resolveBreakpoint(window.innerWidth));

	useEffect(() => {
		//debounce - Delay function calls until a set time elapses after the last invocation
		const calcInnerWidth = debounce(function () {
			setSize(resolveBreakpoint(window.innerWidth));
		}, 200);
		window.addEventListener('resize', calcInnerWidth);
		return () => window.removeEventListener('resize', calcInnerWidth);
	}, []);


	return { size };


};

export default useBreakpoint;