import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Accordion,
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
  Stack,
} from 'react-bootstrap';
import { LinearProgress } from '@mui/material';
import Message from '../Message/Message';
import MultiSelectInput from '../MultiSelectInput';
import GroupListSimple from '../GroupList/GroupListSimple';
import {
  FaArrowAltCircleRight,
  FaMinus,
  FaPlus,
  FaTrash,
} from 'react-icons/fa';
import {
  useGetCustomersQuery,
  useGetDataForSelectCustomersQuery,
} from '../../slices/customersApiSlice';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

const SelectCustomers = ({ close, selectItems, items, mode = 'single' }) => {
  const [skip, setSkip] = useState(true);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [selectedRegions, setSelectedRegions] = useState([]);
  const [allRegions, setAllRegions] = useState([]);
  const [searchString, setSearchString] = useState('');
  const [allCustomers, setAllCustomers] = useState([]);
  const [selectedCustomers, setSelectedCustomers] = useState(items || []);
  const [selectedCustomersIds, setSelectedCustomersIds] = useState(
    items?.map((c) => c?.id || c?._id) || []
  );
  const { theme, currentProvider, currentBreakpoint } = useSelector(
    (state) => state.auth
  );

  const {
    data: dataForSelect,
    isLoading: isLoadingData,
    isError: isErrorData,
    error: errorData,
  } = useGetDataForSelectCustomersQuery({
    providerId: currentProvider?._id,
  });

  //   console.log('selectedRegions', selectedRegions);
  //   console.log('selectedUsers', selectedUsers);

  const {
    data: customers,
    // refetch: customersRefetch,
    isLoading: isLoadingCustomers,
    isError: isErrorCustomer,
    error: errorCustomer,
  } = useGetCustomersQuery(
    {
      providerId: currentProvider?._id,
      keyword: searchString,
      regionIds: selectedRegions,
      userIds: selectedUsers,
    },
    {
      skip,
    }
  );

  useEffect(() => {
    if (Array.isArray(customers?.rows)) {
      setSkip(true);
      setAllCustomers(customers.rows);
    }
  }, [customers]);

  useEffect(() => {
    // if (dataForSelect?.customers) setAllCustomers(dataForSelect.customers);
    if (dataForSelect?.users) setAllUsers(dataForSelect.users);
    if (dataForSelect?.regions) setAllRegions(dataForSelect.regions);
  }, [dataForSelect]);

  const addOneCustomer = (item) => {
    // console.log('addOneCustomer', item);
    const exist = selectedCustomers.find(
      (customer) => customer._id === item._id
    );
    if (exist) return;
    const updatedArray = [...selectedCustomers, item];
    setSelectedCustomers(updatedArray);

    const filteredCustomersIds = selectedCustomersIds.filter(
      (id) => id !== item._id
    );
    setSelectedCustomersIds([...filteredCustomersIds, item._id]);
  };

  const addAllFoundCustomers = () => {
    const filtered = allCustomers.filter(
      (item) => !selectedCustomersIds.includes(item.id)
    );
    if (filtered.length === 0) return;

    setSelectedCustomers([...selectedCustomers, ...filtered]);

    const filteredIds = filtered.map((item) => item.id);
    setSelectedCustomersIds([...selectedCustomersIds, ...filteredIds]);
  };

  const removeCustomer = (item) => {
    // console.log('removeCustomer', item);
    const updatedArray = selectedCustomers.filter(
      (customer) => customer._id !== item._id
    );
    setSelectedCustomers(updatedArray);

    const updatedArrayIds = selectedCustomersIds.filter(
      (id) => id !== item._id
    );
    setSelectedCustomersIds(updatedArrayIds);
  };

  const handleSelectUsers = (event) => {
    const {
      target: { value },
    } = event;
    const selected = typeof value === 'string' ? value.split(',') : value;
    // console.log(items);
    setSelectedUsers(selected);
    // customersRefetch();
  };

  const handleSelectRegions = (event) => {
    const {
      target: { value },
    } = event;
    const selected = typeof value === 'string' ? value.split(',') : value;
    setSelectedRegions(selected);
    // customersRefetch();
  };

  const handleApplyFilter = () => {
    setSkip(false);
    // customersRefetch();
  };

  const themeStyle = {
    backgroundColor: theme === 'dark' ? '#212529' : 'white',
    borderColor: theme === 'dark' ? '#212529' : '#b5c0c1',
    color: theme === 'dark' ? 'white' : 'black',
  };

  const SelectedTab = () => {
    return (
      <>
        <Row className='d-flex justify-content-between'>
          <Col>
            <Button
              size={
                ['lg', 'xl', 'xxl'].includes(currentBreakpoint) ? 'lg' : 'sm'
              }
              type='button'
              variant='primary'
              className='my-2'
              onClick={() => selectItems(selectedCustomers)}
            >
              Сохранить
            </Button>
          </Col>
          <Col className='d-flex justify-content-end'>
            <Button
              size={
                ['lg', 'xl', 'xxl'].includes(currentBreakpoint) ? 'lg' : 'sm'
              }
              type='button'
              variant='primary'
              className='my-2'
              onClick={close}
            >
              Закрыть
            </Button>
          </Col>
        </Row>
        <GroupListSimple
          action={false}
          variant={'flush'}
          className='p-0 border-0'
          rows={selectedCustomers}
          buttons={[
            {
              onClick: removeCustomer,
              icon: <FaMinus />,
            },
          ]}
        />
      </>
    );
  };

  if (isLoadingData) {
    return <LinearProgress />;
  } else if (isErrorData) {
    return (
      <Message>
        {errorData?.data?.message || errorData?.message || errorData?.error}
      </Message>
    );
  }

  return (
    <>
      <Row>
        <Col xs={12} sm={12} md={currentBreakpoint.includes('xxl') ? 8 : 12}>
          <Tabs
            defaultActiveKey='search'
            id='uncontrolled-tab-example'
            className='mb-3'
          >
            <Tab eventKey='search' title='Поиск'>
              <span className='text-info'>Фильтры:</span>
              <MultiSelectInput
                selectedItems={selectedUsers}
                handleMultiSelect={handleSelectUsers}
                items={allUsers}
                label='Сотрудники'
                closeButton={true}
              />
              <MultiSelectInput
                selectedItems={selectedRegions}
                handleMultiSelect={handleSelectRegions}
                items={allRegions}
                label='Регионы'
                closeButton={true}
              />
              <Form.Group controlId='title' className='my-2' as={Row}>
                <Form.Label column sm='3' className='pb-0'>
                  Текст
                </Form.Label>
                <Col sm='9'>
                  <Form.Control
                    style={themeStyle}
                    type='text'
                    placeholder='Поиск по ИНН или Наименованию'
                    value={searchString}
                    onChange={(e) => setSearchString(e.target.value)}
                  ></Form.Control>
                </Col>
              </Form.Group>
              {isLoadingCustomers ? (
                <LinearProgress />
              ) : isErrorCustomer ? (
                <Message>
                  {errorCustomer?.data?.message ||
                    errorCustomer?.message ||
                    errorCustomer?.error}
                </Message>
              ) : (
                <>
                  <Stack
                    direction='horizontal'
                    gap={3}
                    className='justify-content-between'
                  >
                    <Button
                      size={
                        ['lg', 'xl', 'xxl'].includes(currentBreakpoint)
                          ? 'lg'
                          : 'sm'
                      }
                      type='button'
                      variant='primary'
                      className='my-2'
                      onClick={handleApplyFilter}
                    >
                      Поиск
                    </Button>
                    {allCustomers?.length > 0 && (
                      <Button
                        size={
                          ['lg', 'xl', 'xxl'].includes(currentBreakpoint)
                            ? 'lg'
                            : 'sm'
                        }
                        type='button'
                        variant='primary'
                        className='my-2'
                        onClick={addAllFoundCustomers}
                      >
                        Добавить все <FaArrowAltCircleRight />
                      </Button>
                    )}
                  </Stack>
                  <GroupListSimple
                    action={false}
                    variant={'flush'}
                    className='p-0 border-0'
                    rows={allCustomers.filter(
                      (item) => !selectedCustomersIds.includes(item._id)
                    )}
                    // rows={allCustomers}
                    buttons={[
                      {
                        onClick: addOneCustomer,
                        icon: <FaPlus size={20} />,
                      },
                    ]}
                  />
                </>
              )}
            </Tab>
            {!currentBreakpoint.includes('xxl') && (
              <Tab
                eventKey='selected'
                title={`Выбранные (${selectedCustomers?.length})`}
              >
                <SelectedTab />
              </Tab>
            )}
          </Tabs>
        </Col>
        {currentBreakpoint.includes('xxl') && (
          <Col xs={12} sm={12} md={4}>
            <Tabs
              defaultActiveKey='selected'
              id='uncontrolled-tab-example'
              className='mb-3'
            >
              <Tab
                eventKey='selected'
                title={`Выбранные (${selectedCustomers?.length})`}
              >
                <SelectedTab />
              </Tab>
            </Tabs>
          </Col>
        )}
      </Row>
    </>
  );
};

export default SelectCustomers;
