
export const tableStyles = (theme, addMargin = 0) => {
	const set = {
		[theme.breakpoints.between('xs', 'sm')]: {
			'& .grid-header-order': {
				padding: '0px',
				textAlign: 'left',
				justifyContent: 'flex-start',
			},
			'& .grid-header-order button': {
				display: 'none',
			},
			// '& .grid-header-price': {
			//   padding: '0px',
			//   paddingRight: '8px',
			//   //textAlign: 'right',
			// },
			'& .grid-header-price button': {
				display: 'none',
			},
		},
		[theme.palette.mode === 'light']: {
			'& .grid-cell-order': {
				backgroundColor: '#e1f5fe',
				justifyContent: 'flex-start',
			},
		},
		[theme.palette.mode === 'dark']: {
			'& .grid-cell-order': {
				backgroundColor: 'rgba(255, 255, 255, 0.12)',
				justifyContent: 'flex-start',
			},
		},
		'& .grid-cell-name': {
			whiteSpace: 'normal !important',
			lineHeight: '14px',
			paddingLeft: `${5 + addMargin}px !important`,
			paddingRight: '0px !important',
		},
		'& .regions': {
			whiteSpace: 'normal !important',
			lineHeight: '14px',
			paddingLeft: `1px !important`,
			paddingRight: '0px !important',
		},
		'& .photos': {
			// transform: 'unset !important',
		},

		'& .line-through': {
			textDecoration: 'line-through !important',
			opacity: 0.5
		},
		'& .grid-cell-order': {
			borderRight: 'solid',
			borderRightWidth: 'thin',
			borderLeft: 'solid',
			borderLeftWidth: 'thin',
			justifyContent: 'center !important',
			paddingLeft: '2px !important',
			paddingRight: '2px !important',
		},
		'& .grid-cell-price-edit': {
			borderRight: 'solid',
			borderRightWidth: 'thin',
			borderLeft: 'solid',
			borderLeftWidth: 'thin',
			justifyContent: 'flex-start !important',
			paddingLeft: '5px !important',
			paddingRight: '2px !important',
		},
		'& .grid-cell-return': {
			borderRight: 'solid',
			borderRightWidth: 'thin',
			borderLeft: 'solid',
			borderLeftWidth: 'thin',
			justifyContent: 'center !important',
			paddingLeft: '2px !important',
			paddingRight: '2px !important',
			backgroundColor:
				theme.palette.mode === 'dark'
					? '#3d1a1a !important'
					: '#fddfdf !important',
		},
		'& .noteditable': {
			backgroundColor: '#1976d261 !important',
			paddingTop: '2px!important',
			paddingBottom: '2px!important',
			minHeight: '16px!important',
		},
		'& .grid-header-order': {
			paddingLeft: '2px !important',
			paddingRight: '2px !important',
		},
		'& .MuiInputBase-input': {
			paddingLeft: '0px !important',
			paddingRight: '0px !important',
		},
		'& .MuiDataGrid-iconButtonContainer': {
			display: 'none !important',
		},
		'& .promo': {
			// backgroundColor: 'bisque !important',
			fontWeight: 'bold !important',
			fontSize: '15px !important'
		},
		'& .grid-cell-unit': {
			paddingLeft: '2px !important',
			paddingRight: '2px !important',
		},
		'& .grid-cell-price': {
			justifyContent: 'center !important',
			paddingLeft: '2px !important',
			paddingRight: '4px !important',
		},
		'& .grid-header-price': {
			justifyContent: 'center !important',
			paddingLeft: '2px !important',
			paddingRight: '2px !important',
		},
		'& .grid-header-unit': {
			justifyContent: 'center !important',
			paddingLeft: '2px !important',
			paddingRight: '2px !important',
		},
		'& .MuiDataGrid-row': {
			//textDecoration: 'underline !important',
			//textDecorationColor: 'white !important',
			//textDecorationStyle: 'wavy  !important',
			// minHeight: '40px!important',
			// maxHeight: '40px!important',
		},
		'& .MuiDataGrid-columnHeaders': {
			minHeight: '24px !important',
			maxHeight: '24px !important',
			backgroundColor:
				theme.palette.mode === 'dark'
					? `dimgrey !important`
					: `#b7c8d9 !important`,
		},
	}
	return set
}