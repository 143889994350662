import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { BASE_URL } from '../constans'
import { logout } from './authSlice';

const baseQuery = (baseQueryOptions) => async (args, api, extraOptions) => {
	const { signal, dispatch, getState } = api;
	const result = await fetchBaseQuery(baseQueryOptions)(args, api, extraOptions);

	if (result.error) {
		// dispatch toast message
		const { data, status } = result.error;
		if (status === 409) {
			dispatch(logout());
		}
	}

	return result;
};

// const baseQuery = fetchBaseQuery({
// 	baseUrl: BASE_URL
// })

export const apiSlice = createApi({
	baseQuery: baseQuery({
		baseUrl: BASE_URL,
	}),
	// baseQuery: baseQuery,
	tagTypes: ['ProviderProductMenu', 'News', 'Product', 'Orders', 'Tasks', 'User', 'TaskUserList', 'Group', 'Provider', 'Customer', 'RouteList'], //are used to define the types of data that will be fetching from our API.
	endpoints: (builder) => ({})
})