import React from 'react';
import { useSelector } from 'react-redux';
import { useGetOrderDetailsQuery } from '../../../slices/ordersApiSlice';
import { LinearProgress } from '@mui/material';
import Message from '../../Message';
import { Accordion, Col, Container, ListGroup, Row } from 'react-bootstrap';
import DataTable from '../../DataTable';
import { getDataString } from '../../../utils/gets';
import { colsWidth } from '../../../utils/authUtils';

const OrderView = ({ orderId }) => {
  const { selectedProducts, currentBreakpoint, theme, currentProvider, pov } =
    useSelector((state) => state.auth);

  const { data, isLoading, isError, error } = useGetOrderDetailsQuery({
    orderId,
    providerId: currentProvider?._id,
    pov,
  });

  if (isLoading) {
    return <LinearProgress />;
  } else if (isError) {
    return (
      <Message>
        {error?.data?.message || error?.message || error?.error}
      </Message>
    );
  }

  const {
    orderItems,
    comment,
    contacts,
    createdAt,
    priceType,
    totalPrice,
    customer,
    user,
    shippingAddress,
  } = data?.doc;

  const rows = [];
  if (Array.isArray(orderItems)) {
    for (let index = 0; index < orderItems.length; index++) {
      const element = orderItems[index];
      rows.push({
        ...element,
        id: element?._id,
      });
    }
  }

  const columns = [
    { field: 'id', headerName: '№', width: 50, hide: true },
    { field: 'promo', headerName: 'promo', width: 10, hide: true },
    {
      field: 'name',
      headerName: 'Название',
      flex: 1,
      editable: false,
      cellClassName: 'grid-cell-name',
    },
    {
      field: 'price',
      headerName: 'Цена',
      width: 70,
      editable: false,
      headerClassName: 'grid-header-price',
      sortable: false,
    },
    {
      field: 'unit',
      headerName: 'Ед.',
      type: 'string',
      width: colsWidth.unit,
      editable: false,
      sortable: false,
      align: 'left',
      headerAlign: 'left',
    },
    {
      field: 'qty',
      type: 'number',
      headerName: 'Заявка',
      width: colsWidth.qty,
      editable: false,
      //sortable: false,
      headerAlign: 'left',
      headerClassName: 'grid-header-order',
      cellClassName: 'grid-cell-order',
    },
    {
      field: 'ret',
      type: 'number',
      headerName: 'Возврат',
      width: colsWidth.qty,
      editable: false,
      //sortable: false,
      headerAlign: 'left',
      headerClassName: 'grid-header-order',
      cellClassName: 'grid-cell-return',
    },
  ];

  const visibilityColumns = {
    parentCode: false,
    promo: false,
  };

  return (
    <Container>
      <Row>
        <Col md={12} lg={8} className='mb-3'>
          {Array.isArray(rows) && (
            <DataTable
              rows={rows || []}
              columns={columns}
              columnVisible={{ id: false, ...visibilityColumns }}
              rowSelectionModel={selectedProducts}
              checkboxSelection={false}
            />
          )}
        </Col>
        <Col md={12} lg={4}>
          <Accordion defaultActiveKey='0'>
            <Accordion.Item
              eventKey={`${
                currentBreakpoint === 'xs' ||
                currentBreakpoint === 'sm' ||
                currentBreakpoint === 'md'
                  ? '10'
                  : '0'
              }`}
              style={{ backgroundColor: 'none !important' }}
            >
              <Accordion.Header>Свойства:</Accordion.Header>
              <Accordion.Body>
                <ListGroup variant='flush'>
                  <ListGroup.Item variant={theme} className='p-1'>
                    <Row>
                      <Col>Дата и время:</Col>
                      <Col>{getDataString(createdAt, '')}</Col>
                    </Row>
                  </ListGroup.Item>
                  <ListGroup.Item variant={theme} className='p-1'>
                    <Col>Покупатель:</Col>
                    <Col className='text-wrap text-break'>{customer?.name}</Col>
                  </ListGroup.Item>
                  <ListGroup.Item variant={theme} className='p-1'>
                    <Row>
                      <Col>Автор:</Col>
                      <Col>{user?.name}</Col>
                    </Row>
                  </ListGroup.Item>
                  <ListGroup.Item variant={theme} className='p-1'>
                    <Col>Тип цен:</Col>
                    <Col className='text-wrap text-break'>
                      {priceType?.name}
                    </Col>
                  </ListGroup.Item>
                  <ListGroup.Item variant={theme} className='p-1'>
                    <Row>
                      <Col>Сумма:</Col>
                      <Col>{totalPrice}</Col>
                    </Row>
                  </ListGroup.Item>

                  <ListGroup.Item variant={theme} className='p-1'>
                    <Col>Адрес доставки:</Col>
                    <Col className='text-wrap text-break'>
                      {shippingAddress}
                    </Col>
                  </ListGroup.Item>
                  <ListGroup.Item variant={theme} className='p-1'>
                    <Col>Телефоны:</Col>
                    <Col className='text-wrap text-break'>{contacts}</Col>
                  </ListGroup.Item>
                  <ListGroup.Item variant={theme} className='p-1'>
                    <Col>Комментарий:</Col>
                    <Col className='text-wrap text-break'>{comment}</Col>
                  </ListGroup.Item>
                </ListGroup>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Col>
      </Row>
    </Container>
  );
};

export default OrderView;
