import React from 'react';
import { Offcanvas } from 'react-bootstrap';

const BottomCanvas = ({ children, onHide, item, show }) => {
  return (
    <Offcanvas
      // show={typeof item === 'object'}
      show={show}
      onHide={() => onHide(false)}
      placement={'bottom'}
      className='bg-black'
    >
      <Offcanvas.Header closeButton className='py-1'>
        <Offcanvas.Title className='fs-6'>{item?.name}</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body className='p-1'>
        {children}
        {/* <HistoryChart item={showHistory} /> */}
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default BottomCanvas;
