import { CONTACTS_URL, UPLOAD_URL } from '../constans';
import { apiSlice } from './apiSlice';

export const sectionsApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		createUpdateContact: builder.mutation({
			query: (data) => ({
				url: `${CONTACTS_URL}/${data?.contactId}`,
				method: 'POST',
				body: {
					...data
				}
			}),
		}),
		getContactDetails: builder.query({
			query: (data) => ({
				url: `${CONTACTS_URL}/${data?.contactId}`,
				params: {
					...data
				}
			}),
			keepUnusedDataFor: 5
		}),
		getContacts: builder.query({
			query: (data) => ({
				url: `${CONTACTS_URL}`,
				params: {
					...data
				}
			}),
		}),
		deleteContact: builder.mutation({
			query: (data) => ({
				url: `${CONTACTS_URL}/${data?.contactId}`,
				method: 'DELETE',
				params: {
					...data
				}
			}),
		}),
		uploadContactPhoto: builder.mutation({
			query: (data) => ({
				url: UPLOAD_URL,
				method: 'POST',
				body: data
			}),
		}),
	})
});

export const {
	useUploadContactPhotoMutation,
	useDeleteContactMutation,
	useCreateUpdateContactMutation,
	useGetContactDetailsQuery,
	useGetContactsQuery
} = sectionsApiSlice;