import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  Col,
  Form,
  InputGroup,
  Row,
  Stack,
  ListGroup,
} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { addToCart } from '../../../slices/cartSlice';
import { FaEdit } from 'react-icons/fa';
import { LinkContainer } from 'react-router-bootstrap';
import { useTheme } from '@emotion/react';
import ZoomableImage from '../../Image/ZoomableImage';
import Skeleton from '@mui/material/Skeleton';
import Ratio from 'react-bootstrap/Ratio';
import { Divider } from '@mui/material';
import { toast } from 'react-toastify';
import { useUpdateCartItemMutation } from '../../../slices/cartsApiSlice';

const FIX_VALUE = 1;
const SINGLE_VALUE = 2;
const SELECT_VALUE = 3;
// const PRESS_BUTTON = 4;
// const GIVE_VOTE = 5;

const NewsItem = ({ item, variant, noEditLink, as, className }) => {
  const [value, setValue] = useState(1);
  const [price, setPrice] = useState('');
  const [checkValue, setCheckValue] = useState(false);

  const [type, setType] = useState(0);
  const [valueLimit, setValueLimit] = useState({ min: 0, max: 0 });
  const [multiple, setMultiple] = useState(1);
  const [valueList, setValueList] = useState('');

  const [updateCartItemOnServer] = useUpdateCartItemMutation();
  const { currentProvider, currentCustomer, pov, currentBreakpoint, theme } =
    useSelector((state) => state.auth);
  const dispatch = useDispatch();

  // useEffect(() => {
  //   console.log(currentBreakpoint);
  // }, [currentBreakpoint]);
  // useEffect(() => {
  //   console.log(theme);
  // }, [theme?.palette]);

  const updateOnServer = async (data) => {
    try {
      await updateCartItemOnServer({ ...data });
    } catch (error) {
      toast.warn(error?.data?.message || error?.message);
    }
  };

  const handleOnClickValueButton = (product) => {
    if (product?._id) {
      //dispach
      dispatch(
        addToCart({
          _id: product._id,
          id: product._id,
          name: product?.name,
          unit: product?.unit,
          parentCode: product?.parentCode,
          qty: value,
          ret: '',
          providerName: currentProvider.name,
          providerId: currentProvider._id,
          customerName: currentCustomer.name,
          customerId: currentCustomer._id,
        })
      );

      updateOnServer({
        providerId: currentProvider._id,
        customerId: currentCustomer._id,
        productId: product._id,
        qty: value,
        ret: '',
        price,
      });
    }
  };

  useEffect(() => {
    if (item) {
      const {
        fixValue,
        singleValue,
        singleValueMax,
        singleValueMin,
        multipleValue,
        valueList,
        price,
      } = item;

      if (fixValue) {
        setType(FIX_VALUE);
        setValue(Number(fixValue));
      } else if (singleValue) {
        setType(SINGLE_VALUE);
        setValue(Number(singleValue));
        if (singleValueMin || singleValueMax) {
          setValueLimit({ min: singleValueMin, max: singleValueMax });
        }
        if (multipleValue) {
          setMultiple(multipleValue);
        }
      } else if (valueList && valueList.length > 0) {
        setType(SELECT_VALUE);
        setValueList(valueList);
      }
      //} else {
      setPrice(price || '');
    } else {
      setType(false);
    }
  }, [item]);

  useEffect(() => {
    let timeOutId = false;

    function check(val) {
      if (type === SINGLE_VALUE) {
        let updatedValue = Number(val);
        if (isNaN(val)) {
          updatedValue = 1;
        }
        if (valueLimit.max > 0 && updatedValue > valueLimit.max) {
          updatedValue = valueLimit.max;
        }
        if (valueLimit.min > 0 && updatedValue < valueLimit.min) {
          updatedValue = valueLimit.min;
        }
        if (multiple > 1) {
          updatedValue = Math.floor(updatedValue / multiple) * multiple;
        }
        setCheckValue(false);
        setValue(updatedValue);
      }
    }

    if (value > 0 && checkValue === true) {
      timeOutId = setTimeout(() => check(value), 1000);
    }

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [value, checkValue]);

  let subTitle = [];
  if (multiple && multiple > 1) {
    subTitle.push(`кратно ${multiple}`);
  }
  if (valueLimit.max > 0) {
    subTitle.push(`не более ${valueLimit.max}`);
  }
  if (valueLimit.min > 0) {
    subTitle.push(`не менее ${valueLimit.min}`);
  }

  const onChangeHandle = (val) => {
    if (type === SINGLE_VALUE) {
      setCheckValue(true);
    }
    setValue(Number(val));
  };

  const cutString = (text, limit, or = '') => {
    let result = text;
    if (result && typeof result === 'string') {
      if (result.length > limit) result = `${result.substring(0, limit)}...`;
    } else result = or;
    return result;
  };

  const header = cutString(item?.header, 35, 'Объявление');
  const title = cutString(item?.title, 240);
  const body = cutString(item?.body, 240);

  if (variant === 'linear') {
    const imageExist =
      typeof item?.image === 'string' && item?.image?.length > 0;

    let bp;
    switch (currentBreakpoint) {
      case 'xs':
        bp = 12;
        break;
      case 'sm':
        bp = 12;
        break;
      case 'md':
        bp = 5;
        break;
      case 'lg':
        bp = 4;
        break;
      case 'xl':
        bp = 3;
        break;
      case 'xxl':
        bp = 2;
        break;
      default:
        break;
    }

    const themeStyle = {
      backgroundColor: theme === 'dark' ? '#212529' : 'white',
      // borderColor: theme === 'dark' ? '#212529' : '#b5c0c1',
      color: theme === 'dark' ? 'white' : 'black',
    };

    return (
      <ListGroup.Item
        as={as}
        variant={theme}
        className={`bg-${item?.bgcolor} bg-opacity-10 m-0 p-2 ${
          item?.bordercolor && 'border-' + item?.bordercolor + '-subtle'
        } ${className}`}

        // style={{
        //   backgroundColor: item?.bgcolor || 'primary',
        //   border: item?.bordercolor || 'primary',
        // }}
      >
        <Row className={`p-0 ${''}`}>
          {imageExist && (
            <Col md={bp}>
              {/* <Ratio aspectRatio={'1x1'}> */}
              <ZoomableImage
                zoom={true}
                className='rounded-2 img-fluid '
                // className='object-fit-scale'
                //className='object-fit-contain'
                //className='object-fit-fill'
                // style={{ position: 'absolute' }}
                variant='top'
                src={item?.image}
              />
              {/* </Ratio> */}
            </Col>
          )}
          <Col md={imageExist ? 12 - bp : 12}>
            <Row>
              <Col md={12} lg={item?.ad ? 8 : 12} xxl={item?.ad ? 9 : 12}>
                <h5 className='card-title'>
                  {header}{' '}
                  {pov === 'provider' && !noEditLink && (
                    <LinkContainer
                      to={`/provider/news/${item?._id}`}
                      role='button'
                      className='ms-2'
                    >
                      <FaEdit />
                    </LinkContainer>
                  )}
                </h5>
                {title && <p className='card-text'>{title}</p>}
                {body && (
                  <>
                    <p className='card-text'>
                      <small className='text-muted'>{body}</small>
                    </p>
                  </>
                )}
              </Col>

              {item?.ad && (
                <Col md={12} lg={4} xxl={3}>
                  {item?.oldPrice && item?.newPrice && (
                    <Row>
                      {item?.oldPrice && (
                        <Col className='me-2 fs-5 text-decoration-line-through'>
                          {item?.oldPrice}
                        </Col>
                      )}
                      {item?.newPrice && (
                        <Col className='fs-5'>{item?.newPrice}</Col>
                      )}
                    </Row>
                  )}
                  <InputGroup>
                    {valueList ? (
                      <Form.Control
                        as='select'
                        value={value}
                        onChange={(e) => setValue(Number(e.target.value))}
                      >
                        {valueList?.split(',').map((x, index) => (
                          <option key={index} value={Number(x.trim())}>
                            {x.trim()}
                          </option>
                        ))}
                      </Form.Control>
                    ) : item?.fixValue || item?.singleValue ? (
                      <>
                        <Form.Control
                          readOnly={!!item?.fixValue}
                          style={themeStyle}
                          className='px-1'
                          value={value}
                          onChange={(e) => onChangeHandle(e.target.value)}
                        />
                      </>
                    ) : (
                      <></>
                    )}

                    {(item?.fixValue ||
                      item?.singleValue ||
                      item?.valueList) && (
                      <Button
                        onClick={() => handleOnClickValueButton(item.product)}
                        variant='outline-secondary'
                        id='button-addon2'
                      >
                        {item?.buttonTitle || 'Добавить'}
                      </Button>
                    )}
                  </InputGroup>
                  {subTitle.length > 0 && (
                    <Form.Label className='mt-1'>{`Число ${subTitle.join(
                      ' '
                    )}`}</Form.Label>
                  )}
                  {/* </Col>
                    </Row> */}
                </Col>
              )}
            </Row>
          </Col>
        </Row>
      </ListGroup.Item>
      // <Card
      //   className=' bg-opacity-10 m-0'
      //   bg={item?.bgcolor || 'primary'}
      //   border={item?.bordercolor || 'primary'}
      // >
      //   <Row
      //     // className='no-gutters'
      //     style={{ height: '10px' }}
      //     // style={{ maxHeight: '10px' }}
      //   >
      //     <Col sm className='bg-success'>
      //       <Ratio aspectRatio={'1x1'}>
      //         {item?.image ? (
      //           <ZoomableImage
      //             zoom={true}
      //             // className='rounded-2 img-fluid '
      //             className='object-fit-scale'
      //             // className='object-fit-contain'
      //             // className='object-fit-fill'
      //             // style={{ position: 'absolute' }}
      //             variant='top'
      //             src={item?.image}
      //           />
      //         ) : (
      //           <Skeleton
      //             sx={{ bgcolor: 'grey.900' }}
      //             variant='rectangular'
      //             width={100}
      //             height={100}
      //             className='rounded-2 img-fluid '
      //           />
      //         )}
      //       </Ratio>
      //     </Col>
      //     <Col sm className='bg-info'>
      //       <div className='card-body'>
      //         <h5 className='card-title'>{header}</h5>
      //         <p className='card-text'>{title}</p>
      //         <p className='card-text'>
      //           <small className='text-muted'>
      //             Последнее обновление: 3 мин. назад
      //           </small>
      //         </p>
      //       </div>
      //     </Col>
      //     <Col sm className='bg-warning'>
      //       54
      //     </Col>
      //   </Row>
      // </Card>
    );
  }

  console.log('type', type);

  return (
    <Card
      className=' bg-opacity-10 m-2'
      bg={item?.bgcolor || 'primary'}
      border={item?.bordercolor || 'primary'}
    >
      <Card.Header>
        <Stack direction='horizontal' gap={3}>
          <span
            style={{ maxHeight: '70px' }}
            className='fs-4 me-auto overflow-hidden'
          >
            {header}
          </span>
          {pov === 'provider' &&
            currentProvider?.role === 'a' &&
            !item?.hideEditIcon && (
              <LinkContainer to={`/provider/news/${item?._id}`} role='button'>
                <FaEdit />
              </LinkContainer>
            )}
        </Stack>
      </Card.Header>

      {item?.image ? (
        <div
          className='stretchy-wrapper'
          style={{
            width: '100%',
            paddingBottom: '56.25%' /* 16:9 */,
            position: 'relative',
          }}
          // style={{
          //   display: 'grid',
          //   gridTemplateColumns: 'repeat(auto-fill, minmax(363px, 1fr))',
          // }}
        >
          <Card.Img
            style={{
              position: 'absolute',
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
            }}
            // style={{ aspectRatio: '1/1', width: '100%', objectFit: 'contain' }}
            className='rounded-0'
            src={item?.image}
            alt={item?.name || item._id}
          />
        </div>
      ) : item?.useProductImage && item?.product?.image ? (
        <Card.Img
          className='rounded-0'
          src={item?.product?.image}
          alt={item?.name || item._id}
        />
      ) : (
        ''
      )}
      <Card.Body>
        {item?.title && <Card.Title>{title}</Card.Title>}
        {item?.body && <Card.Text>{body}</Card.Text>}
        {item?.oldPrice && item?.newPrice && (
          <div>
            {item?.oldPrice && (
              <span className='me-2 fs-3 text-decoration-line-through'>
                {item?.oldPrice}
              </span>
            )}
            {item?.newPrice && <span className='fs-3'>{item?.newPrice}</span>}
          </div>
        )}
        {/* {item?.buttonTitle && (
          <Button variant='primary'>{item.buttonTitle}</Button>
        )} */}
        {item?.ad && (
          <Row>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <InputGroup>
                {valueList ? (
                  <Form.Control
                    as='select'
                    value={value}
                    onChange={(e) => setValue(Number(e.target.value))}
                  >
                    {valueList?.split(',').map((x, index) => (
                      <option key={index} value={Number(x.trim())}>
                        {x.trim()}
                      </option>
                    ))}
                  </Form.Control>
                ) : item?.fixValue || item?.singleValue ? (
                  <Form.Control
                    readOnly={!!item?.fixValue}
                    className='px-1'
                    value={value}
                    onChange={(e) => onChangeHandle(e.target.value)}
                  />
                ) : (
                  <></>
                )}

                {(item?.fixValue || item?.singleValue || item?.valueList) && (
                  <Button
                    onClick={() => handleOnClickValueButton(item.product)}
                    variant='outline-secondary'
                    id='button-addon2'
                  >
                    {item?.buttonTitle || 'Добавить'}
                  </Button>
                )}
              </InputGroup>
              {subTitle.length > 0 && (
                <Form.Label className='mt-1'>{`Число ${subTitle.join(
                  ' '
                )}`}</Form.Label>
              )}
            </Col>
          </Row>
        )}
      </Card.Body>
    </Card>
  );
};

export default NewsItem;
