import { Alert } from '@mui/material';
import { StyledGridOverlay } from '../StyledGridOverlay/StyledGridOverlay';
// import { Button } from 'react-bootstrap';
// import { LinkContainer } from 'react-router-bootstrap';
import NoRowOverlaySVG from '../NoRowOverlaySVG/NoRowOverlaySVG';

export function NoRowsOverlayUsers() {
  return (
    <StyledGridOverlay>
      <NoRowOverlaySVG />
      <Alert icon={false} security='info' variant='outlined' className='m-1'>
        Процедура добавления сотрудников:
        <br />
        1. Сотрудник регистрируется на сайте
        <br />
        2. При входе выбирает роль "Поставщик"
        <br />
        3. Нажимает кнопку "Создать поставщика"
        <br />
        4. В поле "У меня есть код" вводит код Приглашения
        <br />
        5. Нажимает кнопку "Применить"
        <br />
      </Alert>
      {/* <Box sx={{ my: 1 }}>
        <LinkContainer to={'/provider/routes/new'}>
          <Button role='link' size='sm'>
            Создайте первый маршрут
          </Button>
        </LinkContainer>
      </Box> */}
    </StyledGridOverlay>
  );
}
