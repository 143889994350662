import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Col, Row, Stack } from 'react-bootstrap';
import {
  Alert,
  Divider,
  IconButton,
  InputBase,
  LinearProgress,
  Paper,
} from '@mui/material';
import Message from '../Message/Message';
import GroupListSimple from '../GroupList/GroupListSimple';
import { FaSearch, FaTimes } from 'react-icons/fa';
import { useGetProductsQuery } from '../../slices/productsApiSlice';
import { useGetGroupsQuery } from '../../slices/groupsApiSlice';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { useTheme } from '@mui/material/styles';

const SelectProducts = ({
  open,
  setOpen,
  selectItems,
  items,
  mode = 'single',
}) => {
  const [skip, setSkip] = useState(true);
  const [allGroups, setAllGroups] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [searchString, setSearchString] = useState('');
  const [allProducts, setAllProducts] = useState([]);
  // const [selectedProducts, setSelectedProducts] = useState(items || []);

  // const { breakpoints } = useTheme();
  // const fullScreen = useMediaQuery(breakpoints.down('md'));

  // const [selectedProductsIds, setSelectedProductsIds] = useState(
  //   items?.map((c) => c?.id || c?._id) || []
  // );
  const { theme, currentProvider, currentBreakpoint, pov } = useSelector(
    (state) => state.auth
  );

  const {
    data: groups,
    isLoading: isLoadingGroups,
    isError: isErrorGetGroups,
    error: errorGetGroups,
  } = useGetGroupsQuery({
    providerId: currentProvider?._id,
    notEmpty: true,
  });

  const {
    data,
    // refetch: customersRefetch,
    isLoading: isLoadingProducts,
    isError: isErrorGetProducts,
    error: errorGetProducts,
  } = useGetProductsQuery(
    {
      providerId: currentProvider?._id,
      keyword: searchString,
      pov: pov,
      groupId: selectedGroups?._id === 'all' ? '' : selectedGroups?._id,
    },
    {
      skip,
    }
  );

  useEffect(() => {
    if (Array.isArray(data?.rows)) {
      setSkip(true);
      setAllProducts(data?.rows);
    }
  }, [data]);

  useEffect(() => {
    if (groups?.rows) setAllGroups(groups.rows);
  }, [groups]);

  const addOne = (item) => {
    if (mode === 'single') {
      selectItems([item]);
      setOpen(false);
    }
  };

  useEffect(() => {
    if (
      selectedGroups._id === 'all' &&
      (searchString === '' || searchString === undefined)
    ) {
      setAllProducts([]);
    }
    // eslint-disable-next-line
  }, [selectedGroups]);

  const handleSelectGroup = (selected) => {
    // console.log(selected);

    // const {
    //   target: { value },
    // } = event;
    // const selected = typeof value === 'string' ? value.split(',') : value;
    setSelectedGroups(selected);
    setSkip(false);
  };

  // console.log('selectedGroups', selectedGroups);
  // const handleApplyFilter = () => {
  //   setSkip(false);
  // };

  const themeStyle = {
    backgroundColor: theme === 'dark' ? '#212529' : 'white',
    // borderColor: theme === 'dark' ? '#212529' : '#b5c0c1',
    color: theme === 'dark' ? 'white' : 'black',
  };

  // const SelectedTab = () => {
  //   return (
  //     <>
  //       <Row className='d-flex justify-content-between'>
  //         <Col>
  //           <Button
  //             size={
  //               ['lg', 'xl', 'xxl'].includes(currentBreakpoint) ? 'lg' : 'sm'
  //             }
  //             type='button'
  //             variant='primary'
  //             className='my-2'
  //             onClick={() => selectItems(selectedProducts)}
  //           >
  //             Сохранить
  //           </Button>
  //         </Col>
  //         <Col className='d-flex justify-content-end'>
  //           <Button
  //             size={
  //               ['lg', 'xl', 'xxl'].includes(currentBreakpoint) ? 'lg' : 'sm'
  //             }
  //             type='button'
  //             variant='primary'
  //             className='my-2'
  //             onClick={close}
  //           >
  //             Закрыть
  //           </Button>
  //         </Col>
  //       </Row>
  //       <GroupListSimple
  //         action={false}
  //         variant={'flush'}
  //         className='p-0 border-0'
  //         rows={selectedProducts}
  //         buttons={[
  //           {
  //             onClick: removeOne,
  //             icon: <FaMinus />,
  //           },
  //         ]}
  //       />
  //     </>
  //   );
  // };

  if (isLoadingGroups) {
    return <LinearProgress />;
  } else if (isErrorGetGroups) {
    return (
      <Message>
        {errorGetGroups?.data?.message ||
          errorGetGroups?.message ||
          errorGetGroups?.error}
      </Message>
    );
  }
  // console.log(currentBreakpoint);

  const onClickResetSearch = (e) => {
    setSearchString('');
    setSkip(false);
  };
  const onClickSearch = (str) => {
    setSearchString(str);
    setSkip(false);
  };

  const onChangeSearchString = (str) => {
    setSearchString(str);
    setSkip(true);
  };

  const view = 'doubleColumn';

  if (view === 'doubleColumn') {
    return (
      <Dialog
        // fullScreen={fullScreen}
        fullWidth={true}
        maxWidth={
          ['md', 'lg', 'xl', 'xxl'].includes(currentBreakpoint)
            ? 'md'
            : currentBreakpoint
        }
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby='responsive-dialog-title'
        // style={themeStyle}
      >
        <DialogTitle id='responsive-dialog-title' className='m-1 mx-2 p-0'>
          <Stack
            direction='horizontal'
            gap={1}
            className='justify-content-between'
          >
            <span> {'Выбрать товар'}</span>
            <Button onClick={() => setOpen(false)}>
              <FaTimes size={20} />
            </Button>
          </Stack>
        </DialogTitle>
        <DialogContent style={themeStyle} className='py-2'>
          <Row>
            <Col>
              <GroupListSimple
                rows={allGroups}
                onClickItem={handleSelectGroup}
                currentId={selectedGroups?._id}
                startListItem={{ _id: 'all', name: 'Искать во всех группах' }}
              />
            </Col>

            <Col>
              <CustomizedInputBase
                placeholder={'Фильтр по наименованию'}
                onClickReset={onClickResetSearch}
                onClickSearch={onClickSearch}
                onChange={onChangeSearchString}
                value={searchString}
              />
              {allProducts?.length === 0 && searchString.length === 0 && (
                <Alert className='mt-2 me-1' variant='outlined' icon={false}>
                  Ищите по наименованию или выберите группу
                </Alert>
              )}
              {isLoadingProducts ? (
                <LinearProgress />
              ) : isErrorGetProducts ? (
                <Message>
                  {errorGetProducts?.data?.message ||
                    errorGetProducts?.message ||
                    errorGetProducts?.error}
                </Message>
              ) : (
                <GroupListSimple
                  rows={allProducts}
                  onClickItem={addOne}
                  variant={'flush'}
                  className='p-1 border-0'
                />
              )}
            </Col>
          </Row>
        </DialogContent>
      </Dialog>
    );
  }

  function CustomizedInputBase({
    placeholder,
    onClickSearch,
    onClickReset,
    value,
  }) {
    const [str, setStr] = useState(value);

    return (
      <Paper
        component='form'
        sx={{
          p: '2px 4px',
          display: 'flex',
          alignItems: 'center',
          // width: 400
        }}
      >
        {/* <IconButton sx={{ p: '10px' }} aria-label='menu'>
          <MenuIcon />
        </IconButton> */}

        <InputBase
          sx={{ ml: 1, flex: 1 }}
          placeholder={placeholder || 'Введите строку'}
          inputProps={{ 'aria-label': 'search google maps' }}
          value={str}
          onChange={(e) => setStr(e.target.value)}
        />

        <IconButton
          type='submit'
          sx={{ p: '10px' }}
          aria-label='search'
          onClick={() => onClickSearch(str)}
        >
          <FaSearch size={20} />
        </IconButton>

        <Divider sx={{ height: 28, m: 0.5 }} orientation='vertical' />
        <IconButton
          color='primary'
          sx={{ p: '10px' }}
          aria-label='directions'
          onClick={() => {
            setStr('');
            onClickReset();
          }}
        >
          <FaTimes size={20} />
        </IconButton>
      </Paper>
    );
  }
};

export default SelectProducts;
