import React from 'react';
import { ListGroup } from 'react-bootstrap';
import { FaShieldAlt } from 'react-icons/fa';
import { useSelector } from 'react-redux';

const UnitManagerList = ({ admins, users, className }) => {
  const { theme } = useSelector((state) => state.auth);
  return (
    <ListGroup>
      {admins &&
        admins.map((el, index) => (
          <ListGroup.Item
            as={'div'}
            variant={theme}
            //action
            key={el._id}
            className={`d-flex justify-content-between align-items-start ${className}`}
          >
            <div>{el.name}</div> <FaShieldAlt />
          </ListGroup.Item>
        ))}
      {users &&
        users.map((el, index) => (
          <ListGroup.Item
            as={'div'}
            variant={theme}
            // action
            key={el._id}
            className={`d-flex justify-content-between align-items-start ${className}`}
          >
            {el.name}
          </ListGroup.Item>
        ))}
    </ListGroup>
  );
};

export default UnitManagerList;
