import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import {
  useGetProviderProductMenuQuery,
  useGetProviderUsersQuery,
  useGetProvidersQuery,
} from '../../slices/providersApiSlice';
import UnitList from './UnitList';
import { useGetProviderPricesQuery } from '../../slices/pricesApiSlice';
import {
  useGetCustomersQuery,
  useGetProviderCustomersQuery,
} from '../../slices/customersApiSlice';
import { useSelector } from 'react-redux';
import { useGetRoutesQuery } from '../../slices/routesApiSlice';
import { useGetRegionsQuery } from '../../slices/regionsApiSlice';

function SelectUnit({
  title,
  show,
  setShow,
  onClose,
  rows,
  setSelected,
  payload,
}) {
  const { theme, pov } = useSelector((state) => state.auth);

  const handleClose = () => {
    setShow(false);
  };

  if (!show) {
    return;
  }

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        className='mt-5 pb-3 modal-dialog-scrollable'
        style={{ zIndex: 100000 }}
        contentClassName={`modal-${theme}-content`}
      >
        <Modal.Header closeButton>
          <Modal.Title style={{ whiteSpace: 'pre-line' }}>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {title === 'Поставщики' ? (
            <UnitList query={useGetProvidersQuery} handleClick={setSelected} />
          ) : title === 'Группы' ? (
            <UnitList
              query={useGetProviderProductMenuQuery}
              payload={payload}
              handleClick={setSelected}
            />
          ) : title === 'Сотрудники' ? (
            <UnitList
              query={useGetProviderUsersQuery}
              payload={payload}
              handleClick={setSelected}
            />
          ) : title === 'Регионы' ? (
            <UnitList
              query={useGetRegionsQuery}
              payload={payload}
              handleClick={setSelected}
            />
          ) : title === 'Маршруты' ? (
            <UnitList
              query={useGetRoutesQuery}
              payload={payload}
              handleClick={setSelected}
            />
          ) : title === 'Типы цен' ? (
            <UnitList
              query={useGetProviderPricesQuery}
              payload={payload}
              handleClick={setSelected}
            />
          ) : title === 'Покупатели' && pov === 'provider' ? (
            <UnitList
              query={useGetProviderCustomersQuery}
              handleClick={setSelected}
              payload={payload}
              options={{ search: true, theme }}
            />
          ) : title === 'Покупатели' && pov === 'customer' ? (
            <UnitList
              query={useGetCustomersQuery}
              handleClick={setSelected}
              payload={payload}
            />
          ) : (
            <List>
              {rows &&
                rows.map((item, index) => (
                  <ListItemButton key={index} onClick={() => setSelected(item)}>
                    <ListItemText primary={item.name} />
                  </ListItemButton>
                ))}
            </List>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleClose}>
            Закрыть
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default SelectUnit;
