import React from 'react';
// import { useGetRoutesQuery } from '../../../slices/routesApiSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Chip,
  LinearProgress,
  List,
  ListItemButton,
  ListItemText,
  Toolbar,
} from '@mui/material';
import Message from '../../Message/Message';
import { Container } from 'react-bootstrap';
// import { useGetContractsQuery } from '../../../slices/contractsApiSlice';
import { useGetActiveRegionsQuery } from '../../../slices/regionsApiSlice';
import { setCurrentRegion, setSearchString } from '../../../slices/authSlice';

const ContractsMenu = ({ handleDrawerClose }) => {
  // const { regionId = 'all' } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pov, currentProvider, currentRegion } = useSelector(
    (state) => state.auth
  );
  // console.log('currentRegion', currentRegion);
  // const [selectedId, setSelectedId] = useState(regionId);

  //   const { data, isLoading, isError, error } = useGetRoutesQuery(
  //     { providerId: currentProvider?._id },
  //     { refetchOnMountOrArgChange: true }
  //   );

  const { data, isLoading, isError, error } = useGetActiveRegionsQuery(
    { providerId: currentProvider?._id },
    { refetchOnMountOrArgChange: true }
  );

  // console.log(data);
  //   const countAll = data?.rows?.reduce((acc, item) => acc + item.count, 0) || '';

  const handleClick = (item) => {
    // console.log(item);
    handleDrawerClose(true);
    // setSelectedId(item?.id);
    dispatch(setSearchString(''));
    dispatch(setCurrentRegion(item));
    if (item?.id === 'all') {
      navigate(`/provider/contracts/`);
    } else {
      navigate(`/provider/contracts/region/${item?.id || 'undefined'}`);
    }
  };

  return (
    <>
      {isLoading ? (
        <LinearProgress />
      ) : isError ? (
        <Message>
          {error?.data?.message || error?.message || error.error}
        </Message>
      ) : (
        <>
          <Toolbar className='mx-1 px-0 mobileMenu-toolbar'>
            <Container>Группы:</Container>
          </Toolbar>
          <List
            sx={{
              '& .Mui-selected': {
                backgroundColor:
                  pov === 'customer'
                    ? '#1976d261 !important'
                    : '#2fd21961 !important',
              },
            }}
          >
            <ListItemButton
              sx={{ borderLeftWidth: '3px !important' }}
              onClick={() => handleClick({ id: 'all', name: 'Все' })}
              selected={currentRegion?.id === 'all' || currentRegion === null}
            >
              <ListItemText primary={'Все'} />
              {/* <Chip
                label={countAll}
                size='small'
                color='primary'
                variant='outlined'
              /> */}
            </ListItemButton>
            {Array.isArray(data?.rows) &&
              data.rows.map((item, index) => (
                <ListItemButton
                  key={item?.id || index}
                  sx={{ borderLeftWidth: '3px !important' }}
                  //   className={
                  //     openId === group._id ? 'border-info border-start ' : ''
                  //   }
                  onClick={() => handleClick(item)}
                  selected={currentRegion?.id === item?.id}
                >
                  <ListItemText primary={item?.name} />
                  <Chip
                    label={item?.count}
                    size='small'
                    color={pov === 'customer' ? 'primary' : 'success'}
                    variant='outlined'
                  />
                </ListItemButton>
              ))}
          </List>
        </>
      )}
    </>
  );
};

export default ContractsMenu;
