import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Container, ListGroup, Row } from 'react-bootstrap';
import ToolsPanel from '../components/ToolsPanel';
import {
  FaBook,
  FaBookMedical,
  FaFingerprint,
  FaHandshake,
  FaList,
  FaMapMarkedAlt,
  FaRegCommentDots,
  FaRoute,
  FaRubleSign,
  FaShapes,
  FaTasks,
  FaUsersCog,
} from 'react-icons/fa';
import { setShowLeftMenu } from '../slices/authSlice';

const ProviderHomeScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { theme, currentGroup, currentProvider } = useSelector(
    (state) => state.auth
  );

  const buttons = [];

  const handleClickListItem = (to, options) => {
    if (options?.state) {
      navigate(to, { state: { ...options.state } });
    } else {
      navigate(to);
    }
    if (options?.showDrawer) {
      dispatch(setShowLeftMenu(true));
    }
  };

  const faProps = {
    size: 35,
    color: theme === 'dark' ? 'gray' : 'black',
    className: 'me-2',
  };

  const buttonList = [
    {
      role: 'am',
      name: 'Задачи',
      icon: <FaTasks {...faProps} />,
      onClick: () => handleClickListItem('/provider/tasks'),
    },
    // {
    //   role: 'am',
    //   name: 'Заявочник',
    //   icon: <FaBookMedical {...faProps} />,
    //   onClick: () =>
    //     handleClickListItem(`/provider/orders/edit/${'new'}`, {
    //       state: { backUrl: '/provider' },
    //     }),
    // },
    {
      role: 'am',
      name: 'Журнал заявок',
      icon: <FaBook {...faProps} />,
      onClick: () => handleClickListItem('/provider/orders'),
    },
    {
      role: 'a',
      name: 'Новости и объявления',
      icon: <FaRegCommentDots {...faProps} />,
      onClick: () => handleClickListItem('/provider/news'),
    },
    {
      role: 'am',
      name: 'Покупатели',
      icon: <FaHandshake {...faProps} />,
      onClick: () => handleClickListItem('/provider/contracts'),
    },
    {
      role: 'a',
      name: 'Группы покупателей',
      icon: <FaMapMarkedAlt {...faProps} />,
      onClick: () => handleClickListItem('/provider/regions'),
    },
    {
      role: 'a',
      name: 'Сотрудники',
      icon: <FaUsersCog {...faProps} />,
      onClick: () => handleClickListItem('/provider/users'),
    },
    {
      role: 'am',
      name: 'Реквизиты',
      icon: <FaFingerprint {...faProps} />,
      onClick: () => handleClickListItem('/provider/details'),
    },
    {
      role: 'a',
      name: 'Типы цен',
      icon: <FaRubleSign {...faProps} />,
      onClick: () => handleClickListItem('/provider/prices'),
    },
    {
      role: 'am',
      name: 'Номенклатура и цены',
      icon: <FaShapes {...faProps} />,
      onClick: () =>
        handleClickListItem(
          `/provider/products/${currentGroup?._id ? currentGroup._id : ''}`,
          { showDrawer: true }
        ),
    },
    {
      role: 'a',
      name: 'Редактор меню',
      icon: <FaList {...faProps} />,
      onClick: () => handleClickListItem('/provider/groups'),
    },
  ];

  return (
    <>
      <ToolsPanel
        buttons={buttons}
        title={`Домашняя страница поставщика`}
        isLoading={false}
      />
      <Container>
        <Row>
          <Col>
            <ListGroup>
              {buttonList
                .filter(
                  (item) => item.role.indexOf(currentProvider.role) !== -1
                )
                .map((item) => (
                  <ListGroup.Item
                    key={item.name}
                    variant={theme}
                    action
                    onClick={item.onClick}
                  >
                    {item.icon}
                    {item.name}
                  </ListGroup.Item>
                ))}
            </ListGroup>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ProviderHomeScreen;
