import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { addToCart, removeFromCart, setCartParams } from '../slices/cartSlice';
import ToolsPanel from '../components/ToolsPanel/ToolsPanel';
import DataTable from '../components/DataTable/DataTable';
import { colsWidth } from '../utils/authUtils.js';
import { Accordion, Col, Container, Row } from 'react-bootstrap';
import {
  useGetOrderByIdMutation,
  useSetOrderValueMutation,
} from '../slices/ordersApiSlice.js';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import OrderArchiveList from '../components/OrderArchiveList/OrderArchiveList.jsx';
import { getOrderHeaderString } from '../utils/gets.js';
import { FaCogs } from 'react-icons/fa';
import {
  setAnimateMenuIcon,
  setGridSettingMode,
  setSelectedProducts,
} from '../slices/authSlice.js';
import CustomerToolbar from '../components/DataTableToolbar/CustomerToolbar/CustomerToolbar.jsx';

const CartScreenDataGrid = () => {
  const { orderId } = useParams();
  const { search } = useLocation();
  const sp = new URLSearchParams(search);
  const back = sp.get('back');

  // const { search } = useLocation();
  // const sp = new URLSearchParams(search);
  // const needRefetch = sp.get('refetch') ? true : false;
  // console.log('needRefetch', needRefetch);
  // const [skipLoadCartFromServer, setSkipLoadCartFromServer] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart);
  const [orderItems, setOrderItems] = useState();
  const [orderParams, setOrderParams] = useState({});
  const {
    gridSettingMode,
    selectedProducts,
    currentCustomer,
    currentProvider,
    currentBreakpoint,
    showUnitColumn,
    showPrice,
    showReturnColumn,
    theme,
    showMultipleColumn,
  } = useSelector((state) => state.auth);
  const { cartItems } = cart;

  const [getOrderById] = useGetOrderByIdMutation();
  const [deleteOrder] = useSetOrderValueMutation();

  const checkOutHandler = () => {
    navigate('/login?redirect=/shipping');
  };

  const fetchData = async () => {
    try {
      const data = {
        orderId,
        customerId: currentCustomer?._id,
        providerId: currentProvider?._id,
      };
      // console.log('fetchData.payload', data);
      const result = await getOrderById(data).unwrap();
      const { doc } = result;

      //сохраняем результат запроса в состояния
      setOrderParams({
        provider: doc?.provider,
        customer: doc?.customer,
        _id: doc?._id,
        createdAt: doc?.createdAt,
        isCanceled: doc?.isCanceled,
        isDelivered: doc?.isDelivered,
      });

      //сохраняем результат запроса в состояния
      if (Array.isArray(doc?.orderItems)) {
        setOrderItems(doc?.orderItems);
      } else {
        setOrderItems([]);
      }
    } catch (error) {
      toast.error(error?.data?.message || error?.error);
    }
  };

  const rows = [];
  if (Array.isArray(orderItems)) {
    for (let index = 0; index < orderItems.length; index++) {
      const item = orderItems[index];
      let multiple = item?.multiple ? item?.multiple : '';

      let name = item?.shortName || item?.name;
      if (!showUnitColumn && !showMultipleColumn) {
        name = `${name} ( ${multiple} ${item?.unit} )`;
      } else if (!showUnitColumn && showMultipleColumn) {
        name = `${name} ( ${item?.unit} )`;
      } else if (showUnitColumn && !showMultipleColumn) {
        name = `${name}  ${multiple ? '(' + multiple + ' в уп.)' : ''}`;
      }

      rows.push({
        ...item,
        id: item._id,
        qty: item?.qty === 0 ? '' : item?.qty,
        ret: item?.ret === 0 ? '' : item?.ret,
        name,
      });
    }
  }

  // if (orderParams) console.log(orderParams);

  useEffect(() => {
    if (!orderId) {
      //если в URL нет идентификатора заявки, значит показываем содержимое корзины
      //фильтруем корзину по текущему поставщику и текущему покупателю
      const rows = cartItems
        .filter(
          (item) =>
            item.providerId === currentProvider._id &&
            item.customerId === currentCustomer._id
        )
        .map((item) => ({
          ...item,
          id: item._id,
          qty: item?.qty === 0 ? '' : item?.qty,
          ret: item?.ret === 0 ? '' : item?.ret,
        })); //добавляем поле id для grid (требование разработчика)

      setOrderItems(rows);
    } else {
      //если в URL есть идентификатор заявки, делаем запрос на сервер
      fetchData();
    }
    // eslint-disable-next-line
  }, [orderId]);

  // console.log('orderParams', orderParams);
  // console.log('orderItems', orderItems);

  useEffect(() => {
    if (Array.isArray(orderItems) && orderItems.length === 0) {
      dispatch(setAnimateMenuIcon(true));
    }
  }, [orderItems, dispatch]);

  const setRowSelectionModel = (data) => {
    dispatch(setSelectedProducts(data));
  };

  const columns = [
    { field: 'id', headerName: '№', width: 50, hide: true },
    { field: 'promo', headerName: 'promo', width: 10, hide: true },
    {
      field: 'name',
      headerName: 'Название',
      flex: 1,
      editable: false,
      cellClassName: 'grid-cell-name',
      // cellClassName: (params) => {
      //   if (params.value == null) {
      //     return '';
      //   }

      //   return clsx('super-app', {
      //     negative: params.value < 0,
      //     positive: params.value > 0,
      //   });
      // },
    },
    {
      field: 'multiple',
      headerName: 'в уп',
      width: 50,
      editable: false,
      sortable: false,
    },
    {
      field: 'price',
      headerName: 'Цена',
      width: 80,
      editable: false,
      headerClassName: 'grid-header-price',
      sortable: false,
    },
    {
      field: 'unit',
      headerName: 'Ед.',
      type: 'string',
      width: colsWidth.unit,
      editable: false,
      sortable: false,
      align: 'left',
      headerAlign: 'left',
    },
    {
      field: 'qty',
      type: 'number',
      headerName: 'Заявка',
      width: colsWidth.qty,
      editable: !orderId,
      //sortable: false,
      headerAlign: 'left',
      headerClassName: 'grid-header-order',
      cellClassName: 'grid-cell-order',
    },
  ];

  if (currentProvider?.canReturn) {
    columns.push({
      field: 'ret',
      type: 'number',
      headerName: 'Возврат',
      width: colsWidth.qty,
      editable: true,
      //sortable: false,
      headerAlign: 'left',
      headerClassName: 'grid-header-order',
      cellClassName: 'grid-cell-return',
    });
  }

  if (['xl', 'xxl'].includes(currentBreakpoint)) {
    columns.push({
      field: 'info',
      type: 'string',
      headerName: 'Информация',
      flex: 0.5,
      editable: false,
      headerAlign: 'left',
      // headerClassName: 'grid-header-order',
      // cellClassName: 'grid-cell-return',
    });
  }

  const visibilityColumns = {};
  visibilityColumns.parentCode = false;
  visibilityColumns.promo = false;
  visibilityColumns.price = showPrice;
  visibilityColumns.ret = showReturnColumn && currentProvider?.canReturn;
  visibilityColumns.unit = showUnitColumn;
  visibilityColumns.multiple = showMultipleColumn;

  const handleOnSelectRow = (data) => {
    //console.log('handleOnSelectRow', data);
    // if (data?.row) {
    //   dispatch(
    //     setSelectedVendor({
    //       id: data.row.id,
    //       name: data.row.name,
    //       defaultPrice: data.row.defaultPrice,
    //     })
    //   );
    //   if (selectedCustomer) {
    //     navigate(`/customers/${selectedCustomer.id}/${data.row.id}`);
    //   }
    // }
  };

  const handleOnUpdateRow = (data) => {
    // const price = data.newRow?.price || 0;
    // const multiple = data.newRow?.multiple || 0;
    let qty = data.newRow?.qty || 0;
    if (qty !== 0) {
      qty = qty < 0 ? qty * -1 : qty; //Math.abs(qty)
      // if (multiple > 0) {
      //   if (qty / multiple >= 0) {
      //     qty = Math.floor(qty / multiple) * multiple;
      //   } else {
      //     qty = Math.ceil(qty / multiple) * multiple;
      //   }
      //   qty = multiple > qty ? multiple : qty;
      // }
    }
    let ret = data.newRow?.ret || 0;
    if (ret !== 0) {
      ret = ret < 0 ? ret * -1 : ret; //Math.abs(ret)
    }

    if (qty === 0 && ret === 0) {
      dispatch(
        removeFromCart({
          id: data.newRow.id,
          providerId: currentProvider._id,
          customerId: currentCustomer._id,
        })
      );
    } else {
      dispatch(
        addToCart({
          ...data.newRow,
          _id: data.newRow.id,
          providerName: currentProvider.name,
          providerId: currentProvider._id,
          customerName: currentCustomer.name,
          customerId: currentCustomer._id,
          qty,
          ret,
        })
      );
    }
  };

  // const handleClickShare = () => {
  //   navigate(`/pdf/${orderId || 'cart'}`);
  // };

  const handleClickShowStuff = () => {
    dispatch(setGridSettingMode(!gridSettingMode));
    if (!gridSettingMode) {
      setRowSelectionModel([]);
    }
  };

  const loadOrderItems = (copy = false) => {
    if (
      orderParams?.provider?._id &&
      orderParams?.customer?._id &&
      orderParams?._id &&
      orderItems.length > 0
    ) {
      const payload = {
        providerId: orderParams.provider?._id,
        customerId: orderParams.customer?._id,
        params: { orderId: orderParams._id, createdAt: orderParams?.createdAt },
      };

      if (!copy) {
        dispatch(setCartParams(payload));
      }

      for (let index = 0; index < orderItems.length; index++) {
        const row = orderItems[index];
        dispatch(
          addToCart({
            name: row.name,
            unit: row.unit,
            id: row.product._id,
            parentCode: row.product?.parentCode,
            image: row.image,
            description: row.product?.description,
            code: row.code,
            price: row.price,
            qty: row.qty,
            ret: row.ret === 0 ? '' : row.ret,
            _id: row.product._id,
            multiple: row?.multiple,
            providerName: currentProvider.name,
            providerId: currentProvider._id,
            customerName: currentCustomer.name,
            customerId: currentCustomer._id,
          })
        );
      }
      navigate('/customer/cart');
    }
  };

  const handleClickEditOrder = () => {
    loadOrderItems();
  };

  const handleClickCopyOrder = () => {
    loadOrderItems(true);
  };

  const handleClickCopyFromCloud = () => {
    if (Array.isArray(orderItems)) {
      for (let index = 0; index < orderItems.length; index++) {
        const row = orderItems[index];
        dispatch(
          addToCart({
            name: row.name,
            unit: row.unit,
            id: row._id,
            parentCode: row?.parentCode,
            image: row.image,
            description: row?.description,
            code: row.code,
            price: row.price,
            qty: row.qty,
            ret: row.ret,
            _id: row._id,
            multiple: row?.multiple,
            providerName: currentProvider.name,
            providerId: currentProvider._id,
            customerName: currentCustomer.name,
            customerId: currentCustomer._id,
          })
        );
      }
      navigate('/customer/cart');
    }
  };

  const handleClickDeleteOrder = async () => {
    if (orderId) {
      try {
        const res = await deleteOrder({
          orderId,
          key: 'isCanceled',
          value: true,
        }).unwrap();
        fetchData();
      } catch (error) {
        toast.error(error?.data?.message || error?.error);
      }
    }
  };

  const handleClickRestoreOrder = async () => {
    if (orderId) {
      try {
        await deleteOrder({
          orderId,
          key: 'isCanceled',
          value: false,
        }).unwrap();
        fetchData();
      } catch (error) {
        toast.error(error?.data?.message || error?.error);
      }
    }
  };
  const buttons = [];

  if (orderId === '' || !orderId) {
    buttons.push({
      size: 'normal',
      name: currentBreakpoint === 'xs' ? 'Отправить' : 'Отправить заявку',
      action: checkOutHandler,
      disabled: orderItems.length === 0,
      className: 'btn-block',
      variant: orderItems.length === 0 ? 'outline-success' : 'success',
    });
  }

  buttons.push({
    className: `bg-transparent ${gridSettingMode && 'border-success'}`,
    name: 'Инструменты',
    type: 'component',
    action: handleClickShowStuff,
    component: (props) => {
      return <FaCogs size={22} color={theme === 'dark' ? 'white' : 'black'} />;
    },
  });

  let title = 'Новая заявка';

  if (orderParams && orderParams?.createdAt) {
    title = `${getOrderHeaderString(orderParams, 'Заявка от')} ${
      orderParams?.isCanceled ? ' (отменена)' : ''
    }`;
  }
  if (orderParams && orderParams?._id === 'cart') {
    title = 'Заявка в облаке';
  }

  // console.log(orderParams);

  let submenu = [];
  // if (orderParams?.isDelivered) {
  //   submenu = [
  //     {
  //       name: 'Скопировать',
  //       action: handleClickCopyOrder,
  //       size: 'sm',
  //     },
  //   ];
  // } else {
  // const docDate = DateTime.fromISO(orderParams.createdAt).startOf('day');
  // const currentDate = DateTime.local().startOf('day');
  // const isDateEqual = docDate.ts === currentDate.ts;
  // Сравниваем даты
  // if (!orderParams?.isDelivered) {

  // }
  // else {
  //   submenu = [
  //     {
  //       name: 'Скопировать',
  //       action: handleClickCopyOrder,
  //       size: 'sm',
  //     },
  //   ];
  // }
  if (orderParams?._id === 'cart') {
    submenu = [
      {
        name: 'Загрузить из облака',
        action: handleClickCopyFromCloud,
        size: 'sm',
      },
    ];
  } else {
    submenu = [
      {
        name: 'Редактировать',
        action: handleClickEditOrder,
        hide: orderParams?.isCanceled || orderParams.isDelivered,
        size: 'sm',
      },
      {
        name: 'Скопировать',
        action: handleClickCopyOrder,
        size: 'sm',
      },
      {
        name: 'Восстановить',
        action: handleClickRestoreOrder,
        hide: !orderParams?.isCanceled,
        size: 'sm',
      },
      {
        name: 'Удалить',
        action: handleClickDeleteOrder,
        hide: orderParams?.isCanceled || orderParams.isDelivered,
        size: 'sm',
      },
    ];
  }

  // console.log(submenu);

  return (
    <>
      <ToolsPanel
        buttons={buttons}
        title={title}
        isLoading={false}
        backURL={back ? back : '/customer/cart'}
        // submenu={submenu}
      />
      <Container>
        <Row>
          <Col md={12} lg={8} className='mb-3'>
            {gridSettingMode && <CustomerToolbar buttons={submenu} />}
            {Array.isArray(orderItems) && (
              <DataTable
                rows={rows}
                columns={columns}
                columnVisible={{ id: false, ...visibilityColumns }}
                onSelectRow={handleOnSelectRow}
                onUpdateRow={handleOnUpdateRow}
                setRowSelectionModel={setRowSelectionModel}
                rowSelectionModel={selectedProducts}
                checkboxSelection={false}
              />
            )}
          </Col>
          <Col md={12} lg={4}>
            <Accordion defaultActiveKey='0'>
              <Accordion.Item
                eventKey={`${
                  currentBreakpoint === 'xs' ||
                  currentBreakpoint === 'sm' ||
                  currentBreakpoint === 'md'
                    ? '10'
                    : '0'
                }`}
                style={{ backgroundColor: 'none !important' }}
              >
                <Accordion.Header>Архив заявок</Accordion.Header>
                <Accordion.Body>
                  <OrderArchiveList />
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default CartScreenDataGrid;
