import * as React from "react";
const AvatarProviderColored = (props) => (
  <svg
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 297 297"
    style={{
      enableBackground: "new 0 0 297 297",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <g>
      <g>
        <g>
          <g>
            <circle
              style={{
                fill: "#E3E5E4",
              }}
              cx={148.5}
              cy={148.5}
              r={148.5}
            />
          </g>
        </g>
      </g>
      <path
        style={{
          fill: "#929DA5",
        }}
        d="M217.893,120.46L49.5,198l99,99c66.54,0,122.855-43.765,141.739-104.079L217.893,120.46z"
      />
      <g>
        <path
          style={{
            fill: "#C63C22",
          }}
          d="M249.688,198h-72.395v-82.5h28.48c6.549,0,12.536,3.7,15.465,9.558l11.721,23.442l15.036,11.434 c5.105,3.149,8.213,8.718,8.213,14.716v16.83C256.208,195.081,253.289,198,249.688,198z"
        />
      </g>
      <g>
        <path
          style={{
            fill: "#F86E51",
          }}
          d="M181.5,198h-132V95.166c0-6.996,5.671-12.666,12.667-12.666h106.667 c6.996,0,12.667,5.671,12.667,12.666V198H181.5z"
        />
      </g>
      <g>
        <path
          style={{
            fill: "#E25D47",
          }}
          d="M181.5,95.167c0-6.996-5.671-12.667-12.667-12.667h-20.667V198H181.5V95.167z"
        />
      </g>
      <g>
        <path
          style={{
            fill: "#D0D5D9",
          }}
          d="M212.597,126.195L224,149h-33v-27h14.81C208.684,122,211.312,123.624,212.597,126.195z"
        />
      </g>
      <g>
        <circle
          style={{
            fill: "#32373B",
          }}
          cx={89.291}
          cy={198}
          r={23.291}
        />
      </g>
      <g>
        <circle
          style={{
            fill: "#484F54",
          }}
          cx={89.291}
          cy={198}
          r={9}
        />
      </g>
      <g>
        <circle
          style={{
            fill: "#32373B",
          }}
          cx={221.291}
          cy={198}
          r={23.291}
        />
      </g>
      <g>
        <circle
          style={{
            fill: "#484F54",
          }}
          cx={221.292}
          cy={198}
          r={9}
        />
      </g>
      <g>
        <circle
          style={{
            fill: "#32373B",
          }}
          cx={116.5}
          cy={99}
          r={39}
        />
      </g>
      <g>
        <circle
          style={{
            fill: "#ECF0F1",
          }}
          cx={116.5}
          cy={99}
          r={31.318}
        />
      </g>
      <g>
        <path
          style={{
            fill: "#32373B",
          }}
          d="M128.907,112.546c-0.773,0-1.552-0.255-2.2-0.779l-12.409-10.046 C113.477,101.056,113,100.056,113,99V79.5c0-1.933,1.567-3.5,3.5-3.5s3.5,1.567,3.5,3.5v17.83l11.111,8.995 c1.503,1.217,1.734,3.421,0.519,4.923C130.938,112.103,129.927,112.546,128.907,112.546z"
        />
      </g>
    </g>
  </svg>
);
export default AvatarProviderColored;
