import React, { useState } from 'react';
import { Button, Col, Form, ListGroup, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Accordion from 'react-bootstrap/Accordion';
import { FaMarker, FaPhotoVideo, FaRegTimesCircle } from 'react-icons/fa';
import { Badge, LinearProgress } from '@mui/material';
import ZoomableImage from '../../Image/ZoomableImage';
import ProgressBar from 'react-bootstrap/ProgressBar';

const TaskItem = ({
  userId,
  item,
  theme,
  uploadImage,
  onChangeHandler,
  isLoadingUpdate,
  onClick,
}) => {
  const [comment, setComment] = useState(item?.comment || '');
  const [photos, setPhotos] = useState(item?.photos || []);

  // console.log('item', item);
  const setValuesHandler = (value, controlId) => {
    switch (controlId) {
      case 'comment':
        setComment(value);
        break;
      case 'photos':
        // const updatedPhotos = photos.filter((item) => item !== value);
        if (value) {
          setPhotos([...photos, value]);
        }
        break;
      default:
        break;
    }
  };

  const themeStyle = {
    backgroundColor: theme === 'dark' ? '#212529' : 'white',
    borderColor: theme === 'dark' ? '#212529' : '#b5c0c1',
    color: theme === 'dark' ? 'white' : 'black',
  };

  const uploadFileHandler = async (e) => {
    const formData = new FormData();
    formData.append('image', e.target.files[0]);
    console.log('formData', formData);

    try {
      const res = await uploadImage(formData).unwrap();
      console.log(res);
      setValuesHandler(res.image, 'photos');
    } catch (error) {
      toast.error(error?.data.message || error?.message || error.error);
      return false;
    }
  };

  if (item?.multitask) {
    let progress;
    if (
      'count' in item &&
      'done' in item &&
      typeof item.count === 'number' &&
      typeof item.done === 'number' &&
      item.count > 0
    ) {
      progress = (item.done / item.count) * 100;
    }
    // console.log('progress', progress);

    return (
      <ListGroup.Item
        key={item._id}
        variant={theme}
        as='li'
        onClick={() => onClick(item)}
        className={`${progress && 'pb-0'}`}
      >
        <span className='d-flex justify-content-between align-items-start'>
          <Col xs={11} style={{ lineHeight: 1.2 }}>
            <Row>
              <Col xs={12} sm={12} md={12}>
                <div className='me-auto'>
                  <div className='fw-bold'>{item?.title}</div>
                  {item?.description}
                </div>
              </Col>
            </Row>
          </Col>
          <Col xs={1} className='d-flex justify-content-end'>
            <span className='d-flex   flex-wrap justify-content-end gap-1'>
              {item?.photoRequired ? <FaPhotoVideo size={20} /> : null}
              {item?.commentRequired ? <FaMarker size={20} /> : null}
            </span>
          </Col>
        </span>
        {progress ? (
          <ProgressBar
            className='mt-2 mb-2'
            now={progress}
            label={`${progress}%`}
          />
        ) : null}
      </ListGroup.Item>
    );
  }

  return (
    <ListGroup.Item
      key={item._id}
      variant={theme}
      as='li'
      className='d-flex justify-content-between align-items-start'
    >
      {item?.commentRequired || item?.photoRequired ? (
        <>
          <Accordion className='w-100' bsPrefix='task-accordion'>
            <Accordion.Item eventKey='0'>
              <Accordion.Header>
                <Col xs={11}>
                  <Row>
                    <Col xs={12} sm={12} md={6}>
                      <div className='me-auto'>
                        <div className='fw-bold'>{item?.title}</div>
                        {item?.description}
                      </div>
                    </Col>
                    <Col xs={12} sm={12} md={6}>
                      {(userId === 'all' ||
                        userId === 'undefined' ||
                        userId === undefined) &&
                        item?.user?.name && (
                          <div className='me-auto'>
                            <strong>Исполнитель: </strong>
                            {item?.user?.name}
                          </div>
                        )}
                      {item?.customer?.name && (
                        <div className='me-auto'>
                          <strong>Покупатель: </strong>
                          {item?.customer?.name}
                        </div>
                      )}
                    </Col>
                  </Row>
                </Col>
                <Col xs={1} className='d-flex justify-content-end'>
                  <span className='d-flex   flex-wrap justify-content-end gap-1'>
                    {/* <Stack direction='horizontal' gap={1}> */}
                    {item?.photoRequired && <FaPhotoVideo size={20} />}
                    {item?.commentRequired && <FaMarker size={20} />}

                    {/* </Stack> */}
                  </span>
                </Col>
              </Accordion.Header>
              <Accordion.Body className='mt-2 p-2'>
                <Form className='me-1'>
                  {item?.photoRequired && (
                    <Form.Group as={Row} controlId={'Фото'}>
                      <Form.Label column sm='12' className='pb-0'>
                        Изображение:
                      </Form.Label>
                      <Col sm='12' className='mt-2'>
                        <Row>
                          {photos.map((photo, index) => (
                            <Col
                              key={index}
                              xs={6}
                              sm={6}
                              md={4}
                              lg={3}
                              xl={2}
                              className='mt-3'
                            >
                              <Badge
                                badgeContent={
                                  <FaRegTimesCircle
                                    size={24}
                                    color='red'
                                    onClick={() =>
                                      setPhotos(
                                        photos.filter((item) => item !== photo)
                                      )
                                    }
                                  />
                                }
                              >
                                <ZoomableImage
                                  zoom={true}
                                  src={photo}
                                  className='w-100'
                                />
                              </Badge>
                            </Col>
                          ))}
                        </Row>
                      </Col>
                      <Col sm='5' className='mt-2'>
                        <Form.Control
                          required={item?.photoRequired}
                          disabled={false}
                          style={themeStyle}
                          type='file'
                          label='Файл'
                          name={'photos'}
                          onChange={uploadFileHandler}
                        ></Form.Control>
                      </Col>
                    </Form.Group>
                  )}
                  {item?.commentRequired && (
                    <Form.Group as={Row} controlId={'comment'} className='my-2'>
                      <Form.Label column sm='3' className='pb-0'>
                        Ответ:
                      </Form.Label>

                      <Col sm={9}>
                        <Form.Control
                          //   isInvalid={item?.required && !item['value']}
                          //   required={item?.required}
                          //   disabled={false}
                          required={item?.commentRequired}
                          style={themeStyle}
                          type={'text'}
                          placeholder={'Сообщение'}
                          value={comment || ''}
                          onChange={(e) =>
                            setValuesHandler(e.target.value, 'comment')
                          }
                        ></Form.Control>
                      </Col>
                    </Form.Group>
                  )}
                </Form>
                {(item?.photoRequired && photos?.length > 0) ||
                (item?.commentRequired && comment?.length > 0) ? (
                  <Button
                    type='button'
                    variant='primary'
                    className='my-2'
                    onClick={() =>
                      onChangeHandler({
                        taskId: item._id,
                        done: true,
                        photos,
                        comment,
                      })
                    }
                  >
                    Задание выполнено
                  </Button>
                ) : (
                  <Button
                    type='button'
                    variant='primary'
                    className='my-2'
                    disabled={true}
                  >
                    Для выполнения требуются данные
                  </Button>
                )}
                {isLoadingUpdate && <LinearProgress />}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </>
      ) : (
        <>
          <Col xs={11} style={{ lineHeight: 1.2 }}>
            <Row>
              <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                <div className='me-auto'>
                  <div className='fw-bold'>{item?.title}</div>
                  {item?.description}
                </div>
              </Col>
              <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                {!userId && item?.user?.name && (
                  <div className='me-auto'>
                    <strong>Исполнитель: </strong>
                    {item?.user?.name}
                  </div>
                )}
                {item?.customer?.name && (
                  <div className='me-auto'>
                    <strong>Покупатель: </strong>
                    {item?.customer?.name}
                  </div>
                )}
              </Col>
            </Row>
          </Col>
          <Col xs={1} className='d-flex justify-content-end'>
            <Form.Check // prettier-ignore
              checked={item?.checked}
              type='switch'
              // label={item.name}
              onChange={() => onChangeHandler({ taskId: item._id })}
            />
          </Col>
        </>
      )}
    </ListGroup.Item>
  );
};

export default TaskItem;
