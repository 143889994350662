import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import SelectUnit from '../../SelectUnit/SelectUnit';
import { Button, CloseButton, Form, InputGroup, Stack } from 'react-bootstrap';
import { FaTimes } from 'react-icons/fa';

const defaultOptions = { show: false, title: '' };

const InputSelectable = ({
  style,
  value,
  unitType,
  keyName = 'name',
  placeholder,
  type,
  onChange,
  onClear,
  showClearButton = false,
}) => {
  const [options, setOptions] = useState(defaultOptions);
  const [val, setVal] = useState(value);
  const { theme, currentProvider, pov } = useSelector((state) => state.auth);

  const themeStyle = {
    backgroundColor: theme === 'dark' ? '#212529' : 'white',
    borderColor: theme === 'dark' ? '#212529' : '#b5c0c1',
    color: theme === 'dark' ? 'white' : 'black',
  };

  const handleSetSelected = (selected) => {
    if (selected === false) {
      //
    } else {
      onChange(selected);
      setVal(selected);
    }
    setOptions(defaultOptions);
  };

  return (
    <>
      {showClearButton ? (
        <InputGroup>
          <Form.Control
            style={style || themeStyle}
            type={type}
            placeholder={placeholder}
            value={val[keyName] ? val[keyName] : ''}
            onClick={() => setOptions({ show: true, title: unitType })}
            readOnly={true}
          />
          <Button
            variant='outline-secondary'
            // id='button-addon2'
            onClick={onClear}
          >
            <FaTimes />
          </Button>
        </InputGroup>
      ) : (
        <Form.Control
          style={style || themeStyle}
          type={type}
          placeholder={placeholder}
          value={val[keyName] ? val[keyName] : ''}
          onClick={() => setOptions({ show: true, title: unitType })}
          readOnly={true}
        ></Form.Control>
      )}

      {options?.show && (
        <SelectUnit
          show={options.show}
          setShow={handleSetSelected}
          setSelected={handleSetSelected}
          payload={{ providerId: currentProvider?._id, pov }}
          title={options?.title}
        />
      )}
    </>
  );
};

export default InputSelectable;
