import { createSlice } from '@reduxjs/toolkit';
import { updateCart } from '../utils/cartUtils';

const initialState = localStorage.getItem('cart')
	? JSON.parse(localStorage.getItem('cart'))
	: {
		cartItems: [],
		cartParams: {},
		shippingAddress: '',
		paymentMethod: 'По договору',
		stat: [], totalPrice: 0, totalReturn: 0,
		comment: '', contacts: ''
	};

const cartSlice = createSlice({
	name: 'cart',
	initialState,
	reducers: {
		setCartParams: (state, action) => {
			const { providerId, customerId, params } = action.payload;
			const key = `${customerId}-${providerId}`;
			state.cartParams[key] = {
				...params
			}
			// let filtered = [];
			// if (state.cartParams) {
			// 	filtered = state.cartParams.filter(item => item.providerId !== providerId && item.customerId !== customerId);
			// }
			// state.cartParams = [...filtered, { providerId, customerId, orderId, createdAt }];
			return state;
		},
		unsetCartParams: (state, action) => {
			const { providerId, customerId } = action.payload;
			const key = `${customerId}-${providerId}`;
			// state.cartItems = state.cartParams.filter(item => item.providerId !== providerId && item.customerId !== customerId);
			state.cartParams[key] = {}
			return state;
		},
		addToCart: (state, action) => {
			const item = action.payload;
			const { providerId, customerId } = item;
			const existItem = state.cartItems.filter((x) =>
				x.providerId === providerId &&
				x.customerId === customerId).find(x => x._id === item._id);

			if (existItem) {
				state.cartItems = state.cartItems
					.map(x =>
						x._id === item._id
							&& x.providerId === providerId
							&& x.customerId === customerId
							? { ...x, qty: item?.qty || '', ret: item?.ret || '' }
							: x);
			} else {
				state.cartItems = [...state.cartItems, item];
			}

			return updateCart(state);
		},
		removeFromCart: (state, action) => {
			const { id: itemId, providerId, customerId } = action.payload;

			state.cartItems = state.cartItems.filter((item) => {
				if (item.providerId !== providerId) {
					return true
				}
				if (item.customerId !== customerId) {
					return true
				}
				if (item._id !== itemId) {
					return true
				}
				return false
			});

			return updateCart(state);
		},
		saveShippingAddress: (state, action) => {
			state.shippingAddress = action.payload;
			return updateCart(state);
		},
		saveComment: (state, action) => {
			state.comment = action.payload;
			return updateCart(state);
		},
		saveContacts: (state, action) => {
			state.contacts = action.payload;
			return updateCart(state);
		},
		savePaymentMethod: (state, action) => {
			state.paymentMethod = action.payload;
			return updateCart(state);
		},
		clearCartItems: (state, action) => {
			const { providerId, customerId } = action.payload;
			state.cartItems = state.cartItems.filter((item) => item.providerId !== providerId && item.customerId !== customerId);
			return updateCart(state);
		},
		replaceCartItems: (state, action) => {
			const { providerId, customerId, cartItems, providerName, customerName } = action.payload;
			const filteredCartItems = state.cartItems.filter((item) => item.providerId !== providerId && item.customerId !== customerId);
			state.cartItems = [...filteredCartItems, ...cartItems.map((item) => ({ ...item, providerId, customerId, providerName, customerName }))];
			return updateCart(state);
		},
		resetCart: (state) => (state = initialState)
	}
});

export const { replaceCartItems, unsetCartParams, setCartParams, resetCart, saveComment, saveContacts, addToCart, removeFromCart, saveShippingAddress, savePaymentMethod, clearCartItems } = cartSlice.actions; //экспорт для вызова в компонентах
export default cartSlice.reducer; //экспорт для добавления в store
