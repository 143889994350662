import { useDispatch, useSelector } from 'react-redux';
import { Col, Container, ListGroup, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import {
  logout,
  setCurrentCustomer,
  setCurrentProvider,
  setPov,
  setPriceEditMode,
  setShowLeftMenu,
} from '../slices/authSlice.js';
import { useCheckUserExistMutation } from '../slices/usersApiSlice.js';
import { useEffect } from 'react';

const StartScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { theme, userInfo, currentRoute } = useSelector((state) => state.auth);
  const [checkUserExist] = useCheckUserExistMutation();

  const selectHandle = async (unitName) => {
    let result;
    if (userInfo?._id) {
      //если пользователь залогинен либо в localstorage остались какие-то отпечатки прошлого сеанса
      try {
        result = await checkUserExist({
          userId: userInfo?._id,
          pov: unitName,
        }).unwrap();
        // console.log('result of check', result);
      } catch (error) {
        // console.log('result of check = error:', error);
        //в LocalStorage есть данные о залогинном пользователе, однако
        //запрос на сервер показал что такого пользователя не существует.
        dispatch(logout());
        navigate(`/login?redirect=${unitName}`);
        return;
        // navigate(`/login?redirect=/${unitName}/select/${unitName}`);
      }
    } else {
      dispatch(logout());
      navigate(`/login?redirect=${unitName}`);
      return;
      // navigate(`/login?redirect=/${unitName}/select/${unitName}`);
    }

    dispatch(setPov(unitName));
    // console.log(result);
    if (unitName === 'customer') {
      if (result?.singleCustomer && result?.singleCustomer?._id) {
        // console.log('result.singleCustomer._id', result.singleCustomer._id);
        dispatch(setCurrentCustomer(result.singleCustomer));
        if (result?.singleProvider && result?.singleProvider?._id) {
          // console.log('result.singleProvider._id', result.singleProvider._id);
          dispatch(setCurrentProvider(result.singleProvider));
        } else {
          dispatch(setCurrentProvider(false));
        }
        // console.log(`/customer`);
        navigate(`/customer`);
      } else {
        dispatch(setCurrentCustomer(false));
        dispatch(setCurrentProvider(false));
        dispatch(setShowLeftMenu(true));
        if (currentRoute?._id) {
          navigate(`/customer/select/customer/${currentRoute?._id}`);
        } else {
          navigate(`/customer/select/customer/?back=/start`);
        }
      }
    } else if (unitName === 'provider') {
      dispatch(setCurrentCustomer(false));
      dispatch(setPriceEditMode(true));
      if (result?.singleProvider && result?.singleProvider?._id) {
        dispatch(setCurrentProvider(result.singleProvider));
        navigate(`/provider`);
      } else {
        dispatch(setCurrentProvider(false));
        navigate(`/provider/select/provider/?back=/start`);
      }
    } else {
      dispatch(setCurrentCustomer(false));
      dispatch(setCurrentProvider(false));
      navigate(`/${unitName}/select/${unitName}?back=/start`);
    }
  };

  useEffect(() => {
    dispatch(setShowLeftMenu(false));
    dispatch(setPov(null));
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Container className='d-flex justify-content-center align-items-center text-muted toast-container'>
        *Выберите свою роль
      </Container>

      <Container className='d-flex flex-column min-vh-80 justify-content-center align-items-center'>
        <Row>
          <Col>
            <ListGroup
              className='rounded '
              variant='flush'
              //style={{ backgroundColor: 'none !important' }}
            >
              <ListGroup.Item
                onClick={() => selectHandle('customer')}
                //className={`pb-0 bg-${theme === 'dark' ? 'black' : 'light'} `}
                variant={theme}
                //variant='primary'
                className='pb-0'
                style={{ cursor: 'pointer' }}
                //action
              >
                <h1>Покупатель</h1>
              </ListGroup.Item>
              <ListGroup.Item
                onClick={() => selectHandle('provider')}
                className={`pb-0`}
                variant={theme}
                style={{ cursor: 'pointer' }}
                //variant='info'
                //action
              >
                <h1>Поставщик</h1>
              </ListGroup.Item>
            </ListGroup>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default StartScreen;
