import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
  Stack,
} from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import Message from '../components/Message/Message';
import { toast } from 'react-toastify';

import SelectUnit from '../components/SelectUnit/SelectUnit';
import ToolsPanel from '../components/ToolsPanel';
import { useSelector } from 'react-redux';
import { LinearProgress } from '@mui/material';
import GroupListSimple from '../components/GroupList/GroupListSimple';
import { FaTrash } from 'react-icons/fa';
import {
  useCreateUpdateRegionMutation,
  useDeleteRegionMutation,
  useGetRegionDetailsQuery,
} from '../slices/regionsApiSlice';

const ProviderRegionEditScreen = () => {
  const { regionId } = useParams();
  const navigate = useNavigate();
  const [showSelectWindow, setShowSelectWindow] = useState(false);
  const [selectedUnit, setSelectedUnit] = useState();
  const [modalTitle, setModalTitle] = useState('');
  const [shortName, setShortName] = useState('');
  const [name, setName] = useState('');
  const [managers, setManagers] = useState([]);
  const { theme, currentBreakpoint, currentProvider } = useSelector(
    (state) => state.auth
  );

  useEffect(() => {
    if (selectedUnit) {
      // setManager(selectedUnit);
      setManagers([
        ...managers.filter((item) => item._id !== selectedUnit?._id),
        selectedUnit,
      ]);
      setSelectedUnit('');
      setModalTitle('');
      setShowSelectWindow(false);
    }
    // eslint-disable-next-line
  }, [selectedUnit]);

  const [deleteRegion] = useDeleteRegionMutation();
  const { data, refetch, isLoading, isError, error } = useGetRegionDetailsQuery(
    {
      regionId,
      providerId: currentProvider?._id,
    }
  );

  // console.log('ProviderRouteEditScreen', data);
  //console.log('managers', managers);

  useEffect(() => {
    if (data) {
      // setManager(data?.row?.manager);
      setName(data?.row?.name);
      setShortName(data?.row?.shortName);
      if (
        Array.isArray(data?.row?.managers) &&
        data?.row?.managers?.length > 0
      ) {
        setManagers(data?.row?.managers);
      }
    }
  }, [data]);

  const [createUpdate, { isLoading: loadingUpdate }] =
    useCreateUpdateRegionMutation();

  const updateHandler = async () => {
    const toSave = {
      regionId: regionId,
      providerId: currentProvider._id,
      // managerId: manager?._id,
      name,
      shortName,
      managers,
    };

    try {
      await createUpdate(toSave).unwrap();
      refetch();
      navigate('/provider/regions');
    } catch (error) {
      toast.error(error?.data?.message || error?.message || error.error);
    }
  };

  const submitHandler = (event) => {
    event.preventDefault();
    updateHandler();
  };

  const selectUnit = (title) => {
    setModalTitle(title);
    setShowSelectWindow(true);
  };

  const handleClickClose = () => {
    navigate('/provider/regions');
  };

  const handleClickDeleteManager = (payload) => {
    setManagers(managers.filter((manager) => manager._id !== payload._id));
  };

  const handlerClickDeleteRegion = async () => {
    if (window.confirm('Вы уверены, что хотите удалить регион?')) {
      try {
        await deleteRegion({
          regionId,
          providerId: currentProvider?._id,
        }).unwrap();
        //toast.success('Договор удалён');
        navigate('/provider/regions');
      } catch (error) {
        toast.error(error?.data?.message || error?.message || error.error);
      }
    }
  };

  const themeStyle = {
    backgroundColor: theme === 'dark' ? '#212529' : 'white',
    borderColor: theme === 'dark' ? '#212529' : '#b5c0c1',
    color: theme === 'dark' ? 'white' : 'black',
  };

  return (
    <>
      <ToolsPanel
        //buttons={buttons}
        backURL={'/provider/regions'}
        title={`Группа покупателей: ${name ? name : '(новая)'}`}
        isLoading={loadingUpdate}
      />
      {isLoading ? (
        <LinearProgress />
      ) : isError ? (
        <Message>{error?.data?.message || error.error}</Message>
      ) : (
        <Container>
          <Row>
            <Col sx={12} sm={12} md={12} lg={10} xxl={8}>
              <Form onSubmit={submitHandler}>
                <Card className=' bg-transparent p-3'>
                  <Form.Group controlId='name' className='my-2' as={Row}>
                    <Form.Label column sm='4' className='pb-0'>
                      Наименование
                    </Form.Label>
                    <Col sm='8'>
                      <Form.Control
                        style={themeStyle}
                        type='text'
                        placeholder='Наименование'
                        value={name || ''}
                        onChange={(e) => setName(e.target.value)}
                      ></Form.Control>
                    </Col>
                  </Form.Group>

                  <Form.Group controlId='shortname' className='my-2' as={Row}>
                    <Form.Label column sm='4' className='pb-0'>
                      Код группы
                    </Form.Label>
                    <Col sm='8'>
                      <Form.Control
                        style={themeStyle}
                        type='text'
                        placeholder='Символьный код'
                        value={shortName || ''}
                        onChange={(e) => setShortName(e.target.value)}
                      ></Form.Control>
                    </Col>
                  </Form.Group>
                  <Form.Group controlId='manager' className='my-2' as={Row}>
                    <Form.Label column sm='4' className='pb-0'>
                      Менеджеры
                    </Form.Label>
                    <Col sm='8'>
                      <GroupListSimple
                        rows={managers}
                        buttons={[
                          {
                            icon: <FaTrash />,
                            onClick: handleClickDeleteManager,
                          },
                        ]}
                      />
                      <span className='d-flex justify-content-end mt-1'>
                        <Button
                          variant='primary'
                          onClick={() => selectUnit('Сотрудники')}
                        >
                          Добавить менеджера
                        </Button>
                      </span>
                    </Col>
                  </Form.Group>
                </Card>
                <div className='d-flex justify-content-between'>
                  <Stack
                    direction='horizontal'
                    gap={3}
                    //class='justify-content-center'
                  >
                    <Button
                      size={
                        ['lg', 'xl', 'xxl'].includes(currentBreakpoint)
                          ? 'lg'
                          : 'sm'
                      }
                      type='submit'
                      variant='primary'
                      className='my-2'
                    >
                      Сохранить
                    </Button>
                    <Button
                      size={
                        ['lg', 'xl', 'xxl'].includes(currentBreakpoint)
                          ? 'lg'
                          : 'sm'
                      }
                      variant='primary'
                      className='my-2'
                      onClick={handleClickClose}
                    >
                      Закрыть
                    </Button>
                  </Stack>
                  <Button
                    size={
                      ['lg', 'xl', 'xxl'].includes(currentBreakpoint)
                        ? 'lg'
                        : 'sm'
                    }
                    variant='danger'
                    className='my-2 mx-2 opacity-50'
                    close='true'
                    onClick={handlerClickDeleteRegion}
                  >
                    Удалить
                  </Button>
                </div>
              </Form>
            </Col>
            <Col sx={12} sm={12} md={12} lg={6}></Col>
          </Row>
        </Container>
      )}

      <SelectUnit
        show={showSelectWindow}
        setShow={setShowSelectWindow}
        setSelected={setSelectedUnit}
        //rows={unitRows}
        payload={{ providerId: currentProvider?._id }}
        title={modalTitle}
        owner={currentProvider?._id}
      />
    </>
  );
};

export default ProviderRegionEditScreen;
