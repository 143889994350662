import React from 'react';
import { ListGroup, Stack, Row, Col } from 'react-bootstrap';
import {
  FaAngleDown,
  FaCheck,
  FaCheckDouble,
  FaCheckSquare,
  FaList,
  FaRegCheckSquare,
  FaRegSquare,
  FaTrash,
} from 'react-icons/fa';
import { useSelector } from 'react-redux';

const GroupListSimple = ({
  disableFieldName = 'active',
  action = true,
  emptyName,
  titleFieldName = 'name',
  secondParam,
  className,
  rows,
  onClickItem = () => {},
  onDeleteItem = () => {},
  variant = 'flush',
  currentId,
  arrayFieldName,
  buttons = [],
  onClickIcon,
  as = 'list',
  breakpoints = [6, 3, 3],
  orders = [],
  startListItem,
}) => {
  const { theme } = useSelector((state) => state.auth);

  // console.log(currentId);

  if (as === 'table' && orders.length > 0) {
    return (
      <>
        {Array.isArray(rows) &&
          rows.map((row, index) => (
            <ListGroup.Item
              as={'div'}
              active={row?._id === currentId}
              action={action}
              className={`p-0 list-group-item d-flex justify-content-between align-items-center ${
                className ? className : ''
              }`}
              variant={theme}
              key={row?._id ? row?._id : index}
            >
              {row && (
                <>
                  {orders.map((keyName, index) => (
                    <Col key={index} sm={breakpoints[index]}>
                      {typeof row[keyName] === 'boolean' ? (
                        row[keyName] ? (
                          <FaCheck />
                        ) : null
                      ) : (
                        row[keyName]
                      )}
                    </Col>
                  ))}
                </>
              )}
            </ListGroup.Item>
          ))}
      </>
    );
  }

  return (
    <>
      <ListGroup variant={variant}>
        {startListItem && (
          <ListGroup.Item
            as={'div'}
            active={currentId === startListItem?._id}
            // action={action}
            className={`p-0 list-group-item d-flex justify-content-between align-items-center ${
              className ? className : ''
            }`}
            variant={theme}
          >
            <span
              className='p-2 w-100 d-flex overflow-hidden'
              role={action ? 'button' : ''}
              onClick={() => onClickItem(startListItem, secondParam)}
            >
              {startListItem?.name}
            </span>
          </ListGroup.Item>
        )}
        {rows &&
          rows.map((group, index) => (
            <ListGroup.Item
              as={'div'}
              active={group?._id && group?._id === currentId}
              // action={action}
              className={`p-0 list-group-item d-flex justify-content-between align-items-center ${
                className ? className : ''
              }`}
              variant={theme}
              key={group?._id ? group?._id : index}
            >
              {'active' in group && !group.active ? (
                <>
                  <span className='p-2 w-100 d-flex overflow-hidden text-decoration-line-through'>
                    {group[titleFieldName] ? group[titleFieldName] : emptyName}
                  </span>
                  <span className='d-flex'>
                    <button
                      type='button'
                      className={`btn btn-sm btn-outline-primary me-2`}
                      onClick={() => onDeleteItem(group)}
                    >
                      <FaTrash />
                    </button>
                  </span>
                </>
              ) : (
                <span
                  className='p-2 w-100 d-flex overflow-hidden'
                  role={action ? 'button' : ''}
                  onClick={() => onClickItem(group, secondParam)}
                >
                  {group[titleFieldName] ? group[titleFieldName] : emptyName}
                </span>
              )}

              <span className='d-flex me-2'>
                {group?.count && <span className='d-flex'>{group.count}</span>}

                {arrayFieldName && Array.isArray(group[arrayFieldName]) && (
                  <>
                    {group[arrayFieldName].length === 1 ? (
                      <span className='d-flex  p-0 me-2'>
                        <FaCheck />
                      </span>
                    ) : group[arrayFieldName].length > 1 ? (
                      <span className='d-flex  p-0 me-2'>
                        <FaCheckDouble />
                      </span>
                    ) : null}
                  </>
                )}
                {Array.isArray(buttons) && buttons.length > 0 && (
                  <span className='d-flex  p-0'>
                    {buttons.map((button, index) => (
                      <span key={index}>
                        {button?.cond ? (
                          <span className='me-1'>
                            {group[button.cond.fieldName] ===
                            button.cond.fieldValue
                              ? button.cond.icon
                              : ''}
                          </span>
                        ) : (
                          <button
                            key={index}
                            type='button'
                            className={`btn btn-sm btn-outline-primary ${
                              button?.icon ? 'border-0' : ''
                            }`}
                            onClick={() => button.onClick(group)}
                          >
                            {button?.icon ? button.icon : button.name}
                          </button>
                        )}
                      </span>
                    ))}
                  </span>
                )}
              </span>
            </ListGroup.Item>
          ))}
      </ListGroup>
    </>
  );
};

export default GroupListSimple;
