import { DateTime } from 'luxon';


export const getLocalDate = (createdAt) => {


	// const localVariant = DateTime.fromISO(item.createdAt);
	// return localVariant.toString();
	try {
		const localVariant = DateTime.fromISO(createdAt).toString();
		const anotherVariant = localVariant.substring(0, 10).split('-').reverse().join('-');
		const str = `${anotherVariant} ${`0${new Date(createdAt).getHours()}`.slice(-2)}:${`0${new Date(createdAt).getMinutes()}`.slice(-2)}:${`0${new Date(createdAt).getSeconds()}`.slice(-2)}`;
		return str;
	} catch (error) {
		return createdAt;
	}

}

// export const getOrderHeaderString2 = (item, prefix = '', withAuthor) => {
// 	if (item && item?.createdAt) {
// 		try {
// 			const anotherVariant = item?.createdAt?.substring(0, 10).split('-').reverse().join('-');
// 			const str = `${prefix} ${anotherVariant} ${`0${new Date(item?.createdAt).getHours()}`.slice(-2)}:${`0${new Date(item?.createdAt).getMinutes()}`.slice(-2)}:
// 				${`0${new Date(item?.createdAt).getSeconds()}`.slice(-2)}`;
// 			return str;

// 		} catch (error) {
// 			const str = `${prefix} &nbsp; ${item?.createdAt?.substring(0, 10)} &nbsp; &nbsp; ${item?.user?.name}`;
// 			return str;
// 		}

// 	} else {
// 		<span>{typeof item}</span>
// 	}
// }
export const getDataString = (createdAt, prefix, withTime) => {
	if (createdAt) {
		const part1 = createdAt?.substring(0, 10).split('-').reverse().join('-');

		const str = `${prefix} ${part1} ${`0${new Date(createdAt).getHours()}`.slice(-2)}:${`0${new Date(createdAt).getMinutes()}`.slice(-2)}:${`0${new Date(createdAt).getSeconds()}`.slice(-2)}`;
		return str;
	} else {
		<span>{typeof createdAt}</span>
	}
}


export const getOrderHeaderString = (item, prefix = '', withAuthor = false) => {
	if (item && item?.createdAt) {

		// const localVariant = DateTime.fromISO(item.createdAt);
		// return localVariant.toString();
		try {
			const localVariant = DateTime.fromISO(item.createdAt).toString();
			const anotherVariant = localVariant.substring(0, 10).split('-').reverse().join('-');
			const str = `${prefix} ${anotherVariant} ${`0${new Date(item?.createdAt).getHours()}`.slice(-2)}:${`0${new Date(item?.createdAt).getMinutes()}`.slice(-2)}:${`0${new Date(item?.createdAt).getSeconds()}`.slice(-2)}  ${withAuthor ? "    ".concat(item?.user?.name) : ''}`;
			return str;

		} catch (error) {
			const str = `${prefix} &nbsp; ${item?.createdAt?.substring(0, 10)} &nbsp; &nbsp; ${item?.user?.name}`;
			return str;
		}

	} else {
		<span>{typeof item}</span>
	}
}