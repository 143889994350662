import { createSlice } from '@reduxjs/toolkit';
import { DateTime } from 'luxon';


const initialState = {
	userInfo:
		localStorage.getItem('userInfo')
			? JSON.parse(localStorage.getItem('userInfo'))
			: null,
	// currentGroup: localStorage.getItem('currentGroup')
	// 	? JSON.parse(localStorage.getItem('currentGroup'))
	// 	: null,
	currentRoute: localStorage.getItem('currentRoute') && localStorage.getItem('currentRoute') !== 'undefined'
		? JSON.parse(localStorage.getItem('currentRoute'))
		: null,
	currentProvider: localStorage.getItem('currentProvider') && localStorage.getItem('currentProvider') !== 'undefined'
		? JSON.parse(localStorage.getItem('currentProvider'))
		: null,
	currentCustomer: localStorage.getItem('currentCustomer') && localStorage.getItem('currentCustomer') !== 'undefined'
		? JSON.parse(localStorage.getItem('currentCustomer'))
		: null,
	currentRegion: null,
	priceEditMode: localStorage.getItem('priceEditMode')
		? JSON.parse(localStorage.getItem('priceEditMode'))
		: false,
	theme: localStorage.getItem('theme')
		? JSON.parse(localStorage.getItem('theme'))
		: 'light',
	currentBreakpoint: localStorage.getItem('currentBreakpoint')
		? JSON.parse(localStorage.getItem('currentBreakpoint'))
		: 'md',
	showPrice: localStorage.getItem('showPrice')
		? JSON.parse(localStorage.getItem('showPrice'))
		: true,
	showReturnColumn: true,
	addMarginWhenCellEdit: localStorage.getItem('addMarginWhenCellEdit')
		? JSON.parse(localStorage.getItem('addMarginWhenCellEdit'))
		: true,
	showUnitColumn: localStorage.getItem('showUnitColumn')
		? JSON.parse(localStorage.getItem('showUnitColumn'))
		: true,
	showMultipleColumn: localStorage.getItem('showMultipleColumn')
		? JSON.parse(localStorage.getItem('showMultipleColumn'))
		: true,
	mobileMenuIsOpen: localStorage.getItem('mobileMenuIsOpen')
		? JSON.parse(localStorage.getItem('mobileMenuIsOpen'))
		: false,
	leftMenuBinded: localStorage.getItem('leftMenuBinded')
		? JSON.parse(localStorage.getItem('leftMenuBinded'))
		: false,
	viewMode: localStorage.getItem('viewMode')
		? JSON.parse(localStorage.getItem('viewMode'))
		: 'table',
	pov: localStorage.getItem('pov')
		? JSON.parse(localStorage.getItem('pov'))
		: null,
	orientation: localStorage.getItem('orientation')
		? JSON.parse(localStorage.getItem('orientation'))
		: {
			angle: 0,
			type: ''
		},
	gridSettingMode: false,
	showAllNews: false,
	selectedProducts: [],
	selectedCitiesIds: [],
	animateMenuIcon: false,
	currentSection: '',
	searchString: '',
	currentGroup: '',
	date1mem: DateTime.local().toISO(),
	date2mem: DateTime.local().toISO(),
	showLeftMenu: true,
	parcel: {},
	showGlobalModal: '',
	tasks: {},
	existNews: []
}



const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		setCurrentRoute: (state, action) => {
			state.currentRoute = action.payload;
			localStorage.setItem('currentRoute', JSON.stringify(action.payload));
		},
		setCurrentRegion: (state, action) => {
			state.currentRegion = action.payload;
		},
		setTasks: (state, action) => {
			const { customerId, providerId, count } = action.payload;
			if (customerId && providerId) {
				const id = `${customerId}-${providerId}`;
				state.tasks[id] = count;
			}
		},
		setShowGlobalModal: (state, action) => {
			state.showGlobalModal = action.payload;
		},
		setShowLeftMenu: (state, action) => {
			state.showLeftMenu = action.payload;
		},
		existNewsSet: (state, action) => {
			state.existNews = [...state.existNews, action.payload];
		},
		existNewsUnset: (state, action) => {
			state.existNews = state.existNews.filter(id => id !== action.payload);
		},
		setNewsVisibility: (state, action) => {
			state.showAllNews = action.payload;
		},
		setDate1mem: (state, action) => {
			state.date1mem = action.payload;
		},
		setDate2mem: (state, action) => {
			state.date2mem = action.payload;
		},
		setAnimateMenuIcon: (state, action) => {
			state.animateMenuIcon = action.payload;
		},
		setPov: (state, action) => {
			state.pov = action.payload;
			localStorage.setItem('pov', JSON.stringify(action.payload))
		},
		setAddMarginWhenCellEdit: (state, action) => {
			state.addMarginWhenCellEdit = action.payload;
			localStorage.setItem('addMarginWhenCellEdit', JSON.stringify(action.payload))
		},
		setViewMode: (state, action) => {
			state.viewMode = action.payload;
			localStorage.setItem('viewMode', JSON.stringify(action.payload))
		},
		setOrientation: (state, action) => {
			state.orientation = action.payload;
			localStorage.setItem('orientation', JSON.stringify(action.payload))
		},
		setMobileMenuIsOpen: (state, action) => {
			state.mobileMenuIsOpen = action.payload;
			state.animateMenuIcon = false;
			localStorage.setItem('mobileMenuIsOpen', JSON.stringify(action.payload))
		},
		setLeftMenuBinded: (state, action) => {
			state.leftMenuBinded = action.payload;
			localStorage.setItem('leftMenuBinded', JSON.stringify(action.payload))
			//return updateState(state);
		},
		setCredentials: (state, action) => {
			state.userInfo = action.payload;
			localStorage.setItem('userInfo', JSON.stringify(action.payload))
		},
		setCurrentGroup: (state, action) => {
			state.searchString = '';
			state.currentGroup = action.payload;
			//localStorage.setItem('currentGroup', JSON.stringify(action.payload))
		},
		setCurrentSection: (state, action) => {
			//state.searchString = '';
			state.currentSection = action.payload;
		},
		setCurrentProvider: (state, action) => {
			state.currentProvider = action.payload;
			localStorage.setItem('currentProvider', JSON.stringify(action.payload))
		},
		setCurrentCustomer: (state, action) => {
			state.currentCustomer = action.payload;
			localStorage.setItem('currentCustomer', JSON.stringify(action.payload))
		},
		setPriceEditMode: (state, action) => {
			state.priceEditMode = action.payload;
			localStorage.setItem('priceEditMode', JSON.stringify(action.payload))
		},
		setCurrentBreakpoint: (state, action) => {
			state.currentBreakpoint = action.payload;
			localStorage.setItem('currentBreakpoint', JSON.stringify(action.payload))
			//return updateState(state);
		},
		setTheme: (state, action) => {
			state.theme = action.payload;
			localStorage.setItem('theme', JSON.stringify(action.payload))
		},
		setSearchString: (state, action) => {
			state.searchString = action.payload;
			//state.currentSection = '';
			//localStorage.setItem('searchString', JSON.stringify(action.payload))
		},

		setShowPrice: (state, action) => {
			state.showPrice = action.payload;
			localStorage.setItem('showPrice', JSON.stringify(action.payload))
			//return updateState(state);
		},
		setShowReturnColumn: (state, action) => {
			state.showReturnColumn = action.payload;
			//localStorage.setItem('showReturnColumn', JSON.stringify(action.payload))
			//return updateState(state);
		},
		setShowMyltipleColumn: (state, action) => {
			state.showMultipleColumn = action.payload;
			localStorage.setItem('showMultipleColumn', JSON.stringify(action.payload))
			//return updateState(state);
		},
		setShowUnitColumn: (state, action) => {
			state.showUnitColumn = action.payload;
			localStorage.setItem('showUnitColumn', JSON.stringify(action.payload))
			//return updateState(state);
		},
		logout: (state, action) => {
			state.userInfo = null;
			state.cart = null;
			state.currentGroup = null;
			state.pov = null;
			state.searchString = null;
			state.currentProvider = null;
			state.currentCustomer = null;
			localStorage.clear();
			// localStorage.removeItem('userInfo');
			// localStorage.removeItem('cart');
			// localStorage.removeItem('currentGroup');
			// localStorage.removeItem('pov');
			// localStorage.removeItem('searchString');
			// localStorage.removeItem('currentProvider');
			// localStorage.removeItem('currentCustomer');
		},
		setSelectedProducts: (state, action) => {
			state.selectedProducts = action.payload;
		},
		setSelectedCitiesIds: (state, action) => {
			state.selectedCitiesIds = action.payload;
		},
		setGridSettingMode: (state, action) => {
			state.gridSettingMode = action.payload;
		},
		setParcel: (state, action) => {
			state.parcel = action.payload;
			localStorage.setItem('parcel', JSON.stringify(action.payload));
		}
	}
});

export const { setShowMyltipleColumn, existNewsSet, existNewsUnset, setCurrentRegion,
	setTasks, setSelectedCitiesIds,
	setCustomerParams, setCurrentRoute, setAddMarginWhenCellEdit, setShowGlobalModal, setParcel, setShowLeftMenu, setNewsVisibility, setDate1mem, setDate2mem, setCurrentSection, setAnimateMenuIcon, setSelectedProducts, setPov, setViewMode, setOrientation, setLeftMenuBinded, setMobileMenuIsOpen, setGridSettingMode, setShowUnitColumn, setCurrentBreakpoint, setShowReturnColumn, setSearchString, setCredentials, setCurrentGroup, setShowPrice, logout, setCurrentCustomer, setCurrentProvider, setPriceEditMode, setTheme } = authSlice.actions;
export default authSlice.reducer;