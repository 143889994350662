import { useState } from 'react';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Message from '../Message/Message';
import { Collapse } from '@mui/material';
import { LinearProgress } from '@mui/material';
import { Button, Container, Form, InputGroup } from 'react-bootstrap';

const UnitList = ({ query, handleClick, payload, options }) => {
  const [keyword, setKeyword] = useState('');
  const { data, refetch, isLoading, isError, error } = query({
    ...payload,
    keyword,
  });
  const [openId, setOpenId] = useState('');

  console.log('payload', payload);

  const clickHandle = (item, isGroup) => {
    if (isGroup) {
      if (item._id === openId) {
        setOpenId('');
      } else {
        setOpenId(item._id);
      }
    } else {
      handleClick(item);
    }
  };

  const handleClickSearch = () => {
    refetch();
  };

  const themeStyle = {
    backgroundColor: options?.theme === 'dark' ? '#212529' : 'white',
    borderColor: options?.theme === 'dark' ? '#212529' : '#b5c0c1',
    color: options?.theme === 'dark' ? 'white' : 'black',
  };

  return (
    <>
      {isLoading ? (
        <LinearProgress />
      ) : isError ? (
        <Message>
          {error?.data?.message || error?.error || error?.message}
        </Message>
      ) : (
        <>
          {options?.search && (
            <Container>
              <InputGroup className='mb-3'>
                <InputGroup.Text>Поиск</InputGroup.Text>
                <Form.Control
                  className='border-success'
                  style={themeStyle}
                  type='text'
                  placeholder='Введите строку для поиска'
                  value={keyword}
                  onChange={(e) => setKeyword(e.target.value)}
                ></Form.Control>
                <Button
                  variant='primary'
                  id='button-search'
                  onClick={handleClickSearch}
                >
                  Поиск
                </Button>
              </InputGroup>
            </Container>
          )}
          <List>
            {Array.isArray(data?.rows) &&
              data.rows.map((item, index) => (
                <span key={index}>
                  <ListItemButton
                    onClick={() =>
                      clickHandle(
                        item,
                        Array.isArray(item.children) && item.children.length > 0
                      )
                    }
                  >
                    <ListItemText primary={item.name} />
                    {Array.isArray(item.children) &&
                      item.children.length > 0 &&
                      (openId === item._id ? <ExpandLess /> : <ExpandMore />)}
                  </ListItemButton>
                  {Array.isArray(item.children) && item.children.length > 0 && (
                    <Collapse
                      in={openId === item._id}
                      timeout='auto'
                      unmountOnExit
                    >
                      <List component='div' disablePadding>
                        {item.children.map((sub) => (
                          <ListItemButton
                            sx={{ pl: 4 }}
                            key={sub._id}
                            onClick={() => clickHandle(sub, false)}
                          >
                            <ListItemText primary={sub.name} />
                          </ListItemButton>
                        ))}
                      </List>
                    </Collapse>
                  )}
                </span>
              ))}
          </List>
        </>
      )}
    </>
  );
};

export default UnitList;
