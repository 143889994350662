import { Box, LinearProgress, useTheme } from '@mui/material';
import { DataGrid, ruRU, useGridApiRef } from '@mui/x-data-grid';
import React, { useEffect } from 'react';
import { tableStyles } from './sx';
import { NoRowsOverlayDefault } from './NoRowsOverlayDefault/NoRowsOverlayDefault';

const DataTable = ({
  mobileView,
  addMargin,
  onCellEditStop,
  onCellEditStart,
  // apiDataGridRef = undefined,
  setRowSelectionModel = () => {},
  noRowsOverlay,
  rowSelectionModel,
  checkboxSelection,
  autoHeight = true,
  isLoading = false,
  rows,
  columns,
  columnVisible = { id: true },
  rowHeight = 40,
  onSelectCell = () => {},
  onSelectRow = () => {},
  onUpdateRow = () => {},
  sx = {},
  onProcessRowUpdateError = (error) => {},
}) => {
  const apiRef = useGridApiRef();
  const theme = useTheme();
  //  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  //  console.log('DataTable');

  //  if (rows) {
  //    if (rows.length === 0) {
  //      dispatch(setAnimateMenuIcon(true));
  //    }
  //  }
  // console.log('apiDataGridRef', apiDataGridRef);
  const onCellKeyDown = (params, event) => {
    onSelectRow({ row: params.row, field: params.field, key: event.key });
  };

  const processRowUpdate = (newRow, oldRow) => {
    try {
      if (newRow?.qty < 0) {
        newRow.qty = Math.abs(newRow.qty);
      }
      if (newRow?.ret > 0) {
        // newRow.ret = Number(newRow?.ret) * -1;
        newRow.ret = Number(newRow?.ret);
      }
    } catch (error) {}

    onUpdateRow({ newRow, oldRow });
    return newRow;
  };

  // useEffect(() => {
  //   if (apiDataGridRef?.current?.subscribeEvent) {
  //     const handleCellClick = (params) => {
  //       onSelectCell({ row: params.row, field: params.field });
  //       if (mobileView) {
  //         try {
  //           if (params.field === 'qty' || params.field === 'ret') {
  //             try {
  //               apiDataGridRef?.current?.startCellEditMode({
  //                 id: params.row.id,
  //                 field: params.field,
  //               });
  //             } catch (error) {}
  //           }
  //         } catch (error) {}
  //       }
  //     };

  //     return apiDataGridRef?.current?.subscribeEvent(
  //       'cellClick',
  //       handleCellClick
  //     );
  //   }
  //   //eslint-disable-next-line
  // }, [apiDataGridRef]);

  useEffect(() => {
    if (apiRef?.current?.subscribeEvent) {
      const handleCellClick = (params) => {
        onSelectCell({ row: params.row, field: params.field });

        if (mobileView) {
          try {
            if (params.field === 'qty' || params.field === 'ret') {
              try {
                apiRef?.current?.startCellEditMode({
                  id: params.row.id,
                  field: params.field,
                });
              } catch (error) {}
            }
          } catch (error) {}
        }
      };

      return apiRef?.current?.subscribeEvent('cellClick', handleCellClick);
    }
    //eslint-disable-next-line
  }, [apiRef]);

  return (
    <Box sx={tableStyles(theme, addMargin)}>
      <DataGrid
        onCellEditStop={onCellEditStop}
        onCellEditStart={onCellEditStart}
        apiRef={apiRef}
        experimentalFeatures={{ newEditingApi: true }}
        onRowSelectionModelChange={(newRowSelectionModel) => {
          setRowSelectionModel(newRowSelectionModel);
        }}
        rowSelectionModel={rowSelectionModel}
        checkboxSelection={checkboxSelection || false}
        getCellClassName={(params) => {
          const classNames = [];
          if (params.field === 'name' && params.row?.promo) {
            classNames.push('promo');
          }
          if (params.field === 'regions') {
            classNames.push('regions');
          }
          if (params.field === 'photos') {
            classNames.push('photos');
          }
          if (params.row?.noteditable) {
            classNames.push('noteditable');
          }
          if (params.row?.isDivider) {
            classNames.push('noteditable isDivider');
          }
          if ('isDelivered' in params.row && !params.row?.isDelivered) {
            classNames.push('promo');
          }
          if (params.row?.isCanceled) {
            classNames.push('line-through');
          }
          if (params.field === 'name' && params.row?.image) {
            classNames.push('withimage');
          }
          if (params.field === 'name' && params.row?.hide === true) {
            classNames.push('text-decoration-line-through text-warning');
          }
          return classNames.join(' ');
        }}
        autoHeight={autoHeight}
        sx={{ ...sx, '--DataGrid-overlayHeight': '400px' }}
        editMode='cell'
        isCellEditable={(params) => !params.row?.noteditable}
        processRowUpdate={processRowUpdate}
        onCellKeyDown={onCellKeyDown}
        onProcessRowUpdateError={onProcessRowUpdateError}
        //apiRef={apiRef}
        rowHeight={rowHeight}
        rows={rows}
        columns={columns}
        columnVisibilityModel={columnVisible}
        initialState={{
          // pagination: {
          //   paginationModel: {
          //     pageSize: 5,
          //   },
          // },
          columns: {
            columnVisibilityModel: {
              id: false,
            },
          },
        }}
        //pageSizeOptions={[5]}
        //checkboxSelection
        slots={{
          loadingOverlay: LinearProgress,
          //  toolbar: DataTableToolbar,
          noRowsOverlay: noRowsOverlay ? noRowsOverlay : NoRowsOverlayDefault,
        }}
        loading={isLoading}
        disableRowSelectionOnClick
        slotProps={{
          toolbar: {
            // props required by CustomGridToolbar
            someCustomString: 'Hello',
            someCustomNumber: 42,
          },
          columnsPanel: {
            disableHideAllButton: true,
            disableShowAllButton: true,
            disableColumnSelector: true,
          },
        }}
        disableColumnMenu
        localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
        hideFooter={rows?.length < 10}
      />
    </Box>
  );
};

export default DataTable;
