import { createContext, useEffect, useMemo, useState } from 'react';
import MuiScreen from './screens/MuiScreen';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { useDispatch } from 'react-redux';
import { setCurrentBreakpoint, setTheme } from './slices/authSlice';
import useBreakpoint from './hooks/use-breakpoint';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'

export const ColorModeContext = createContext({ toggleColorMode: () => { } });

function getStorageTheme() {
  return localStorage.getItem('theme')
    ? JSON.parse(localStorage.getItem('theme'))
    : 'light'
}

const App = () => {
  // console.log('App render');
  //const container = useRef()
  const dispatch = useDispatch();
  const { size } = useBreakpoint();
  const [mode, setMode] = useState(getStorageTheme());
  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
      },
    }),
    [],
  );


  useEffect(() => {
    dispatch(setCurrentBreakpoint(size))
  }, [size, dispatch])

  useEffect(() => {
    dispatch(setTheme(mode))
  }, [mode, dispatch])

  // xs, extra - small: 0px
  // sm, small: 600px
  // md, medium: 900px
  // lg, large: 1200px
  // xl, extra - large: 1536px

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode,
        },
        breakpoints: {
          values: {
            xs: 0,
            sm: 600,
            md700: 700,
            md: 900,
            lg: 1200,
            xl: 1536,
          },
        },
      }),
    [mode],
  );

  return (
    <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale="ru">
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <MuiScreen />
        </ThemeProvider>
      </ColorModeContext.Provider>
    </LocalizationProvider>
  )
}

export default App;