import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
  useCreateGroupMutation,
  useGetGroupDetailsQuery,
  useUpdateGroupMutation,
} from '../slices/groupsApiSlice';
import Message from '../components/Message';
import ToolsPanel from '../components/ToolsPanel';
import {
  Accordion,
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
  Stack,
} from 'react-bootstrap';
import ProductList from '../components/ProductList/ProductList';
import { Alert } from '@mui/material';
import { toast } from 'react-toastify';
import GroupListSimple from '../components/GroupList/GroupListSimple/GroupListSimple';

const ProviderGroupEditScreen = () => {
  const { groupId } = useParams();
  //console.log('groupId', groupId); //id || newItem || newRoot

  const navigate = useNavigate();
  const [isGroup, setIsGroup] = useState(false);
  const [name, setName] = useState('');
  const [shortName, setShortName] = useState('');
  const [code, setCode] = useState('');
  // const [parent, setParent] = useState('');
  const [parentCode, setParentCode] = useState('');
  const [children, setChildren] = useState([]);

  const { theme, currentBreakpoint, currentProvider } = useSelector(
    (state) => state.auth
  );

  const { data, refetch, isLoading, isError, error } = useGetGroupDetailsQuery({
    groupId,
    providerId: currentProvider?._id,
  });

  useEffect(() => {
    if (data && data?.row) {
      const row = data?.row;
      if (data?.children) {
        setChildren(data.children);
      }
      setName(row?.name || '');
      setShortName(row?.shortName || '');
      setCode(row?.code || '');
      // setParent(row?.parent || '');
      setParentCode(row?.parentCode || '');
      setIsGroup(row?.isGroup || '');
    }
  }, [data]);

  const [updateGroup, { isLoading: loadingUpdate }] = useUpdateGroupMutation();
  const [createGroup, { isLoading: loadingCreate }] = useCreateGroupMutation();

  const save = async (withClose) => {
    let result;
    if (name) {
      const payload = {
        name,
        groupId,
        shortName,
        code,
        parentCode: isGroup ? '' : parentCode,
        isGroup,
        providerId: currentProvider?._id,
      };
      //console.log('submitHandler', 'payload', payload);
      try {
        if (['new', 'newItem', 'newRoot'].includes(groupId)) {
          result = await createGroup(payload).unwrap();
        } else {
          result = await updateGroup(payload).unwrap();
        }

        refetch();
        if (withClose) {
          navigate('/provider/groups');
        } else if (['new', 'newItem', 'newRoot'].includes(groupId)) {
          // console.log('submitHandler', 'result', result);
          navigate(`/provider/groups/${result?._id}`);
        }
      } catch (error) {
        toast.error(error?.data?.message || error.error);
      }
    }
  };

  const submitHandler = (event) => {
    event.preventDefault();
    if (!name) {
      toast.warning('Пустое наименование недопустимо');
      return;
    }
    save(event.target.getAttribute('close') === 'true');
  };

  const themeStyle = {
    backgroundColor: theme === 'dark' ? '#212529' : 'white',
    borderColor: theme === 'dark' ? '#212529' : '#b5c0c1',
    color: theme === 'dark' ? 'white' : 'black',
  };

  let disabledGroupCheck = true;
  if (
    ['new', 'newItem', 'newRoot'].includes(groupId) ||
    children?.length === 0
  ) {
    disabledGroupCheck = false;
  }

  return (
    <>
      <ToolsPanel
        //buttons={buttons}
        backURL={'/provider/groups/'}
        title={`Пункт меню ${isGroup ? ' (Группа)' : ''}`}
        isLoading={isLoading || loadingUpdate || loadingCreate}
      />
      {isLoading ? (
        <></>
      ) : isError && groupId !== 'new' ? (
        <Message>{error?.data?.message || error.error}</Message>
      ) : (
        <Container>
          <Row>
            <Col lg={12} xl={8} xxl={8}>
              <Form onSubmit={submitHandler}>
                <Card
                  className={`bg-transparent ${
                    currentBreakpoint === 'xs' || currentBreakpoint === 'sm'
                      ? 'border-0 shadow-none'
                      : 'p-3'
                  }`}
                >
                  <Form.Group as={Row} controlId='name' className='my-2'>
                    <Form.Label column sm='3' className='pb-0'>
                      Наименование
                    </Form.Label>
                    <Col sm='9'>
                      <Form.Control
                        isInvalid={!name}
                        style={themeStyle}
                        type='text'
                        placeholder='Наименование'
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      ></Form.Control>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} controlId='shortname' className='my-2'>
                    <Form.Label column sm='3' className='pb-0'>
                      Краткое имя
                    </Form.Label>
                    <Col sm='9'>
                      <Form.Control
                        style={themeStyle}
                        type='text'
                        placeholder='Введите краткое наименование'
                        value={shortName}
                        onChange={(e) => setShortName(e.target.value)}
                      ></Form.Control>
                    </Col>
                  </Form.Group>
                  {/* <Form.Group as={Row} controlId='code' className='my-2'>
                    <Form.Label column sm='3'>
                      Код поставщика
                    </Form.Label>
                    <Col sm='9'>
                      <Form.Control
                        style={themeStyle}
                        type='text'
                        placeholder='Код из вашей системы учёта'
                        value={code}
                        onChange={(e) => setCode(e.target.value)}
                      ></Form.Control>
                    </Col>
                  </Form.Group> */}
                  {/* {!isGroup &&
                    !['new', 'newItem', 'newRoot'].includes(groupId) && (
                      <Form.Group
                        as={Row}
                        controlId='parentcode'
                        className='my-2'
                      >
                        <Form.Label column sm='3'>
                          Код родителя
                        </Form.Label>{' '}
                        <Col sm='9'>
                          <Form.Control
                            as={'div'}
                            style={themeStyle}
                            type='text'
                            placeholder='Введите ваш код иначе будет создан автоматически'
                            value={parentCode}
                            onChange={(e) => setParentCode(e.target.value)}
                          ></Form.Control>
                        </Col>
                      </Form.Group>
                    )} */}
                  {/* {groupId !== 'newItem' && ( */}
                  <Form.Group controlId='isGroup' className='my-2' as={Row}>
                    <Col sm='12'>
                      <Alert severity='info' variant='outlined'>
                        <Form.Check
                          disabled={disabledGroupCheck}
                          type='checkbox'
                          label={'Это группа'}
                          checked={isGroup}
                          onChange={(e) => setIsGroup(e.target.checked)}
                        ></Form.Check>
                        {!['new', 'newItem', 'newRoot'].includes(groupId) ? (
                          <span>
                            <strong>
                              Флаг группы можно установить в 2-х случаях: <br />
                              <br />
                              1. В момент создания нового пункта меню <br />
                              2. Если состав пуст
                              <br />
                            </strong>

                            <br />
                          </span>
                        ) : (
                          <span className={`${isGroup ? '' : 'd-none'}`}>
                            <strong>
                              Группа не может включать в себя товары или услуги
                              !
                            </strong>
                            <br />
                            Группа включает в себя только другие пункты меню.
                            <br />
                            Группы используются в случах, когда пунктов меню
                            становится слишком много и меню становится слишком
                            длинным.
                            <br />
                          </span>
                        )}
                      </Alert>
                    </Col>
                  </Form.Group>
                  {/* )} */}
                </Card>
                <Stack
                  direction='horizontal'
                  gap={3}
                  //class='justify-content-center'
                >
                  {/* <Button
                    type='button'
                    variant='primary'
                    className='my-2'
                    onClick={submitHandler}
                    size={
                      ['lg', 'xl', 'xxl'].includes(currentBreakpoint)
                        ? 'lg'
                        : 'sm'
                    }
                  >
                    Записать
                  </Button> */}
                  <Button
                    variant='primary'
                    className='my-2 mx-2'
                    close='true'
                    size={
                      ['lg', 'xl', 'xxl'].includes(currentBreakpoint)
                        ? 'lg'
                        : 'sm'
                    }
                    onClick={submitHandler}
                  >
                    Сохранить
                  </Button>
                  <Button
                    size={
                      ['lg', 'xl', 'xxl'].includes(currentBreakpoint)
                        ? 'lg'
                        : 'sm'
                    }
                    variant='primary'
                    className='my-2'
                    onClick={() => navigate(`/provider/groups/`)}
                  >
                    Закрыть
                  </Button>
                </Stack>
              </Form>
            </Col>
            {!['new', 'newItem', 'newRoot'].includes(groupId) && (
              <Col lg={12} xl={4} xxl={4} className='mt-3'>
                <Accordion
                  defaultActiveKey='1'
                  //className='position-fixed me-4'
                >
                  <Accordion.Item eventKey='1'>
                    <Accordion.Header>Состав:</Accordion.Header>
                    <Accordion.Body>
                      {data?.row?.isGroup ? (
                        <GroupListSimple itemList={children} />
                      ) : (
                        <ProductList
                          groupId={data?.row?._id}
                          key={data?.row?._id}
                          isGroup={data?.row?.isGroup}
                        />
                      )}
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </Col>
            )}
          </Row>
        </Container>
      )}
    </>
  );
};

export default ProviderGroupEditScreen;
