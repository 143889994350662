import React, { useEffect, useState } from 'react';
import { Col, Container, Form, ListGroup, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import Message from '../components/Message';
import ToolsPanel from '../components/ToolsPanel/ToolsPanel';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import {
  useChangeTaskStatusMutation,
  useGetCustomerTasksQuery,
} from '../slices/tasksApiSlice';
import Accordion from 'react-bootstrap/Accordion';
import { useUploadImageMutation } from '../slices/imagesApiSlice';
import TaskItem from '../components/tasks/TaskItem';
import { FaHome } from 'react-icons/fa';

const CustomerTasksScreen = () => {
  const { state: backUrl } = useLocation();
  // const dispatch = useDispatch();
  const [visibility, setVisibility] = useState(true);
  const [values, setValues] = useState([]);
  const [items, setItems] = useState([]);

  const { theme, currentProvider, currentCustomer, pov } = useSelector(
    (state) => state.auth
  );

  const { data, refetch, isLoading, isError, error } = useGetCustomerTasksQuery(
    {
      customerId: currentCustomer?._id,
      providerId: currentProvider?._id,
      all: !visibility,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  // console.log(data);

  useEffect(() => {
    if (Array.isArray(data?.rows)) {
      const arr = {};
      for (let index = 0; index < data.rows.length; index++) {
        const task = data.rows[index];
        arr[task._id] = { ...task };
      }
      setItems(arr);
    }
  }, [data]);

  // console.log(items);

  const [
    changeTaskStatus,
    { isLoading: isLoadingUpdate, isError: isErrorUpdate, error: errorUpdate },
  ] = useChangeTaskStatusMutation();
  const [uploadImage] = useUploadImageMutation();
  const onChangeHandler = async (data) => {
    try {
      await changeTaskStatus({ ...data });
      refetch();
    } catch (error) {
      toast.error(error?.data?.message || error.error);
    }
  };

  if (isErrorUpdate) {
    toast.error(errorUpdate?.data?.message || errorUpdate.error);
  }

  const handleSetVisibility = () => {
    setVisibility(!visibility);
  };

  const buttons = [
    {
      type: 'switch',
      action: handleSetVisibility,
      disabled: visibility,
      name: 'все',
    },
    { hide: true }, //увеличить ширину родительского компонента Stack
    { hide: true }, //увеличить ширину родительского компонента Stack
    { hide: true }, //увеличить ширину родительского компонента Stack
  ];

  return (
    <>
      <ToolsPanel
        title='Задачи'
        backURL={backUrl ? backUrl : `/customer`}
        backIcon={<FaHome size={22} />}
        // isLoading={isLoadingUpdate || isLoading}
        buttons={buttons}
      />
      {isLoading ? (
        <></>
      ) : isError ? (
        <Message>{error?.data?.message || error.error}</Message>
      ) : (
        <>
          <Container>
            <Row>
              <Col>
                <ListGroup>
                  {data?.rows?.length > 0 &&
                    data?.rows?.map((item, index) => (
                      <TaskItem
                        isErrorUpdate={isErrorUpdate}
                        isLoadingUpdate={isLoadingUpdate}
                        key={item?._id || index}
                        item={item}
                        theme={theme}
                        uploadImage={uploadImage}
                        onChangeHandler={onChangeHandler}
                      />
                    ))}
                </ListGroup>
              </Col>
            </Row>
          </Container>
        </>
      )}
    </>
  );
};

export default CustomerTasksScreen;
