import { SECTIONS_URL } from '../constans';
import { apiSlice } from './apiSlice';

export const sectionsApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		bindUnitToUserByInvite: builder.mutation({
			query: (invite) => ({
				url: `${SECTIONS_URL}/${invite}`,
				method: 'PUT'
			}),
		}),
		updateUnitSections: builder.mutation({
			query: (data) => ({
				url: `${SECTIONS_URL}/unit/`,
				method: 'POST',
				body: {
					sections: data.sections,
					unitId: data.unitId
				}
			}),
		}),
		getUnitSections: builder.query({
			query: (unitId) => ({
				url: `${SECTIONS_URL}/checked/${unitId}`,
			}),
			keepUnusedDataFor: 5
		}),
		getAllSectionsForUnit: builder.query({
			query: (unitId) => ({
				url: `${SECTIONS_URL}/all/${unitId}`,
			}),
		}),
		getAllSections: builder.query({
			query: (data) => ({
				url: `${SECTIONS_URL}`,
				params: {
					...data
				}
			}),
		}),
	})
});

export const {
	useGetAllSectionsQuery,
	useUpdateUnitSectionsMutation,
	useGetAllSectionsForUnitQuery,
	useGetUnitSectionsQuery,
} = sectionsApiSlice;