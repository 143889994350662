import React, { useState } from 'react';
import { Accordion } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import GroupListSortable from '../GroupListSortable';
import GroupListSimple from '../GroupListSimple/';

const GroupListCollapsed = (props) => {
  const {
    rows,
    refetch,
    onClick,
    onClickItem,
    onClickMove,
    onClickDelete,
    selectedRoot,
    iconPosition,
    viewMode,
    updateKey,
    sortable = true,
  } = props;
  const [activeKey, setActiveKey] = useState('');
  const { currentBreakpoint } = useSelector((state) => state.auth);

  const onClickRoot = (item) => {
    setActiveKey(item?._id);
    onClick(item);
  };

  return (
    <>
      <Accordion
        activeKey={'selectedRoot' in props ? selectedRoot?._id : activeKey}
      >
        {Array.isArray(rows) &&
          rows.map((group, index) => (
            <Accordion.Item
              eventKey={group?._id}
              key={group?._id}
              className='border-0 rounded-top-1'
            >
              <Accordion.Header
                onClick={() => onClickRoot(group)}
                className='rounded-top-1'
              >
                {group?.name || group?.title}
              </Accordion.Header>
              {Array.isArray(group?.children) && group.children.length > 0 && (
                <Accordion.Body
                  className={`p-${
                    ['xs', 'sm'].includes(currentBreakpoint) ? 1 : 2
                  }`}
                >
                  {sortable ? (
                    <GroupListSortable
                      updateKey={updateKey}
                      refetch={refetch}
                      rows={group?.children}
                      selectedRoot={selectedRoot}
                      onClickItem={onClickItem}
                      onClickDelete={onClickDelete}
                      onClickMove={onClickMove}
                      selectedItemId={''}
                      iconPosition={iconPosition}
                      viewMode={viewMode}
                    />
                  ) : (
                    <GroupListSimple
                      rows={group?.children}
                      onClickItem={onClickItem}
                    />
                  )}
                </Accordion.Body>
              )}
            </Accordion.Item>
          ))}
      </Accordion>
    </>
  );
};

export default GroupListCollapsed;
