import { CUSTOMERS_URL, UPLOAD_URL } from '../constans';
import { apiSlice } from './apiSlice'

export const customersApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getDataForSelectCustomers: builder.query({
			query: (data) => ({
				url: `${CUSTOMERS_URL}/forselect/`,
				params: {
					...data
				}
			}),
			keepUnusedDataFor: 5
		}),
		// Вызываем когда покупатель ищет новых поставщиков
		// CustomerSearchProviderScreen.jsx
		searchProvidersForCustomer: builder.query({
			query: (data) => ({
				url: `${CUSTOMERS_URL}/providers/search`,
				params: {
					...data
				}
			}),
			keepUnusedDataFor: 5
		}),

		// Вызываем когда покупатель в роли покупателя выбирает поставщиков для выбранного покупателя
		// CustomerSelectProviderScreen.jsx
		getProvidersOfCustomer: builder.query({
			query: (data) => ({
				url: `${CUSTOMERS_URL}/${data?.customerId}/providers/`,
				params: {
					...data
				}
			}),
			keepUnusedDataFor: 5
		}),

		// Вызываем когда пользователь в роли покупателя выбирает другого покупателя
		// CustomerSelectCustomerScreen.jsx
		getCustomersOfUser: builder.query({
			query: (data) => ({
				url: `${CUSTOMERS_URL}/ofuser/`,
				params: {
					...data
				}
			}),
			keepUnusedDataFor: 5
		}),

		// Вызываем когда пользователь в роли покупателя выбирает другого покупателя
		// CustomerSelectCustomerScreen.jsx
		getCustomerDetails: builder.query({
			query: (data) => ({
				url: `${CUSTOMERS_URL}/details/`,
				params: {
					...data
				}
			}),
			keepUnusedDataFor: 5
		}),

		// Вызываем когда пользователь в роли покупателя выбирает другого покупателя
		// CustomerSelectCustomerScreen.jsx
		getCustomerProviderDetails: builder.query({
			query: (data) => ({
				url: `${CUSTOMERS_URL}/provider/details/${data?.providerId}`,
				params: {
					...data
				}
			}),
			keepUnusedDataFor: 5
		}),



		getProviderCustomers: builder.query({
			query: (data) => ({
				url: `${CUSTOMERS_URL}/ofprovider/${data?.providerId}`,
				params: {
					...data
				}
			}),
			keepUnusedDataFor: 5
		}),
		getCustomers: builder.query({
			query: (data) => ({
				url: CUSTOMERS_URL,
				params: {
					...data
				}
			}),
			// providesTags: ['Customer'],
			keepUnusedDataFor: 5
		}),
		getCustomerFields: builder.query({
			query: () => ({
				url: `${CUSTOMERS_URL}/fields`
			}),
			keepUnusedDataFor: 5,
		}),
		checkCustomerAllowed: builder.mutation({
			query: (data) => ({
				url: `${CUSTOMERS_URL}/${data.customerId}/check`,
				method: 'POST',
			}),
			keepUnusedDataFor: 5,
		}),
		createCustomer: builder.mutation({
			query: (data) => ({
				url: `${CUSTOMERS_URL}`,
				method: 'POST',
				body: { ...data }
			}),
			invalidatesTags: ['Customer'] //очистить ранее созданный кеш
		}),
		updateCustomer: builder.mutation({
			query: (data) => ({
				url: `${CUSTOMERS_URL}/${data._id}`,
				method: 'PUT',
				body: data
			}),
			invalidatesTags: ['Customer'] //очистить ранее созданный кеш
		}),
		updateCustomerField: builder.mutation({
			query: (data) => ({
				url: `${CUSTOMERS_URL}/${data._id}/field`,
				method: 'POST',
				body: data
			}),
			invalidatesTags: ['Customer'] //очистить ранее созданный кеш
		}),
		uploadCustomerImage: builder.mutation({
			query: (data) => ({
				url: UPLOAD_URL,
				method: 'POST',
				body: data
			}),
		}),
		deleteCustomer: builder.mutation({
			query: (CustomerId) => ({
				url: `${CUSTOMERS_URL}/${CustomerId}`,
				method: 'DELETE'
			}),
			invalidatesTags: ['Customer'] //очистить ранее созданный кеш
		}),
	})
})

//список проверенных хуков
export const { useGetProvidersOfCutomerQuery } = customersApiSlice;

export const {
	useGetDataForSelectCustomersQuery,
	useCheckCustomerAllowedMutation,
	useGetCustomerFieldsQuery,
	useGetCustomerProviderDetailsQuery,
	useGetProvidersOfCustomerQuery,
	useGetCustomersOfUserQuery,
	useSearchProvidersForCustomerQuery,
	useGetProviderCustomersQuery,
	useGetCustomerDetailsQuery,

	useUpdateCustomerFieldMutation,
	useGetCustomersQuery,
	useCreateCustomerMutation,
	useUpdateCustomerMutation,
	useUploadCustomerImageMutation,
	useDeleteCustomerMutation } = customersApiSlice;