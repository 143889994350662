import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ToolsPanel from '../components/ToolsPanel';
import { useUploadImageMutation } from '../slices/imagesApiSlice';
import { toast } from 'react-toastify';
import EditForm from '../components/EditForm';
import { Accordion, Button, Col, Container, Row, Stack } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useUpdateUnitMutation } from '../slices/unitsApiSlice';
import UnitManagerList from '../components/UnitManagerList';
import Invites from '../components/Invites';
import {
  useGetCustomerDetailsQuery,
  useUpdateCustomerMutation,
} from '../slices/customersApiSlice';
import GroupListSimple from '../components/GroupList/GroupListSimple';
import { FaEdit, FaHome } from 'react-icons/fa';
import GroupTwoColumnList from '../components/GroupList/GroupTwoColumnList/GroupTwoColumnList';
import { LinearProgress } from '@mui/material';
import Message from '../components/Message';

const CustomerDetailsEditScreen = () => {
  const navigate = useNavigate();
  const [uploadImage] = useUploadImageMutation();
  const [editMode, setEditMode] = useState(false);
  const [values, setValues] = useState([]);
  const [admins, setAdmins] = useState([]);
  const [users, setUsers] = useState([]);
  const [role, setRole] = useState('user');
  const { theme, currentCustomer, currentBreakpoint } = useSelector(
    (state) => state.auth
  );

  const setValuesHandler = (val, name) => {
    const newValues = values.map((el) =>
      el.name === name ? { ...el, value: val } : { ...el }
    );
    setValues(newValues);
  };

  const [updateCustomer, { isLoading: loadingUpdate }] =
    useUpdateCustomerMutation();

  //Получение данных
  const { data, refetch, isLoading, isError, error } =
    useGetCustomerDetailsQuery(
      { customerId: currentCustomer?._id },
      { refetchOnMountOrArgChange: true }
    );

  useEffect(() => {
    if (data) {
      setValues(data?.rows);
      setUsers(data?.users);
      setAdmins(data?.admins);
      if (data?.role) {
        setRole(data.role);
      }
    }
  }, [data]);

  const submitHandler = async (event) => {
    event.preventDefault();
    // const closeAfter = event.target.getAttribute('close') === 'true';
    let toSave = {};
    toSave.fields = [...values];
    toSave._id = currentCustomer?._id;
    // toSave.type = 'customer';

    try {
      await updateCustomer(toSave).unwrap();
      refetch();
      setEditMode(false);
      navigate(`/customer/details`);
    } catch (error) {
      toast.error(error?.data?.message || error?.message || error.error);
    }
  };

  const uploadFileHandler = async (e) => {
    const controlId = e.target.getAttribute('name');
    const formData = new FormData();
    formData.append('image', e.target.files[0]);
    try {
      const res = await uploadImage(formData).unwrap();
      toast.success(res.message);
      setValuesHandler(res.image, controlId);
    } catch (error) {
      toast.error(error?.data.message || error?.message || error.error);
      return false;
    }
  };

  const handleClickProvider = () => {};

  const onlyRead = currentCustomer?.role === 'a' ? false : true;

  const buttons = [];
  if (currentCustomer.role === 'a') {
    buttons.push({
      className: `bg-transparent ${editMode && 'border-success'}`,
      name: 'Инструменты',
      type: 'component',
      action: () => setEditMode(!editMode),
      component: (props) => {
        return (
          <FaEdit size={22} color={theme === 'dark' ? 'white' : 'black'} />
        );
      },
    });
  }

  return (
    <>
      <ToolsPanel
        buttons={buttons}
        backIcon={<FaHome size={22} />}
        backURL={'/customer'}
        title={`Реквизиты покупателя`}
        isLoading={loadingUpdate}
      />
      {isLoading ? (
        <LinearProgress />
      ) : isError ? (
        <Message>{error?.data?.message || error?.error}</Message>
      ) : (
        <Container>
          <Row>
            <Col md={12} lg={8}>
              {editMode ? (
                <EditForm
                  theme={theme}
                  back={'customer'}
                  values={values}
                  setValues={setValuesHandler}
                  submit={submitHandler}
                  uploadFileHandler={uploadFileHandler}
                  onlyRead={onlyRead}
                />
              ) : (
                <>
                  <Accordion className='me-0 mt-0 mb-1' defaultActiveKey='0'>
                    <Accordion.Item
                      eventKey='0'
                      style={{ backgroundColor: 'none !important' }}
                    >
                      <Accordion.Header>Реквизиты</Accordion.Header>
                      <Accordion.Body>
                        <GroupTwoColumnList rows={values} rowClasses='p-1' />
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </>
              )}
              <Stack direction='horizontal' gap={3} className='mb-1'>
                {editMode && (
                  <>
                    <Button
                      size={
                        ['lg', 'xl', 'xxl'].includes(currentBreakpoint)
                          ? 'lg'
                          : 'sm'
                      }
                      type='button'
                      variant='primary'
                      className='my-2'
                      onClick={submitHandler}
                    >
                      Записать
                    </Button>
                  </>
                )}
                <Button
                  size={
                    ['lg', 'xl', 'xxl'].includes(currentBreakpoint)
                      ? 'lg'
                      : 'sm'
                  }
                  variant='primary'
                  className='my-2'
                  onClick={() => navigate(`/customer`)}
                >
                  Закрыть
                </Button>
              </Stack>
            </Col>
            <Col md={12} lg={4}>
              {data && (
                <Accordion className='me-0 mt-0' defaultActiveKey='0'>
                  {Array.isArray(data?.providers) && (
                    <Accordion.Item
                      eventKey='0'
                      style={{ backgroundColor: 'none !important' }}
                    >
                      <Accordion.Header>Поставщики:</Accordion.Header>
                      <Accordion.Body>
                        <GroupListSimple
                          variant={'flush'}
                          className='p-1 border-0'
                          rows={data?.providers}
                          onClickItem={handleClickProvider}
                        />
                      </Accordion.Body>
                    </Accordion.Item>
                  )}
                  {(Array.isArray(users) || Array.isArray(admins)) && (
                    <Accordion.Item
                      eventKey='2'
                      style={{ backgroundColor: 'none !important' }}
                    >
                      <Accordion.Header>Управляющие</Accordion.Header>
                      <Accordion.Body>
                        <UnitManagerList
                          className='p-1 border-0'
                          role={role}
                          admins={admins}
                          users={users}
                        />
                        {editMode && (
                          <Accordion className='mt-2'>
                            <Accordion.Header>Приглашения</Accordion.Header>
                            <Accordion.Body className='p-0'>
                              <Invites
                                onlyRead={onlyRead}
                                unitType={'customer'}
                                unitId={currentCustomer?._id}
                              />
                            </Accordion.Body>
                          </Accordion>
                        )}
                      </Accordion.Body>
                    </Accordion.Item>
                  )}
                </Accordion>
              )}
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
};

export default CustomerDetailsEditScreen;
