import React, { useEffect, useState } from 'react';
import ToolsPanel from '../components/ToolsPanel/ToolsPanel';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Col, Container, ListGroup, Row } from 'react-bootstrap';
import {
  setAnimateMenuIcon,
  setCurrentProvider,
  setSelectedCitiesIds,
} from '../slices/authSlice';
import { toast } from 'react-toastify';
import { useCreateContractMutation } from '../slices/contractsApiSlice';
import { useCheckExistContractMutation } from '../slices/contractsApiSlice';
import { useSearchProvidersForCustomerQuery } from '../slices/customersApiSlice';
import { LinearProgress } from '@mui/material';
import Message from '../components/Message';
// import Checkbox from '@mui/material/Checkbox';
// import TextField from '@mui/material/TextField';
// import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
// import CheckBoxIcon from '@mui/icons-material/CheckBox';
// import SelectCity from '../components/MultiSelectInput';
import { useGetProviderCitiesQuery } from '../slices/providersApiSlice';
import MultiSelectInput from '../components/MultiSelectInput';

// const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
// const checkedIcon = <CheckBoxIcon fontSize='small' />;

const CustomerSearchProviderScreen = () => {
  const { search, state } = useLocation();
  const sp = new URLSearchParams(search);
  const sectionId = sp.get('section');
  const params = useParams();
  const { keyword } = params;

  const {
    theme,
    currentCustomer,
    pov,
    currentSection,
    searchString,
    selectedCitiesIds,
  } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [selectedSubjects, setSelectedSubjects] = useState([]);
  const [selectedCities, setSelectedCities] = useState([]);
  const [cities, setCities] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const {
    data: providerCities,
    refetch,
    isLoading: isLoadingCities,
    isError: isErrorCities,
    error: errorLoadingCities,
  } = useGetProviderCitiesQuery({});

  useEffect(() => {
    if (Array.isArray(providerCities?.rows)) {
      if (providerCities?.rows.length < 10) {
        let cities = [];
        for (let index = 0; index < providerCities?.rows.length; index++) {
          const sub = providerCities?.rows[index];
          if (Array.isArray(sub.cities)) {
            cities = cities.concat(sub.cities);
          }
        }
        setCities(cities);
      } else {
        setSubjects(providerCities.rows);
      }
    }
  }, [providerCities]);

  useEffect(() => {
    if (!searchString && keyword) {
      navigate('/customer/search/provider');
    }
  }, [searchString]);
  // const [value, setValue] = React.useState(options[0]);
  // const [inputValue, setInputValue] = React.useState('');
  // console.log('sectionId', sectionId);

  const queryData = {
    pov,
    sectionId: sectionId,
    keyword: keyword,
    customerId: currentCustomer?._id,
    cities: selectedCitiesIds,
  };

  //console.log('queryData', queryData);

  const { data, isLoading, isError, error } =
    useSearchProvidersForCustomerQuery(queryData);
  //console.log('search data', data);

  useEffect(() => {
    //console.log('search data', data);
    if (!Array.isArray(data?.rows) || data?.rows?.length === 0) {
      //помигаем кнопкой мобильному пользователю о необходимости открыть меню
      dispatch(setAnimateMenuIcon(true));
    }
  }, [data, dispatch]);

  const [checkContract] = useCheckExistContractMutation();
  const [createContract] = useCreateContractMutation();

  const handleSelectProvider = async (selectedProvider) => {
    let url = '';
    if (sectionId) {
      url = `/customer/about/provider/${selectedProvider._id}?back=?section=${sectionId}`;
    } else if (keyword) {
      url = `/customer/about/provider/${selectedProvider._id}?back=${keyword}`;
    }

    let contractExist = false;
    //Проверим, существует ли договор с этим поставщиком
    // console.log('checkContract');
    try {
      await checkContract({
        customerId: currentCustomer?._id,
        providerId: selectedProvider?._id,
      }).unwrap();
      //переносим пользователя в корзину
      contractExist = true;
    } catch (error) {
      //договор не найден
      //toast.error(error?.data?.message || error?.message || error.error);
    }

    //Создадим новый договор
    if (!contractExist) {
      //поля договора
      const payload = {
        customerId: currentCustomer?._id,
        providerId: selectedProvider?._id,
      };

      // console.log('createContract', payload);
      try {
        await createContract(payload).unwrap();
      } catch (error) {
        toast.error(error?.data?.message || error?.message || error.error);
        return;
      }
    }

    dispatch(setCurrentProvider(selectedProvider));
    navigate(url);
  };

  let title = 'Поиск поставщиков';
  if (currentSection?._id) {
    title = 'Категория: ' + currentSection?.name;
  }
  if (searchString) {
    title = 'Поиск по фразе: ' + searchString;
  }

  const handleMultiSelectCities = (event, th) => {
    const {
      target: { value },
    } = event;
    const selected = typeof value === 'string' ? value.split(',') : value;
    // setSelectedCities(selected);
    dispatch(setSelectedCitiesIds(selected));
    refetch();
  };

  const handleMultiSelectSubjects = (event, th) => {
    const {
      target: { value },
    } = event;
    const selected = typeof value === 'string' ? value.split(',') : value;
    setSelectedSubjects(selected);
    // console.log('selected subjects', selected);
    // console.log('selected cities', selectedCities);

    const filtered = subjects.filter((item) => selected.includes(item.name));
    let cities = [];
    for (let index = 0; index < filtered.length; index++) {
      const sub = filtered[index];
      if (Array.isArray(sub.cities)) {
        cities = cities.concat(sub.cities);
      }
    }
    setCities(cities);
    // console.log('cities', cities);
    const _selectedCities = [];
    for (let index = 0; index < selectedCities.length; index++) {
      const city = selectedCities[index];

      const exist = cities.find((item) => item._id === city);
      // console.log(city, !!exist);
      if (exist) {
        _selectedCities.push(city);
      }
    }
    // setSelectedCities(_selectedCities);
    dispatch(setSelectedCitiesIds(_selectedCities));
    refetch();
  };

  return (
    <>
      <ToolsPanel
        backURL={state?.backUrl ? state?.backUrl : `/customer/select/provider/`}
        title={title}
      />
      {isLoading ? (
        <LinearProgress />
      ) : isError ? (
        <Message>{error?.data?.message || error.error}</Message>
      ) : (
        <>
          <Container>
            <Row className='mb-2'>
              {Array.isArray(subjects) && subjects.length > 0 && (
                <Col md={12} lg={6}>
                  <MultiSelectInput
                    selectedItems={selectedSubjects}
                    handleMultiSelect={handleMultiSelectSubjects}
                    items={subjects}
                    label='Фильтр по регионам'
                  />
                </Col>
              )}
              {Array.isArray(cities) && cities.length > 0 && (
                <Col md={12} lg={6}>
                  <MultiSelectInput
                    selectedItems={selectedCitiesIds}
                    handleMultiSelect={handleMultiSelectCities}
                    items={cities}
                    label='Выберите ближайший к вам город'
                  />
                </Col>
              )}
            </Row>
            <Row>
              <Col>
                <ListGroup>
                  {Array.isArray(data?.rows) &&
                    data?.rows?.map((el) => (
                      <ListGroup.Item
                        variant={theme}
                        action
                        key={el._id}
                        onClick={() => handleSelectProvider(el, false)}
                      >
                        {el.name}
                      </ListGroup.Item>
                    ))}
                </ListGroup>
              </Col>
            </Row>
          </Container>
        </>
      )}
    </>
  );
};

export default CustomerSearchProviderScreen;
