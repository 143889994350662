import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
  Stack,
  Tab,
  Tabs,
} from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';

import { toast } from 'react-toastify';

import SelectUnit from '../components/SelectUnit/SelectUnit';
import Message from '../components/Message';
import {
  useCreateProductMutation,
  useGetProductDetailsQuery,
  useUpdateProductMutation,
  useUploadProductImageMutation,
} from '../slices/productsApiSlice';
import { useDispatch, useSelector } from 'react-redux';
import ToolsPanel from '../components/ToolsPanel';
import { setCurrentGroup } from '../slices/authSlice';
import styles from './providerProductEditScreen.module.css';
import { LinearProgress } from '@mui/material';
import PriceList from '../components/Product/PriceList';
// import {
//   useGetProviderGroupsQuery,
//   useGetProvidersQuery,
// } from '../../slices/providersApiSlice';

const ProviderProductEditScreen = () => {
  const { productId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    theme,
    pov,
    currentGroup,
    currentProvider,
    currentBreakpoint,
    searchString,
  } = useSelector((state) => state.auth);

  //состояния для механизма выбора из модального окна
  const [showSelectWindow, setShowSelectWindow] = useState(false);
  //const [unitRows, setUnitRows] = useState([]);
  // const [selectedUnit, setSelectedUnit] = useState();
  const [modalTitle, setModalTitle] = useState('');
  // const [ownerId, setOwnerId] = useState('');
  const [tabKey, setTabKey] = useState('params');

  //состояния для характеристик товара
  const [name, setName] = useState('');
  const [shortName, setShortName] = useState('');
  const [code, setCode] = useState('');
  // const [parentCode, setParentCode] = useState('');
  const [image, setImage] = useState('');
  const [description, setDescription] = useState('');
  const [unit, setUnit] = useState('');
  const [multiple, setMultiple] = useState(1);
  const [hide, setHide] = useState(false);
  const [group, setGroup] = useState(currentGroup);

  const [uploadProductImage] = useUploadProductImageMutation();

  const [updateProduct, { isLoading: loadingUpdate }] =
    useUpdateProductMutation();
  const [createProduct, { isLoading: loadingCreate }] =
    useCreateProductMutation();

  const {
    data: product,
    refetch,
    isLoading,
    isError,
    error,
  } = useGetProductDetailsQuery({
    productId,
    providerId: currentProvider?._id,
  });

  console.log(product);

  useEffect(() => {
    if (product && productId !== 'new') {
      setName(product.name);
      setShortName(product?.shortName || '');
      setGroup(product?.group || '');
      setCode(product?.code || '');
      setMultiple(product?.multiple || 1);
      setImage(product.image || '');
      setUnit(product.unit || '');
      setHide(product.hide || '');
      setDescription(product.description || '');
    }
    // eslint-disable-next-line
  }, [product]);

  const updateHandler = async (withClose) => {
    const payload = {
      _id: productId,
      name,
      shortName,
      code,
      parentCode: group?._id, //старый вариант
      groupId: group?._id,
      image,
      unit,
      hide,
      description,
      multiple,
      providerId: currentProvider?._id,
    };

    // console.log(payload);
    // return;

    try {
      if (productId === 'new') {
        await createProduct(payload).unwrap();
      } else {
        await updateProduct(payload).unwrap();
      }

      toast.success(`Товар ${productId === 'new' ? 'создан' : 'обновлён'}`);
      if (currentGroup?._id !== group?._id) {
        dispatch(setCurrentGroup(group));
      }

      navigate(`/provider/products/${group?._id ? group?._id : ''}`);
    } catch (error) {
      toast.error(error?.data?.message || error?.message || error.error);
    }
  };

  const submitHandler = (event) => {
    event.preventDefault();
    if (!name) {
      toast.warning('Пустое наименование недопустимо');
      return;
    }
    if (!group) {
      toast.warning('Группа не выбрана');
      return;
    }

    updateHandler(event.target.getAttribute('close') === 'true');
    refetch();
  };

  const uploadFileHandler = async (e) => {
    const formData = new FormData();
    formData.append('image', e.target.files[0]);
    try {
      const res = await uploadProductImage(formData).unwrap();

      if (res?.image) {
        setImage(res.image);
      } else {
        e.preventDefault();
        toast.warn(res?.message);
      }
    } catch (error) {
      toast.error(error?.data.message || error?.message || error.error);
    }
  };

  const handleSelectGroup = (item) => {
    // console.log(item);
    setGroup(item);
    setShowSelectWindow(false);
  };

  const handleChangeUnit = (value) => {
    if (value.length <= 4) {
      setUnit(value);
    }
  };

  const selectGroup = () => {
    setModalTitle('Группы');
    setShowSelectWindow(true);
  };

  const handleClickCreateAction = () => {
    navigate(`/provider/news/new?pid=${productId}`);
  };

  const themeStyle = {
    backgroundColor: theme === 'dark' ? '#212529' : 'white',
    borderColor: theme === 'dark' ? '#212529' : '#b5c0c1',
    color: theme === 'dark' ? 'white' : 'black',
  };

  return (
    <>
      <ToolsPanel
        backURL={
          searchString
            ? `/provider/products/search/${searchString}`
            : `/provider/products/${currentGroup?._id && currentGroup._id}`
        }
        title={productId === 'new' ? 'Создание товара' : `Редактор товара`}
        isLoading={loadingUpdate || loadingCreate}
      />
      {isLoading ? (
        <LinearProgress />
      ) : isError && productId !== 'new' ? (
        <Message>
          {error?.data?.message || error?.error || error?.message}
        </Message>
      ) : (
        <Container>
          <Row>
            {/* <Col sx={12} sm={12} md={12} lg={6}> */}
            <Col lg={12} xxl={8}>
              <Tabs
                defaultActiveKey='params'
                id='uncontrolled-tab'
                activeKey={tabKey}
                onSelect={(k) => setTabKey(k)}
                // justify
                // fill
              >
                <Tab eventKey='params' title='Свойства'>
                  <Form onSubmit={submitHandler}>
                    <Card className=' bg-transparent p-3'>
                      <Row>
                        <Col sx={12} sm={12} md={12} lg={6}>
                          <Form.Group controlId='name' className='my-2'>
                            <Form.Label>Наименование</Form.Label>
                            <Form.Control
                              style={themeStyle}
                              type='text'
                              placeholder='Введите наименование товара'
                              value={name}
                              onChange={(e) => setName(e.target.value)}
                            ></Form.Control>
                          </Form.Group>

                          <Form.Group controlId='shortname' className='my-2'>
                            <Form.Label>Краткое наименование</Form.Label>
                            <Form.Control
                              style={themeStyle}
                              type='text'
                              placeholder='Введите краткое наименование'
                              value={shortName}
                              onChange={(e) => setShortName(e.target.value)}
                            ></Form.Control>
                          </Form.Group>

                          <Form.Group
                            controlId='hide'
                            className='my-2'
                            as={Row}
                          >
                            <Col sm='12'>
                              <Form.Check
                                isValid
                                type='checkbox'
                                label='Скрыть товар от покупателей'
                                checked={hide}
                                onChange={(e) =>
                                  setHide(Boolean(e.target.checked))
                                }
                              ></Form.Check>
                            </Col>
                          </Form.Group>

                          <Form.Group controlId='provider' className='my-2'>
                            <Form.Label>Группа в левом меню</Form.Label>
                            <Form.Control
                              style={themeStyle}
                              type='text'
                              placeholder='Выберите группу меню'
                              value={group?.name || ''}
                              onClick={() => selectGroup()}
                              //onChange={(e) => onChangeHandler}
                              readOnly={true}
                            ></Form.Control>
                          </Form.Group>

                          <Stack direction='horizontal' gap={2}>
                            <Form.Group controlId='unit' className='my-2'>
                              <Form.Label>Ед.изм. (1-4 cимв.)</Form.Label>
                              <Form.Control
                                style={themeStyle}
                                type='text'
                                placeholder='1-3 символа'
                                value={unit}
                                onChange={(e) =>
                                  handleChangeUnit(e.target.value)
                                }
                              ></Form.Control>
                            </Form.Group>
                            <Form.Group controlId='code' className='my-2'>
                              <Form.Label>Код поставщика</Form.Label>
                              <Form.Control
                                style={themeStyle}
                                type='text'
                                placeholder='Введите код из вашей системы учета'
                                value={code}
                                onChange={(e) => setCode(e.target.value)}
                              ></Form.Control>
                            </Form.Group>
                          </Stack>
                          <Stack direction='horizontal' gap={2}>
                            <Form.Group controlId='multiple' className='my-2'>
                              <Form.Label>Колво в упаковке</Form.Label>
                              <Form.Control
                                style={themeStyle}
                                type='number'
                                placeholder='число'
                                value={multiple}
                                onChange={(e) => setMultiple(e.target.value)}
                              ></Form.Control>
                            </Form.Group>
                          </Stack>
                          <Form.Group controlId='description' className='my-2'>
                            <Form.Label>Описание товара</Form.Label>
                            <Form.Control
                              style={themeStyle}
                              as={'textarea'}
                              type='text'
                              placeholder='Введите описание товара'
                              value={description}
                              onChange={(e) => setDescription(e.target.value)}
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                        <Col sx={12} sm={12} md={12} lg={6}>
                          {image && (
                            <Card.Img
                              className='rounded-0'
                              src={image}
                              alt={name}
                            />
                          )}
                          <Form.Group controlId='image' className='my-2'>
                            <Form.Label>Картинка / фотография</Form.Label>
                            <Form.Control
                              readOnly={true}
                              style={themeStyle}
                              type='text'
                              placeholder='Имя файла'
                              value={image}
                              onChange={(e) => setImage(e.target.value)}
                            ></Form.Control>
                            <Form.Control
                              style={themeStyle}
                              type='file'
                              label='Выберите файл (jpg png)'
                              onChange={uploadFileHandler}
                            ></Form.Control>
                          </Form.Group>
                          <Form.Group className='my-2'>
                            <Form.Label>Идентификатор товара:</Form.Label>
                            <Row>
                              <Col
                                className='d-flex align-items-center'
                                onClick={() => {
                                  navigator.clipboard.writeText(product?._id);
                                }}
                              >
                                <label className={styles.tooltip}>
                                  {product?._id}
                                  <input
                                    type='checkbox'
                                    onClick={(e) =>
                                      setTimeout(() => {
                                        e.target.checked = false;
                                      }, 2000)
                                    }
                                  />
                                  <span>скопирован</span>
                                </label>
                              </Col>
                              <Col>
                                {productId !== 'new' && productId && (
                                  <Button onClick={handleClickCreateAction}>
                                    Создать акцию
                                  </Button>
                                )}
                              </Col>
                            </Row>
                          </Form.Group>
                        </Col>
                      </Row>
                    </Card>
                    <Stack
                      direction='horizontal'
                      gap={3}
                      //class='justify-content-center'
                    >
                      <Button
                        type='submit'
                        variant='primary'
                        className='my-2'
                        size={
                          ['lg', 'xl', 'xxl'].includes(currentBreakpoint)
                            ? 'lg'
                            : 'sm'
                        }
                      >
                        Сохранить
                      </Button>

                      <Button
                        size={
                          ['lg', 'xl', 'xxl'].includes(currentBreakpoint)
                            ? 'lg'
                            : 'sm'
                        }
                        variant='primary'
                        className='my-2'
                        onClick={() =>
                          navigate(
                            `/provider/products/${
                              currentGroup && currentGroup._id
                            }`
                          )
                        }
                      >
                        Закрыть
                      </Button>
                    </Stack>
                  </Form>
                </Tab>
                <Tab
                  eventKey='prices'
                  title='Цены'
                  disabled={productId === 'new'}
                >
                  {tabKey === 'prices' && <PriceList productId={productId} />}
                </Tab>
              </Tabs>
            </Col>
          </Row>
        </Container>
      )}

      <SelectUnit
        show={showSelectWindow}
        setShow={setShowSelectWindow}
        setSelected={handleSelectGroup}
        payload={{ providerId: currentProvider?._id, pov, all: true }}
        title={modalTitle}
        owner={currentProvider?._id}
      />
    </>
  );
};

export default ProviderProductEditScreen;
