import React, { useEffect, useState } from 'react';
import ToolsPanel from '../components/ToolsPanel';
import { Col, Container, Row } from 'react-bootstrap';
import { FaPlus, FaRoute } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { useGetContractsQuery } from '../slices/contractsApiSlice';
import DataTable from '../components/DataTable/DataTable';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { setSearchString } from '../slices/authSlice';

const ProviderContractsScreen = () => {
  const params = useParams();
  // console.log('params', params);
  const { keyword, regionId = 'all' } = params;

  // const [_keyword, setKeyword] = useState('');
  // const [_regionId, setRegionId] = useState('');

  // useEffect(() => {
  //   setRegionId(regionId);
  //   // eslint-disable-next-line
  // }, [regionId]);

  // console.log('_keyword', _keyword, '_regionId', _regionId);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    theme,
    currentBreakpoint,
    currentProvider,
    leftMenuBinded,
    searchString,
  } = useSelector((state) => state.auth);

  useEffect(() => {
    if (!keyword) {
      dispatch(setSearchString(''));
    }
    // eslint-disable-next-line
  }, [keyword]);

  useEffect(() => {
    if (!searchString && keyword) {
      navigate('/provider/contracts/');
    }
    // eslint-disable-next-line
  }, [searchString]);

  const { data, isLoading, isError, error } = useGetContractsQuery(
    {
      providerId: currentProvider?._id,
      keyword,
      regionId,
    },
    { refetchOnMountOrArgChange: true }
  );

  useEffect(() => {
    if (isError) {
      toast.error(error?.data?.message || error.error);
    }
    // eslint-disable-next-line
  }, [isError]);

  // console.log(data);
  const rows = data?.rows?.map((item) => ({
    ...item,
    id: item._id,
    customerId: item.customer?._id,
    customer: item.customer?.name,
    price: item.price?.name,
    regions: Array.isArray(item?.regions)
      ? item.regions
          .map((r) =>
            ['xs', 'sm'].includes(currentBreakpoint)
              ? r?.shortName || r.name
              : r.name
          )
          .join(', ')
      : '',
    createdAt: new Date(item?.createdAt),
  }));

  const columns = [
    { field: 'id', headerName: 'id', width: 50, hide: true },
    { field: 'customerId', headerName: 'customerId', width: 50, hide: true },
    { field: 'priceId', headerName: 'priceId', width: 50, hide: true },
    {
      field: 'customer',
      headerName: 'Покупатель',
      flex: 1,
    },
    {
      field: 'regions',
      headerName: ['xs', 'sm'].includes(currentBreakpoint) ? 'М' : 'Группы',
      flex: ['xs', 'sm'].includes(currentBreakpoint) ? 0.5 : 0.6,
      // cellClassName:(params)=>{ return ''}
    },
    {
      field: 'price',
      headerName: 'Тип цен',
      flex: 0.5,
    },
    {
      field: 'canReturn',
      headerName: 'Возврат',
      flex: 0.2,
      type: 'boolean',
    },
    {
      field: 'providerAgree',
      headerName: 'Утв',
      width: 20,
      type: 'boolean',
    },
    {
      field: 'customerAgree',
      headerName: 'Он',
      width: 20,
      type: 'boolean',
    },
    {
      field: 'createdAt',
      headerName: 'от..',
      flex: 0.4,
      type: 'date',
    },
  ];

  const visibilityColumns = {};
  visibilityColumns.id = false;
  visibilityColumns.customerId = false;
  visibilityColumns.priceId = false;
  if (currentBreakpoint === 'xs') {
    visibilityColumns.createdAt = false;
    visibilityColumns.customerAgree = false;
    visibilityColumns.canReturn = false;
    visibilityColumns.price = false;
  }
  if (currentBreakpoint === 'sm') {
    visibilityColumns.customerAgree = false;
    visibilityColumns.canReturn = false;
    visibilityColumns.price = leftMenuBinded ? false : true;
  }
  if (currentBreakpoint === 'md') {
    visibilityColumns.customerAgree = false;
    visibilityColumns.canReturn = false;
  }

  const handleOnSelectCell = (data) => {
    // let states = {};

    // console.log('searchString', searchString);
    // console.log('keyword', keyword);
    // console.log('regionId', regionId);

    // if (searchString) {
    //   states = { backURL: `/provider/contracts/search/${searchString}` };
    // } else if (regionId) {
    //   states = { backURL: `/provider/contracts/region/${regionId}` };
    // }

    if (data?.row?.id) {
      // console.log('states', states);
      navigate(`/provider/contracts/${data.row.id}`);
      // navigate(`/provider/contracts/${data.row.id}`, { state: { ...states } });
    }
  };

  const handleClickAdd = () => {
    navigate('/provider/contracts/create');
  };

  const buttons = [];
  // buttons.push({
  //   className: `bg-transparent border-2`,
  //   name: 'Редактор марщрутов',
  //   type: 'component',
  //   action: () =>
  //     navigate(`/manager/routes/`, {
  //       state: { backURL: '/provider/contracts' },
  //     }),
  //   component: (props) => {
  //     return <FaRoute size={22} color={theme === 'dark' ? 'white' : 'black'} />;
  //   },
  // });

  buttons.push({
    className: `bg-transparent`,
    name: 'Добавить договор',
    type: 'component',
    action: handleClickAdd,
    component: (props) => {
      return <FaPlus size={22} color={theme === 'dark' ? 'white' : 'black'} />;
    },
  });

  return (
    <>
      <ToolsPanel
        title={keyword ? `Поиск по фразе: ${keyword}` : 'Все покупатели'}
        buttons={buttons}
        backURL={keyword ? '/provider/contracts' : '/provider'}
        // onClickBack={resetSearch}
        isLoading={isLoading}
      />
      <Container>
        <Row>
          <Col
            // md={12}
            // lg={8}
            className='mb-3'
          >
            {Array.isArray(rows) && (
              <DataTable
                rows={rows || []}
                columns={columns}
                columnVisible={{ id: false, ...visibilityColumns }}
                onSelectCell={handleOnSelectCell}
                checkboxSelection={false}
              />
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ProviderContractsScreen;
