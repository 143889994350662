import {
  Box,
  Checkbox,
  Chip,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
} from '@mui/material';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
//import { FormControl } from 'react-bootstrap';

const SelectPrice = ({
  selectedPrices,
  handleMultiSelectPrice,
  providerPrices,
}) => {
  return (
    <Row>
      <Col>
        <FormControl
          sx={{
            my: 1,
            minWidth: 300,
          }}
        >
          <InputLabel id='demo-multiple-checkbox-label'>
            Выберите тип цен
          </InputLabel>
          <Select
            labelId='multiple-checkbox-label'
            id='multiple-checkbox'
            multiple
            value={selectedPrices}
            onChange={(e) => handleMultiSelectPrice(e)}
            input={<OutlinedInput label='Выберите тип цен' />}
            renderValue={(selected) => (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected.map((value, index) => (
                  <Chip
                    key={index}
                    label={
                      providerPrices.find((item) => item._id === value).name
                    }
                  />
                ))}
              </Box>
            )}
            //MenuProps={MenuProps}
          >
            {providerPrices.map((item) => (
              <MenuItem key={item._id} value={item._id}>
                <Checkbox checked={selectedPrices.indexOf(item._id) > -1} />
                <ListItemText primary={item.name} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Col>
    </Row>
  );
};

export default SelectPrice;
