import React, { useContext } from 'react';
import { ListGroup } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useLogoutMutation } from '../../slices/usersApiSlice';
import { ColorModeContext } from '../../App';
import {
  logout,
  setCurrentCustomer,
  setCurrentProvider,
  setCurrentSection,
  setPov,
  setPriceEditMode,
  setSearchString,
} from '../../slices/authSlice';
import CartLink from '../CartLink/CartLink';
import { FaExpeditedssl, FaMask, FaMoon, FaSun, FaUser } from 'react-icons/fa';
import { setCartParams } from '../../slices/cartSlice';

const MenuUser = ({ onClick }) => {
  const { theme, pov, userInfo, currentProvider } = useSelector(
    (state) => state.auth
  );

  const colorMode = useContext(ColorModeContext);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [logoutApiCall] = useLogoutMutation();

  if (!userInfo?._id) {
    return;
  }

  const logoutHandler = async () => {
    try {
      await logoutApiCall().unwrap();
    } catch (error) {}
    dispatch(logout());
    navigate('/login');
  };

  const handleClickChangeRole = () => {
    navigate('/start');

    dispatch(setPov(false));
    dispatch(setCurrentCustomer(false));
    dispatch(setCurrentProvider(false));
    dispatch(setPriceEditMode(false));
    dispatch(setCurrentSection(''));
    dispatch(setSearchString(''));
    dispatch(setCartParams({}));
  };

  const handleClickCanvas = (to) => {
    if (typeof onClick === 'function') {
      onClick(false);
    }
    navigate(to);
  };

  const changeThemeHandle = () => {
    colorMode.toggleColorMode();
    if (typeof onClick === 'function') {
      onClick(false);
    }
  };

  // const handleSetGridSettingMode = () => {
  //   dispatch(setGridSettingMode(!gridSettingMode));
  //   if (typeof onClick === 'function') {
  //     onClick(false);
  //   }
  // };

  return (
    <>
      <ListGroup variant='flush'>
        {pov === 'customer' && (
          <>
            {currentProvider?._id && (
              <ListGroup.Item
                action
                onClick={() => handleClickCanvas('/customer/cart')}
                variant={theme}
              >
                <CartLink size={28} fontSize={20} />
              </ListGroup.Item>
            )}
          </>
        )}

        <ListGroup.Item variant={theme} action onClick={changeThemeHandle}>
          {theme === 'dark' ? <FaSun size={30} /> : <FaMoon size={30} />}
          {theme === 'light' ? ' Темная тема' : ' Светлая тема'}
        </ListGroup.Item>
        <ListGroup.Item
          variant={theme}
          action
          onClick={() => handleClickCanvas('/profile')}
        >
          <FaUser size={30} /> {userInfo?.name}
        </ListGroup.Item>
        <ListGroup.Item variant={theme} action onClick={handleClickChangeRole}>
          <FaMask size={30} /> Сменить роль
        </ListGroup.Item>
        <ListGroup.Item variant={theme} action onClick={logoutHandler}>
          <FaExpeditedssl size={30} /> Выйти из системы
        </ListGroup.Item>
      </ListGroup>
    </>
  );
};

export default MenuUser;
