import { UNITS_URL } from '../constans';
import { apiSlice } from './apiSlice';

export const unitsApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		updateUnit: builder.mutation({
			query: (data) => ({
				url: `${UNITS_URL}/${data._id}`,
				method: 'PUT',
				body: data
			}),
			invalidatesTags: ['Unit'] //очистить ранее созданный кеш
		}),
		createUnit: builder.mutation({
			query: (data) => ({
				url: `${UNITS_URL}`,
				method: 'POST',
				body: { ...data }
			}),
			invalidatesTags: ['Unit'] //очистить ранее созданный кеш
		}),
		getUnitDetails: builder.query({
			query: (data) => ({
				url: `${UNITS_URL}/${data.unitId}`,
				params: {
					...data,
					pov: data?.pov,
					unitType: data.unitType,
					filter: data.filter,
				}
			}),
			keepUnusedDataFor: 5,
			providesTags: ['Unit'],
		}),
		checkIsUnitAllowed: builder.mutation({
			query: (data) => ({
				url: `${UNITS_URL}/check`,
				method: 'POST',
				body: {
					unitId: data?.unitId,
					pov: data?.pov
				}
			}),
			keepUnusedDataFor: 5
		}),
		getUnits: builder.query({
			query: (data) => ({
				url: UNITS_URL,
				params: {
					pov: data?.pov,
					sectionId: data?.sectionId,
					keyword: data?.keyword,
					customerId: data?.customerId
				}
			}),
			keepUnusedDataFor: 5
		}),
		getContractors: builder.query({
			query: (unitId) => ({
				url: `${UNITS_URL}/contractors/${unitId}`,
			}),
			keepUnusedDataFor: 5
		}),
		deleteUnit: builder.mutation({
			query: (unitId) => ({
				url: `${UNITS_URL}/${unitId}`,
				method: 'DELETE'
			}),
			invalidatesTags: ['Unit'] //очистить ранее созданный кеш
		}),
	})
});

export const { useGetUnitsQuery,
	useCheckIsUnitAllowedMutation,
	useGetContractorsQuery,
	useGetUnitDetailsQuery,
	useCreateUnitMutation,
	useUpdateUnitMutation,
	useDeleteUnitMutation } = unitsApiSlice;