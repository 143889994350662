import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import {
  Accordion,
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
  Stack,
} from 'react-bootstrap';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Message from '../components/Message/Message';
import { toast } from 'react-toastify';
import SelectUnit from '../components/SelectUnit/SelectUnit';
import ToolsPanel from '../components/ToolsPanel';
import { useSelector } from 'react-redux';
import { LinearProgress } from '@mui/material';
import GroupListSimple from '../components/GroupList/GroupListSimple';
import { FaTrash } from 'react-icons/fa';
import {
  useCreateUpdateMultiTaskMutation,
  useCreateUpdateTaskMutation,
  useGetTaskDetailsQuery,
} from '../slices/tasksApiSlice';

import SelectCustomers from '../components/SelectCustomers';

const ProviderTaskEditScreen = () => {
  const params = useParams();
  const { taskId } = params;
  const { search } = useLocation();
  const sp = new URLSearchParams(search);
  const cid = sp.get('cid') || '';
  // console.log(taskId);
  const { pathname, state } = useLocation();
  const { backUrl } = state || {};
  // console.log('state', state);
  const isMultitask = pathname.includes('/multitasks');
  // const isNew = taskId === 'new';
  // const customerId = state ? state['customerId'] : '';
  // console.log(customerId);
  const navigate = useNavigate();
  const [showSelectWindow, setShowSelectWindow] = useState(false);
  //const [unitRows, setUnitRows] = useState([]);
  const [selectedUnit, setSelectedUnit] = useState({});
  const [modalTitle, setModalTitle] = useState('');

  const [multitask, setMultitask] = useState(isMultitask);
  const [tasks, setTasks] = useState([]);
  const [author, setAuthor] = useState({});
  const [customer, setCustomer] = useState({});
  const [checked, setChecked] = useState(false);
  const [user, setUser] = useState({});
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [comment, setComment] = useState('');
  const [photoRequired, setPhotoRequired] = useState(false);
  const [commentRequired, setCommentRequired] = useState(false);
  const [customerSelectMode, setCustomerSelectMode] = useState(false);
  const [customers, setCustomers] = useState([]);

  const { theme, currentBreakpoint, currentProvider, showLeftMenu } =
    useSelector((state) => state.auth);

  const [createMultitask, { isLoading: loadingUpdateMultitask }] =
    useCreateUpdateMultiTaskMutation();

  const [createUpdateTask, { isLoading: loadingUpdate }] =
    useCreateUpdateTaskMutation();

  useEffect(() => {
    if (selectedUnit) {
      if (modalTitle === 'Сотрудники') {
        setUser(selectedUnit);
      } else if (modalTitle === 'Покупатели') {
        setCustomer(selectedUnit);
      }
      // setManager(selectedUnit);

      setSelectedUnit('');
      setModalTitle('');
      setShowSelectWindow(false);
    }
    // eslint-disable-next-line
  }, [selectedUnit]);

  const { data, refetch, isLoading, isError, error } = useGetTaskDetailsQuery({
    taskId,
    customerId: cid,
    providerId: currentProvider?._id,
    multitask: isMultitask,
    edit: true,
  });

  // console.log('useGetTaskDetailsQuery', data);
  //console.log('managers', managers);

  useEffect(() => {
    if (data?.row) {
      const {
        multitask,
        tasks,
        title,
        author,
        user,
        description,
        comment,
        checked,
        contract,
        photoRequired,
        commentRequired,
        customers,
      } = data.row;

      if ('multitask' in data.row) setMultitask(multitask);
      if (Array.isArray(tasks)) setTasks(tasks);
      if (Array.isArray(customers)) setCustomers(customers);
      if (typeof customers === 'string') setCustomers(customers.split(','));

      setUser(user || {});
      setTitle(title || '');
      setDescription(description || '');
      setChecked(checked || false);
      setComment(comment || '');
      if (contract && contract?.customer) setCustomer(contract?.customer);
      setAuthor(author);
      setPhotoRequired(photoRequired);
      setCommentRequired(commentRequired);
    }
    if (Array.isArray(data?.customers)) {
      setCustomers(data.customers);
    }
    if (data?.customer) {
      setCustomer(data.customer);
    }
  }, [data]);

  const updateHandler = async () => {
    const toSave = {
      taskId: taskId,
      providerId: currentProvider._id,
      customerId: customer?._id,
      user: user?._id,
      title,
      description,
      comment,
      photoRequired,
      commentRequired,
    };

    if (multitask) {
      toSave.customerIds = customers.map((customer) => customer._id).join(',');
      toSave.multitask = true;
    }

    try {
      if (multitask) {
        await createMultitask(toSave).unwrap();
      } else {
        await createUpdateTask(toSave).unwrap();
      }
      refetch();
      if (multitask) {
        if (taskId === 'new') {
          navigate('/provider/multitasks');
        } else {
          navigate('/provider/multitasks/' + taskId);
        }
      } else {
        navigate('/provider/tasks');
      }
    } catch (error) {
      toast.error(error?.data?.message || error?.message || error.error);
    }
  };

  const selectUnit = (title) => {
    setModalTitle(title);
    setShowSelectWindow(true);
  };

  const handleClickSave = () => {
    updateHandler();
  };

  const handleClickClose = () => {
    if (multitask) {
      if (taskId === 'new') {
        navigate('/provider/multitasks');
      } else {
        navigate('/provider/multitasks/' + taskId);
      }
    } else {
      navigate('/provider/tasks');
    }
  };

  const handleSelectItems = (array) => {
    setCustomers(array);
    setCustomerSelectMode(false);
  };

  const themeStyle = {
    backgroundColor: theme === 'dark' ? '#212529' : 'white',
    borderColor: theme === 'dark' ? '#212529' : '#b5c0c1',
    color: theme === 'dark' ? 'white' : 'black',
  };

  // console.log('customer', customer);
  return (
    <>
      <ToolsPanel
        //buttons={buttons}
        onClickBack={
          customerSelectMode ? () => setCustomerSelectMode(false) : undefined
        }
        backURL={
          backUrl
            ? backUrl
            : isMultitask
            ? '/provider/multitasks'
            : '/provider/tasks'
        }
        title={`${isMultitask ? 'Групповое задание' : 'Персональное задание'} ${
          taskId === 'new' ? '(новое)' : '(редактирование)'
        }`}
        isLoading={loadingUpdate || loadingUpdateMultitask}
        subtitle={customerSelectMode && title}
      />
      {isLoading ? (
        <LinearProgress />
      ) : isError ? (
        <Message>{error?.data?.message || error.error}</Message>
      ) : (
        <Container>
          <Row>
            <Col sx={12} sm={12} md={12} lg={10} xxl={8}>
              <Form>
                {!customerSelectMode && (
                  <>
                    {/* <Card className=' bg-transparent px-2'> */}
                    {isMultitask && (
                      <Form.Group
                        controlId='isMultitask'
                        // className='my-2'
                        as={Row}
                      >
                        <Col sm='12'>
                          <Form.Check
                            disabled={true}
                            type='checkbox'
                            label='Мультизадача'
                            checked={multitask}
                            onChange={(ev) => setMultitask(ev.target.checked)}
                          ></Form.Check>
                        </Col>
                      </Form.Group>
                    )}
                    <Form.Group controlId='title' className='mb-2' as={Row}>
                      <Form.Label column sm='4' className='pb-0'>
                        Кратко
                      </Form.Label>
                      <Col sm='8'>
                        <Form.Control
                          style={themeStyle}
                          type='text'
                          placeholder='Описание'
                          value={title || ''}
                          onChange={(e) => setTitle(e.target.value)}
                        ></Form.Control>
                      </Col>
                    </Form.Group>
                    <Form.Group
                      controlId='description'
                      className='my-2'
                      as={Row}
                    >
                      <Form.Label column sm='4' className='pb-0'>
                        Описание задачи:
                      </Form.Label>
                      <Col sm='8'>
                        <Form.Control
                          as='textarea'
                          style={themeStyle}
                          type='text'
                          placeholder='если требуется'
                          value={description || ''}
                          onChange={(e) => setDescription(e.target.value)}
                        ></Form.Control>
                      </Col>
                    </Form.Group>
                    {!isMultitask && (
                      <>
                        <Form.Group controlId='user' className='my-2' as={Row}>
                          <Form.Label column sm='4' className='pb-0'>
                            Исполнитель
                          </Form.Label>
                          <Col sm='8'>
                            <Form.Control
                              style={themeStyle}
                              type='text'
                              placeholder='Выберите пользователя'
                              value={user?.name || ''}
                              onClick={() => selectUnit('Сотрудники')}
                              readOnly={true}
                            ></Form.Control>
                          </Col>
                        </Form.Group>
                        <Form.Group
                          controlId='customer'
                          className='my-2'
                          as={Row}
                        >
                          <Form.Label column sm='4' className='pb-0'>
                            Покупатель
                          </Form.Label>
                          <Col sm='8'>
                            <Form.Control
                              style={themeStyle}
                              type='text'
                              placeholder='Выберите покупателя'
                              value={customer?.name || ''}
                              onClick={() => selectUnit('Покупатели')}
                              readOnly={true}
                            ></Form.Control>
                          </Col>
                        </Form.Group>
                      </>
                    )}

                    <>
                      <Form.Group
                        controlId='photoRequired'
                        className='my-2'
                        as={Row}
                      >
                        <Col sm='12'>
                          <Form.Check
                            isValid
                            type='checkbox'
                            label='Требуется фотография'
                            checked={photoRequired}
                            onChange={(e) => setPhotoRequired(e.target.checked)}
                          ></Form.Check>
                        </Col>
                      </Form.Group>
                      <Form.Group
                        controlId='commentRequired'
                        className='my-2'
                        as={Row}
                      >
                        <Col sm='12'>
                          <Form.Check
                            isValid
                            type='checkbox'
                            label='Требуется комментарий'
                            checked={commentRequired}
                            onChange={(ev) =>
                              setCommentRequired(ev.target.checked)
                            }
                          ></Form.Check>
                        </Col>
                      </Form.Group>

                      <Stack
                        direction='horizontal'
                        gap={3}
                        //class='justify-content-center'
                      >
                        <Button
                          size={
                            ['lg', 'xl', 'xxl'].includes(currentBreakpoint)
                              ? 'lg'
                              : 'sm'
                          }
                          // type='submit'
                          onClick={handleClickSave}
                          variant='primary'
                          className='my-2'
                        >
                          Сохранить изменения
                        </Button>
                        <Button
                          size={
                            ['lg', 'xl', 'xxl'].includes(currentBreakpoint)
                              ? 'lg'
                              : 'sm'
                          }
                          variant='primary'
                          className='my-2'
                          onClick={handleClickClose}
                        >
                          Закрыть
                        </Button>
                      </Stack>

                      {isMultitask && (
                        <Accordion
                          className='me-1 mt-2'
                          defaultActiveKey='0'
                          // activeKey={'0'}
                        >
                          <Accordion.Item
                            eventKey='0'
                            style={{ backgroundColor: 'none !important' }}
                          >
                            <Accordion.Header>
                              {customerSelectMode
                                ? 'Подбор покупателей'
                                : 'Покупатели'}
                            </Accordion.Header>
                            <Accordion.Body>
                              <Button
                                size={
                                  ['lg', 'xl', 'xxl'].includes(
                                    currentBreakpoint
                                  )
                                    ? 'lg'
                                    : 'sm'
                                }
                                type='button'
                                variant='primary'
                                className='my-2'
                                onClick={() =>
                                  setCustomerSelectMode(!customerSelectMode)
                                }
                              >
                                {Array.isArray(customers) &&
                                customers.length > 0
                                  ? 'Изменить список'
                                  : 'Добавить покупателей'}
                              </Button>
                              <GroupListSimple
                                action={false}
                                variant={'flush'}
                                className='p-0 border-0'
                                rows={customers}
                                as='table'
                                orders={['name', 'checked']}
                                breakpoints={[11, 1]}
                                // buttons={[
                                //   {
                                //     onClick: removeCustomer,
                                //     icon: <FaTrash />,
                                //   },
                                // ]}
                              />
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      )}
                    </>
                    {/* </Card> */}
                  </>
                )}
              </Form>
            </Col>
            {customerSelectMode && isMultitask && (
              <Col sx={12} sm={12} md={12} lg={12}>
                {/* <Card className=' bg-transparent '> */}
                <Accordion
                  className='me-1 '
                  defaultActiveKey='0'
                  // activeKey={'0'}
                >
                  <Accordion.Item
                    eventKey='0'
                    style={{ backgroundColor: 'none !important' }}
                  >
                    <Accordion.Header>Подбор покупателей</Accordion.Header>
                    <Accordion.Body>
                      <SelectCustomers
                        close={() => setCustomerSelectMode(false)}
                        selectItems={handleSelectItems}
                        items={customers}
                        showLeftMenu={showLeftMenu}
                        theme={theme}
                      />
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
                {/* </Card> */}
              </Col>
            )}
          </Row>
        </Container>
      )}

      <SelectUnit
        show={showSelectWindow}
        setShow={setShowSelectWindow}
        setSelected={setSelectedUnit}
        //rows={unitRows}
        payload={{ providerId: currentProvider?._id }}
        title={modalTitle}
        owner={currentProvider?._id}
      />
    </>
  );
};

export default ProviderTaskEditScreen;
