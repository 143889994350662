import React from 'react';
import { FaAlignJustify } from 'react-icons/fa';
import { useSelector } from 'react-redux';
//import { setAnimateMenuIcon } from '../../slices/authSlice';
import './style.css';

// function useInterval(f, delay) {
//   const [timer, setTimer] = useState(null);
//   //здесь будем хранить идентификатор таймера, который запущен прямо сейчас

//   const start = () => {
//     if (timer) return; //таймер уже запущен, то игнорим повторный запуск
//     //запускаем вызов нашей функции f через каждые delay миллисекунд
//     setTimer(setInterval(f, delay));
//     //setInterval () возвращает положительное числовое целое ненулевое значение,
//     //которое определяет таймер
//   };

//   const stop = () => {
//     if (!timer) return; //таймер ещё не запущен, игнорим
//     //очищаем работу таймера с идентификатором, который ранее сохранили в состояние timer
//     setTimer(clearInterval(timer));
//   };

//   //при старте останавливаем
//   useEffect(() => stop, []);

//   //возвращаем кортеж с функциями и булевым  состоянием таймера на текущий момент
//   return [start, stop, timer != null];
// }

const DrawerIconButtom = ({ size }) => {
  //const [iconSize, setSize] = useState(30);
  const { animateMenuIcon } = useSelector((state) => state.auth);
  //const dispatch = useDispatch();
  //var oper = 1;
  // const [start, stop, running] = useInterval(
  //   (_) =>
  //     setSize((prevState) => {
  //       if (
  //         prevState === undefined ||
  //         prevState === 'undefined' ||
  //         prevState === 0
  //       ) {
  //         return 27;
  //       }
  //       if (prevState >= 30) {
  //         oper = -1;
  //       }
  //       if (prevState <= 24) {
  //         oper = 1;
  //       }
  //       //console.log(oper, prevState);
  //       return prevState + oper;
  //     }),

  //   //(x < 5 ? x + 1 : x < 30 ? x - 1 : x - 1)),
  //   40
  // ); // disabled={running}

  // const onClick = () => {
  //   //stop();
  //   if (animateMenuIcon) {
  //     dispatch(setAnimateMenuIcon(false));
  //   }
  // };

  // useEffect(() => {
  //   if (animateMenuIcon) {
  //     start();
  //   } else {
  //     stop();
  //   }
  // }, [animateMenuIcon, start, stop]);

  return (
    <span
      style={{ maxWidth: '30px', minWidth: '30px' }}
      className={animateMenuIcon ? 'pulse' : ''}
    >
      <FaAlignJustify size={size} />
    </span>
  );
};

export default DrawerIconButtom;
