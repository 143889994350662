import { TASKS_URL } from '../constans';
import { apiSlice } from './apiSlice';

export const tasksApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		createUpdateMultiTask: builder.mutation({
			query: (data) => ({
				url: `${TASKS_URL}/multi/${data?.taskId}`,
				method: 'POST',
				body: {
					...data
				}
			}),
			invalidatesTags: ['MultiTasks'],
		}),
		createUpdateTask: builder.mutation({
			query: (data) => ({
				url: `${TASKS_URL}/${data?.taskId}`,
				method: 'POST',
				body: {
					...data
				}
			}),
			invalidatesTags: ['Tasks', 'TaskUserList'],
		}),
		getMultiTaskDetails: builder.query({
			query: (data) => ({
				url: `${TASKS_URL}/multi/${data?._id}`,
				params: {
					...data
				}
			}),
			keepUnusedDataFor: 5
		}),
		getTaskDetails: builder.query({
			query: (data) => ({
				url: `${TASKS_URL}/${data?.taskId}`,
				params: {
					...data
				}
			}),
			keepUnusedDataFor: 5
		}),
		// getMultiTasks: builder.query({
		// 	query: (data) => ({
		// 		url: `${TASKS_URL}/multi/`,
		// 		params: {
		// 			...data
		// 		}
		// 	}),
		// 	// providesTags: ['Tasks'],
		// }),
		getProviderTasks: builder.query({
			query: (data) => ({
				url: `${TASKS_URL}/ofprovider`,
				params: {
					...data
				}
			}),
			// providesTags: ['Tasks'],
		}),
		getCustomerTasks: builder.query({
			query: (data) => ({
				url: `${TASKS_URL}/ofcustomer`,
				params: {
					...data
				}
			}),
			// providesTags: ['Tasks'],
		}),
		getUsersOfTasks: builder.query({
			query: (data) => ({
				url: `${TASKS_URL}/users`,
				params: {
					providerId: data?.providerId
				}
			}),
			providesTags: ['TaskUserList'],
		}),
		checkTasks: builder.query({
			query: (data) => ({
				url: `${TASKS_URL}/count`,
				method: 'GET',
				params: {
					...data
				}
			}),
			providesTags: ['Tasks'],
		}),
		deleteTask: builder.mutation({
			query: (data) => ({
				url: `${TASKS_URL}/${data?.taskId}`,
				method: 'DELETE',
				params: {
					...data
				}
			}),
		}),
		changeTaskStatus: builder.mutation({
			query: (data) => ({
				url: `${TASKS_URL}/${data?.taskId}`,
				method: 'PATCH',
				body: {
					...data
				}
			}),
			invalidatesTags: ['Tasks', 'TaskUserList']
		}),
	})
});

export const {
	useCreateUpdateMultiTaskMutation,
	useGetMultiTaskDetailsQuery,
	// useGetMultiTasksQuery,
	useGetUsersOfTasksQuery,
	useCheckTasksQuery,
	useChangeTaskStatusMutation,
	useDeleteTaskMutation,
	useCreateUpdateTaskMutation,
	useGetTaskDetailsQuery,
	useGetProviderTasksQuery,
	useGetCustomerTasksQuery,
} = tasksApiSlice;