import React from 'react';
import { useSelector } from 'react-redux';
import LeftMenu from '../../LeftMenu';
import { Box, Drawer } from '@mui/material';
import { DRAWER_WIDTH } from '../../../screens/MuiScreen';

const LeftSection = ({ handleDrawerClose }) => {
  const { userInfo, mobileMenuIsOpen, showLeftMenu } = useSelector(
    (state) => state.auth
  );

  const drawer = userInfo && <LeftMenu handleDrawerClose={handleDrawerClose} />;

  return (
    <Box
      component='nav'
      sx={{
        width: { sm: showLeftMenu ? DRAWER_WIDTH : 0 },
        flexShrink: { sm: 0 },
      }}
      aria-label='mailbox folders'
    >
      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
      <Drawer
        className='mobileMenu'
        variant='temporary'
        open={mobileMenuIsOpen}
        //onTransitionEnd={handleDrawerTransitionEnd}
        onClose={handleDrawerClose}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: 'block', sm: 'none' },
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: DRAWER_WIDTH,
            backgroundImage: 'none !important',
          },
        }}
      >
        {drawer}
      </Drawer>
      {showLeftMenu && (
        <Drawer
          className='desktopMenu'
          variant='permanent'
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: DRAWER_WIDTH,
              backgroundImage: 'none !important',
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      )}
    </Box>
  );
};

export default LeftSection;
