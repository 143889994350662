import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ToolsPanel from '../components/ToolsPanel/ToolsPanel';
import { FaEdit, FaFilePdf, FaRegEdit } from 'react-icons/fa';
import OrderView from '../components/orders/OrderView/OrderView.jsx';

const ProviderOrderDetailScreen = () => {
  const navigate = useNavigate();
  const { orderId } = useParams();
  const { state } = useLocation();
  const { theme } = useSelector((state) => state.auth);

  const handleClickShare = () => {
    navigate(`/pdf/${orderId || 'cart'}`);
  };
  const handleClickEditOrder = () => {
    navigate(`/provider/orders/edit/${orderId}`, {
      state: {
        backURL: orderId ? `/provider/orders/${orderId}` : `/provider/orders`,
      },
    });
  };

  const buttons = [];
  // buttons.push({
  //   // disabled: !(Array.isArray(orderItems) && orderItems.length > 0),
  //   className: `bg-transparent`,
  //   name: 'Редактировать',
  //   type: 'component',
  //   action: handleClickEditOrder,
  //   component: (props) => {
  //     return <FaEdit size={22} color={theme === 'dark' ? 'white' : 'black'} />;
  //   },
  // });
  // buttons.push({
  //   // disabled: !(Array.isArray(orderItems) && orderItems.length > 0),
  //   className: `bg-transparent`,
  //   name: 'Печать',
  //   type: 'component',
  //   action: handleClickShare,
  //   component: (props) => {
  //     return (
  //       <FaFilePdf size={22} color={theme === 'dark' ? 'white' : 'black'} />
  //     );
  //   },
  // });

  return (
    <>
      <ToolsPanel
        buttons={buttons}
        title={'Заявка'}
        isLoading={false}
        backURL={state?.backURL ? state?.backURL : '/provider/orders'}
      />
      <OrderView orderId={orderId} />
    </>
  );
};

export default ProviderOrderDetailScreen;
