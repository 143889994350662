import React from 'react';
import { Badge } from 'react-bootstrap';
import { FaShoppingCart } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { LinkContainer } from 'react-router-bootstrap';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { Badge as MuiBadge, Box, IconButton } from '@mui/material';
// import ShoppingCartSharpIcon from '@mui/icons-material/ShoppingCartSharp';
// import NotificationsIcon from '@mui/icons-material/Notifications';

const CartLink = ({
  size = 'small',
  badgeTitle = '',
  badgePos = 'right',
  fontSize = 24,
  className,
  variant = 'mui',
}) => {
  const { currentProvider, currentCustomer, currentBreakpoint } = useSelector(
    (state) => state.auth
  );
  const { cartItems } = useSelector((state) => state.cart);

  const cartQty = cartItems
    .filter(
      (item) =>
        item.providerId === currentProvider?._id &&
        item.customerId === currentCustomer?._id
    )
    .reduce((acc, item) => acc + 1, 0);

  if (variant === 'mui') {
    return (
      <LinkContainer to='/customer/cart' style={{ cursor: 'pointer' }}>
        <IconButton
          size='small'
          aria-label='new notifications'
          color='inherit'
          sx={{ mr: ['xs', 'sm'].includes(currentBreakpoint) ? 0 : 0 }}
        >
          {badgePos !== 'right' && <span className='ms-1'>{badgeTitle}</span>}
          <MuiBadge badgeContent={cartQty} color='warning'>
            <ShoppingCartIcon />
          </MuiBadge>
          {badgePos === 'right' && <span className='me-1'>{badgeTitle}</span>}
        </IconButton>
      </LinkContainer>
    );
  }

  return (
    <LinkContainer to='/customer/cart' style={{ cursor: 'pointer' }}>
      <span className={className}>
        {badgePos === 'right' && (
          <FaShoppingCart style={{ marginRight: '2px' }} size={size} />
        )}
        {cartQty > 0 && (
          <span style={{ fontSize: `${fontSize}px` }}>
            <Badge pill bg='warning' style={{ marginLeft: '5px' }}>
              {cartQty} {badgeTitle ? ' ' + badgeTitle : ''}
            </Badge>
          </span>
        )}
        {badgePos !== 'right' && (
          <FaShoppingCart style={{ marginRight: '5px' }} size={size} />
        )}
      </span>
    </LinkContainer>
  );
};

export default CartLink;
