import React from 'react';
import { useSelector } from 'react-redux';
import { AppBar, IconButton, Toolbar } from '@mui/material';
import DrawerIconButtom from '../../DrawerIconButtom';
import Header from '../../Header/Header';
import { DRAWER_WIDTH } from '../../../screens/MuiScreen';

const HeaderSection = ({ handleDrawerToggle, handleLeftMenuToggle }) => {
  const { pov, userInfo, theme, currentBreakpoint, showLeftMenu } = useSelector(
    (state) => state.auth
  );

  const getColor = () => {
    let povColor = {
      provider: {
        light: '#257e27 !important',
        dark: '#0f340c !important',
      },
      customer: {
        light: '#1976d2 !important',
        dark: '#15385B !important',
      },
    };
    let res = povColor[pov || 'provider'][theme || 'light'];
    return res;
  };

  return (
    <AppBar
      sx={{
        width: { sm: `calc(100% - ${showLeftMenu ? DRAWER_WIDTH : 0}px)` },
        ml: { sm: `${showLeftMenu ? DRAWER_WIDTH : 0}px` },
        bgcolor: getColor(),
      }}
      elevation={0}
    >
      <Toolbar>
        {userInfo && (
          <>
            <IconButton
              color='inherit'
              aria-label='open drawer'
              edge='start'
              onClick={handleDrawerToggle}
              sx={{
                mr: 2,
                display: { sm: 'none' },
              }}
            >
              <DrawerIconButtom />
            </IconButton>
            {['sm', 'md', 'lg', 'xl', 'xxl'].includes(currentBreakpoint) &&
              pov && (
                <IconButton
                  color='inherit'
                  aria-label='open drawer'
                  edge='start'
                  onClick={handleLeftMenuToggle}
                  sx={{
                    mr: 2,
                  }}
                >
                  <DrawerIconButtom />
                </IconButton>
              )}
          </>
        )}

        <Header />
      </Toolbar>
    </AppBar>
  );
};

export default HeaderSection;
