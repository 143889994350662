import { Alert, Box } from '@mui/material';
import { StyledGridOverlay } from '../StyledGridOverlay/StyledGridOverlay';
import { Button } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import NoRowOverlaySVG from '../NoRowOverlaySVG/NoRowOverlaySVG';

export function NoRowsOverlayRegions() {
  return (
    <StyledGridOverlay>
      <NoRowOverlaySVG />
      <Alert icon={false} security='info' variant='outlined'>
        Объединяйте ваших покупателей в группы и назначайте каждой группе
        менеджера. (торгового представителя, оператора обзвона и т.п.)
      </Alert>
      <Box sx={{ my: 1 }}>
        <LinkContainer to={'/provider/regions/new'}>
          <Button role='link' size='sm'>
            Создайте вашу первую группу
          </Button>
        </LinkContainer>
      </Box>
    </StyledGridOverlay>
  );
}
