import { INVITES_URL } from '../constans';
import { apiSlice } from './apiSlice';

export const invitesApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		activateInviteCode: builder.mutation({
			query: (invite) => ({
				url: `${INVITES_URL}/activate`,
				method: 'POST',
				body: {
					code: invite
				}
			}),
		}),
		createInvite: builder.mutation({
			query: (data) => ({
				url: `${INVITES_URL}`,
				method: 'POST',
				body: { ...data }
			}),
		}),
		getInvites: builder.query({
			query: (data) => ({
				url: `${INVITES_URL}/${data?.unitId}`,
				params: {
					pov: data?.pov
				}
			}),
			keepUnusedDataFor: 5
		}),
		clearInvite: builder.mutation({
			query: (data) => ({
				url: `${INVITES_URL}/delete`,
				method: 'POST',
				body: { ...data }
			}),
		}),
	})
});

export const {
	useActivateInviteCodeMutation,
	useGetInvitesQuery,
	useCreateInviteMutation,
	useClearInviteMutation } = invitesApiSlice;