
export const addDecimals = (num) => {
	return (Math.round(num * 100) / 100).toFixed(2);
}

export const calculateTotals = (cartItems) => {
	//Calculate items price
	const itemsPrice = addDecimals(cartItems.reduce((acc, item) => acc + (item?.price || 0) * item.qty, 0));
	const totalReturn = cartItems.reduce((acc, item) => acc + Number(item?.ret) || 0, 0);

	//Calculate shipping price (if order over 100 then free? or 10$)
	//state.shippingPrice = addDecimals(state.itemsPrice > 100) ? 0 : 10;
	const shippingPrice = 0;
	//Calculate tax price (15%)
	//state.taxPrice = addDecimals(Number((0.15 * state.itemsPrice).toFixed(2)));
	const taxPrice = 0;
	//Calculate total price
	const totalPrice = (
		Number(itemsPrice) +
		Number(shippingPrice) +
		Number(taxPrice)
	).toFixed(2);

	return { itemsPrice, shippingPrice, taxPrice, totalPrice, totalReturn };
}

export const updateCart = (state) => {

	// //Calculate items price
	// state.itemsPrice = addDecimals(state.cartItems.reduce((acc, item) => acc + (item?.price || 0) * item.qty, 0));
	// state.totalReturn = state.cartItems.reduce((acc, item) => acc + Number(item?.ret) || 0, 0);

	// //Calculate shipping price (if order over 100 then free? or 10$)
	// //state.shippingPrice = addDecimals(state.itemsPrice > 100) ? 0 : 10;
	// state.shippingPrice = 0;
	// //Calculate tax price (15%)
	// //state.taxPrice = addDecimals(Number((0.15 * state.itemsPrice).toFixed(2)));
	// state.taxPrice = 0;
	// //Calculate total price
	// state.totalPrice = (
	// 	Number(state.itemsPrice) +
	// 	Number(state.shippingPrice) +
	// 	Number(state.taxPrice)
	// ).toFixed(2);

	localStorage.setItem('cart', JSON.stringify(state)); //сохраняем корзину в локальное хранилище
}