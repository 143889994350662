import * as React from "react";
const AvatarCustomerColored = (props) => (
  <svg
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    style={{
      enableBackground: "new 0 0 512 512",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <path
      style={{
        fill: "#E6AF78",
      }}
      d="M304.892,216.838v-29.421H207.11v29.421c0,5.042-3.468,9.421-8.376,10.575l-67.221,15.817 c-9.816,2.311-16.754,11.069-16.754,21.153v44.583c0,9.751,7.904,17.655,17.655,17.655h247.172c9.751,0,17.655-7.904,17.655-17.655 v-44.583c0-10.083-6.937-18.842-16.753-21.152l-67.221-15.817C308.36,226.259,304.892,221.88,304.892,216.838z"
    />
    <path
      style={{
        fill: "#FFD782",
      }}
      d="M380.489,243.23l-56.302-13.247c-8.012,30.206-35.459,52.5-68.186,52.5s-60.174-22.294-68.185-52.5 l-56.302,13.247c-9.816,2.311-16.754,11.069-16.754,21.152v44.584c0,9.75,7.905,17.655,17.655,17.655h247.172 c9.75,0,17.655-7.905,17.655-17.655v-44.584C397.242,254.298,390.304,245.54,380.489,243.23z"
    />
    <path
      style={{
        fill: "#D29B6E",
      }}
      d="M207.109,216.838c0,3.647-1.831,6.927-4.692,8.911c64.448,32.288,102.475-31.559,102.475-31.559 v-6.774H207.11v29.421H207.109z"
    />
    <path
      style={{
        fill: "#F0C087",
      }}
      d="M185.38,59.756l4.734,104.146c0.442,9.729,5.213,18.752,13.004,24.595l22.462,16.846 c5.642,4.232,12.504,6.519,19.556,6.519h21.729c7.052,0,13.914-2.287,19.556-6.519l22.462-16.846 c7.791-5.844,12.562-14.866,13.004-24.595l4.734-104.146H185.38z"
    />
    <path
      style={{
        fill: "#E6AF78",
      }}
      d="M185.38,59.756l4.734,104.146c0.442,9.73,5.213,18.752,13.004,24.596l22.462,16.846 c5.642,4.232,12.504,6.519,19.556,6.519H256c-16.297-5.432-27.161-48.892-27.161-48.892l0.594-53.02 c0.049-4.372,3.293-8.014,7.619-8.646c28.672-4.181,65.273-12.436,89.57-36.479v-5.071L185.38,59.756L185.38,59.756z"
    />
    <path
      style={{
        fill: "#5A4146",
      }}
      d="M174.515,54.324v5.432l5.432,59.756h10.865l10.865-32.594c0,0,70.621,0,108.648-21.729l10.865,54.324 h10.865l5.432-59.756v-5.432C337.485,24.321,313.164,0,283.162,0h-54.324C198.836,0,174.515,24.321,174.515,54.324z"
    />
    <path
      style={{
        fill: "#F0C087",
      }}
      d="M337.144,128.052l-6.135,24.54c-0.727,2.908-3.339,4.947-6.336,4.947l0,0 c-3.294,0-6.072-2.453-6.481-5.721l-3.137-25.098c-0.839-6.711,4.394-12.64,11.158-12.64h0.021 C333.551,114.08,338.919,120.954,337.144,128.052z"
    />
    <path
      style={{
        fill: "#E6AF78",
      }}
      d="M174.856,128.052l6.135,24.54c0.727,2.908,3.339,4.947,6.336,4.947l0,0 c3.294,0,6.072-2.453,6.481-5.721l3.137-25.098c0.839-6.711-4.394-12.64-11.158-12.64h-0.021 C178.45,114.08,173.082,120.954,174.856,128.052z"
    />
    <g>
      <path
        style={{
          fill: "#FFC36E",
        }}
        d="M154.64,282.537l-35.201-31.622c-2.95,3.735-4.68,8.435-4.68,13.466v44.584 c0,9.751,7.904,17.655,17.655,17.655h31.236v-23.878C163.65,295.034,160.374,287.689,154.64,282.537z"
      />
      <path
        style={{
          fill: "#FFC36E",
        }}
        d="M357.361,282.537l35.201-31.622c2.95,3.735,4.68,8.435,4.68,13.466v44.584 c0,9.751-7.904,17.655-17.655,17.655H348.35v-23.878C348.35,295.034,351.626,287.689,357.361,282.537z"
      />
      <path
        style={{
          fill: "#FFC36E",
        }}
        d="M324.186,229.983c-8.011,30.206-35.458,52.5-68.185,52.5s-60.174-22.294-68.185-52.5l-17.15,4.035 l0.086,0.49c10.249,38.643,45.305,65.63,85.249,65.63s75-26.987,85.25-65.63l0.047-0.5L324.186,229.983z"
      />
    </g>
    <path
      style={{
        fill: "#FFCD78",
      }}
      d="M79.448,490.217l-39.452,20.74c-6.531,3.434-14.164-2.112-12.917-9.385l7.534-43.929L2.698,426.532 c-5.283-5.15-2.368-14.123,4.934-15.185l44.108-6.409l19.725-39.969c3.265-6.616,12.701-6.616,15.966,0l19.725,39.969l44.108,6.409 c7.302,1.062,10.217,10.034,4.934,15.185l-31.917,31.112l7.534,43.929c1.247,7.272-6.386,12.818-12.917,9.385L79.448,490.217z"
    />
    <path
      style={{
        fill: "#FFD782",
      }}
      d="M151.265,411.348l-44.108-6.409L87.431,364.97c-3.265-6.616-12.7-6.616-15.966,0L58.8,390.633 l70.056,119.095c2.195-1.91,3.531-4.825,2.961-8.156l-7.534-43.929l31.917-31.112C161.482,421.382,158.567,412.409,151.265,411.348z "
    />
    <path
      style={{
        fill: "#FFFFFF",
      }}
      d="M108.917,417.152c-6.982-1.592-12.478-7.087-14.069-14.069c-0.12-0.525-0.815-0.525-0.936,0 c-1.591,6.982-7.087,12.478-14.069,14.069c-0.525,0.12-0.525,0.815,0,0.936c6.982,1.591,12.478,7.087,14.069,14.069 c0.12,0.525,0.815,0.525,0.936,0c1.591-6.982,7.087-12.478,14.069-14.069C109.442,417.969,109.442,417.272,108.917,417.152z"
    />
    <path
      style={{
        fill: "#FFCD78",
      }}
      d="M256,490.217l-39.452,20.74c-6.531,3.434-14.164-2.112-12.917-9.385l7.534-43.929l-31.917-31.112 c-5.283-5.15-2.368-14.123,4.934-15.185l44.108-6.409l19.725-39.969c3.265-6.616,12.701-6.616,15.966,0l19.725,39.969l44.108,6.409 c7.302,1.062,10.217,10.034,4.934,15.185l-31.917,31.112l7.534,43.929c1.247,7.272-6.386,12.818-12.917,9.385L256,490.217z"
    />
    <path
      style={{
        fill: "#FFD782",
      }}
      d="M327.817,411.348l-44.108-6.409l-19.726-39.969c-3.265-6.616-12.7-6.616-15.966,0l-12.665,25.663 l70.056,119.095c2.195-1.91,3.531-4.825,2.961-8.156l-7.534-43.929l31.917-31.112C338.034,421.382,335.119,412.409,327.817,411.348z "
    />
    <path
      style={{
        fill: "#FFFFFF",
      }}
      d="M285.469,417.152c-6.982-1.592-12.478-7.087-14.069-14.069c-0.12-0.525-0.815-0.525-0.936,0 c-1.591,6.982-7.087,12.478-14.069,14.069c-0.525,0.12-0.525,0.815,0,0.936c6.982,1.591,12.478,7.087,14.069,14.069 c0.12,0.525,0.815,0.525,0.936,0c1.591-6.982,7.087-12.478,14.069-14.069C285.994,417.969,285.994,417.272,285.469,417.152z"
    />
    <path
      style={{
        fill: "#FFCD78",
      }}
      d="M432.552,490.217l-39.452,20.74c-6.531,3.434-14.164-2.112-12.917-9.385l7.534-43.929l-31.917-31.112 c-5.283-5.15-2.368-14.123,4.934-15.185l44.108-6.409l19.725-39.969c3.265-6.616,12.701-6.616,15.966,0l19.725,39.969l44.108,6.409 c7.302,1.062,10.217,10.034,4.934,15.185l-31.917,31.112l7.534,43.929c1.247,7.272-6.386,12.818-12.917,9.385L432.552,490.217z"
    />
    <path
      style={{
        fill: "#FFD782",
      }}
      d="M504.369,411.348l-44.108-6.409l-19.726-39.969c-3.265-6.616-12.7-6.616-15.966,0l-12.665,25.663 l70.056,119.095c2.195-1.91,3.531-4.825,2.961-8.156l-7.534-43.929l31.917-31.112C514.586,421.382,511.67,412.409,504.369,411.348z"
    />
    <path
      style={{
        fill: "#FFFFFF",
      }}
      d="M462.021,417.152c-6.982-1.592-12.478-7.087-14.069-14.069c-0.12-0.525-0.815-0.525-0.936,0 c-1.591,6.982-7.087,12.478-14.069,14.069c-0.525,0.12-0.525,0.815,0,0.936c6.982,1.591,12.478,7.087,14.069,14.069 c0.12,0.525,0.815,0.525,0.936,0c1.591-6.982,7.087-12.478,14.069-14.069C462.546,417.969,462.546,417.272,462.021,417.152z"
    />
  </svg>
);
export default AvatarCustomerColored;
