import React from 'react';
import { Container } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useDispatch, useSelector } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';
import { useUpdateCartItemMutation } from '../../slices/cartsApiSlice';
import { toast } from 'react-toastify';
import { addToCart, removeFromCart } from '../../slices/cartSlice';
import ProductCard from '../ProductCard/ProductCard';
import CustomerToolbar from '../DataTableToolbar/CustomerToolbar/CustomerToolbar';

const DataGrid = ({ rows, refetch, demo, canReturn }) => {
  const dispatch = useDispatch();
  const {
    showLeftMenu,
    theme,
    currentProvider,
    currentCustomer,
    gridSettingMode,
    showUnitColumn,
    showPrice,
    showReturnColumn,
    pov,
  } = useSelector((state) => state.auth);
  const [updateCartItemOnServer] = useUpdateCartItemMutation();

  // console.log(rows);
  const updateOnServer = async (data) => {
    try {
      await updateCartItemOnServer({ ...data });
    } catch (error) {
      toast.warn(error?.data?.message || error?.message);
    }
  };

  const debounced = useDebouncedCallback((payload) => {
    //  setValue(value);
    updateOnServer({
      ...payload,
    });
  }, 500);

  const handleOnUpdateRow = (data) => {
    // console.log(data);
    if (pov === 'customer') {
      const id = data?.newRow?.id;
      const qty = data?.newRow?.qty || 0;
      const ret = data?.newRow?.ret || 0;
      const price = data?.newRow?.price || 0;

      if (id) {
        if (qty === 0 && ret === 0) {
          dispatch(
            removeFromCart({
              id,
              providerId: currentProvider._id,
              customerId: currentCustomer._id,
            })
          );
        } else {
          dispatch(
            addToCart({
              ...data.newRow,
              _id: id,
              providerName: currentProvider.name,
              providerId: currentProvider._id,
              customerName: currentCustomer.name,
              customerId: currentCustomer._id,
            })
          );
        }

        debounced({
          providerId: currentProvider._id,
          customerId: currentCustomer._id,
          productId: id,
          qty,
          ret,
          price,
        });
      }
    }
  };
  // console.log(showLeftMenu);
  return (
    <>
      {gridSettingMode && <CustomerToolbar refetch={refetch} />}
      <Container className='p-0 m-0'>
        <Row
          xs={2}
          sm={showLeftMenu ? 2 : 2}
          md={showLeftMenu ? 2 : 3}
          lg={showLeftMenu ? 3 : 4}
          xl={showLeftMenu ? 4 : 5}
          xxl={6}
          className='g-2'
        >
          {rows &&
            rows
              .filter((item) => !item.noteditable === true)
              .map((item, idx) => (
                <Col
                  key={item?._id || item?.id}
                  className='d-flex  align-items-stretch'
                >
                  <ProductCard
                    // key={idx}
                    item={item}
                    idx={idx}
                    handleOnUpdateRow={handleOnUpdateRow}
                    theme={theme}
                    showPrice={showPrice}
                    showUnitColumn={showUnitColumn}
                    showReturnColumn={canReturn && showReturnColumn}
                  />
                </Col>
              ))}
        </Row>
      </Container>
    </>
  );
};

export default DataGrid;
