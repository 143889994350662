import { NEWS_URL } from '../constans';
import { apiSlice } from './apiSlice'

export const newsApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({

		getNews: builder.query({
			query: (data) => ({
				url: `${NEWS_URL}/provider/${data.providerId}`,
				params: {
					pov: data?.pov,
					providerId: data?.providerId,
					customerId: data?.customerId,
					visibility: data?.visibility,
					pageNumber: data?.pageNumber,
				}
			}),
			keepUnusedDataFor: 5,
			providesTags: ['News']
		}),
		getNewsById: builder.query({
			query: (data) => ({
				url: `${NEWS_URL}/${data?.newsId}`,
				params: {
					productId: data?.productId,
					providerId: data?.providerId,
				}
			}),
			keepUnusedDataFor: 5,
			providesTags: ['News']
		}),
		createNews: builder.mutation({
			query: (data) => ({
				url: `${NEWS_URL}/provider/${data?.providerId}`,
				method: 'POST',
				body: { ...data }
			}),
			invalidatesTags: ['News']
		}),
		updateNews: builder.mutation({
			query: (data) => ({
				url: `${NEWS_URL}/${data?._id}`,
				method: 'POST',
				body: data
			}),
			invalidatesTags: ['News']
		}),
		deleteNews: builder.mutation({
			query: (data) => ({
				url: `${NEWS_URL}/${data?._id}`,
				method: 'DELETE',
				body: { providerId: data?.providerId }
			}),
		}),
	})
})

export const {
	useCreateNewsMutation,
	useDeleteNewsMutation,
	useGetNewsByIdQuery,
	useGetNewsQuery,
	useUpdateNewsMutation
} = newsApiSlice;