import { REGIONS_URL } from '../constans';
import { apiSlice } from './apiSlice';

export const regionsApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		createUpdateRegion: builder.mutation({
			query: (data) => ({
				url: `${REGIONS_URL}/${data?.regionId}`,
				method: 'POST',
				body: {
					...data
				}
			}),
			invalidatesTags: ['Regions']
		}),
		getActiveRegions: builder.query({
			query: (data) => ({
				url: `${REGIONS_URL}/menu`,
				params: {
					providerId: data?.providerId,
				}
			}),
			providesTags: ['Regions'],
			keepUnusedDataFor: 5
		}),
		getRegionDetails: builder.query({
			query: (data) => ({
				url: `${REGIONS_URL}/${data?.regionId}`,
				params: {
					providerId: data?.providerId,
				}
			}),
			keepUnusedDataFor: 5
		}),
		getRegions: builder.query({
			query: (data) => ({
				url: `${REGIONS_URL}`,
				params: {
					...data
				}
			}),
		}),
		deleteRegion: builder.mutation({
			query: (data) => ({
				url: `${REGIONS_URL}/${data?.regionId}`,
				method: 'DELETE',
				params: {
					...data
				}
			}),
			invalidatesTags: ['Regions']
		}),
	})
});

export const {
	useGetActiveRegionsQuery,
	useDeleteRegionMutation,
	useCreateUpdateRegionMutation,
	useGetRegionDetailsQuery,
	useGetRegionsQuery
} = regionsApiSlice;