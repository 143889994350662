import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, ButtonGroup } from 'react-bootstrap';

const UnitSelectButton = () => {
  const navigate = useNavigate();
  const {
    pov,
    currentProvider,
    currentCustomer,
    userInfo,
    currentRoute,
    currentBreakpoint,
  } = useSelector((state) => state.auth);

  if (!pov) {
    return;
  }

  // const handleClickCloseUnit = () => {
  //   navigate('/');

  //   dispatch(setPov(false));
  //   dispatch(setCurrentCustomer(false));
  //   dispatch(setCurrentProvider(false));
  //   dispatch(setPriceEditMode(false));
  //   dispatch(setSearchString(''));
  // };

  const handleSelectUnit = () => {
    if (pov) {
      if (pov === 'customer' && currentRoute?._id) {
        navigate(`/customer/select/customer/${currentRoute?._id}`);
      } else {
        navigate(`${pov}/select/${pov}`);
      }
    } else {
      navigate(`/start`);
    }
  };

  return (
    <>
      {userInfo && (
        <>
          {pov === 'customer' && (
            <Button
              size='sm'
              className='m-0 text-nowrap'
              variant='light'
              onClick={handleSelectUnit}
            >
              {currentCustomer && currentCustomer.name
                ? currentCustomer.name.substring(
                    0,
                    ['xs', 'sm'].includes(currentBreakpoint) ? 18 : 25
                  )
                : 'Выберите покупателя'}
            </Button>
          )}
          {pov === 'provider' && (
            <Button
              size='sm'
              className='m-0 text-nowrap'
              variant='light'
              onClick={handleSelectUnit}
            >
              {currentProvider && currentProvider.name
                ? currentProvider.name.substring(
                    0,
                    ['xs', 'sm'].includes(currentBreakpoint) ? 18 : 25
                  )
                : 'Выберите поставщика'}
            </Button>
          )}
          {pov !== 'provider' && pov !== 'customer' && (
            <Button
              size='sm'
              className='m-0 text-nowrap'
              variant='light'
              onClick={handleSelectUnit}
            >
              Выберите
            </Button>
          )}
        </>
      )}
    </>
  );
};

export default UnitSelectButton;
