import { useGetProductsQuery } from '../slices/productsApiSlice.js';
import Message from '../components/Message/Message';
import { useNavigate, useParams } from 'react-router-dom';
import DataTable from '../components/DataTable/DataTable';
import ToolsPanel from '../components/ToolsPanel/ToolsPanel';
// import { LinearProgress } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import {
  useGetProviderPricesQuery,
  useSavePriceValueMutation,
} from '../slices/pricesApiSlice.js';
import SelectPrice from '../components/SelectPrice/SelectPrice.jsx';
import HistoryChart from '../components/HistoryChart/HistoryChart.jsx';
import BottomCanvas from '../components/BottomCanvas/BottomCanvas.jsx';
import { colsWidth } from '../utils/authUtils.js';
import ModalFrame from '../components/ModalFrame/ModalFrame.jsx';
import ProductCard from '../components/ProductCard/ProductCard.jsx';
import DataGrid from '../components/DataGrid/DataGrid.jsx';
import { FaCogs, FaPlus, FaTable } from 'react-icons/fa';
import {
  setCurrentGroup,
  setGridSettingMode,
  setSearchString,
  setSelectedProducts,
  setViewMode,
} from '../slices/authSlice.js';
import ProviderToolbar from '../components/DataTableToolbar/ProviderToolbar/';
import { Container } from 'react-bootstrap';
import { toast } from 'react-toastify';

const ProviderProductsScreen = () => {
  const params = useParams();
  const { groupId, pageNumber, keyword } = params;
  const dispatch = useDispatch();
  const [showHistory, setShowHistory] = useState(false);
  const [showProductCard, setShowProductCard] = useState(false);
  const navigate = useNavigate();

  const {
    gridSettingMode,
    currentProvider,
    currentGroup,
    currentBreakpoint,
    priceEditMode,
    showUnitColumn,
    showPrice,
    showReturnColumn,
    viewMode,
    pov,
    selectedProducts,
    theme,
    searchString,
  } = useSelector((state) => state.auth);

  const [selectedPrices, setSelectedPrices] = useState([]);
  const { data: providerPrices } = useGetProviderPricesQuery({
    providerId: currentProvider?._id,
    pov,
  });

  const queryData = {
    pov: pov,
    priceEditMode: priceEditMode,
    groupId,
    providerId: currentProvider?._id,
    keyword: keyword,
    pageNumber: pageNumber,
    pricesString: selectedPrices.length > 0 ? selectedPrices.toString() : '',
  };

  console.log('useGetProductsQuery queryData', queryData);

  const {
    data,
    refetch,
    isLoading: loadingProducts,
    isError,
    error,
  } = useGetProductsQuery(queryData, { refetchOnMountOrArgChange: true });

  // console.log(data);

  const [savePriceValue] = useSavePriceValueMutation();

  useEffect(() => {
    if (!currentGroup && groupId && groupId !== 'undefined' && data?.group) {
      dispatch(setCurrentGroup(data.group));
    }
    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    if (
      keyword &&
      (keyword !== 'undefined' || keyword !== undefined) &&
      !searchString
    ) {
      dispatch(setSearchString(keyword));
    }
    // eslint-disable-next-line
  }, [keyword]);

  let rows = [];
  if (data?.rows && data?.rows.length > 0) {
    rows = data.rows.map((item) => ({
      name: `${item.shortName || item.name} `.concat(
        showUnitColumn ? '' : `( ${item?.unit} )`
      ),
      unit: item?.unit,
      id: item._id,
      parentCode: item?.parentCode,
      image: item?.image,
      description: item?.description,
      hide: item?.hide,
      ...item.prices.reduce((result, item) => {
        var key = Object.keys(item)[0]; //0 - это ключ
        // if (priceEditMode) {
        result['price' + item[key]] = item['value'];
        // } else {
        //   result['price'] = item['value'];
        // }
        return result;
      }, {}),
    }));
  }

  const columns = [
    { field: 'id', headerName: '№', width: 50, hide: true },
    { field: 'hide', headerName: 'Скрыт', width: 50, hide: true },
    { field: 'image', headerName: '№', width: 50, hide: true },
    { field: 'description', headerName: '№', width: 50, hide: true },
    {
      field: 'name',
      headerName: 'Название',
      flex: 1,
      editable: false,
      cellClassName: 'grid-cell-name',
    },
    {
      field: 'parentCode',
      headerName: 'Код группы',
      width: 100,
      editable: false,
      sortable: false,
    },
  ];

  if (selectedPrices && Array.isArray(providerPrices?.rows)) {
    selectedPrices.forEach((item) => {
      const obj = providerPrices.rows.find((p) => p._id === item);
      const newColumn = {
        field: `price${item}`,
        headerName: obj.shortName || obj.name,
        width: 70,

        editable: true, //!isLoading,
        headerClassName: 'grid-header-price',
        cellClassName: 'grid-cell-price-edit',
        sortable: false,
      };
      columns.push(newColumn);
    });
  }

  columns.push({
    field: 'unit',
    headerName: 'Ед.',
    width: colsWidth.unit,
    editable: false,
    sortable: false,
    align: 'left',
    headerAlign: 'left',
    headerClassName: 'grid-header-unit',
    cellClassName: 'grid-cell-unit',
  });

  if (['xl', 'xxl'].includes(currentBreakpoint)) {
    columns.push({
      field: 'info',
      type: 'string',
      headerName: 'Информация',
      flex: 0.5,
      editable: false,
      headerAlign: 'left',
      // headerClassName: 'grid-header-order',
      // cellClassName: 'grid-cell-return',
    });
  }

  const visibilityColumns = {};
  visibilityColumns.parentCode = false;
  visibilityColumns.promo = false;
  visibilityColumns.image = false;
  visibilityColumns.description = false;
  visibilityColumns.hide = false;
  visibilityColumns.price = showPrice;
  visibilityColumns.ret = showReturnColumn;
  visibilityColumns.unit = showUnitColumn;

  const setRowSelectionModel = (data) => {
    dispatch(setSelectedProducts(data));
  };

  const handleOnSelectCell = (data) => {
    if (data?.row?.id && data.field === 'name') {
      navigate(`/provider/products/edit/${data.row.id}`);
    } else if (data.field === 'name' && data?.row?.image) {
      //setShowHistory({ id: data?.row?.id, name: data?.row?.name });
      setShowProductCard({
        id: data?.row?.id,
        name: data?.row?.name,
        data: data?.row,
      });
    }
  };
  const handleOnSelectRow = (rows) => {
    //console.log('handleOnSelectRow', rows);
    // if (rows?.row) {
    //   setShowHistory({ id: rows.row.id, name: rows.row.name });
    //   //   dispatch(
    //   //     setSelectedVendor({
    //   //       id: rows.row.id,
    //   //       name: rows.row.name,
    //   //       defaultPrice: rows.row.defaultPrice,
    //   //     })
    //   //   );
    //   //   if (selectedCustomer) {
    //   //     navigate(`/customers/${selectedCustomer.id}/${rows.row.id}`);
    //   //   }
    //}
  };

  const handleOnUpdateRow = async (data) => {
    try {
      const { id: updatedProductId } = data.newRow;
      const arrKeys = Object.keys(data.newRow);
      // Object.keys(data.newRow).forEach((col) => {

      for (let index = 0; index < arrKeys.length; index++) {
        const col = arrKeys[index];
        const first5 = col.substring(0, 5) === 'price';
        const length = col.length;
        let priceId = undefined;
        if (first5 && length > 5) {
          priceId = col.substring(5);
          const newValue = data.newRow[col];
          let oldValue = data.oldRow[col];

          if ((oldValue && oldValue !== newValue) || !oldValue) {
            const result = await savePriceValue({
              priceId,
              productId: updatedProductId,
              newValue,
              providerId: currentProvider?._id,
            }).unwrap();
            console.log(result);
            refetch();
            // } else if (!oldValue) {
            //   savePriceValue({
            //     priceId,
            //     productId: updatedProductId,
            //     newValue,
            //     providerId: currentProvider?._id,
            //   });
            //   refetch();
          }
        }
      }
    } catch (error) {
      toast.error(error?.data?.message || error?.message);
    }
  };

  const handleMultiSelectPrice = (event, th) => {
    const {
      target: { value },
    } = event;

    setSelectedPrices(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };

  const handleClickShowStuff = () => {
    dispatch(setGridSettingMode(!gridSettingMode));
    if (!gridSettingMode) {
      setRowSelectionModel([]);
    }
  };

  const handleClickCreateProduct = () => {
    navigate(`/provider/products/edit/new`);
  };

  const handleSwitchViewMode = () => {
    // console.log(viewMode);
    dispatch(setViewMode(viewMode === 'table' ? 'grid' : 'table'));
  };

  let buttons = [];
  // if (currentProvider?.role === 'a') {
  buttons.push({
    className: `bg-transparent`,
    title: 'Товар',
    name: 'Товар',
    type: 'component',
    action: handleClickCreateProduct,
    component: (props) => {
      return <FaPlus size={22} color={theme === 'dark' ? 'white' : 'black'} />;
    },
  });
  // }

  buttons.push({
    className: `bg-transparent ${
      viewMode !== 'table' && 'border-success  border-2'
    }`,
    name: 'Таблица',
    type: 'component',
    action: handleSwitchViewMode,
    component: (props) => {
      return <FaTable size={22} color={theme === 'dark' ? 'white' : 'black'} />;
    },
  });

  buttons.push({
    className: `bg-transparent ${gridSettingMode && 'border-success'}`,
    name: 'Инструменты',
    type: 'component',
    action: handleClickShowStuff,
    component: (props) => {
      return <FaCogs size={22} color={theme === 'dark' ? 'white' : 'black'} />;
    },
  });

  const handleOnHideModalProductCard = () => {
    setShowProductCard(false);
  };

  return (
    <>
      <ToolsPanel
        backURL={'/provider'}
        buttons={buttons}
        title={
          (keyword && `Поиск по фразе "${keyword}"`) || currentGroup?.name || ''
        }
      />
      {loadingProducts ? (
        <></>
      ) : isError ? (
        <Message>{error?.data?.message || error.error}</Message>
      ) : (
        <>
          <Container>
            {providerPrices?.rows && !gridSettingMode && (
              <SelectPrice
                selectedPrices={selectedPrices}
                handleMultiSelectPrice={handleMultiSelectPrice}
                providerPrices={providerPrices?.rows}
              />
            )}
            {Array.isArray(rows) && (
              <>
                {viewMode === 'table' ? (
                  <>
                    {gridSettingMode && <ProviderToolbar refetch={refetch} />}
                    <DataTable
                      rows={rows}
                      columns={columns}
                      columnVisible={{ id: false, ...visibilityColumns }}
                      onSelectRow={handleOnSelectRow}
                      onUpdateRow={handleOnUpdateRow}
                      onSelectCell={handleOnSelectCell}
                      setRowSelectionModel={setRowSelectionModel}
                      rowSelectionModel={selectedProducts}
                      checkboxSelection={gridSettingMode}
                      isLoading={loadingProducts}
                    />
                  </>
                ) : (
                  <DataGrid rows={rows} demo={true} />
                )}
              </>
            )}
          </Container>
          <BottomCanvas onHide={setShowHistory} item={showHistory}>
            <HistoryChart item={showHistory} />
          </BottomCanvas>
          <ModalFrame
            size='sm'
            show={!!showProductCard}
            onHide={handleOnHideModalProductCard}
            header={showHistory?.name}
          >
            <ProductCard
              item={showProductCard}
              onHide={handleOnHideModalProductCard}
            />
          </ModalFrame>
        </>
      )}
    </>
  );
};

export default ProviderProductsScreen;
