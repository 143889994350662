import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  InputGroup,
  ListGroup,
  Row,
  Stack,
} from 'react-bootstrap';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import {
  useCreateNewsMutation,
  useGetNewsByIdQuery,
  useUpdateNewsMutation,
} from '../slices/newsApiSlice';
import Message from '../components/Message';
import ToolsPanel from '../components/ToolsPanel';
import { useUploadProviderImageMutation } from '../slices/providersApiSlice';
// import { useGetProductFieldValueMutation } from '../slices/productsApiSlice';
// import { Alert } from '@mui/material';
import NewsItem from '../components/news/NewsItem/NewsItem';
// import { FaRegWindowClose, FaTimes } from 'react-icons/fa';
import { MobileDatePicker } from '@mui/x-date-pickers';
import { DateTime } from 'luxon';
// import SelectCustomers from '../components/SelectCustomers';
import SelectProducts from '../components/SelectProducts/SelectProducts';
import { FaTimes } from 'react-icons/fa';
// import ZoomableImage from '../components/Image/ZoomableImage';

const NOTHING = 0;
const FIX_VALUE = 1;
const SINGLE_VALUE = 2;
const SELECT_VALUE = 3;
// const PRESS_BUTTON = 4;
// const GIVE_VOTE = 5;

const types = [
  { id: 0, name: 'Не требуется' },
  { id: 1, name: 'Фиксированное количество' },
  { id: 2, name: 'Любое количество' },
  { id: 3, name: 'Выбор количества из списка' },
];

const ProviderNewsEditScreen = () => {
  const { search } = useLocation();
  const sp = new URLSearchParams(search);
  const pid = sp.get('pid') || '';

  const { newsId } = useParams();
  const navigate = useNavigate();
  const { pov, theme, currentBreakpoint, currentProvider } = useSelector(
    (state) => state.auth
  );
  const [type, setType] = useState(0);
  const [ad, setAd] = useState(false);
  const [useProductImage, setUseProductImage] = useState(false);
  const [name, setName] = useState('');
  const [code, setCode] = useState('');
  const [image, setImage] = useState('');
  const [title, setTitle] = useState('');
  const [bgcolorCheck, setBgcolorCheck] = useState(false);
  const [bordercolorCheck, setBordercolorCheck] = useState(false);
  const [bgcolor, setBgcolor] = useState('');
  const [bordercolor, setBordercolor] = useState('');
  const [header, setHeader] = useState('');
  const [body, setBody] = useState('');
  const [buttonTitle, setButtonTitle] = useState('');
  const [product, setProduct] = useState('');
  // const [productId, setProductId] = useState('');
  const [oldPrice, setOldPrice] = useState('');
  const [newPrice, setNewPrice] = useState('');
  const [comment, setComment] = useState('');
  const [valueList, setValueList] = useState('');
  const [fixValue, setFixValue] = useState('');
  const [singleValue, setSingleValue] = useState('');
  const [singleValueMax, setSingleValueMax] = useState('');
  const [singleValueMin, setSingleValueMin] = useState('');
  const [multipleValue, setMultipleValue] = useState('');
  const [active, setActive] = useState('');
  const [openSelector, setOpenSelector] = useState(false);
  const [untilDate, setUntilDate] = useState('');

  // const [getProductName] = useGetProductFieldValueMutation();

  // useEffect(() => {
  //   if (pid) {
  //     setProductId(pid);
  //   }
  // }, [pid]);

  // useEffect(() => {
  //   let getIt = false;
  //   if (productId) {
  //     if (product?._id !== productId) {
  //       getIt = true;
  //     }
  //   }

  //   const getAndSetProduct = async (id) => {
  //     try {
  //       const result = await getProductName({
  //         productId: id,
  //         providerId: currentProvider?._id,
  //         fields: 'name,image,unit',
  //       }).unwrap();
  //       setProduct(result);
  //       setProductId(result?._id);
  //       //toast.success(result?.name);
  //     } catch (error) {
  //       toast.error(error?.data?.message || error.error);
  //     }
  //   };

  //   if (getIt) {
  //     getAndSetProduct(productId);
  //   }
  //   // eslint-disable-next-line
  // }, [productId]);

  const {
    data: news,
    refetch,
    isLoading,
    isError,
    error,
  } = useGetNewsByIdQuery(
    {
      newsId,
      productId: product?._id || pid,
      providerId: currentProvider?._id,
    },
    { refetchOnMountOrArgChange: true }
  );

  // console.log('news =', news);
  // console.log('productId =', productId);

  useEffect(() => {
    if (news) {
      setUseProductImage(news?.useProductImage || false);
      setProduct(news?.product);
      setUntilDate(news?.untilDate);
      // setProductId(news?.product?._id);
      setName(news?.name || '');
      setAd(news?.ad || false);
      setCode(news?.code || '');
      setBgcolorCheck(!!news?.bgcolor);
      setBordercolorCheck(!!news?.bordercolor);
      setBgcolor(news?.bgcolor || '');
      setBordercolor(news?.bordercolor || '');
      setImage(news?.image || '');
      setTitle(news?.title || '');
      setHeader(news?.header || '');
      setBody(news?.body || '');
      setOldPrice(news?.oldPrice || '');
      setNewPrice(news?.newPrice || '');
      setComment(news?.comment || '');
      setValueList(news?.valueList || '');
      setFixValue(news?.fixValue || '');
      setSingleValue(news?.singleValue || '');
      setSingleValueMax(news?.singleValueMax || '');
      setSingleValueMin(news?.singleValueMin || '');
      setButtonTitle(news?.buttonTitle || '');
      setMultipleValue(news?.multipleValue || '');
      setActive(news?.active || false);

      const { fixValue, singleValue, valueList } = news;
      // console.log('fixValue', fixValue);
      // console.log('singleValue', singleValue);
      // console.log('valueList', valueList);

      if (fixValue) {
        setType(FIX_VALUE);
      } else if (singleValue) {
        setType(SINGLE_VALUE);
      } else if (valueList && valueList.length > 0) {
        // console.log('select type', SELECT_VALUE);
        setType(SELECT_VALUE);
      }
    }
  }, [news]);

  const [updateNews, { isLoading: loadingUpdate }] = useUpdateNewsMutation();
  const [createNews, { isLoading: loadingCreate }] = useCreateNewsMutation();
  const [
    uploadProviderImage,
    {
      isLoading: isLoadingUpload,
      isError: isErrorOmageUpload,
      error: errImageUpload,
    },
  ] = useUploadProviderImageMutation();

  const handleChangeTypeQty = (selectedType) => {
    //
    // console.log(selectedType);
    // { id: 0, name: 'Не требуется' },
    // { id: 1, name: 'Фиксированное количество' },
    // { id: 2, name: 'Любое количество' },
    // { id: 3, name: 'Выбор количества из списка' },

    setType(selectedType);
    switch (selectedType) {
      case 0:
        setFixValue('');
        setSingleValue('');
        setValueList('');
        setMultipleValue('');
        setSingleValueMax('');
        setSingleValueMin('');
        break;
      case 1:
        //фиксированное количество
        setSingleValue('');
        setValueList('');
        setMultipleValue('');
        setSingleValueMax('');
        setSingleValueMin('');
        break;
      case 2:
        //Любое количество
        setFixValue('');
        setValueList('');
        break;
      case 3:
        // количество из списка
        setFixValue('');
        setSingleValue('');
        setMultipleValue('');
        setSingleValueMax('');
        setSingleValueMin('');
        break;
      default:
        break;
    }
  };

  if (pov !== 'provider') {
    return;
  }

  const updateHandler = async (withClose) => {
    const toSave = {
      providerId: currentProvider?._id,
      _id: newsId,
      productId: product?._id,
      useProductImage,
      name,
      code,
      image,
      title,
      bgcolor: bgcolorCheck ? (bgcolor ? bgcolor : 'primary') : 'primary',
      bordercolor: bordercolorCheck ? bordercolor : '',
      header,
      body,
      ad,
      untilDate,
      buttonTitle,
      valueList,
      fixValue,
      singleValue:
        type === SINGLE_VALUE ? (multipleValue ? multipleValue : 1) : 0,
      singleValueMax,
      singleValueMin,
      multipleValue,
      oldPrice,
      newPrice,
      comment,
      active,
    };

    // console.log('toSave', toSave);

    try {
      let result = {};
      if (newsId === 'new') {
        result = await createNews(toSave).unwrap();
      } else {
        result = await updateNews(toSave).unwrap();
      }

      if (result.error) {
        toast.error(result.error);
      } else {
        refetch();
        // toast.success(
        //   `Новость ${newsId === 'new' ? 'опубликована' : 'обновлена'}`
        // );

        if (withClose) {
          navigate('/provider/news');
        } else if (newsId === 'new') {
          navigate(`/provider/news/${result._id}`);
        }
      }
    } catch (error) {
      toast.error(error?.data?.message || error?.message || error.error);
    }
  };

  const submitHandler = (event) => {
    event.preventDefault();
    if (type !== NOTHING && !product && ad === true) {
      toast.error('Для ввода количества требуется товар');
    } else {
      updateHandler(event.target.getAttribute('close') === 'true');
    }
  };

  const uploadFileHandler = async (e) => {
    const formData = new FormData();
    formData.append('image', e.target.files[0]);
    try {
      const res = await uploadProviderImage(formData).unwrap();

      if (res?.image) {
        setImage(res.image);
      } else {
        e.preventDefault();
        toast.warn(res?.message);
      }
    } catch (error) {
      toast.error(error?.data?.message || error?.message || error.error);
    }
  };

  const handleOpenSelectProduct = () => {
    setOpenSelector(true);
  };

  const handleSelectProduct = (result) => {
    //
    // console.log(result);
    if (Array.isArray(result) && result[0]) {
      setProduct(result[0]);
    } else if (result) {
      setProduct(result);
    }
  };

  const handleSetUseProductImage = (result) => {
    if (!useProductImage && product?.image) {
      setImage(product?.image);
    }
    setUseProductImage(!useProductImage);
  };

  if (isErrorOmageUpload) {
    toast.error(
      errImageUpload?.data?.message ||
        errImageUpload?.message ||
        errImageUpload.error
    );
  }

  const themeStyle = {
    backgroundColor: theme === 'dark' ? '#212529' : 'white',
    borderColor: theme === 'dark' ? '#212529' : '#b5c0c1',
    color: theme === 'dark' ? 'white' : 'black',
  };
  // const themeStyleError = {
  //   backgroundColor: theme === 'dark' ? '#212529' : 'white',
  //   borderColor: theme === 'dark' ? 'danger' : '#b5c0c1',
  //   color: theme === 'dark' ? 'white' : 'black',
  // };

  const colors = [
    'primary',
    'secondary',
    'success',
    'info',
    'warning',
    'danger',
    'light',
  ];

  const handleClickClose = () => {
    if (pid) {
      navigate(`/provider/products/edit/${pid}`);
    } else {
      navigate(`/provider/news`);
    }
  };
  // console.log('valueList', valueList);

  return (
    <>
      <ToolsPanel
        //buttons={buttons}
        backURL={'/provider/news'}
        title={`Редактор новостей и объявлений`}
        isLoading={
          isLoading || loadingUpdate || loadingCreate || isLoadingUpload
        }
      />
      {isLoading ? (
        <></>
      ) : isError && newsId !== 'new' ? (
        <Message>{error?.data?.message || error.error}</Message>
      ) : (
        <>
          <Container>
            <Row>
              <Col lg={12} xl={12} xxl={12}>
                <Form onSubmit={submitHandler}>
                  <Card
                    className={`bg-transparent ${
                      currentBreakpoint === 'xs' || currentBreakpoint === 'sm'
                        ? ''
                        : 'p-3'
                    }`}
                  >
                    <Row>
                      <Col sx={12} sm={12} md={12} lg={6}>
                        <Row>
                          <Col>
                            <Card.Header as='h5'>
                              <span className='fs-6 mb-1'>
                                Настройка заголовка
                              </span>
                              <Form.Control
                                className='border-1'
                                isInvalid={!header}
                                style={themeStyle}
                                type='text'
                                placeholder='Заголовок'
                                value={header}
                                onChange={(e) => setHeader(e.target.value)}
                              ></Form.Control>
                            </Card.Header>
                          </Col>
                        </Row>

                        <Row className='m-2'>
                          <Col>
                            <Form.Group controlId='image'>
                              <span>Настройка изображения</span>
                              <Stack
                                direction='vertical'
                                gap={2}
                                className='d-flex justify-content-start'
                              >
                                <div className='w-25'>
                                  {/* <ZoomableImage
                                    src={image}
                                    zoom={true}
                                    className='rounded-0 img-thumbnail'
                                  /> */}
                                  <Card.Img
                                    className='rounded-0 img-thumbnail'
                                    src={image}
                                    alt={name}
                                  />
                                </div>
                                <Stack
                                  direction='horizontal'
                                  gap={2}
                                  className='d-flex justify-content-start'
                                >
                                  <div>
                                    <Form.Control
                                      readOnly={true}
                                      style={themeStyle}
                                      type='text'
                                      placeholder='Выберите файл картинки'
                                      value={image}
                                      onChange={(e) => setImage(e.target.value)}
                                    ></Form.Control>
                                    <Form.Control
                                      style={themeStyle}
                                      type='file'
                                      label='Выберите картинку'
                                      onChange={uploadFileHandler}
                                    ></Form.Control>
                                  </div>

                                  {image && (
                                    <Button onClick={() => setImage('')}>
                                      <FaTimes size={20} color='white' />
                                    </Button>
                                  )}
                                </Stack>
                              </Stack>
                            </Form.Group>
                          </Col>
                        </Row>

                        <Card.Body>
                          <span>Настройка подзаголовка</span>
                          <Card.Title>
                            <Form.Control
                              //className='border-success'
                              style={themeStyle}
                              type='text'
                              placeholder='Подзаголовок'
                              value={title}
                              onChange={(e) => setTitle(e.target.value)}
                            ></Form.Control>
                          </Card.Title>
                          <Card.Text>
                            <span>Настройка текста анонса</span>
                            <Form.Control
                              as='textarea'
                              style={themeStyle}
                              type='text'
                              placeholder='Краткое описание'
                              value={body}
                              onChange={(e) => setBody(e.target.value)}
                            ></Form.Control>
                          </Card.Text>
                          {type !== NOTHING && (
                            <>
                              {/* <Form.Group controlId='provider' className='my-2'>
                                <span className='fs-6 mb-1'>
                                  Настройка товара/услуги
                                </span>
                                <Form.Control
                                  isInvalid={product?._id}
                                  style={
                                    !product ? themeStyleError : themeStyle
                                  }
                                  type='text'
                                  placeholder='Выберите товар'
                                  value={product?.name}
                                  onClick={handleSelectCustomer}
                                  // onChange={(e) =>
                                  //   setProductId(e.target.value.trim())
                                  // }
                                ></Form.Control>
                                {/* {product?.name && (
                                  <Alert variant='outlined' severity='info'>
                                    {product?.name}
                                  </Alert>
                                )} 
                              </Form.Group> */}
                              <InputGroup className='mb-3'>
                                <Form.Control
                                  isInvalid={!product?._id}
                                  // style={
                                  //   !product?._id ? themeStyleError : themeStyle
                                  // }
                                  style={themeStyle}
                                  placeholder={product?.name}
                                  // aria-label="Recipient's username"
                                  // aria-describedby='basic-addon2'
                                  // value={product?.name || ''}
                                  onClick={handleOpenSelectProduct}
                                />
                                <Button
                                  variant='outline-secondary'
                                  id='button-addon2'
                                  onClick={() => setProduct([])}
                                >
                                  <FaTimes size={20} />
                                </Button>
                              </InputGroup>
                              <Row>
                                <Col>
                                  <Form.Group
                                    controlId='useProductImage'
                                    className='my-2'
                                    as={Row}
                                  >
                                    <Col sm='12'>
                                      <Form.Check
                                        id='useProductImage'
                                        // isValid={active}
                                        type='checkbox'
                                        label={`Использовать картинку товара`}
                                        checked={useProductImage}
                                        onChange={handleSetUseProductImage}
                                      ></Form.Check>
                                    </Col>
                                  </Form.Group>
                                </Col>
                              </Row>
                            </>
                          )}
                        </Card.Body>
                      </Col>
                      <Col sx={12} sm={12} md={12} lg={6}>
                        <Card.Body>
                          <Row>
                            <Col>
                              <Form.Group
                                controlId='isActive'
                                className='my-2'
                                as={Row}
                              >
                                <Col sm='12'>
                                  <Form.Check
                                    id='isActive'
                                    isValid={active}
                                    type='checkbox'
                                    label={`Показывать в ленте ${
                                      untilDate ? ' до : ' : 'всегда'
                                    }`}
                                    checked={active}
                                    onChange={(e) =>
                                      setActive(e.target.checked)
                                    }
                                  ></Form.Check>
                                </Col>
                              </Form.Group>
                            </Col>
                            <Col>
                              <MobileDatePicker
                                isInvalid={false}
                                //disablePast={true}
                                value={DateTime.fromISO(untilDate)}
                                onChange={(newValue) =>
                                  setUntilDate(newValue.toISO())
                                }
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <Form.Group
                                controlId='bgColor'
                                className='my-2'
                                as={Row}
                              >
                                <Col sm='12'>
                                  <Form.Check
                                    id='bgColor'
                                    // isValid={active}
                                    type='checkbox'
                                    label={`Цвет фона`}
                                    checked={bgcolorCheck}
                                    onChange={(e) => {
                                      setBgcolorCheck(!bgcolorCheck);
                                      setBgcolor('primary');
                                    }}
                                  ></Form.Check>
                                </Col>
                              </Form.Group>
                            </Col>
                          </Row>
                          {bgcolorCheck && (
                            <Row>
                              <Col lg={12}>
                                <Stack direction='horizontal' gap={1}>
                                  {colors &&
                                    colors.map((val, index) => (
                                      <Button
                                        key={index}
                                        className={`bg-${val}  text-black`}
                                        role='button'
                                        onClick={() => setBgcolor(val)}
                                      >
                                        &nbsp;{bgcolor === val && 'V'}
                                      </Button>
                                    ))}
                                </Stack>
                              </Col>
                            </Row>
                          )}
                          <Row>
                            <Col>
                              <Form.Group
                                controlId='bordercolorCheck'
                                className='my-2'
                                as={Row}
                              >
                                <Col sm='12'>
                                  <Form.Check
                                    id='bordercolorCheck'
                                    // isValid={active}
                                    type='checkbox'
                                    label={`Цвет рамки`}
                                    checked={bordercolorCheck}
                                    onChange={(e) => {
                                      setBordercolorCheck(!bordercolorCheck);
                                      setBordercolor('');
                                    }}
                                  ></Form.Check>
                                </Col>
                              </Form.Group>
                            </Col>
                          </Row>
                          {bordercolorCheck && (
                            <Row className='mt-2'>
                              <Col lg={12}>
                                <Stack direction='horizontal' gap={1}>
                                  {colors &&
                                    colors.map((val, index) => (
                                      <Button
                                        key={index}
                                        className={`bg-${val} text-black`}
                                        role='button'
                                        onClick={() => setBordercolor(val)}
                                      >
                                        &nbsp;{bordercolor === val && 'V'}
                                      </Button>
                                    ))}
                                </Stack>
                              </Col>
                            </Row>
                          )}
                          <Row>
                            <Col>
                              <Form.Group
                                controlId='isAction'
                                className='my-2'
                                as={Row}
                              >
                                <Col sm='12'>
                                  <Form.Check
                                    id='isAction'
                                    type='checkbox'
                                    label='Акция'
                                    checked={ad}
                                    onChange={(e) => setAd(e.target.checked)}
                                  ></Form.Check>
                                </Col>
                              </Form.Group>
                            </Col>
                          </Row>
                          {ad && (
                            <>
                              <Row>
                                <span className='mb-1'>
                                  Блок "Старая и новая цена"
                                </span>
                                <Col>
                                  <span className='fs-3 text-decoration-line-through'>
                                    <Form.Control
                                      //className='border-success'
                                      style={themeStyle}
                                      type='text'
                                      placeholder='Старая цена'
                                      value={oldPrice}
                                      onChange={(e) =>
                                        setOldPrice(e.target.value)
                                      }
                                    ></Form.Control>
                                  </span>
                                </Col>
                                <Col>
                                  <span className='fs-3'>
                                    <Form.Control
                                      //className='border-success'
                                      style={themeStyle}
                                      type='text'
                                      placeholder='Новая цена'
                                      value={newPrice}
                                      onChange={(e) =>
                                        setNewPrice(e.target.value)
                                      }
                                    ></Form.Control>
                                  </span>
                                </Col>
                              </Row>

                              <Row>
                                <span className='mb-1 my-3'>
                                  Настройка поля ввода количества
                                </span>
                                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                  <Form.Control
                                    style={themeStyle}
                                    as='select'
                                    value={type}
                                    onChange={(e) => {
                                      handleChangeTypeQty(
                                        Number(e.target.value)
                                      );
                                      // setSingleValue(
                                      //   Number(e.target.value) === 2 ? 1 : 0
                                      // );
                                    }}
                                  >
                                    {types.map((x, index) => (
                                      <option key={index} value={x.id}>
                                        {x.name}
                                      </option>
                                    ))}
                                  </Form.Control>

                                  <InputGroup>
                                    {type === SELECT_VALUE && (
                                      <Form.Group
                                        controlId='provider'
                                        className='my-2'
                                      >
                                        <Form.Label className='pb-0'>
                                          Введите числа, разделённые запятой
                                        </Form.Label>
                                        <Form.Control
                                          style={themeStyle}
                                          type='text'
                                          placeholder='10,20,30....'
                                          value={valueList}
                                          onChange={(e) =>
                                            setValueList(e.target.value)
                                          }
                                        ></Form.Control>
                                      </Form.Group>
                                    )}

                                    {type === FIX_VALUE && (
                                      <Form.Group
                                        controlId='provider'
                                        className='my-2'
                                      >
                                        <Form.Label className='pb-0'>
                                          Введите фиксированное количество
                                        </Form.Label>
                                        <Form.Control
                                          style={themeStyle}
                                          type='number'
                                          placeholder=''
                                          value={fixValue}
                                          onChange={(e) =>
                                            setFixValue(Number(e.target.value))
                                          }
                                        ></Form.Control>
                                      </Form.Group>
                                    )}

                                    {type === SINGLE_VALUE && (
                                      <>
                                        <Row>
                                          <Col>
                                            <Form.Group
                                              controlId='provider'
                                              className='my-2'
                                            >
                                              <Form.Label className='pb-0'>
                                                Кратность
                                              </Form.Label>
                                              <Form.Control
                                                //className='border-success'
                                                style={themeStyle}
                                                type='number'
                                                placeholder=''
                                                value={multipleValue}
                                                onChange={(e) =>
                                                  setMultipleValue(
                                                    Number(e.target.value)
                                                  )
                                                }
                                              ></Form.Control>
                                            </Form.Group>
                                          </Col>
                                          <Col>
                                            <Form.Group
                                              controlId='provider'
                                              className='my-2'
                                            >
                                              <Form.Label className='pb-0'>
                                                Не менее
                                              </Form.Label>
                                              <Form.Control
                                                //className='border-success'
                                                style={themeStyle}
                                                type='number'
                                                placeholder=''
                                                value={singleValueMin}
                                                onChange={(e) =>
                                                  setSingleValueMin(
                                                    Number(e.target.value)
                                                  )
                                                }
                                              ></Form.Control>
                                            </Form.Group>
                                          </Col>
                                          <Col>
                                            <Form.Group
                                              controlId='provider'
                                              className='my-2'
                                            >
                                              <Form.Label className='pb-0'>
                                                Не более
                                              </Form.Label>
                                              <Form.Control
                                                //className='border-success'
                                                style={themeStyle}
                                                type='number'
                                                placeholder=''
                                                value={singleValueMax}
                                                onChange={(e) =>
                                                  setSingleValueMax(
                                                    Number(e.target.value)
                                                  )
                                                }
                                              ></Form.Control>
                                            </Form.Group>
                                          </Col>
                                        </Row>
                                      </>
                                    )}
                                  </InputGroup>
                                </Col>
                              </Row>
                              {[1, 2, 3].includes(type) && (
                                <Row>
                                  <span className='mb-1 mt-2'>
                                    Настройка кнопки
                                  </span>
                                  <Col>
                                    <Form.Control
                                      style={themeStyle}
                                      type='text'
                                      placeholder='Имя кнопки'
                                      value={buttonTitle}
                                      onChange={(e) =>
                                        setButtonTitle(e.target.value)
                                      }
                                    ></Form.Control>
                                  </Col>
                                </Row>
                              )}
                            </>
                          )}
                        </Card.Body>
                      </Col>
                    </Row>
                  </Card>

                  <Stack
                    direction='horizontal'
                    gap={3}
                    //class='justify-content-center'
                  >
                    <Button
                      disabled={loadingCreate}
                      type='button'
                      variant='primary'
                      className='my-2'
                      size={
                        ['lg', 'xl', 'xxl'].includes(currentBreakpoint)
                          ? 'lg'
                          : 'sm'
                      }
                      onClick={submitHandler}
                    >
                      Сохранить
                    </Button>
                    <Button
                      type='button'
                      variant='primary'
                      className='my-2 mx-2'
                      close='true'
                      size={
                        ['lg', 'xl', 'xxl'].includes(currentBreakpoint)
                          ? 'lg'
                          : 'sm'
                      }
                      onClick={submitHandler}
                    >
                      Сохранить закрыть
                    </Button>
                    <Button
                      size={
                        ['lg', 'xl', 'xxl'].includes(currentBreakpoint)
                          ? 'lg'
                          : 'sm'
                      }
                      variant='primary'
                      className='my-2'
                      onClick={handleClickClose}
                    >
                      Закрыть
                    </Button>
                  </Stack>
                </Form>
              </Col>
              {/* <Col lg={12} xl={4} xxl={4}>
                <Col sx={4}>
    
                  <NewsItem
                    // variant={'linear'}
                    item={{
                      active,
                      bgcolor,
                      useProductImage,
                      bordercolor,
                      ad,
                      header,
                      untilDate,
                      product,
                      title,
                      valueList,
                      fixValue,
                      singleValue,
                      singleValueMax,
                      singleValueMin,
                      buttonTitle,
                      oldPrice,
                      newPrice,
                      body,
                      image,
                      hideEditIcon: true,
                    }}
                  />

                </Col>
              </Col> */}
            </Row>
          </Container>

          <Container className='mt-2'>
            <Card.Header>*Как будет выглядеть объявление в списке:</Card.Header>
            <ListGroup>
              {/* <NewsItem key={item._id} item={item} variant={'linear'} /> */}
              <NewsItem
                key='1'
                variant={'linear'}
                noEditLink={true}
                item={{
                  active,
                  bgcolor,
                  useProductImage,
                  bordercolor,
                  ad,
                  header,
                  untilDate,
                  product,
                  title,
                  valueList,
                  fixValue,
                  singleValue,
                  singleValueMax,
                  singleValueMin,
                  buttonTitle,
                  oldPrice,
                  newPrice,
                  body,
                  image,
                  hideEditIcon: true,
                }}
              />
            </ListGroup>
          </Container>
          {openSelector && (
            <SelectProducts
              open={openSelector}
              setOpen={setOpenSelector}
              selectItems={handleSelectProduct}
              mode='single'
            />
          )}
        </>
      )}
    </>
  );
};

export default ProviderNewsEditScreen;
