import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  setAnimateMenuIcon,
  setCurrentProvider,
  setPriceEditMode,
  setShowLeftMenu,
} from '../slices/authSlice';
import ToolsPanel from '../components/ToolsPanel/ToolsPanel';
import { toast } from 'react-toastify';
import { LinearProgress } from '@mui/material';
import Message from '../components/Message';
import { useGetProvidersOfCustomerQuery } from '../slices/customersApiSlice';
import GroupListSimple from '../components/GroupList/GroupListSimple';
import { FaHome, FaSearchPlus } from 'react-icons/fa';
import WelcomeCustomer from '../components/welcom/WelcomeCustomer';
import LetsSearchProvider from '../components/welcom/LetsSearchProvider';

const CustomerSelectProviderScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { currentCustomer, theme } = useSelector((state) => state.auth);

  const { data, isLoading, isError, error } = useGetProvidersOfCustomerQuery(
    {
      customerId: currentCustomer?._id,
    },
    { refetchOnMountOrArgChange: true }
  );
  // console.log('useGetProvidersQuery', data);
  //   const [checkContract] = useCheckExistContractMutation();
  // console.log(data);
  // useEffect(() => {
  //   if (Array.isArray(data?.rows) && data.rows.length === 1) {
  //     const row = data.rows[0];
  //     if (row?._id) {
  //       dispatch(setCurrentProvider(row));
  //       navigate(`/customer/cart`);
  //     }
  //   }
  //   // eslint-disable-next-line
  // }, [data]);

  const handleSelectUnit = async (provider) => {
    // console.log('handleSelectUnit', currentCustomer, provider?._id);
    if (currentCustomer && provider?._id) {
      dispatch(setShowLeftMenu(true));
      dispatch(setAnimateMenuIcon(false));
      dispatch(setCurrentProvider(provider));
      dispatch(setPriceEditMode(false));
      //переносим пользователя на страницу новостей
      navigate(`/customer/cart`);
    } else {
      toast.error('Сначала выберите покупателя');
    }
  };

  // const handleOpenUnit = (unit) => {

  // };

  const handleClickSearch = () => {
    // navigate(`/customer/search/provider/`);
    navigate(`/customer/search/provider/`, {
      state: { backUrl: '/customer/select/provider/' },
    });
  };

  let buttons = [];
  if (data?.canSearch) {
    buttons.push({
      className: `bg-transparent`,
      name: 'Поиск',
      type: 'component',
      action: handleClickSearch,
      component: (props) => {
        return (
          <FaSearchPlus
            size={22}
            color={theme === 'dark' ? 'white' : 'black'}
          />
        );
      },
    });
  }

  const fakeData = [
    { _id: '1', name: '- ' },
    { _id: '2', name: '- ' },
    { _id: '3', name: '- ' },
  ];

  return (
    <>
      <ToolsPanel
        // backIcon={<FaHome size={22} />}
        backURL={'/customer'}
        buttons={buttons}
        title={`Список ваших поставщиков`}
      />
      {isLoading ? (
        <LinearProgress />
      ) : isError ? (
        <Message>{error?.data?.message || error?.error}</Message>
      ) : (
        <Container>
          <Row>
            <Col>
              {data?.rows?.length === 0 ? (
                <>
                  <LetsSearchProvider
                    handleClick={handleClickSearch}
                    theme={theme}
                  />
                  {/* <GroupListSimple
                    className=''
                    variant=''
                    rows={fakeData}
                    onClickItem={() => {}}
                  />
                  <Container className='bg-info mt-3 p-2 rounded bg-opacity-25'>
                    <Row>
                      <Col>
                        Список ваших поставщиков пуст. Начните с поиска.
                        {/* <Button className='m-1' onClick={handleClickSearch}>
                          <FaSearchPlus size={22} />
                        </Button> 
                      </Col>
                    </Row>
                  </Container> */}
                </>
              ) : (
                Array.isArray(data?.rows) && (
                  <GroupListSimple
                    variant=''
                    rows={data.rows}
                    onClickItem={handleSelectUnit}
                  />
                )
              )}
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
};

export default CustomerSelectProviderScreen;
