import { Outlet, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';

const PrivateRoute = () => {
  const { userInfo } = useSelector((state) => state.auth);

  useEffect(() => {}, [userInfo]);

  return userInfo ? <Outlet /> : <Navigate to='/login' replace />;
};

export default PrivateRoute;
