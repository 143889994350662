import * as React from "react";
const SearchPartnerColored = (props) => (
  <svg
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    style={{
      enableBackground: "new 0 0 512 512",
    }}
    xmlSpace="preserve"
    {...props}

  >
    <path style={{ fill: '#F4EECB' }} d="M467.478,247.835H44.522C19.933,247.835,0,227.902,0,203.314v-49.944
	c0-24.588,19.933-44.522,44.522-44.522h422.957c24.588,0,44.522,19.933,44.522,44.522v49.944
	C512,227.902,492.066,247.835,467.478,247.835z"/>
    <path style={{
      fill: '#E2DCBC'
    }} d="M467.478,108.846H44.522C19.933,108.846,0,128.759,0,153.322v50.036
	c0,24.564,19.933,44.476,44.522,44.476h422.957c24.588,0,44.522-19.912,44.522-44.476v-50.036
	C512,128.76,492.066,108.846,467.478,108.846z M495.304,203.359c0,15.328-12.483,27.797-27.826,27.797H44.522
	c-15.343,0-27.826-12.469-27.826-27.797v-50.036c0-15.328,12.483-27.797,27.826-27.797h422.957
	c15.343,0,27.826,12.469,27.826,27.797V203.359z"/>
    <path style={{
      fill: '#BFBBA3'
    }} d="M64,177.785v1.112c0,4.606-3.738,8.339-8.348,8.339s-8.348-3.733-8.348-8.339v-1.112
	c0-4.606,3.738-8.339,8.348-8.339S64,173.179,64,177.785z M89.043,169.446c-4.61,0-8.348,3.733-8.348,8.339v1.112
	c0,4.606,3.738,8.339,8.348,8.339c4.61,0,8.348-3.733,8.348-8.339v-1.112C97.391,173.179,93.654,169.446,89.043,169.446z
	 M122.435,169.446c-4.61,0-8.348,3.733-8.348,8.339v1.112c0,4.606,3.738,8.339,8.348,8.339s8.348-3.733,8.348-8.339v-1.112
	C130.783,173.179,127.045,169.446,122.435,169.446z"/>
    <path style={{
      fill: '#84A7B7'
    }} d="M505.48,356.327l-62.963-62.899c-8.693-8.685-22.788-8.685-31.481,0l-25.115-25.089
	c30.76-45.416,26.02-107.667-14.237-147.882c-45.64-45.594-119.638-45.594-165.278,0s-45.64,119.515,0,165.11
	c40.257,40.215,102.573,44.951,148.034,14.223l0,0l25.115,25.089c-8.693,8.684-8.693,22.765,0,31.449l62.964,62.899
	c8.693,8.684,22.788,8.684,31.481,0l31.481-31.449C514.174,379.092,514.174,365.012,505.48,356.327z"/>
    <path style={{
      fill: '#256277'
    }} d="M385.92,268.34l25.115,25.089l-31.481,31.449l-25.115-25.089c6.079-4.109,11.862-8.844,17.244-14.223
	C377.067,280.188,381.806,274.413,385.92,268.34z"/>
    <path style={{
      fill: '#C3E4ED'
    }} d="M366.957,203.012c0,42.986-34.883,77.833-77.913,77.833s-77.913-34.847-77.913-77.833
	s34.883-77.833,77.913-77.833S366.957,160.025,366.957,203.012z"/>
    <path style={{
      fill: '#DCF3F9'
    }} d="M362.438,176.867l-99.566,99.464c-14.562-5.188-27.13-14.571-36.228-26.707l109.06-108.949
	C347.852,149.764,357.245,162.319,362.438,176.867z M315.215,129.692l-99.566,99.464c1.304,3.653,2.869,7.18,4.681,10.557
	l105.453-105.345C322.403,132.559,318.871,130.995,315.215,129.692z"/>
    <path style={{
      fill: '#FFFFFF'
    }} d="M289.391,144.81c12.295,0,22.261,9.956,22.261,22.239c0,12.281-9.966,22.239-22.261,22.239
	c-12.295,0-22.261-9.956-22.261-22.239S277.097,144.81,289.391,144.81z"/>
  </svg>
);
export default SearchPartnerColored;
