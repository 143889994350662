import React, { useEffect, useState } from 'react';
import { Button, ListGroup, Pagination } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { getOrderHeaderString } from '../../utils/gets';
import { useGetCustomerOrdersQuery } from '../../slices/ordersApiSlice';
import Loader from '../Loader/Loader';
import Message from '../Message';
import {
  FaCheckDouble,
  FaRegCheckSquare,
  FaRegSquare,
  FaRegTrashAlt,
  FaTimes,
} from 'react-icons/fa';
import { existNewsSet } from '../../slices/authSlice';

const OrderArchiveList = ({ currentId }) => {
  const { orderId } = useParams();
  const [pageNumber, setPageNamber] = useState(1); //текущая страница
  const [pageSize, setPageSize] = useState(10); //количество на странице
  const [showPaginationPanel, setShowPaginationPanel] = useState(false);
  const { cartItems } = useSelector((state) => state.cart);
  const { theme, currentCustomer, currentProvider, pov } = useSelector(
    (state) => state.auth
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { data, refetch, isLoading, isError, error } =
    useGetCustomerOrdersQuery(
      {
        pov,
        customerId: currentCustomer?._id,
        providerId: currentProvider?._id,
        pageNumber: pageNumber,
        pageSize: pageSize,
      },
      { refetchOnMountOrArgChange: true }
    );

  // console.log('data', data);
  useEffect(() => {
    if (data?.existNews) {
      dispatch(existNewsSet(currentProvider?._id));
    }
    //eslint-disable-next-line
  }, [data]);

  const handleClickOnOrder = (orderId) => {
    navigate(`/customer/cart/${orderId}`);
  };

  const handleClickGetCloudCart = () => {
    navigate(`/customer/cart/cloud`);
  };

  const handleClickMore = () => {
    setShowPaginationPanel(true);
  };

  const handleClickRefresh = () => {
    refetch();
  };

  let pagesItems = [];

  for (let number = 1; number <= data?.pages; number++) {
    pagesItems.push(
      <Pagination.Item
        linkClassName={`archive-page-link-${theme}`}
        onClick={() => setPageNamber(number)}
        key={number}
        active={pageNumber === number}
        className={pageNumber === number ? '' : 'text-white'}
      >
        {number}
      </Pagination.Item>
    );
  }

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : isError ? (
        <>
          <Message variant={'warning'}>{`Ошибка: ${
            error?.data?.message || error.error
          }`}</Message>
          <Button
            size='sm'
            type='button'
            variant='primary'
            className='my-2'
            onClick={handleClickRefresh}
          >
            Обновить
          </Button>
        </>
      ) : (
        <>
          <ListGroup bsPrefix='narrow'>
            {Array.isArray(data?.orders) && data.orders.length > 0 && (
              <ListGroup.Item
                onClick={() => handleClickOnOrder('')}
                variant={theme}
                action
                className={`orderList-active ${
                  (orderId === '' || !orderId) && !currentId
                    ? 'bg-dark-subtle rounded-2 p-2 text-bg-info'
                    : 'p-2'
                }`}
              >
                {!!currentId ? 'Корректировка' : 'Текущая заявка'}
              </ListGroup.Item>
            )}
            {data?.existCartItems &&
              Array.isArray(cartItems) &&
              cartItems.length === 0 && (
                <ListGroup.Item
                  onClick={() => handleClickGetCloudCart()}
                  variant={theme}
                  action
                  className={`orderList-active ${
                    orderId === 'cloud'
                      ? 'bg-dark-subtle rounded-2 p-2 text-bg-info'
                      : 'p-2'
                  }`}
                >
                  Заявка в облаке
                </ListGroup.Item>
              )}
            {Array.isArray(data?.orders) && data.orders.length > 0 ? (
              data.orders.map((item) => (
                <ListGroup.Item
                  onClick={() => handleClickOnOrder(item._id)}
                  variant={theme}
                  action
                  key={item._id}
                  className={`${
                    orderId === item._id || currentId === item._id
                      ? 'bg-dark-subtle rounded-2 p-2 text-bg-info'
                      : 'p-2'
                  }`}
                >
                  <span
                    style={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {item?.isCanceled ? (
                      <FaRegTrashAlt className='me-2' color='darkred' />
                    ) : item?.isDelivered ? (
                      <FaRegCheckSquare className='me-2' color='green' />
                    ) : (
                      <FaRegSquare className='me-2' />
                    )}
                    <span
                      className={`${
                        item?.isCanceled ? 'text-decoration-line-through' : ''
                      }`}
                    >
                      {getOrderHeaderString(item, '', true)}
                    </span>
                  </span>
                </ListGroup.Item>
              ))
            ) : (
              <ListGroup.Item
                as={'div'}
                variant={theme}
                className='d-flex justify-content-between align-items-start'
              >
                {data?.existCartItems ? '' : 'Заявок не найдено'}
              </ListGroup.Item>
            )}
          </ListGroup>
          {showPaginationPanel ? (
            <Pagination className='m-0 mt-2'>{pagesItems}</Pagination>
          ) : (
            <>
              {data?.pages && Number(data?.pages) > 1 ? (
                <Button
                  size='sm'
                  type='button'
                  variant='primary'
                  className='my-2'
                  onClick={handleClickMore}
                >
                  Показать более ранние
                </Button>
              ) : (
                ''
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default OrderArchiveList;
