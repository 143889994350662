import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider
} from 'react-router-dom'
import './assets/styles/bootstrap.custom.css';
import './assets/styles/index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import HomeScreen from './screens/HomeScreen';
import { Provider } from 'react-redux';
import store from './store'
import LoginScreen from './screens/LoginScreen';
import RegisterScreen from './screens/RegisterScreen';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import PaymentScreen from './screens/PaymentScreen';
import ProfileScreen from './screens/ProfileScreen';
import AdminRoute from './components/AdminRoute/AdminRoute';
import CartScreenDataGrid from './screens/CartScreenDataGrid';
import AddUnitByInvite from './screens/AddUnitByInvite';
import PdfScreen from './screens/PdfScreen';
import ProviderHomeScreen from './screens/ProviderHomeScreen';
import CheckPovRoute from './components/CheckPovRoute/';
import CustomerHomeScreen from './screens/CustomerHomeScreen';
import StartScreen from './screens/StartScreen';
import NewsScreen from './screens/NewsScreen';
import CheckProviderRoute from './components/CheckPovRoute/CheckProviderRoute';
import CheckCustomerRoute from './components/CheckPovRoute/CheckCustomerRoute';
import AboutUnitScreen from './screens/AboutUnitScreen';
import ProviderDetailsEditScreen from './screens/ProviderDetailsEditScreen';
import ProviderProductsScreen from './screens/ProviderProductsScreen';
import ProviderSectionsEditScreen from './screens/ProviderSectionsEditScreen';
import ProviderProductEditScreen from './screens/ProviderProductEditScreen';
import ProviderContractsScreen from './screens/ProviderContractsScreen';
import ProviderContractEditScreen from './screens/ProviderContractEditScreen';
import ProviderOrdersScreen from './screens/ProviderOrdersScreen';
import ProviderOrderDetailScreen from './screens/ProviderOrderDetailScreen';
import ProviderNewsEditScreen from './screens/ProviderNewsEditScreen';
import ProviderGroupsScreen from './screens/ProviderGroupsScreen';
import ProviderGroupEditScreen from './screens/ProviderGroupEditScreen';
import ProviderPricesScreen from './screens/ProviderPricesScreen';
import ProviderPriceEditScreen from './screens/ProviderPriceEditScreen';
import CustomerSelectProviderScreen from './screens/CustomerSelectProviderScreen';
import ProviderSelectProviderScreen from './screens/ProviderSelectProviderScreen';
import CustomerSearchProviderScreen from './screens/CustomerSearchProviderScreen';
import CustomerSelectCustomerScreen from './screens/CustomerSelectCustomerScreen';
import CustomerCartScreen from './screens/CustomerCartScreen';
import CustomerDetailsEditScreen from './screens/CustomerDetailsEditScreen';
import CustomerOrderScreen from './screens/CustomerOrderScreen';
import CustomerAboutProviderScreen from './screens/CustomerAboutProviderScreen';
import CustomerShippingScreen from './screens/CustomerShippingScreen';
import CustomerPlaceOrderScreen from './screens/CustomerPlaceOrderScreen';
import ProviderCreateCustomerScreen from './screens/ProviderCreateCustomerScreen';
import CustomerCreateCustomerScreen from './screens/CustomerCreateCustomerScreen';
import ProviderCreateProviderScreen from './screens/ProviderCreateProviderScreen';
import ProviderUsersScreen from './screens/ProviderUsersScreen';
import ProviderRegionsScreen from './screens/ProviderRegionsScreen';
import ProviderRegionEditScreen from './screens/ProviderRegionEditScreen';
import ManagerRoutesScreen from './screens/ManagerRoutesScreen';
import ManagerRoutesEditScreen from './screens/ManagerRoutesEditScreen';
import ManagerBindRoutesScreen from './screens/ManagerBindRoutesScreen';
import ProviderCitiesEditScreen from './screens/ProviderCitiesEditScreen';
import ProviderTaskEditScreen from './screens/ProviderTaskEditScreen';
import CustomerTasksScreen from './screens/CustomerTasksScreen';
import ProviderTasksScreen from './screens/ProviderTasksScreen';
import ProviderTaskScreen from './screens/ProviderTaskScreen';
import ProviderContactEditScreen from './screens/ProviderContactEditScreen';
import ProviderOrderScreen from './screens/ProviderOrderScreen';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path='/' element={<App />}>
      <Route path='/login' element={<LoginScreen />} />
      <Route path='/register' element={<RegisterScreen />} />
      <Route path='' element={<PrivateRoute />}>
        <Route index={true} path='/' element={<HomeScreen />} />
        <Route path='/start' element={<StartScreen />} />
        <Route path='/user/invite/' element={<AddUnitByInvite />} />
        <Route path='/user/invite/:invite' element={<AddUnitByInvite />} />
        <Route path='/profile' element={<ProfileScreen />} />
        <Route path='/news' element={<HomeScreen />} />
        <Route path='' element={<CheckPovRoute />}>
          <Route path='/news/:providerId' element={<NewsScreen />} />
          <Route path='/about/:providerId' element={<AboutUnitScreen />} />
          <Route path='/pdf' element={<PdfScreen />} />
          <Route path='/pdf/:orderId' element={<PdfScreen />} />
          <Route path='/manager/routes/bind/:contractId' element={<ManagerBindRoutesScreen />} />
          <Route path='/manager/routes/:routeId' element={<ManagerRoutesEditScreen />} />
          <Route path='/manager/routes/' element={<ManagerRoutesScreen />} />
          <Route path='/customer/select/customer/:routeId' element={<CustomerSelectCustomerScreen />} />
          <Route path='/customer/select/customer/' element={<CustomerSelectCustomerScreen />} />
          <Route path='/provider/select/provider/' element={<ProviderSelectProviderScreen />} />
          <Route path='/provider/create/provider/' element={<ProviderCreateProviderScreen />} />
          <Route path='/customer/create/customer/' element={<CustomerCreateCustomerScreen />} />
          <Route path='' element={<CheckProviderRoute />}>
            <Route path='/provider' element={<ProviderHomeScreen />} />
            <Route path='/provider/manager/routes/' element={<ManagerRoutesScreen />} />
            <Route path='/provider/manager/routes/bind/:contractId' element={<ManagerBindRoutesScreen />} />
            <Route path='/provider/manager/routes/:routeId' element={<ManagerRoutesEditScreen />} />
            <Route path='/provider/contacts/:contactId' element={<ProviderContactEditScreen />} />
            <Route path='/provider/multitasks' element={<ProviderTasksScreen />} />
            <Route path='/provider/multitasks/:taskId' element={<ProviderTaskScreen />} />
            <Route path='/provider/multitasks/edit/:taskId' element={<ProviderTaskEditScreen />} />
            <Route path='/provider/tasks' element={<ProviderTasksScreen />} />
            <Route path='/provider/tasks/ofuser/:userId' element={<ProviderTasksScreen />} />
            <Route path='/provider/tasks/:taskId' element={<ProviderTaskEditScreen />} />
            <Route path='/provider/users' element={<ProviderUsersScreen />} />
            <Route path='/provider/prices' element={<ProviderPricesScreen />} />
            <Route path='/provider/regions' element={<ProviderRegionsScreen />} />
            <Route path='/provider/regions/:regionId' element={<ProviderRegionEditScreen />} />
            <Route path='/provider/prices/:priceId' element={<ProviderPriceEditScreen />} />
            <Route path='/provider/groups' element={<ProviderGroupsScreen />} />
            <Route path='/provider/groups/:groupId' element={<ProviderGroupEditScreen />} />
            <Route path='/provider/sections' element={<ProviderSectionsEditScreen />} />
            <Route path='/provider/cities' element={<ProviderCitiesEditScreen />} />
            <Route path='/provider/orders/edit/:orderId' element={<ProviderOrderScreen />} />
            <Route path='/provider/orders/new/:customerId' element={<ProviderOrderScreen />} />
            <Route path='/provider/orders/new/' element={<ProviderOrderScreen />} />
            <Route path='/provider/orders/:orderId' element={<ProviderOrderDetailScreen />} />
            <Route path='/provider/orders' element={<ProviderOrdersScreen />} />
            <Route path='/provider/orders/search/:keyword' element={<ProviderOrdersScreen />} />
            <Route path='/provider/contracts/create' element={<ProviderCreateCustomerScreen />} />
            <Route path='/provider/contracts' element={<ProviderContractsScreen />} />
            <Route path='/provider/contracts/region/:regionId' element={<ProviderContractsScreen />} />
            <Route path='/provider/contracts/search/:keyword' element={<ProviderContractsScreen />} />
            <Route path='/provider/contracts/:contractId' element={<ProviderContractEditScreen />} />
            <Route path='/provider/news' element={<NewsScreen />} />
            <Route path='/provider/news/:newsId' element={<ProviderNewsEditScreen />} />
            <Route path='/provider/details' element={<ProviderDetailsEditScreen />} />
            <Route path='/provider/products' element={<ProviderProductsScreen />} />
            <Route path='/provider/products/edit/:productId' element={<ProviderProductEditScreen />} />
            <Route path='/provider/products/search/:keyword' element={<ProviderProductsScreen />} />
            <Route path='/provider/products/search/:keyword/page/:pageNumber' element={<ProviderProductsScreen />} />
            <Route path='/provider/products/:groupId/page/:pageNumber' element={<ProviderProductsScreen />} />
            <Route path='/provider/products/:groupId' element={<ProviderProductsScreen />} />
          </Route>
          <Route path='' element={<CheckCustomerRoute />}>
            <Route path='/customer/manager/routes/' element={<ManagerRoutesScreen />} />
            <Route path='/customer/manager/routes/bind/:contractId' element={<ManagerBindRoutesScreen />} />
            <Route path='/customer/manager/routes/:routeId' element={<ManagerRoutesEditScreen />} />
            <Route path='/customer/tasks' element={<CustomerTasksScreen />} />
            <Route path='/customer/details' element={<CustomerDetailsEditScreen />} />
            <Route path='/customer/about/provider/:providerId' element={<CustomerAboutProviderScreen />} />
            <Route path='/customer/search/provider/' element={<CustomerSearchProviderScreen />} />
            <Route path='/customer/search/provider/:keyword' element={<CustomerSearchProviderScreen />} />
            <Route path='/customer/select/provider/' element={<CustomerSelectProviderScreen />} />
            <Route path='/customer/order/search/:keyword' element={<CustomerOrderScreen />} />
            <Route path='/customer/order/search/:keyword/page/:pageNumber' element={<CustomerOrderScreen />} />
            <Route path='/customer/order/:groupId/page/:pageNumber' element={<CustomerOrderScreen />} />
            <Route path='/customer/order/:groupId' element={<CustomerOrderScreen />} />
            <Route path='/customer' element={<CustomerHomeScreen />} />
            <Route path='/customer/cart/:orderId' element={<CartScreenDataGrid />} />
            <Route path='/customer/cart' element={<CustomerCartScreen />} />
            <Route path='/customer/shipping' element={<CustomerShippingScreen />} />
            <Route path='/customer/payment' element={<PaymentScreen />} />
            <Route path='/customer/placeorder' element={<CustomerPlaceOrderScreen />} />
          </Route>
        </Route>
      </Route>
      <Route path='' element={<AdminRoute />}>
      </Route>
    </Route>
  )
)

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <RouterProvider router={router} />
  </Provider>
  // </React.StrictMode>
);

reportWebVitals();
