import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { FaPlus } from 'react-icons/fa';
import ToolsPanel from '../components/ToolsPanel';
import { Col, Container, Row } from 'react-bootstrap';
import DataTable from '../components/DataTable/DataTable';
import { NoRowsOverlayRegions } from '../components/DataTable/NoRowsOverlayRegions/NoRowsOverlayRoutes';
import { LinearProgress } from '@mui/material';
import Message from '../components/Message';
import { useGetRegionsQuery } from '../slices/regionsApiSlice';

const ProviderRegionsScreen = () => {
  const navigate = useNavigate();
  const { theme, currentProvider } = useSelector((state) => state.auth);

  const { data, isLoading, isError, error } = useGetRegionsQuery(
    {
      providerId: currentProvider?._id,
    },
    { refetchOnMountOrArgChange: true }
  );

  // console.log(data);
  const rows = data?.rows?.map((item) => ({
    ...item,
    id: item._id,
    user: Array.isArray(item?.managers)
      ? item?.managers.map((item) => item?.name)?.join(', ')
      : '',
  }));

  const columns = [
    { field: 'id', headerName: 'id', width: 50, hide: true },
    {
      field: 'name',
      headerName: 'Группа',
      flex: 0.7,
    },
    {
      field: 'user',
      headerName: 'Менеджер',
      flex: 1,
    },
    {
      field: 'contracts',
      headerName: 'Покупателей',
      flex: 0.3,
    },
  ];

  const visibilityColumns = {};
  visibilityColumns.id = false;

  const handleOnSelectCell = (data) => {
    if (data?.row?.id) {
      navigate(`/provider/regions/${data.row.id}`);
    }
  };

  const handleClickAdd = () => {
    navigate(`/provider/regions/new`);
  };

  const buttons = [];
  buttons.push({
    className: `bg-transparent`,
    name: 'Добавить регион',
    type: 'component',
    action: handleClickAdd,
    component: (props) => {
      return <FaPlus size={22} color={theme === 'dark' ? 'white' : 'black'} />;
    },
  });

  return (
    <>
      <ToolsPanel
        title='Группы покупателей'
        buttons={buttons}
        backURL={'/provider'}
      />
      {isLoading ? (
        <LinearProgress />
      ) : isError ? (
        <Message>{error?.data?.message || error.error}</Message>
      ) : (
        <Container>
          <Row>
            <Col className='mb-3'>
              {Array.isArray(rows) && (
                <DataTable
                  noRowsOverlay={NoRowsOverlayRegions}
                  rows={rows || []}
                  columns={columns}
                  columnVisible={{ id: false, ...visibilityColumns }}
                  onSelectCell={handleOnSelectCell}
                  checkboxSelection={false}
                />
              )}
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
};

export default ProviderRegionsScreen;
