import React, { useEffect, useState } from 'react';
import { Button, Form, ListGroup, Stack } from 'react-bootstrap';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import DraggableList from '../../DraggableList';
import { useUpdateGroupSortMutation } from '../../../slices/groupsApiSlice';
import {
  FaArrowAltCircleLeft,
  FaArrowAltCircleRight,
  FaEdit,
  FaFolder,
  FaFolderOpen,
  FaTrashAlt,
} from 'react-icons/fa';
import { Divider } from '@mui/material';
import { LinkContainer } from 'react-router-bootstrap';

const cutString = (text, limit, or = '', dotted = false) => {
  let result = text;
  if (result && typeof result === 'string') {
    if (result.length > limit)
      result = `${result.substring(0, limit)}${dotted ? '...' : ''}`;
  } else result = or;
  return result;
};

const ItemMobile = ({
  sortMode,
  iconPosition,
  item,
  selectedRoot,
  onClickMove,
  rootsLength,
  onClickDelete,
  selectedItemId,
}) => {
  const [showIcons, setShowIcons] = useState(item?._id === selectedItemId);

  const parentId =
    selectedRoot._id === item?.parentCode ? '' : selectedRoot._id;

  const Name = () => {
    const color =
      selectedRoot._id === item?._id ? 'text-success' : 'text-muted';

    const showIcon = showIcons ? false : true;
    const Icon = () => {
      if (showIcon) {
        if (selectedRoot._id === item?._id)
          return <FaFolderOpen className={color} />;
        else return <FaFolder className={color} />;
      } else {
        return <></>;
      }
    };

    return (
      <span onClick={() => setShowIcons(!showIcons)}>
        <Stack direction='horizontal' className='justify-content-between'>
          <div>
            {showIcons
              ? item?.name
              : cutString(item?.name, item?.isGroup ? 14 : 15)}
          </div>
          {item?.isGroup && !showIcons && <Icon />}
        </Stack>
      </span>
    );
  };

  const Count = () => {
    return (
      <span>
        {item?.isGroup ? (
          ''
        ) : item?.count ? (
          item.count
        ) : (
          <>
            {!sortMode && (
              <span onClick={() => onClickDelete(item)} role='button'>
                <FaTrashAlt size={22} className={`text-warning-emphasis`} />
              </span>
            )}
          </>
        )}
      </span>
    );
  };
  const Icon = () => {
    const getIconStatus = () => {
      if (item?.isGroup) {
        if (selectedRoot._id === item?._id) return ['open', 'info'];
        else return ['close', 'muted'];
      } else {
        if (iconPosition === 'left') return [iconPosition, 'success'];
        else if (iconPosition === 'right')
          return [iconPosition, selectedRoot?._id ? 'info' : 'muted'];
        else return [iconPosition, 'secondary'];
      }
    };

    const iconStatus = getIconStatus();

    return (
      <span>
        {iconStatus[0] === 'left' ? (
          <span role='button' className=''>
            <FaArrowAltCircleLeft
              size={22}
              className={`text-${iconStatus[1]}`}
              onClick={() => onClickMove(item?._id, parentId)}
            />
          </span>
        ) : iconStatus[0] === 'right' ? (
          <span role='button' className=''>
            <FaArrowAltCircleRight
              size={22}
              className={`text-${iconStatus[1]} d-flex`}
              onClick={() => onClickMove(item?._id, parentId)}
            />
          </span>
        ) : iconStatus[0] === 'open' ? (
          <FaFolderOpen size={22} className={`text-success`} />
        ) : (
          <FaFolder size={22} className={`text-${iconStatus[1]}`} />
        )}
      </span>
    );
  };
  const Edit = () => {
    return (
      <LinkContainer to={`/provider/groups/${item?._id}`}>
        <FaEdit
          size={22}
          className={`text-secondary`}
          onClick={() => onClickMove(item?._id, parentId)}
        />
      </LinkContainer>
    );
  };

  return (
    <>
      <Stack direction='vertical'>
        <Name />
        {showIcons && (
          <>
            <Divider component='div' className='my-1' />
            <Stack
              direction='horizontal'
              gap={1}
              className='justify-content-between'
            >
              {iconPosition === 'left' ? (
                <>
                  <Icon />
                  <Edit />
                  <Count />
                </>
              ) : iconPosition === 'right' ? (
                <>
                  <Count />
                  <Edit />
                  <Icon />
                </>
              ) : (
                <>
                  <Count />
                  <Edit />
                </>
              )}
            </Stack>
          </>
        )}
      </Stack>
    </>
  );
};

const Item = ({
  sortMode,
  iconPosition,
  item,
  selectedRoot,
  onClickMove,
  rootsLength,
  onClickDelete,
  selectedItemId,
}) => {
  // const [showIcons, setShowIcons] = useState(item?._id === selectedItemId);

  const parentId =
    selectedRoot._id === item?.parentCode ? '' : selectedRoot._id;

  const Count = () => {
    //
    const GroupData = () => {
      if (Array.isArray(item?.children)) {
        return (
          <>
            {item.children.length === 0 ? (
              <span onClick={() => onClickDelete(item)} role='button'>
                <FaTrashAlt size={22} className={`text-warning-emphasis`} />
              </span>
            ) : (
              item?.count
            )}
          </>
        );
      } else {
        return <></>;
      }
    };

    const ItemData = () => {
      return (
        <>
          {item?.count ? (
            item.count
          ) : (
            <>
              {!sortMode && (
                <span onClick={() => onClickDelete(item)} role='button'>
                  <FaTrashAlt size={22} className={`text-warning-emphasis`} />
                </span>
              )}
            </>
          )}
        </>
      );
    };

    return (
      <div className='p-0 me-2'>
        {item?.isGroup ? <GroupData /> : <ItemData />}
      </div>
    );
  };

  const Icon = () => {
    const getIconStatus = () => {
      if (item?.isGroup) {
        if (selectedRoot._id === item?._id) return ['open', 'info'];
        else return ['close', 'muted'];
      } else {
        if (iconPosition === 'left') return [iconPosition, 'success'];
        else if (iconPosition === 'right')
          return [iconPosition, selectedRoot?._id ? 'info' : 'muted'];
        else return [iconPosition, 'secondary'];
      }
    };

    const iconStatus = getIconStatus();

    return (
      <div className='p-0 me-2' role='button'>
        {iconStatus[0] === 'left' ? (
          <FaArrowAltCircleLeft
            size={22}
            className={`text-${iconStatus[1]}`}
            onClick={() => onClickMove(item?._id, parentId)}
          />
        ) : iconStatus[0] === 'right' ? (
          <FaArrowAltCircleRight
            size={22}
            className={`text-${iconStatus[1]} d-flex`}
            onClick={() => onClickMove(item?._id, parentId)}
          />
        ) : iconStatus[0] === 'open' ? (
          <FaFolderOpen size={22} className={`text-success`} />
        ) : (
          <FaFolder size={22} className={`text-${iconStatus[1]}`} />
        )}
      </div>
    );
  };

  // const Edit = () => {
  //   return (
  //     <LinkContainer to={`/provider/groups/${item?._id}`}>
  //       <FaEdit
  //         size={22}
  //         className={`text-secondary`}
  //         onClick={() => onClickMove(item?._id, parentId)}
  //       />
  //     </LinkContainer>
  //   );
  // };

  const Name = () => {
    return (
      <div className='p-0 me-auto'>
        <LinkContainer to={`/provider/groups/${item?._id}`}>
          <p
            className='m-0 group-mobile-item'
            style={{ textUnderlineOffset: '20px' }}
          >
            <abbr title='Открыть свойства'>{item?.name}</abbr>
          </p>
        </LinkContainer>
      </div>
    );
  };

  return (
    <>
      <Stack direction='horizontal' gap={0}>
        {iconPosition === 'left' ? (
          <>
            {!sortMode && <Icon />}
            <Name />
            <Count />
          </>
        ) : iconPosition === 'right' ? (
          <>
            <Name />
            <Count />
            {!sortMode && <Icon />}
          </>
        ) : (
          <>
            <Name />
            <Count />
          </>
        )}
      </Stack>
    </>
  );
};

const GroupListSortable = ({
  refetch,
  rows,
  selectedRoot,
  onClick,
  onClickItem,
  onClickMove,
  onClickDelete,
  selectedItemId,
  iconPosition,
  viewMode,
  rootsLength = 0,
  updateKey,
}) => {
  //   const dispatch = useDispatch();
  const { theme, currentBreakpoint, currentProvider } = useSelector(
    (state) => state.auth
  );
  const [itemList, setItemList] = useState([]);
  const [itemListChanged, setItemListChanged] = useState(false);
  const [sortMode, setSortMode] = useState(false);

  useEffect(() => {
    if (Array.isArray(rows)) {
      setItemList(rows);
    }
  }, [rows]);

  const [updateGroups] = useUpdateGroupSortMutation();

  const onDragEnd = (result) => {
    if (!itemListChanged) {
      setItemListChanged(true);
    }
    if (!result.destination) return;
    const items = Array.from(itemList);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setItemList(items);
  };

  const saveWork = async () => {
    try {
      await updateGroups({
        itemList,
        providerId: currentProvider?._id,
      }).unwrap();
      //setOpenId('');
      //setSelectedItem('');
      setItemListChanged(false);
      refetch();
      //toast.success(result.message);
    } catch (error) {
      console.log(error);
      toast.error(error?.data?.message || error.error);
    }
    setSortMode(false);
  };

  const themeStyle = {
    //backgroundColor: theme === 'dark' ? '#212529' : 'white',
    //borderColor: theme === 'dark' ? '#212529' : '#b5c0c1',
    //color: theme === 'dark' ? 'white' : 'black',
  };

  //  console.log('empty', empty);

  const handleChangeSortMode = (value) => {
    if (sortMode && itemListChanged) {
      refetch();
      updateKey('left');
    }
    setSortMode(Boolean(value));
    setItemListChanged(false);
  };

  return (
    <>
      {itemList?.length > 1 && (
        <Stack direction='horizontal' className='justify-content-between  mb-3'>
          <Form.Check
            className={`align-self-center ${sortMode ? 'text-warning' : ''}`}
            type='switch'
            label={`${
              ['xs', 'sm'].includes(currentBreakpoint)
                ? 'Сорт.'
                : 'Режим сортировки'
            }`}
            checked={sortMode}
            onChange={(e) => handleChangeSortMode(e.target.checked)}
          ></Form.Check>
          {itemListChanged && (
            <Button
              onClick={saveWork}
              size='sm'
              variant={!itemListChanged ? 'outline-secondary' : 'success'}
            >
              Сохранить
            </Button>
          )}
        </Stack>
      )}
      <ListGroup>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId='itemList'>
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {itemList &&
                  itemList.map((group, index) => (
                    <DraggableList
                      key={index}
                      id={group._id}
                      index={index}
                      isDisable={!sortMode}
                    >
                      <ListGroup.Item
                        // action={!sortMode}className='overflow-hidden'
                        active={selectedItemId === group?._id}
                        className='align-items-center rounded px-0 row'
                        variant={theme}
                        style={themeStyle}
                        onClick={() => onClickItem(group)}
                      >
                        {viewMode === 'pro' ? (
                          <>
                            {['xs', 'sm'].includes(currentBreakpoint) ? (
                              <ItemMobile
                                key={group?._id}
                                sortMode={sortMode}
                                iconPosition={iconPosition}
                                item={group}
                                selectedRoot={selectedRoot}
                                onClickMove={onClickMove}
                                onClickDelete={onClickDelete}
                                rootsLength={rootsLength}
                              />
                            ) : (
                              <Item
                                key={group?._id}
                                sortMode={sortMode}
                                iconPosition={iconPosition}
                                item={group}
                                selectedRoot={selectedRoot}
                                onClickMove={onClickMove}
                                onClickDelete={onClickDelete}
                                rootsLength={rootsLength}
                              />
                            )}
                          </>
                        ) : (
                          <>
                            {['xs', 'sm'].includes(currentBreakpoint) ? (
                              <ItemMobile
                                key={group?._id}
                                sortMode={sortMode}
                                iconPosition={''}
                                item={group}
                                selectedRoot={selectedRoot}
                                onClickMove={onClickMove}
                                onClickDelete={onClickDelete}
                                rootsLength={rootsLength}
                              />
                            ) : (
                              <Item
                                key={group?._id}
                                sortMode={sortMode}
                                iconPosition={''}
                                item={group}
                                selectedRoot={selectedRoot}
                                onClickMove={onClickMove}
                                onClickDelete={onClickDelete}
                                rootsLength={rootsLength}
                              />
                            )}
                          </>
                          // <Row>
                          //   <ItemName
                          //     item={group}
                          //     count={group?.count}
                          //     name={group?.name}
                          //     sortMode={sortMode}
                          //   />
                          // </Row>
                        )}
                        {/* {iconPosition === 'left' && viewMode === 'pro' ? (
                          <Row>
                            <ItemIcon
                              sortMode={sortMode}
                              color='success'
                              iconPosition={
                                group?.isGroup
                                  ? selectedRoot._id === group?._id
                                    ? 'open'
                                    : 'close'
                                  : iconPosition
                              }
                              item={group}
                              parentId={
                                selectedRoot._id === group?.parentCode
                                  ? ''
                                  : selectedRoot._id
                              }
                              onClickMove={
                                group?.isGroup ? () => {} : onClickMove
                              }
                            />
                            <ItemName
                              item={group}
                              count={group?.count}
                              name={group?.name}
                              sortMode={sortMode}
                            />
                          </Row>
                        ) : iconPosition === 'right' && viewMode === 'pro' ? (
                          <Row>
                            <ItemName
                              sortMode={sortMode}
                              count={group?.count}
                              name={group?.name}
                              item={group}
                            />
                            <ItemIcon
                              sortMode={sortMode}
                              color={
                                group?.isGroup || selectedRoot._id === ''
                                  ? 'muted'
                                  : 'info'
                              }
                              iconPosition={
                                group?.isGroup
                                  ? selectedRoot._id === group?._id
                                    ? 'open'
                                    : 'close'
                                  : rootsLength > 0
                                  ? iconPosition
                                  : ''
                              }
                              item={group}
                              parentId={
                                selectedRoot._id === group?.parentCode
                                  ? ''
                                  : selectedRoot._id
                              }
                              onClickMove={
                                group?.isGroup ? () => {} : onClickMove
                              }
                            />
                          </Row>
                        ) : (
                          <Row>
                            <ItemName
                              item={group}
                              count={group?.count}
                              name={group?.name}
                              sortMode={sortMode}
                            />
                          </Row>
                        )} */}
                      </ListGroup.Item>
                    </DraggableList>
                  ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </ListGroup>
    </>
  );
};

export default GroupListSortable;

// const ItemIcon = ({
//   item,
//   parentId,
//   onClickMove,

//   iconPosition,
//   color = 'primary',
//   sortMode,
// }) => {
//   return (
//     <Col md={1}>
//       {sortMode || iconPosition === '' ? (
//         <span role='button'>&nbsp;</span>
//       ) : (
//         <>
//           {iconPosition === 'left' ? (
//             <FaArrowAltCircleLeft
//               role='button'
//               size={22}
//               className={`text-${color}`}
//               onClick={() => onClickMove(item?._id, parentId)}
//             />
//           ) : iconPosition === 'right' ? (
//             <FaArrowAltCircleRight
//               role='button'
//               size={22}
//               className={`text-${color} d-flex`}
//               onClick={() => onClickMove(item?._id, parentId)}
//             />
//           ) : iconPosition === 'open' ? (
//             <FaFolderOpen size={22} className={`text-success`} />
//           ) : (
//             <FaFolder size={22} className={`text-${color}`} />
//           )}
//         </>
//       )}
//     </Col>
//   );
// };

// const ItemName = ({ item, count, onClickDelete, isGroup, sortMode }) => {
//   return (
//     <Col className='m-0 p-0'>
//       <Stack direction='horizontal' className='d-flex justify-content-between'>
//         <span className='d-flex overflow-hidden'>{item?.name}</span>
//         <span className='d-flex' style={{ width: '25px' }}>
//           {isGroup ? (
//             ''
//           ) : count ? (
//             count
//           ) : (
//             <>
//               {!sortMode && (
//                 <span onClick={() => onClickDelete(item)} role='button'>
//                   <FaTrashAlt size={22} className={`text-warning-emphasis`} />
//                 </span>
//               )}
//             </>
//           )}
//         </span>
//       </Stack>
//     </Col>
//   );
// };

// const ItemOld = ({
//   sortMode,
//   iconPosition,
//   item,
//   selectedRoot,
//   onClickMove,
//   rootsLength,
// }) => {
//   return (
//     <>
//       {iconPosition === 'left' ? (
//         <Row>
//           <ItemIcon
//             sortMode={sortMode}
//             color='success'
//             iconPosition={
//               item?.isGroup
//                 ? selectedRoot._id === item?._id
//                   ? 'open'
//                   : 'close'
//                 : iconPosition
//             }
//             item={item}
//             parentId={
//               selectedRoot._id === item?.parentCode ? '' : selectedRoot._id
//             }
//             onClickMove={item?.isGroup ? () => {} : onClickMove}
//           />
//           <ItemName item={item} count={item?.count} sortMode={sortMode} />
//         </Row>
//       ) : (
//         <Row>
//           <ItemName sortMode={sortMode} count={item?.count} item={item} />
//           <ItemIcon
//             sortMode={sortMode}
//             color={item?.isGroup || selectedRoot._id === '' ? 'muted' : 'info'}
//             iconPosition={
//               item?.isGroup
//                 ? selectedRoot._id === item?._id
//                   ? 'open'
//                   : 'close'
//                 : rootsLength > 0
//                 ? iconPosition
//                 : ''
//             }
//             item={item}
//             parentId={
//               selectedRoot._id === item?.parentCode ? '' : selectedRoot._id
//             }
//             onClickMove={item?.isGroup ? () => {} : onClickMove}
//           />
//         </Row>
//       )}
//     </>
//   );
// };
