import React, { useState } from 'react';
import { useGetProductPricesQuery } from '../../../slices/productsApiSlice';
import { useSelector } from 'react-redux';
import { LinearProgress } from '@mui/material';
import Message from '../../Message';
import { Col, Container, Form, Row } from 'react-bootstrap';
import DataTable from '../../DataTable/DataTable';
import { useSavePriceValueMutation } from '../../../slices/pricesApiSlice';
import { toast } from 'react-toastify';

const PriceList = ({ productId }) => {
  const { currentProvider } = useSelector((state) => state.auth);
  const [showAll, setShowAll] = useState(false);
  const { data, refetch, isLoading, isError, error } = useGetProductPricesQuery(
    {
      providerId: currentProvider?._id,
      productId: productId,
    }
  );
  const [savePriceValue, { isLoading: isLoadingSave }] =
    useSavePriceValueMutation();

  const columns = [
    { field: 'id', headerName: 'id', width: 50, hide: true },
    {
      field: 'name',
      headerName: 'Тип цен',
      flex: 1,
    },
    {
      field: 'price',
      headerName: 'Цена',
      flex: 0.3,
      editable: true,
    },
  ];

  const visibilityColumns = {};
  visibilityColumns.id = false;

  let rows = data?.rows;
  if (!showAll && Array.isArray(rows)) {
    rows = rows.filter((row) => 'price' in row && row?.price > 0);
  }

  const save = async (priceId, value) => {
    try {
      const result = await savePriceValue({
        priceId,
        productId,
        newValue: value,
        providerId: currentProvider?._id,
      }).unwrap();
      refetch();
      //   console.log(result);
    } catch (error) {
      toast.error(error?.data?.message || error?.message || error?.error);
    }
  };

  const handleOnUpdateRow = (data) => {
    const { id, price } = data.newRow;
    if (id) {
      save(id, Number(price));
    }
  };

  return (
    <>
      {isLoading ? (
        <LinearProgress />
      ) : isError ? (
        <Message>
          {error?.data?.message || error?.message || error?.error}
        </Message>
      ) : (
        <Container>
          <Row>
            <Form.Group controlId='canReturn' className='my-2' as={Row}>
              <Col sm='12'>
                <Form.Check
                  isValid
                  type='checkbox'
                  label='Показывать пустые'
                  checked={showAll}
                  onChange={(e) => setShowAll(e.target.checked)}
                ></Form.Check>
              </Col>
            </Form.Group>
          </Row>
          <Row>
            {Array.isArray(data?.rows) && (
              <DataTable
                isLoading={isLoadingSave}
                // noRowsOverlay={NoRowsOverlayUsers}
                rows={rows || []}
                columns={columns}
                columnVisible={{ id: false, ...visibilityColumns }}
                onUpdateRow={handleOnUpdateRow}
                // onSelectCell={handleOnSelectCell}
                // checkboxSelection={false}
              />
            )}
          </Row>
        </Container>
      )}
    </>
  );
};

export default PriceList;
