import React, { useEffect } from 'react';
import { useOrientation } from 'react-use';
import { Outlet } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import { useMediaQuery } from '@mui/material';
import {
  setMobileMenuIsOpen,
  setOrientation,
  setShowLeftMenu,
} from '../slices/authSlice';

import { ToastContainer } from 'react-toastify';
import HomeScreen from './HomeScreen';
import HeaderSection from '../components/sections/HeaderSection/HeaderSection';
import LeftSection from '../components/sections/LeftSection/LeftSection';

import 'react-toastify/dist/ReactToastify.css';

export const DRAWER_WIDTH = 240;

function MuiScreen() {
  const dispatch = useDispatch();
  const { mobileMenuIsOpen, showLeftMenu } = useSelector((state) => state.auth);
  const noPadding = useMediaQuery('(max-width:700px)');

  const orientation = useOrientation();
  useEffect(() => {
    dispatch(setOrientation(orientation));
  }, [orientation, dispatch]);

  if (window.location.pathname === '/') {
    return <HomeScreen />;
  }

  const handleDrawerClose = () => {
    dispatch(setMobileMenuIsOpen(false));
  };

  const handleDrawerToggle = () => {
    dispatch(setMobileMenuIsOpen(!mobileMenuIsOpen));
  };

  const handleLeftMenuToggle = () => {
    dispatch(setShowLeftMenu(!showLeftMenu));
  };

  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <HeaderSection
          handleDrawerToggle={handleDrawerToggle}
          handleLeftMenuToggle={handleLeftMenuToggle}
        />

        <LeftSection handleDrawerClose={handleDrawerClose} />
        <Box
          component='main'
          sx={{
            flexGrow: 1,
            px: noPadding ? 0 : 3,
            pt: 0,
            mb: 3,
            width: { sm: `calc(100% - ${showLeftMenu ? DRAWER_WIDTH : 0}px)` },
          }}
        >
          <Toolbar />
          <Outlet />
        </Box>
      </Box>
      <ToastContainer autoClose={2000} />
    </>
  );
}

export default MuiScreen;
