import { StyledGridOverlay } from '../StyledGridOverlay/StyledGridOverlay';
import NoRowOverlaySVG from '../NoRowOverlaySVG/NoRowOverlaySVG';

export function NoRowsOverlay({ children }) {
  return (
    <StyledGridOverlay>
      <NoRowOverlaySVG />
      {children}
    </StyledGridOverlay>
  );
}
