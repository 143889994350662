import React, { useEffect, useState } from 'react';
import {
  useGetAllSectionsForUnitQuery,
  useUpdateUnitSectionsMutation,
} from '../slices/sectionsApiSlice';
import { Button, Col, Container, Form, ListGroup, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import Message from '../components/Message';
import ToolsPanel from '../components/ToolsPanel/ToolsPanel';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const ProviderSectionsEditScreen = () => {
  const { theme, currentProvider } = useSelector((state) => state.auth);
  const [sections, setSections] = useState([]);
  const [modified, setModified] = useState(false);
  const navigate = useNavigate();

  const { data, refetch, isLoading, isError, error } =
    useGetAllSectionsForUnitQuery(currentProvider?._id);

  const [updateUnitSections, { isLoading: isLoadingUpdate }] =
    useUpdateUnitSectionsMutation();

  useEffect(() => {
    if (data) {
      setSections(data);
    }
  }, [data]);

  const saveHandler = async () => {
    const toSend = {
      sections,
      unitId: currentProvider?._id,
    };
    try {
      await updateUnitSections(toSend).unwrap();
      //console.log(result);
      refetch();
      navigate('/provider/details/');
    } catch (error) {
      toast.error(error?.data?.message || error.error);
    }
  };

  const onChangeHandler = (id) => {
    setModified(true);
    const result = sections.map((item) =>
      item._id === id ? { ...item, checked: !item.checked } : item
    );
    setSections(result);
  };

  const buttons = [
    {
      name: `Сохранить`,
      action: saveHandler,
      className: 'btn-block',
      disabled: !modified,
      variant: modified && 'success',
    },
  ];

  return (
    <>
      <ToolsPanel
        title='Выберите ваши категории'
        backURL={`/provider/details`}
        isLoading={isLoadingUpdate || isLoading}
        buttons={buttons}
      />
      {isLoading ? (
        <></>
      ) : isError ? (
        <Message>{error?.data?.message || error.error}</Message>
      ) : (
        <>
          <Container>
            <Row>
              <Col>
                <Form>
                  <ListGroup>
                    {sections &&
                      sections.map((item) => (
                        <ListGroup.Item
                          as={'div'}
                          variant={theme}
                          key={item._id}
                          className='d-flex justify-content-between align-items-start'
                        >
                          <Form.Check // prettier-ignore
                            checked={item.checked}
                            type='switch'
                            label={item.name}
                            onChange={() => onChangeHandler(item._id)}
                          />
                        </ListGroup.Item>
                      ))}
                  </ListGroup>

                  <Button
                    type='button'
                    variant='primary'
                    className='my-2'
                    onClick={saveHandler}
                  >
                    Сохранить
                  </Button>
                </Form>
              </Col>
            </Row>
          </Container>
        </>
      )}
    </>
  );
};

export default ProviderSectionsEditScreen;
