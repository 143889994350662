import { apiSlice } from './apiSlice.js'
import { ORDERS_URL } from '../constans.js'

const ordersApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		createUpdateOrder: builder.mutation({
			query: (order) => ({
				url: ORDERS_URL,
				method: 'POST',
				body: { ...order }
			}),
			invalidatesTags: ['Orders', 'RouteList'],
		}),
		setOrderValue: builder.mutation({
			query: (data) => ({
				url: `${ORDERS_URL}/${data?.orderId}`,
				method: 'PATCH',
				body: {
					key: data?.key,
					value: data?.value
				}
			}),
			keepUnusedDataFor: 5,
			invalidatesTags: ['Orders']
		}),
		getOrderDetails: builder.query({
			query: (data) => ({
				url: `${ORDERS_URL}/${data?.orderId}`,
				method: 'GET',
				params: {
					...data
				}
			}),
			keepUnusedDataFor: 5
		}),
		getOrderById: builder.mutation({
			query: (data) => ({
				url: `${ORDERS_URL}/${data?.orderId}`,
				method: 'POST',
				body: {
					customerId: data?.customerId,
					providerId: data?.providerId
				}
			}),
			keepUnusedDataFor: 5
		}),
		getMyOrders: builder.query({
			query: () => ({
				url: `${ORDERS_URL}/mine`,
			}),
			keepUnusedDataFor: 5,
			providesTags: ['Orders']

		}),
		getCustomerOrders: builder.query({
			query: ({ customerId, providerId, pageNumber, pageSize, pov }) => ({
				url: `${ORDERS_URL}/customer/`,
				params: {
					pov,
					customerId: customerId,
					providerId: providerId,
					pageNumber: pageNumber,
					pageSize: pageSize,
				}
			}),
			refetchOnFocus: true,
			refetchOnMountOrArgChange: true,
			providesTags: ['Orders'],
			//keepUnusedDataFor: 5
		}),
		getProviderOrders: builder.query({
			query: (data) => ({
				url: `${ORDERS_URL}/provider/`,
				params: {
					customerIds: data?.customerIds,
					providerId: data?.providerId,
					pageNumber: data?.pageNumber,
					pageSize: data?.pageSize,
					date1: data?.date1,
					date2: data?.date2,
					keyword: data?.keyword
				}
			}),
			keepUnusedDataFor: 5,
			providesTags: ['Orders'],
		}),
		deliverOrder: builder.mutation({
			query: (orderId) => ({
				url: `${ORDERS_URL}/${orderId}/deliver`,
				method: 'PUT'
			}),
			keepUnusedDataFor: 5
		}),
	})
})

export const {
	useSetOrderValueMutation,
	useGetProviderOrdersQuery,
	useGetOrderByIdMutation, useGetCustomerOrdersQuery, useCreateUpdateOrderMutation, useGetOrderDetailsQuery, useGetMyOrdersQuery, useDeliverOrderMutation } = ordersApiSlice;
