import {
  Chip,
  Collapse,
  Divider,
  LinearProgress,
  List,
  ListItemButton,
  ListItemText,
} from '@mui/material';
import React from 'react';
import Message from '../../Message';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
// import { setCurrentRoute } from '../../../slices/authSlice';
import { useGetUsersOfTasksQuery } from '../../../slices/tasksApiSlice';

const TasksMenu = ({ handleDrawerClose }) => {
  const { userId } = useParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  // const dispatch = useDispatch();
  const { pov, currentProvider } = useSelector((state) => state.auth);
  const { data, isLoading, isError, error } = useGetUsersOfTasksQuery(
    { providerId: currentProvider?._id },
    { refetchOnMountOrArgChange: true }
  );

  // console.log('data', data);

  const handleClick = (item) => {
    navigate(`/provider/tasks/ofuser/${item._id}`);
    handleDrawerClose(true);
  };
  const handleClickMultiTask = () => {
    navigate(`/provider/multitasks`);
    handleDrawerClose(true);
  };

  return (
    <>
      {isLoading ? (
        <LinearProgress />
      ) : isError ? (
        <Message>
          {error?.data?.message || error?.message || error.error}
        </Message>
      ) : (
        <>
          <List
            sx={{
              '& .Mui-selected': {
                backgroundColor:
                  pov === 'customer'
                    ? '#1976d261 !important'
                    : '#2fd21961 !important',
              },
            }}
          >
            <ListItemButton
              sx={{ borderLeftWidth: '3px !important' }}
              className={pov === 'customer' ? 'border-info' : 'border-success'}
            >
              <ListItemText primary={'Персональные'} />
            </ListItemButton>

            <Collapse
              className={
                pov === 'customer'
                  ? 'border-info border-start '
                  : 'border-success border-start '
              }
              sx={{ borderLeftWidth: '3px !important' }}
              in={true}
              unmountOnExit
            >
              <List component='div' disablePadding>
                <ListItemButton
                  sx={{ borderLeftWidth: '3px !important' }}
                  className={
                    pov === 'customer' ? 'border-info' : 'border-success'
                  }
                  onClick={() =>
                    handleClick({
                      _id: 'all',
                      name: 'Все',
                      sort: 0,
                    })
                  }
                  selected={
                    (userId === 'all' || !userId) &&
                    pathname.includes('/provider/tasks')
                  }
                >
                  <ListItemText primary={'Все'} />
                </ListItemButton>

                {Array.isArray(data?.rows) &&
                  data.rows.map((item, index) => (
                    <ListItemButton
                      key={item?._id || index}
                      sx={{ borderLeftWidth: '3px !important' }}
                      onClick={() => handleClick(item)}
                      selected={userId === item._id}
                    >
                      <ListItemText primary={item.name} />
                      {!!item?.active && (
                        <Chip
                          label={item?.active}
                          size='small'
                          color={pov === 'customer' ? 'primary' : 'success'}
                          variant='outlined'
                        />
                      )}
                    </ListItemButton>
                  ))}
              </List>
            </Collapse>
          </List>
          <Divider className='my-2' />
          <List
            sx={{
              '& .Mui-selected': {
                backgroundColor:
                  pov === 'customer'
                    ? '#1976d261 !important'
                    : '#2fd21961 !important',
              },
            }}
          >
            <ListItemButton
              sx={{ borderLeftWidth: '3px !important' }}
              className={pov === 'customer' ? 'border-info' : 'border-success'}
              selected={pathname.includes('/provider/multitasks')}
              onClick={handleClickMultiTask}
            >
              <ListItemText primary={'Групповые'} />
            </ListItemButton>
          </List>
        </>
      )}
    </>
  );
};

export default TasksMenu;
