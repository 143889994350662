import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Message from '../components/Message/Message.jsx';
import DataTable from '../components/DataTable/DataTable.jsx';
import ToolsPanel from '../components/ToolsPanel/ToolsPanel.jsx';
import { LinearProgress } from '@mui/material';
import { FaCogs } from 'react-icons/fa';
import { Col, Container, Form, Row } from 'react-bootstrap';
import CustomerToolbar from '../components/DataTableToolbar/CustomerToolbar/CustomerToolbar.jsx';
import { toast } from 'react-toastify';
import { colsWidth } from '../utils/authUtils.js';
import { setGridSettingMode } from '../slices/authSlice.js';
import {
  addToCart,
  clearCartItems,
  removeFromCart,
  replaceCartItems,
  unsetCartParams,
} from '../slices/cartSlice.js';
import { useGetHierarchyProductsQuery } from '../slices/productsApiSlice.js';
import { calculateTotals } from '../utils/cartUtils.js';
import { useCreateUpdateOrderMutation } from '../slices/ordersApiSlice.js';
import InputSelectable from '../components/UI/InputSelectable/';

const ProviderOrderScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { orderId, customerId } = useParams();
  const { search, state } = useLocation();

  // const sp = new URLSearchParams(search);
  // const contractId = sp.get('cid');
  let backUrl;
  if (state) {
    backUrl = state?.backUrl;
  }

  // console.log('customerId', customerId);

  const [selectedCustomer, setSelectedCustomer] = useState({});
  // const [canReturn, setCanReturn] = useState(false);
  const [oneClickMode, setOneClickMode] = useState(false);

  const cart = useSelector((state) => state.cart);

  const {
    gridSettingMode,
    currentProvider,
    currentBreakpoint,
    showUnitColumn,
    showPrice,
    showReturnColumn,
    showMultipleColumn,
    theme,
  } = useSelector((state) => state.auth);

  useEffect(() => {
    if (['xs', 'sm', 'md'].includes(currentBreakpoint)) {
      setOneClickMode(true);
    }
  }, [currentBreakpoint]);

  useEffect(() => {
    //для перерисовки компоненты при выборе покупателя в левом меню
    setSelectedCustomer('');
  }, [customerId]);

  const queryData = {
    // orderId,
    providerId: currentProvider?._id,
    customerId: selectedCustomer?._id ? selectedCustomer?._id : customerId,
  };

  // console.log('queryData', queryData);

  const skipFetch = !selectedCustomer?._id && !customerId;
  // console.log('skipFetch', skipFetch);

  const { data, refetch, isLoading, isError, error } =
    useGetHierarchyProductsQuery(queryData, {
      skip: skipFetch,
      refetchOnMountOrArgChange: true,
    });

  // console.log('data', data);

  const [createUpdateOrder] = useCreateUpdateOrderMutation();

  useEffect(() => {
    // if (Array.isArray(data?.cartItems)) {
    //   dispatch(
    //     replaceCartItems({
    //       providerId: currentProvider?._id,
    //       providerName: currentProvider?.name,
    //       customerId: selectedCustomer?._id,
    //       customerName: selectedCustomer?.name,
    //       cartItems: data?.cartItems,
    //     })
    //   );
    // }

    if (data?.customer) {
      setSelectedCustomer(data.customer);
    }
  }, [data]);

  const cartItems = cart.cartItems?.filter(
    (item) =>
      item.providerId === currentProvider._id &&
      item.customerId === selectedCustomer._id
  );

  const getValues = (productId) => {
    const exist = cartItems?.find((x) => x.id === productId);
    return { qty: exist?.qty || '', ret: exist?.ret || '' };
  };

  let rows = [];
  if (Array.isArray(data?.rows) && data.rows.length > 0) {
    for (let index = 0; index < data.rows.length; index++) {
      const item = data.rows[index];

      let multiple = item?.multiple ? item?.multiple : '';
      let name = item?.shortName || item?.name;
      if (!item?.isDivider) {
        if (!showUnitColumn && !showMultipleColumn) {
          name = `${name} ( ${multiple} ${item?.unit} )`;
        } else if (!showUnitColumn && showMultipleColumn) {
          name = `${name} ( ${item?.unit} )`;
        } else if (showUnitColumn && !showMultipleColumn) {
          name = `${name}  ${multiple ? '(' + multiple + ' в уп.)' : ''}`;
        }
      }

      rows.push({
        ...item,
        name,
        id: item._id,
        noteditable: item?.isDivider,
        ...getValues(item._id),
      });
    }
  }

  const columns = [
    { field: 'id', headerName: '№', width: 250 },
    { field: 'noteditable', headerName: '№', width: 50, hide: true },
    { field: 'isDivider', headerName: 'Группа', width: 150, hide: true },
    {
      field: 'name',
      headerName: 'Название',
      flex: 1,
      editable: false,
      cellClassName: 'grid-cell-name',
    },
    {
      field: 'multiple',
      headerName: 'в уп',
      width: 50,
      editable: false,
      headerClassName: 'grid-header-price',
      cellClassName: 'grid-cell-price',
      sortable: false,
    },
    {
      field: 'price',
      headerName: 'Цена',
      width: 80,
      editable: false,
      headerClassName: 'grid-header-price',
      cellClassName: 'grid-cell-price',
      sortable: false,
    },
    {
      field: 'unit',
      headerName: 'Ед.',
      width: colsWidth.unit,
      editable: false,
      sortable: false,
      align: 'left',
      headerAlign: 'left',
      headerClassName: 'grid-header-unit',
      cellClassName: 'grid-cell-unit',
    },
    {
      field: 'qty',
      type: 'number',
      headerName: 'Заявка',
      width: colsWidth.qty,
      editable: true,
      //renderEditCell: renderQtyEditInputCell,
      headerAlign: 'left',
      headerClassName: 'grid-header-order',
      cellClassName: 'grid-cell-order',
    },
  ];

  if (data?.options?.canReturn) {
    columns.push({
      field: 'ret',
      type: 'number',
      headerName: 'Возврат',
      width: colsWidth.qty,
      editable: true,
      //sortable: false,
      headerAlign: 'left',
      headerClassName: 'grid-header-order',
      cellClassName: 'grid-cell-return',
    });
  }

  const visibilityColumns = {
    noteditable: false,
    promo: false,
    image: false,
    description: false,
    code: false,
    isDivider: false,
    price: showPrice,
    ret: showReturnColumn,
    unit: showUnitColumn,
    multiple: showMultipleColumn,
  };

  const handleOnUpdateRow = (data) => {
    let qty = Number(data.newRow?.qty) || 0;

    if (qty !== 0) {
      qty = qty < 0 ? qty * -1 : qty; //Math.abs(qty)
    } else {
      qty = '';
    }

    let ret = Number(data.newRow?.ret) || 0;

    if (ret !== 0) {
      ret = ret < 0 ? ret * -1 : ret; //Math.abs(ret)
    } else {
      ret = '';
    }
    const price = data.newRow?.price || 0;

    if (qty === 0 && ret === 0) {
      dispatch(
        removeFromCart({
          id: data.newRow.id,
          providerId: currentProvider._id,
          customerId: selectedCustomer._id,
        })
      );
    } else {
      dispatch(
        addToCart({
          ...data.newRow,
          _id: data.newRow.id,
          providerName: currentProvider.name,
          providerId: currentProvider._id,
          customerName: selectedCustomer.name,
          customerId: selectedCustomer._id,
          qty,
          ret,
          price,
        })
      );
    }
  };

  const handleClickShowStuff = () => {
    dispatch(setGridSettingMode(!gridSettingMode));
  };

  const placeOrderHandler = async () => {
    if (!selectedCustomer._id) {
      toast.error('Не выбран покупатель');
      return;
    }

    // console.log(cartItems);

    if (cartItems.length === 0) {
      toast.error('Заявка пустая');
      return;
    }

    const { totalPrice, itemsPrice, shippingPrice, taxPrice } =
      calculateTotals(cartItems);

    try {
      const toSend = {
        orderId: orderId ? orderId : 'new',
        orderItems: cartItems,
        providerId: currentProvider?._id,
        customerId: selectedCustomer?._id,
        paymentMethod: selectedCustomer?.paymentMethod,
        shippingAddress: selectedCustomer?.address,
        comment: selectedCustomer?.comment,
        contacts: selectedCustomer?.phone,
        itemsPrice: itemsPrice,
        shippingPrice: shippingPrice,
        taxPrice: taxPrice,
        totalPrice: totalPrice,
      };
      // console.log(toSend);
      const result = await createUpdateOrder(toSend).unwrap();
      // console.log(result);

      dispatch(
        clearCartItems({
          providerId: currentProvider?._id,
          customerId: selectedCustomer?._id,
        })
      );
      dispatch(
        unsetCartParams({
          providerId: currentProvider?._id,
          customerId: selectedCustomer._id,
        })
      );

      if (result?.orderId) {
        navigate(`/provider/orders/${result?.orderId}`, {
          state: { backUrl: '/provider/orders/new' },
        });
      } else {
        navigate(`/provider/orders`);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const handleSelectedCustomer = (sel) => {
    if (sel?._id && sel._id !== selectedCustomer?._id) {
      navigate(`/provider/orders/new/${sel._id}`);
      setSelectedCustomer(sel);
    }
  };

  let buttons = [
    {
      size: 'normal',
      name: 'Сохранить',
      action: placeOrderHandler,
      disabled: rows?.length === 0,
      className: 'btn-block',
      variant: rows?.length === 0 ? 'outline-secondary' : 'success',
    },
    {
      className: `bg-transparent ${
        gridSettingMode && 'border-success  border-2'
      }`,
      name: 'Инструменты',
      type: 'component',
      action: handleClickShowStuff,
      component: (props) => {
        return (
          <FaCogs size={22} color={theme === 'dark' ? 'white' : 'black'} />
        );
      },
    },
  ];

  // console.log('orderId', orderId);

  return (
    <>
      <ToolsPanel
        backURL={backUrl ? backUrl : `/provider/orders/`}
        buttons={buttons}
        title={'Заявочник'}
      />
      <Container>
        <Form.Group controlId='customer' className='my-2' as={Row}>
          <Form.Label column sm='4' className='pb-0'>
            Покупатель
          </Form.Label>
          <Col sm='8'>
            <InputSelectable
              key={selectedCustomer?._id || 'new'}
              type='text'
              placeholder='Выберите покупателя'
              value={selectedCustomer}
              onChange={handleSelectedCustomer}
              unitType='Покупатели'
            />
          </Col>
        </Form.Group>
      </Container>
      {isLoading ? (
        <LinearProgress />
      ) : isError ? (
        <Message> {error?.data?.message || error.error} </Message>
      ) : (
        <Container>
          {Array.isArray(rows) && (
            <span>
              {gridSettingMode && <CustomerToolbar refetch={refetch} />}
              <DataTable
                mobileView={oneClickMode}
                rows={rows}
                columns={columns}
                columnVisible={{ id: false, ...visibilityColumns }}
                onUpdateRow={handleOnUpdateRow}
              />
            </span>
          )}
        </Container>
      )}
    </>
  );
};

export default ProviderOrderScreen;
