import React, { useEffect, useState } from 'react';
// import { useUpdateUnitSectionsMutation } from '../slices/sectionsApiSlice';
import { Button, Col, Container, Form, ListGroup, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import Message from '../components/Message';
import ToolsPanel from '../components/ToolsPanel/ToolsPanel';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import {
  useGetProviderCitiesQuery,
  useUpdateProviderFieldMutation,
} from '../slices/providersApiSlice';
import MultiSelectInput from '../components/MultiSelectInput';

const ProviderCitiesEditScreen = () => {
  const { theme, currentProvider } = useSelector((state) => state.auth);
  // const [items, setItems] = useState([]);
  const [modified, setModified] = useState(false);

  // const [selectedCities, setSelectedCities] = useState([]);
  const [selectedSubjects, setSelectedSubjects] = useState([]);
  const [subjects, setSubjects] = useState([]);
  // const [filteredSubjects, setFilteredSubjects] = useState([]);

  const navigate = useNavigate();

  const { data, refetch, isLoading, isError, error } =
    useGetProviderCitiesQuery({
      all: true,
      providerId: currentProvider?._id,
    });

  const [updateProviderCities, { isLoading: isLoadingUpdate }] =
    useUpdateProviderFieldMutation();

  useEffect(() => {
    if (Array.isArray(data?.rows)) {
      setSubjects(data.rows);
      const _selectedSubjects = data.rows
        .filter((sub) => sub?.count && sub?.count > 0)
        .map((sub) => sub.name);
      setSelectedSubjects(_selectedSubjects);
    }
  }, [data]);

  const saveHandler = async () => {
    let cities = [];
    const filtered = subjects.filter((sub) =>
      selectedSubjects.includes(sub.name)
    );

    for (let index = 0; index < filtered.length; index++) {
      const subject = filtered[index];
      const selectedCities = subject.cities
        .filter((item) => item?.checked)
        .map((item) => item._id);
      cities = cities.concat(selectedCities);
    }

    const toSend = {
      fieldName: 'cities',
      fieldValue: cities,
      _id: currentProvider?._id,
    };

    // console.log(toSend);
    try {
      await updateProviderCities(toSend).unwrap();
      //console.log(result);
      refetch();
      navigate('/provider/details/');
    } catch (error) {
      toast.error(error?.data?.message || error.error);
    }
  };

  const onChangeHandler = (cid, subjectName) => {
    const updatedArray = subjects.map((sub) => {
      if (sub.name === subjectName) {
        const newArray = sub.cities.map((item) =>
          item._id === cid ? { ...item, checked: !item?.checked } : item
        );
        return { ...sub, cities: newArray };
      } else {
        return JSON.parse(JSON.stringify(sub));
      }
    });

    setSubjects(updatedArray);
    setModified(true);
  };

  const buttons = [
    {
      name: `Сохранить`,
      action: saveHandler,
      className: 'btn-block',
      disabled: !modified,
      variant: modified && 'success',
    },
  ];

  const handleMultiSelectSubjects = (event, th) => {
    const {
      target: { value },
    } = event;
    const selected = typeof value === 'string' ? value.split(',') : value;
    setSelectedSubjects(selected); //для отображения в input поиска
  };

  // const handleRemoveSubject = (e) => {
  //   e.preventDefault();
  //   const subjectName = e.target.getAttribute('label');
  //   console.log(subjectName);
  //   const updatedArray = selectedSubjects.filter((sub) => sub !== subjectName);
  //   setSelectedSubjects(updatedArray);
  //   setModified(true);
  // };

  // console.log('selectedSubjects', selectedSubjects);
  // console.log('subjects', subjects);

  return (
    <>
      <ToolsPanel
        subtitle='Выберите города, до которых вы готовы организовать доставку'
        backURL={`/provider/details`}
        isLoading={isLoadingUpdate}
        buttons={buttons}
      />
      {isLoading ? (
        <></>
      ) : isError ? (
        <Message>{error?.data?.message || error.error}</Message>
      ) : (
        <>
          <Container>
            <Row>
              <Col>
                <MultiSelectInput
                  selectedItems={selectedSubjects}
                  handleMultiSelect={handleMultiSelectSubjects}
                  items={subjects}
                  label='Фильтр по регионам'
                  closeButton={true}
                />
                {Array.isArray(subjects) && subjects.length > 0 && (
                  <Form>
                    <ListGroup>
                      {subjects
                        .filter((sub) => selectedSubjects.includes(sub.name))
                        .map((sub, index) => (
                          <span key={index}>
                            <div className='mt-3 mb-1 ms-1'>{sub.name}</div>
                            {Array.isArray(sub?.cities) &&
                              sub.cities.map((item, indx) => (
                                <ListGroup.Item
                                  as={'div'}
                                  variant={theme}
                                  key={item._id || indx}
                                  className='d-flex justify-content-between align-items-start'
                                >
                                  <Form.Check // prettier-ignore
                                    checked={item?.checked || false}
                                    type='switch'
                                    label={item.name}
                                    onChange={() =>
                                      onChangeHandler(item._id, sub.name)
                                    }
                                  />
                                </ListGroup.Item>
                              ))}
                          </span>
                        ))}
                    </ListGroup>

                    <Button
                      type='button'
                      variant='primary'
                      className='my-2'
                      onClick={saveHandler}
                    >
                      Сохранить
                    </Button>
                  </Form>
                )}
              </Col>
            </Row>
          </Container>
        </>
      )}
    </>
  );
};

export default ProviderCitiesEditScreen;
