import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Form, ListGroup, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import Message from '../components/Message';
import ToolsPanel from '../components/ToolsPanel/ToolsPanel';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import {
  useGetContractsRoutesQuery,
  useUpdateContractRoutesMutation,
} from '../slices/routesApiSlice';

const ManagerBindRoutesScreen = () => {
  const { contractId } = useParams();
  const { theme, pov } = useSelector((state) => state.auth);
  const [itemList, setItemList] = useState([]);
  const [modified, setModified] = useState(false);
  const navigate = useNavigate();

  // console.log('contractId', contractId);
  const { data, refetch, isLoading, isError, error } =
    useGetContractsRoutesQuery({
      includeAll: true,
      contractId,
    });

  // console.log(data);

  const [update, { isLoading: isLoadingUpdate }] =
    useUpdateContractRoutesMutation();

  useEffect(() => {
    if (Array.isArray(data?.rows)) {
      // const updated = data.rows.map((row) => ({
      //   ...row,
      //   checked: row?.points?.length > 0,
      // }));
      setItemList(data.rows);
    }
  }, [data]);

  const saveHandler = async () => {
    // console.log(itemList);
    const toSend = {
      contractId,
      itemList,
    };
    try {
      await update(toSend).unwrap();
      //console.log(result);
      refetch();
      navigate(`/${pov}/manager/routes/`);
    } catch (error) {
      toast.error(error?.data?.message || error.error);
    }
  };

  const onChangeHandler = (id) => {
    setModified(true);
    const result = itemList.map((item) =>
      item._id === id ? { ...item, checked: !item.checked } : item
    );
    setItemList(result);
  };

  const buttons = [
    // {
    //   name: `Сохранить`,
    //   action: saveHandler,
    //   className: 'btn-block',
    //   disabled: !modified,
    //   variant: modified && 'success',
    // },
  ];

  return (
    <>
      <ToolsPanel
        title={`Маршруты :  ${data?.item?.customer?.name}`}
        backURL={`/${pov}/manager/routes`}
        // isLoading={isLoadingUpdate}
        buttons={buttons}
      />
      {isLoading ? (
        <></>
      ) : isError ? (
        <Message>{error?.data?.message || error.error}</Message>
      ) : (
        <>
          <Container>
            <Row>
              <Col>
                <Form>
                  <ListGroup>
                    {Array.isArray(itemList) &&
                      itemList.map((item) => (
                        <ListGroup.Item
                          as={'div'}
                          variant={theme}
                          key={item._id}
                          className='d-flex justify-content-between align-items-start'
                        >
                          <Form.Check // prettier-ignore
                            checked={item.checked}
                            type='switch'
                            label={item.name}
                            onChange={() => onChangeHandler(item._id)}
                          />
                        </ListGroup.Item>
                      ))}
                  </ListGroup>

                  <Button
                    type='button'
                    variant='primary'
                    className='my-2'
                    onClick={saveHandler}
                  >
                    Сохранить
                  </Button>
                </Form>
              </Col>
            </Row>
          </Container>
        </>
      )}
    </>
  );
};

export default ManagerBindRoutesScreen;
